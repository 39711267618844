import { Badge, Button, Layout, Modal, Spin } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import M24Breadcrumb from './Breadcrumb'
import PopoverDropdownUserActions from './DropdownUserActions'
import PopoverNotices from './PopoverNotices'
import { useTheme } from '../../../hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { categoryRepository } from 'src/repositories/CategoryRepository'
import SecurityService from 'src/utils/SecurityService'
import { newLineToBr } from 'src/utils/StringUtils'
import M24Notification from 'src/utils/M24Notification'

const { Header } = Layout
interface NavigationProps {
  title: string
  isShowNotice: boolean
  totalUnreadNotice: number
  visibleDropdownUserActions: boolean
  breadcrumbs?: any
  totalCart?: number
  logoutHandler: () => void
  onVisibleChangeNoticesHandler: (visible: boolean) => void
  currentConfigServices: any
}
const Navigation: React.FC<NavigationProps> = (props) => {
  const {
    isShowNotice,
    totalUnreadNotice,
    logoutHandler,
    breadcrumbs,
    onVisibleChangeNoticesHandler,
    visibleDropdownUserActions,
    totalCart,
    currentConfigServices,
  } = props
  const [theme] = useTheme()
  const [isShowFaq, setIsShowFaq] = useState(false)
  const [faqs, setFaqs] = useState('')
  const [loadingFaqs, setLoadingFaqs] = useState<boolean>(false)
  const { t } = useTranslation()

  const getFaqs = () => {
    if (faqs) return

    setLoadingFaqs(true)
    categoryRepository
      .getFaqsByShipment()
      .then((res) => {
        if (!res.content) {
          return
        }

        const user = SecurityService.getUser()
        setFaqs(
          `<span class='fsz-14 line-h-22'>${newLineToBr(res.content?.split('{username}').join(user.username))}</span>`
        )
      })
      .catch((e) => {
        M24Notification.notifyHttpError(e)
      })
      .finally(() => setLoadingFaqs(false))
  }

  return (
    <Header className="header">
      <div
        className={`dpl-flex  align-items-center ${
          breadcrumbs?.enable !== 'NO_BREAD_CRUMB' ? 'justify-content-between' : 'justify-content-end'
        }`}>
        {breadcrumbs?.enable !== 'NO_BREAD_CRUMB' && <M24Breadcrumb breadcrumbs={breadcrumbs} />}
        <div
          id={'header-right-content'}
          className={'header-right-content dpl-flex justify-content-between align-items-center'}>
          <div
            className="flex items-center mr-[40px] h-fit cursor-pointer text-[var(--blue-link)] select-none"
            onClick={() => {
              setIsShowFaq(true)
              getFaqs()
            }}>
            <i className="fa-regular fa-dollar-sign !text-[20px]"></i>
            <span className="inline-block ml-2 mr-1">Hướng dẫn nạp tiền</span>
            <div>
              <span className="rounded-full bg-[#FFEB97] px-[6px] py-[2px] text-[10px] font-medium text-[#1a1a1a]">
                Mới
              </span>
            </div>
          </div>

          <PopoverNotices
            totalUnreadNotice={totalUnreadNotice}
            isShowNotice={isShowNotice}
            onVisibleChangeHandler={onVisibleChangeNoticesHandler}
          />
          {currentConfigServices?.enableOrder && (
            <div className="flex align-items-center mg-l-12 mg-r-8">
              <Link
                to="/shopping-cart"
                className="robotomedium fsz-14 line-h-16 mg-r-5 pd-8">
                <Badge
                  count={totalCart}
                  overflowCount={99}
                  offset={[totalCart! > 99 ? 18 : 5, 0]}
                  className={`header-icon ${theme}`}>
                  <i className="fa-light fa-shopping-cart fsz-18 " />
                </Badge>
              </Link>
            </div>
          )}

          <PopoverDropdownUserActions
            visible={visibleDropdownUserActions}
            logoutHandler={logoutHandler}
          />
        </div>
      </div>

      <Modal
        width={600}
        title={<span className="fsz-16 line-h-24">{t('supplier.faqsCashCharged')}</span>}
        onCancel={() => setIsShowFaq(false)}
        open={isShowFaq}
        destroyOnClose
        centered
        className={`pd-12 faq-modal m22-modal ${theme}`}
        closeIcon={
          <i
            className="far fa-times"
            onClick={() => setIsShowFaq(false)}
          />
        }
        bodyStyle={{ overflowY: 'scroll' }}
        footer={
          <Button
            key="submit"
            type="primary"
            className={`m22-btn ${theme}`}
            onClick={() => setIsShowFaq(false)}>
            {t('supplier.closeFaqs')}
          </Button>
        }>
        <Spin spinning={loadingFaqs}>
          <span
            className="white-space-pre-ln"
            dangerouslySetInnerHTML={{
              __html: faqs,
            }}
          />
        </Spin>
      </Modal>
    </Header>
  )
}

export default Navigation
