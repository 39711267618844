import {useCallback, useEffect, useState} from "react";

const useFetch = <T,C>(fn: (filter?: C | any) => Promise<any>, onSuccess?: (data: any | T[]) => void) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>();
    const [data, setData] = useState<any | T[] >();

    const getData = useCallback((filter?:any) => {
            setLoading(true);
            let cancel = false;
            fn(filter).then((response) => {
                if (cancel) return;
                setLoading(false);
                setData(response.data);
                onSuccess?.(response.data)
            }, error => {
                if (cancel) return;
                setLoading(false);
                setError(error.response);
            })
        },
        [fn, onSuccess],
    );

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {loading, error, data};
}

export default useFetch;
