import { Form, FormInstance } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '../../hooks/useTheme'

export interface M22ComboBoxProps {
  form?: FormInstance
  inputName?: string
  label: any
  value?: any
  isRange?: boolean
  labelRange?: string
  labelWidth?: number
  className?: string
  tooltip?: any
  required?: boolean
  isTextArea?: boolean
  labelClassName?: string
  isValue?: boolean
}

const ComboBox: React.FC<M22ComboBoxProps> = React.memo(({ form, inputName = '', ...props }) => {
  const [focus, setFocus] = useState(false)
  const [theme] = useTheme()
  const refLabel = useRef<any>(null)
  const [labelClass, setLabelClass] = useState('')

  const { children, label, isRange, labelRange, labelWidth, className, tooltip, required, isTextArea, labelClassName, isValue } = props

  const inputValue = Form.useWatch(inputName, form)

  const value = useMemo(() => {
    if (inputName) return inputValue
    return props.value || ''
  }, [inputName, inputValue, props.value])

  useEffect(() => {
    let labelClass
    if (!isRange) {
      labelClass = focus || isValue || (value && value.length !== 0) ? 'label label-float' : 'label'
    } else {
      labelClass = focus || (value && value.length !== 0 && value[0]) ? 'label label-float' : 'label'
    }

    if (isTextArea) {
      labelClass += ' area-label'
    }
    setLabelClass(labelClass)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, isRange, isTextArea, value])

  const labelRangeClass = isRange && (focus || (value && value.length !== 0 && value[0] && value[1])) ? 'label-range label-range-float' : 'label-range'
  return (
    <div
      className={`float-label ${theme} ${className ?? ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}>
      {children}
      <label
        ref={refLabel}
        className={`${labelClass} ${isRange ? 'label-in-range' : ''} `}
        style={{ left: labelWidth ? labelWidth + 12 : 16, top: `${labelClassName}` }}>
        {label}
        {required && <span className={'txt-error'}>*</span>}
        {tooltip}
      </label>

      <label
        className={`${labelRangeClass} whitespace`}
        style={{ left: labelWidth ? `calc(${labelWidth / 2}px + 12px + 50%)` : `calc(50% + 16px)` }}>
        {labelRange}
      </label>
    </div>
  )
})

export default ComboBox
