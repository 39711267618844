import { Button, Col, Input, Row, Select, Spin } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { BoxChatContext } from '../../context/BoxChatContext'

interface FilterInterface {
  isRead?: boolean
  date?: string
  timestampFrom?: string
  timestampTo?: string
  type?: string
  referenceCode?: string
}

export const Thread = () => {
  const [filter, setFilter] = useState<FilterInterface>({
    isRead: undefined,
    date: undefined,
    type: undefined,
    referenceCode: undefined,
  })
  const { threadLoading, onFilterThreads, onThreadScroll } = useContext(BoxChatContext)
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchState, setSearchState] = useState<any>()
  const [selectedAllStatus, setSelectedAllStatus] = useState<any>(true)

  const dates = [
    { code: '1week', name: t('chatBox.filterTimestamp1week') },
    { code: '3weeks', name: t('chatBox.filterTimestamp3weeks') },
    { code: '1month', name: t('chatBox.filterTimestamp1month') },
    { code: '2months', name: t('chatBox.filterTimestamp2months') },
    { code: '3months', name: t('chatBox.filterTimestamp3months') },
  ]

  const threadType = [
    { code: 'ORDER_COMMENT', name: t('chatBox.thread-type-order') },
    { code: 'CLAIM_COMMENT', name: t('chatBox.thread-type-claim') },
    { code: 'SHIPMENT_COMMENT', name: t('chatBox.thread-type-shipment') },
  ]

  const handleFilterChanged = (field: string, value: any) => {
    setFilter((prevState) => {
      const newFilter = { ...prevState, [field]: value }

      if (field === 'date') {
        if (value === '1week') {
          newFilter.timestampFrom = moment().subtract(1, 'week').toISOString()
          newFilter.timestampTo = moment().toISOString()
        } else if (value === '3weeks') {
          newFilter.timestampFrom = moment().subtract(3, 'weeks').toISOString()
          newFilter.timestampTo = moment().toISOString()
        } else if (value === '1month') {
          newFilter.timestampFrom = moment().subtract(1, 'month').toISOString()
          newFilter.timestampTo = moment().toISOString()
        } else if (value === '2months') {
          newFilter.timestampFrom = moment().subtract(2, 'months').toISOString()
          newFilter.timestampTo = moment().toISOString()
        } else if (value === '3months') {
          newFilter.timestampFrom = moment().subtract(3, 'months').toISOString()
          newFilter.timestampTo = moment().toISOString()
        } else if (!value) {
          delete newFilter.timestampFrom
          delete newFilter.timestampTo
        }
      }

      if (field === 'date' || field === 'isRead' || field === 'type') {
        onFilterThreads({
          ...newFilter,
        })
      }

      return { ...newFilter }
    })
  }

  const handleScroll = (e: any) => {
    onThreadScroll(e)
  }

  const handleInputSearch = useCallback(() => {
    onFilterThreads({
      ...filter,
    })
  }, [filter, onFilterThreads])

  const handleClearInputSearch = () => {
    setFilter({
      ...filter,
      referenceCode: undefined,
    })
    onFilterThreads({
      ...filter,
      referenceCode: undefined,
    })
  }

  useEffect(() => {
    onFilterThreads({
      ...filter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <div className="box-chat-thread">
      <Spin spinning={threadLoading}>
        <div className={'pdbt16 px-16'}>
          <div
            id="box-chat-thread__filter"
            className="box-chat-thread__filter">
            <Input
              className={'box-chat-thread-input'}
              placeholder={t('chatBox.search-placeholder')}
              size="large"
              suffix={
                <i
                  className="fa-regular fa-magnifying-glass fsz-14 cursor-pointer"
                  onClick={handleInputSearch}
                />
              }
              allowClear={{
                clearIcon: (
                  <i
                    className="fa-regular fa-xmark fsz-14 pd-t-3 cursor-pointer"
                    onClick={handleClearInputSearch}
                  />
                ),
              }}
              onChange={(event) => {
                setSearchState(event.target.value)
                setFilter((prevState: any) => ({ ...prevState, referenceCode: event.target.value }))
                handleInputSearch()
              }}
              onPaste={(event) => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  referenceCode: event.clipboardData.getData('text'),
                }))
              }}
              onPressEnter={handleInputSearch}
            />
          </div>

          <Row
            gutter={[16, 16]}
            className="pd-t-16">
            <Col span={14}>
              <Select
                size={'large'}
                placeholder={t('chatBox.filterTimestamp')}
                value={filter.date}
                onChange={(value) => handleFilterChanged('date', value)}
                suffixIcon={<i className="fa-regular fa-angle-down"></i>}
                className={'box-chat-thread__filter__item'}
                allowClear
                //@ts-ignore
                getPopupContainer={() => document.getElementById('box-chat-thread__filter')}>
                {dates.map((d) => (
                  <Select.Option
                    key={d.code}
                    value={d.code}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={10}>
              <Select
                size={'large'}
                placeholder={t('chatBox.filter-thread-type')}
                suffixIcon={<i className="fa-regular fa-angle-down"></i>}
                value={filter.type}
                allowClear={true}
                onChange={(value) => handleFilterChanged('type', value)}
                className={'box-chat-thread__filter__item'}
                //@ts-ignore
                getPopupContainer={() => document.getElementById('box-chat-thread__filter')}>
                {threadType.map((s) => (
                  <Select.Option
                    key={s.name}
                    value={s.code}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <div
            id="box-chat-thread__filter"
            className="pd-t-16">
            <Button
              className={`status-filter-btn ${selectedAllStatus ? 'status-filter-btn_selected' : ''}`}
              type="text"
              size="small"
              onClick={() => {
                setSelectedAllStatus(true)
                handleFilterChanged('isRead', '')
              }}>
              {t('chatBox.filterStatusAll')}
            </Button>

            <Button
              className={`mg-l-16 status-filter-btn ${!selectedAllStatus ? 'status-filter-btn_selected' : ''}`}
              size="small"
              type="text"
              onClick={() => {
                setSelectedAllStatus(false)
                handleFilterChanged('isRead', 'false')
              }}>
              {t('chatBox.filterStatusUnRead')}
            </Button>
          </div>
        </div>
        <div
          className="box-chat-thread__threads"
          onScroll={handleScroll}>
          {/* {threads.map((item) => (
            <ThreadItem
              key={item.id}
              thread={item}
            />
          ))} */}
        </div>
      </Spin>
    </div>
  )
}
