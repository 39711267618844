import React from "react";
import {ClaimInterface} from "../Interface/ClaimInterface";
import {ClaimModel} from "../model/ClaimModel";
import {ProviderInterface} from "../Interface/ProviderInterface";
import {ProviderModel} from "../model/ProviderModel";
import {ClaimStateInterface} from "../Interface/ClaimStateInterface";

export interface ClaimDetailContextInterface {
    claim: ClaimInterface
    provider: ProviderInterface
    statuses: ClaimStateInterface[]
    providerUsername: string
    onRatingSuccess: () => void
    onArchiveSuccess: () => void
}

export const ClaimDetailContext = React.createContext<ClaimDetailContextInterface>({
    claim: ClaimModel,
    providerUsername: "",
    provider: ProviderModel,
    statuses: [],
    onRatingSuccess: () => {},
    onArchiveSuccess: () => {}
})
