import { Repository } from '../core/repositories/Repository';
import { url } from '../core/helpers/string';
import { httpConfig } from '../core/config/http';
import { API_BASE_URL } from '../core/config';
import { IBodySaveService } from 'src/Interface/WarehoustListInterface';

export class WarehouseRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.setBaseURL(url(API_BASE_URL, ''));
  }

  public saveService(body: IBodySaveService) {
    return this.http.post('/warehouse-service/config', body);
  }
  
  getServiceSaved() {
    return this.http.get('/warehouse-service');
  }
}

export const warehouseRepository: WarehouseRepository =
  new WarehouseRepository();
