import lodash from 'lodash'
import {
  EMPTY_INFO,
  SHIPMENT_DECLARE_VALUE_CREATE,
  SHIPMENT_DECLARE_VALUE_DELETE,
  SHIPMENT_DECLARE_VALUE_UPDATE,
  SHIPMENT_FEE_UPDATE,
  SHIPMENT_PRODUCT_CREATE,
  SHIPMENT_PRODUCT_UPDATE,
  SHIPMENT_RECEIPT_DELETE,
  SHIPMENT_UPDATE,
  SHIPMENT_WAYBILL_CREATE,
  SHIPMENT_WAYBILL_DELETE,
} from '../core/config'
import { formatMoneyByUnit, formatNumber } from '../core/helpers/string'

export const parseOrderLog = (t: any, items: any, orderCurrencyNotUsed: string, isShipment?: boolean) => {
  const orderCurrency = undefined
  let logs: any = []
  try {
    items.forEach((item: any) => {
      let itemTransform: any = {}
      itemTransform.fullname = item.actor.fullname ? item.actor.fullname : '---'
      itemTransform.timestamp = item.timestamp ? item.timestamp : '---'
      itemTransform.role = item.role
      if (
        isShipment &&
        item.activity !== SHIPMENT_RECEIPT_DELETE &&
        item.activity !== SHIPMENT_PRODUCT_CREATE &&
        item.activity !== SHIPMENT_PRODUCT_UPDATE &&
        item.activity !== SHIPMENT_WAYBILL_CREATE &&
        item.activity !== SHIPMENT_DECLARE_VALUE_UPDATE &&
        item.activity !== SHIPMENT_FEE_UPDATE &&
        item.activity !== SHIPMENT_UPDATE &&
        item.activity !== SHIPMENT_DECLARE_VALUE_CREATE &&
        item.activity !== SHIPMENT_DECLARE_VALUE_DELETE &&
        item.activity !== SHIPMENT_WAYBILL_DELETE
      ) {
        item.activity = item.activity.replace('SHIPMENT', 'ORDER')
      }
      switch (item.activity) {
        case 'ORDER_CREATE':
        case 'ORDER_RECEIPT_CREATE':
        case 'ORDER_RECEIPT_DELETE':
          itemTransform.property = item.activity
          itemTransform.value = item.data.code
          break
        case 'ORDER_PRODUCT_UPDATE':
          // , purchasingStaff,  actualPrice(don gia), staffRemark(ghi chu)
          if (item.data.length > 0) {
            // purchasedQuantity, receivedQuantity,
            if (Array.isArray(item.data)) itemTransform.logs = []
            item.data.forEach((ele: any, index: number) => {
              if (ele.property === 'purchasedQuantity') {
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  name: item.reference.code,
                  property: ele.property,
                  oldValue: ele.oldValue
                    ? formatNumber(ele.oldValue)
                    : ele.oldValue === 0
                    ? t('log_order.out_of_stock')
                    : t('log_order.not_buy'),
                  newValue:
                    ele.newValue === null
                      ? t('log_order.not_buy')
                      : ele.newValue.toString() === (0).toString()
                      ? t('log_order.out_of_stock')
                      : formatNumber(ele.newValue),
                })
              }
              if (ele.property === 'staffRemark') {
                let oldRemark = ele.oldValue && ele.oldValue.toString().replace(/↵+/g, '').trim()
                let newRemark = ele.newValue && ele.newValue.toString().replace(/↵+/gm, '').trim()

                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  name: item.reference.code,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: ele.property,
                  oldValue: !oldRemark || oldRemark === '' ? t('log_order.empty') : oldRemark,
                  newValue: !newRemark || newRemark === '' ? t('log_order.empty') : newRemark,
                })
              } else if (ele.property === 'actualPrice') {
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  name: item.reference.code,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: ele.property,
                  oldValue: formatMoneyByUnit(ele.oldValue, item.reference.currency),
                  newValue: formatMoneyByUnit(ele.newValue, item.reference.currency),
                })
              } else if (ele.property === 'confirm') {
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  name: item.reference.code,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: ele.property,
                  confirm: ele.newValue.confirm ? t('log_order.agree') : t('log_order.reject'),
                  newValue:
                    ele.newValue.property === 'actualPrice'
                      ? formatMoneyByUnit(lodash.get(ele, 'newValue.newValue', ''), item.reference.currency)
                      : ele.newValue.newValue,
                  type:
                    ele.newValue.property === 'actualPrice'
                      ? t('log_order.sale_price')
                      : t('log_order.purchasedQuantity_label'),
                })
              }
            })
          }
          break
        case 'ORDER_UPDATE':
          if (Array.isArray(item.data) && item.data.length > 0) {
            itemTransform.property = lodash.get(item.data, '0.property')
            if (lodash.get(item.data, '0.property') === 'merchantContacts') {
              if (item.data[0].oldValue) {
                itemTransform.oldValue = !lodash.isEmpty(item.data[0].oldValue.aliwangwang)
                  ? item.data[0].oldValue.aliwangwang
                  : t('log_order.empty')
              } else itemTransform.oldValue = t('log_order.empty')

              if (item.data[0].newValue) {
                itemTransform.newValue = !lodash.isEmpty(item.data[0].newValue.aliwangwang)
                  ? item.data[0].newValue.aliwangwang
                  : t('log_order.empty')
              } else itemTransform.newValue = t('log_order.empty')
            } else if (item.data[0].property === 'purchasingStaff') {
              itemTransform.oldValue = item.data[0].oldValue ? item.data[0].oldValue.code : t('log_order.empty')
              itemTransform.newValue = item.data[0].newValue ? item.data[0].newValue.code : t('log_order.empty')
            } else if (item.data[0].property === 'merchantShippingCost') {
              itemTransform.oldValue = item.data[0].oldValue
                ? formatMoneyByUnit(item.data[0].oldValue, orderCurrency)
                : item.data[0].oldValue === 0
                ? t('log_order.free')
                : t('log_order.empty')
              itemTransform.newValue = item.data[0].newValue
                ? formatMoneyByUnit(item.data[0].newValue, orderCurrency)
                : item.data[0].newValue === 0
                ? t('log_order.free')
                : t('log_order.empty')
            } else if (
              item.data[0].property === 'deliveryWarehouse' ||
              item.data[0].property === 'receivingWarehouse'
            ) {
              // deliveryWarehouse, receivingWarehouse
              itemTransform.oldValue = lodash.get(item.data[0].oldValue, 'name', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item.data[0].newValue, 'name', t('log_order.empty'))
            } else if (item.data[0].property === 'emdPercent') {
              itemTransform.property = 'ORDER_UPDATE_EMD'
              itemTransform.oldValue = formatNumber(lodash.get(item, 'data[0].oldValue', 0))
              itemTransform.newValue = formatNumber(lodash.get(item, 'data[0].newValue', 0))
              itemTransform.name = item.reference ? item.reference.code : null
            } else if (item.data[0].property === 'note') {
              itemTransform.oldValue = lodash.get(item, 'data[0].oldValue', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item, 'data[0].newValue', t('log_order.empty'))
            } else {
              //purchasingAccount
              itemTransform.oldValue = lodash.get(item, 'data[0].oldValue', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item, 'data[0].newValue', t('log_order.empty'))
            }
          }

          break
        case 'ORDER_ADDRESS_UPDATE':
          if (Array.isArray(item.data)) itemTransform.logs = []
          item.data.forEach((ele: any, index: number) => {
            if (ele.property === 'location' && ele.oldValue.display !== ele.newValue.display) {
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                timestamp: item.timestamp ? item.timestamp : '---',
                property: 'ORDER_ADDRESS_UPDATE_LOCATION',
                oldValue: lodash.get(ele, 'oldValue.display', t('log_order.empty')),
                newValue: lodash.get(ele, 'newValue.display', t('log_order.empty')),
              })
            } else if (ele.property === 'note') {
              if (
                (ele.oldValue && ele.oldValue.toString().trim() !== ele.newValue.toString().trim()) ||
                (!ele.oldValue && ele.newValue && ele.newValue.toString().trim() !== '')
              )
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: 'ORDER_ADDRESS_UPDATE_note',
                  oldValue: ele.oldValue && ele.oldValue.trim() !== '' ? ele.oldValue : t('log_order.empty'),
                  newValue: ele.newValue.trim() !== '' ? ele.newValue : t('log_order.empty'),
                })
            } else if (ele.property && ele.oldValue && ele.oldValue.toString() !== ele.newValue.toString()) {
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                timestamp: item.timestamp ? item.timestamp : '---',
                property: `ORDER_ADDRESS_UPDATE_${ele.property}`,
                oldValue: ele.oldValue,
                newValue: ele.newValue,
              })
            }
          })
          break
        case 'ORDER_STATUS_UPDATE':
          itemTransform.property = item.activity
          if (item.data && Array.isArray(item.data)) {
            itemTransform.newValue = lodash.get(item.data[0].newValue, 'name', '---')
            itemTransform.oldValue = lodash.get(item.data[0].oldValue, 'name', '---')
          } else {
            itemTransform.newValue = lodash.get(item.data.newValue, 'name', '---')
            itemTransform.oldValue = lodash.get(item.data.oldValue, 'name', '---')
          }
          break
        case 'ORDER_CANCELLED':
        case 'ORDER_FEE_CALCULATE_ALL':
          itemTransform.property = item.activity
          itemTransform.name = item.data.code
          break
        case 'ORDER_CONFIRM_RECEIVED':
          // itemTransform.property = item.activity;
          // itemTransform.newValue = lodash.get(lodash.filter(statuses, {code: item.data.newValue}), '0.name', '');
          // itemTransform.oldValue = lodash.get(lodash.filter(statuses, {code: item.data.oldValue}), '0.name', '');
          break
        case 'ORDER_FEE_CREATED':
          itemTransform.property = item.activity
          itemTransform.value = item.data.type.name
          itemTransform.amount = formatMoneyByUnit(lodash.get(item, 'data.actualAmount', null))
          itemTransform.reason = item.data.reason
          break
        case 'ORDER_FEE_UPDATE':
          itemTransform.logs = []
          item.data.forEach((ele: any, index: number) => {
            itemTransform.name = lodash.get(item.reference, 'type.name', '---')
            if (ele.property === 'reason') {
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                timestamp: item.timestamp ? item.timestamp : '---',
                oldValue: ele.oldValue || t('log_order.empty'),
                newValue: ele.newValue || '---',
                name: lodash.get(item.reference, 'type.name', '---'),
                property: 'ORDER_FEE_UPDATE_REASON',
              })
            }
            if (ele.property === 'free' && ele.newValue !== ele.oldValue) {
              if (ele.newValue === true) itemTransform.value = t('log_order.free')
              else if (ele.newValue === false) itemTransform.value = t('log_order.cancel_free')
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                name: itemTransform.name,
                timestamp: item.timestamp ? item.timestamp : '---',
                reason: itemTransform.reason,
                value: itemTransform.value,
                property: 'ORDER_FEE_UPDATE_FREE',
              })
            }
            if (ele.property === 'amount') {
              itemTransform.property = 'ORDER_FEE_UPDATE_AMOUNT'
              itemTransform.newValue = formatMoneyByUnit(ele.newValue)
              itemTransform.oldValue = formatMoneyByUnit(ele.oldValue)
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                name: itemTransform.name,
                reason: itemTransform.reason,
                timestamp: item.timestamp ? item.timestamp : '---',
                newValue: itemTransform.newValue,
                oldValue: itemTransform.oldValue,
                property: 'ORDER_FEE_UPDATE_AMOUNT',
              })
            }
            if (ele.property === 'actualPrice') {
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                name: itemTransform.name,
                reason: itemTransform.reason,
                timestamp: item.timestamp ? item.timestamp : '---',
                newValue: formatMoneyByUnit(ele.newValue),
                oldValue: formatMoneyByUnit(ele.oldValue),
                property: 'ORDER_FEE_UPDATE_AMOUNT_ACTUAL',
              })
            }
          })
          break
        case 'ORDER_TAG_CREATE':
          break
        case 'ORDER_TAG_DELETE':
          itemTransform.property = itemTransform.storageDescription ? 'ORDER_TAG_CREATE_DESCRIPTION' : item.activity
          if (item.data.description) itemTransform.storageDescription = item.data.description
          else itemTransform.reason = t('log_order.empty')
          itemTransform.value = lodash.get(item.data.tag, 'name', '')
          break
        case 'ORDER_TRACKING_CREATE':
        case 'ORDER_PACKAGE_CREATE':
        case 'ORDER_PACKAGE_DELETE':
        case 'ORDER_TRACKING_DELETE':
          itemTransform.property = item.activity
          if (item.activity === 'ORDER_TRACKING_DELETE')
            itemTransform.value = lodash.get(item, 'data[0].code', t('log_order.empty'))
          else itemTransform.value = lodash.get(item, 'data.code', t('log_order.empty'))
          break
        case 'ORDER_PACKAGE_UPDATE':
          if (Array.isArray(item.data)) itemTransform.logs = []
          if (item.data && Array.isArray(item.data)) {
            item.data.forEach((ele: any) => {
              if (ele.property) {
                let unit = ''
                if (ele.property === 'volumetric') unit = 'cm3'
                else if (
                  ele.property === 'netWeight' ||
                  ele.property === 'dimensionalWeight' ||
                  ele.property === 'packagingWeight' ||
                  ele.property === 'actualWeight'
                ) {
                  unit = 'kg'
                } else if (ele.property === 'length' || ele.property === 'width' || ele.property === 'height') {
                  unit = ' cm'
                }
                let itemTemp = {
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: `ORDER_PACKAGE_UPDATE_${ele.property}`,
                  name: item.reference.code && item.reference.code,
                  oldValue: ele.oldValue
                    ? lodash.isNumber(ele.oldValue)
                      ? formatNumber(ele.oldValue) + unit
                      : ele.property === 'status'
                      ? lodash.get(ele.oldValue, 'name', '---')
                      : ele.oldValue
                    : ele.oldValue === 0
                    ? 0 + unit
                    : '---',
                  newValue: ele.newValue
                    ? lodash.isNumber(ele.newValue)
                      ? formatNumber(ele.newValue) + unit
                      : ele.property === 'status'
                      ? lodash.get(ele.newValue, 'name', '---')
                      : ele.newValue
                    : ele.newValue === 0
                    ? 0 + unit
                    : '---',
                }
                if (ele.property === 'status' && !ele.oldValue.hasOwnProperty('name')) {
                  itemTemp.oldValue = ele.oldValue.name
                  itemTemp.newValue = ele.newValue.name
                }
                itemTransform.logs.push(itemTemp)
              }
            })
          } else {
            itemTransform.property = `ORDER_PACKAGE_UPDATE_${item.data.property}`
            itemTransform.oldValue =
              item.data.property === 'status' || item.property === 'status'
                ? lodash.get(item.data.oldValue, 'name', '---')
                : '---'
            itemTransform.newValue =
              item.data.property === 'status' || item.property === 'status'
                ? lodash.get(item.data.newValue, 'name', '---')
                : '---'
            itemTransform.name = item.reference.code
          }
          break
        case 'ORDER_SERVICE_UPDATE':
          itemTransform.logs = []
          if (lodash.get(item.data, '0.type') === 'SIMPLE_VALUE_CHANGE') {
            itemTransform.logs.push({
              fullname: item.actor.fullname ? item.actor.fullname : '---',
              role: item.role,
              timestamp: item.timestamp ? item.timestamp : '---',
              property: `${item.activity}_SIMPLE_VALUE_CHANGE`,
            })
          } else {
            lodash.get(item.data, '0.addedValues').forEach((ele: any, index: number) => {
              if (ele)
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: `${item.activity}_ADD`,
                  addValue: ele.name,
                })
            })
            lodash.get(item.data, '0.removedValues').forEach((ele: any, index: number) => {
              if (ele)
                itemTransform.logs.push({
                  fullname: item.actor.fullname ? item.actor.fullname : '---',
                  role: item.role,
                  timestamp: item.timestamp ? item.timestamp : '---',
                  property: `${item.activity}_REMOVE`,
                  removeValue: ele.name,
                })
            })
          }
          break
        case 'ORDER_SERVICE_APPROVED':
          itemTransform.logs = []
          item.data.forEach((ele: any) => {
            itemTransform.logs.push({
              fullname: item.actor.fullname ? item.actor.fullname : '---',
              role: item.role,
              timestamp: item.timestamp ? item.timestamp : '---',
              property: ele.newValue ? `${item.activity}_ADD` : `${item.activity}_REMOVE`,
              service: item.reference.name,
            })
          })
          break
        case SHIPMENT_PRODUCT_CREATE:
          itemTransform.logs = []
          let eventProdAdd: any = {}
          eventProdAdd.fullname = item.actor.fullname ?? '---'
          eventProdAdd.role = item.role
          eventProdAdd.timestamp = item.timestamp ?? '---'
          eventProdAdd.property = `shipment_${item.activity}`
          eventProdAdd.productCode = item.data.code
          eventProdAdd.productName = item.data.name
          itemTransform.logs.push(eventProdAdd)
          break
        case SHIPMENT_PRODUCT_UPDATE:
          itemTransform.logs = []
          console.log('item', item)
          item.data.forEach((ele: any) => {
            const logItem = {
              fullname: item.actor.fullname ? item.actor.fullname : '---',
              role: item.role,
              timestamp: item.timestamp ? item.timestamp : '---',
              oldValue: ele.oldValue || t('log_order.empty'),
              newValue: ele.newValue || '---',
              name: lodash.get(item.reference, 'code', '---'),
              property: '',
            }
            if (ele.property === 'name') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_NAME'
            }

            if (ele.property === 'translatedName') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_TRANSLATEDNAME'
            }

            if (ele.property === 'quantity') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_QUANTITY'
            }

            if (ele.property === 'unitPrice') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_UNITPRICE'
            }

            if (ele.property === 'productImage') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_IMAGE'
            }
            if (ele.property === 'merchantName') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_MERCHANT_NAME'
            }
            if (ele.property === 'merchantContact') {
              logItem.property = 'SHIPMENT_PRODUCT_UPDATE_MERCHANT_CONTACT'
            }
            itemTransform.logs.push(logItem)
          })
          break
        case SHIPMENT_WAYBILL_CREATE:
          itemTransform.logs = []
          let eventWaybillAdd: any = {}
          eventWaybillAdd.fullname = item.actor.fullname ?? '---'
          eventWaybillAdd.role = item.role
          eventWaybillAdd.timestamp = item.timestamp ?? '---'
          eventWaybillAdd.property = `shipment_${item.activity}`
          eventWaybillAdd.waybillCode = item.data.code
          itemTransform.logs.push(eventWaybillAdd)
          break
        case SHIPMENT_DECLARE_VALUE_UPDATE:
          itemTransform.logs = []
          for (const change of item.data) {
            const eventDeclareValueUpdate: any = {}
            eventDeclareValueUpdate.fullname = item.actor.fullname ?? '---'
            eventDeclareValueUpdate.role = item.role
            eventDeclareValueUpdate.timestamp = item.timestamp ?? '---'
            eventDeclareValueUpdate.property = `shipment_${item.activity}_${change.property}`
            eventDeclareValueUpdate.oldValue = change.oldValue ? formatMoneyByUnit(change.oldValue) : EMPTY_INFO
            eventDeclareValueUpdate.newValue = change.newValue ? formatMoneyByUnit(change.newValue) : EMPTY_INFO
            itemTransform.logs.push(eventDeclareValueUpdate)
          }
          break
        case SHIPMENT_FEE_UPDATE:
          itemTransform.logs = []
          item.data.forEach((ele: any, index: number) => {
            itemTransform.name = lodash.get(item.reference, 'type.name', '---')
            if (ele.property === 'reason') {
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                timestamp: item.timestamp ? item.timestamp : '---',
                oldValue: ele.oldValue || t('log_order.empty'),
                newValue: ele.newValue || '---',
                name: lodash.get(item.reference, 'type.name', '---'),
                property: 'ORDER_FEE_UPDATE_REASON',
              })
            } else if (ele.property === 'free' && ele.newValue !== ele.oldValue) {
              if (ele.newValue === true) itemTransform.value = t('log_order.free')
              else if (ele.newValue === false) itemTransform.value = t('log_order.cancel_free')
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                name: itemTransform.name,
                timestamp: item.timestamp ? item.timestamp : '---',
                reason: itemTransform.reason,
                value: itemTransform.value,
                property: 'ORDER_FEE_UPDATE_FREE',
              })
            }
            if (ele.property === 'amount' || ele.property === 'actualPrice') {
              itemTransform.property = 'ORDER_FEE_UPDATE_AMOUNT'
              itemTransform.newValue = ele.newValue ? formatMoneyByUnit(ele.newValue, orderCurrency) : EMPTY_INFO
              itemTransform.oldValue = ele.oldValue ? formatMoneyByUnit(ele.oldValue, orderCurrency) : EMPTY_INFO
              itemTransform.logs.push({
                fullname: item.actor.fullname ? item.actor.fullname : '---',
                role: item.role,
                name: itemTransform.name,
                reason: itemTransform.reason,
                timestamp: item.timestamp ? item.timestamp : '---',
                newValue: itemTransform.newValue,
                oldValue: itemTransform.oldValue,
                property: 'ORDER_FEE_UPDATE_AMOUNT',
              })
            }
          })
          break
        case SHIPMENT_RECEIPT_DELETE:
          itemTransform.logs = []
          for (const change of item.data) {
            const eventDeclareValueUpdate: any = {}
            eventDeclareValueUpdate.fullname = item.actor.fullname ?? '---'
            eventDeclareValueUpdate.role = item.role
            eventDeclareValueUpdate.timestamp = item.timestamp ?? '---'
            eventDeclareValueUpdate.property = `shipment_${item.activity}`
            eventDeclareValueUpdate.originalCode = change.code
            itemTransform.logs.push(eventDeclareValueUpdate)
          }
          break
        case SHIPMENT_DECLARE_VALUE_CREATE:
          itemTransform.logs = []
          const eventDeclareValueUpdate: any = {}
          eventDeclareValueUpdate.fullname = item.actor.fullname ?? '---'
          eventDeclareValueUpdate.role = item.role
          eventDeclareValueUpdate.timestamp = item.timestamp ?? '---'
          eventDeclareValueUpdate.property = `shipment_${item.activity}`
          eventDeclareValueUpdate.declareValue = item.data.declareValue
            ? formatMoneyByUnit(item.data.declareValue, orderCurrency)
            : EMPTY_INFO
          itemTransform.logs.push(eventDeclareValueUpdate)
          break
        case SHIPMENT_DECLARE_VALUE_DELETE:
          itemTransform.logs = []
          const eventDeclareValueDelete: any = {}
          eventDeclareValueDelete.fullname = item.actor.fullname ?? '---'
          eventDeclareValueDelete.role = item.role
          eventDeclareValueDelete.timestamp = item.timestamp ?? '---'
          eventDeclareValueDelete.property = `shipment_${item.activity}`
          itemTransform.logs.push(eventDeclareValueDelete)
          break
        case SHIPMENT_UPDATE:
          if (Array.isArray(item.data) && item.data.length > 0) {
            itemTransform.property = lodash.get(item.data, '0.property')
            if (item.data[0].property === 'merchantContacts') {
              if (item.data[0].oldValue) {
                itemTransform.oldValue = !lodash.isEmpty(item.data[0].oldValue.aliwangwang)
                  ? item.data[0].oldValue.aliwangwang
                  : t('log_order.empty')
              } else itemTransform.oldValue = t('log_order.empty')

              if (item.data[0].newValue) {
                itemTransform.newValue = !lodash.isEmpty(item.data[0].newValue.aliwangwang)
                  ? item.data[0].newValue.aliwangwang
                  : t('log_order.empty')
              } else itemTransform.newValue = t('log_order.empty')
            }

            if (item.data[0].property === 'purchasingStaff') {
              itemTransform.oldValue = item.data[0].oldValue ? item.data[0].oldValue.code : t('log_order.empty')
              itemTransform.newValue = item.data[0].newValue ? item.data[0].newValue.code : t('log_order.empty')
            }

            if (item.data[0].property === 'merchantShippingCost') {
              itemTransform.oldValue = item.data[0].oldValue
                ? formatMoneyByUnit(item.data[0].oldValue, orderCurrency)
                : item.data[0].oldValue === 0
                ? t('log_order.free')
                : t('log_order.empty')
              itemTransform.newValue = item.data[0].newValue
                ? formatMoneyByUnit(item.data[0].newValue, orderCurrency)
                : item.data[0].newValue === 0
                ? t('log_order.free')
                : t('log_order.empty')
            }

            if (item.data[0].property === 'hsCode') {
              const oldValue = item.data[0]?.oldValue
                ? t(`log_order.hsCode_${item.data[0]?.oldValue}`)
                : t('log_order.empty')
              const newValue = item.data[0]?.newValue
                ? t(`log_order.hsCode_${item.data[0]?.newValue}`)
                : t('log_order.empty')
              itemTransform.oldValue = oldValue
              itemTransform.newValue = newValue
            } else if (
              item.data[0].property === 'deliveryWarehouse' ||
              item.data[0].property === 'receivingWarehouse'
            ) {
              // deliveryWarehouse, receivingWarehouse
              itemTransform.oldValue = lodash.get(item.data[0], 'oldValue', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item.data[0], 'newValue', t('log_order.empty'))
            }

            if (item.data[0].property === 'refCustomerCode' || item.data[0].property === 'refShipmentCode') {
              itemTransform.oldValue = item.data[0].oldValue ? item.data[0].oldValue : EMPTY_INFO
              itemTransform.newValue = item.data[0].newValue ? item.data[0].newValue : EMPTY_INFO
            }

            if (item.data[0].property === 'emdPercent') {
              itemTransform.property = 'ORDER_UPDATE_EMD'
              itemTransform.oldValue = formatNumber(lodash.get(item, 'data[0].oldValue', 0))
              itemTransform.newValue = formatNumber(lodash.get(item, 'data[0].newValue', 0))
              itemTransform.name = item.reference ? item.reference.code : null
            }

            if (item.data[0].property === 'note') {
              itemTransform.oldValue = lodash.get(item, 'data[0].oldValue', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item, 'data[0].newValue', t('log_order.empty'))
            }

            if (item.data[0].property === 'staffNote') {
              //purchasingAccount
              itemTransform.oldValue = lodash.get(item, 'data[0].oldValue', t('log_order.empty'))
              itemTransform.newValue = lodash.get(item, 'data[0].newValue', t('log_order.empty'))
            }
          }

          break
        case SHIPMENT_WAYBILL_DELETE:
          itemTransform.waybillCode = item.data.code
          itemTransform.property = `shipment_${SHIPMENT_WAYBILL_DELETE}`
          break

        case 'payment':
        case 'claim':
        case 'emd':
        case 'refund':
        case 'collect':
        case 'gift':
        case 'deposit':
        case 'charge':
        case 'withdraw':
        case 'adjust':
          itemTransform.reason = item.memo ? item.memo : '---'
          itemTransform.property = item.activity
          if (item.activity === 'emd')
            itemTransform.amount = item.amount && formatMoneyByUnit(item.amount).toString().replace('-', '')
          else itemTransform.amount = item.amount && formatMoneyByUnit(item.amount)
          break
        case 'ORDER_COUPON_APPLY':
          itemTransform.property = item.activity
          itemTransform.content = item.data ? item.data.code + ' - ' + item.data.description : ''
          break
        case 'ORDER_UPDATE_EMD':
          itemTransform.oldValue = formatNumber(lodash.get(item, 'data[0].oldValue', 0))
          itemTransform.newValue = formatNumber(lodash.get(item, 'data[0].newValue', 0))
          itemTransform.property = item.activity
          itemTransform.name = item.reference ? item.reference.code : null
          break
        case 'ORDER_UPDATE_EXCHANGE_RATE':
          itemTransform.oldValue = formatMoneyByUnit(lodash.get(item, 'data[0].oldValue', 0))
          itemTransform.newValue = formatMoneyByUnit(lodash.get(item, 'data[0].newValue', 0))
          itemTransform.property = 'edit_exchange_rate'
          break
        case 'ORDER_UPDATE_INTERNAL_EXCHANGE_RATE':
          itemTransform.property = 'edit_internal_exchange_rate'
          break
        default:
          break
      }
      if (itemTransform.logs && itemTransform.logs.length > 0) logs.push(...itemTransform.logs)
      else if (itemTransform.property) logs.push(itemTransform)
    })
  } catch (e) {
    console.log(e)
  }

  return logs
}
