import { Modal, Button, Row, Input, Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { useTheme } from 'src/hooks/useTheme'
interface InlineEditProps {
  defaultValue?: any
  type?: 'input' | 'textarea' | 'textarea-modal'
  className?: string
  title: string
  loading?: boolean
  isInTab?: boolean
  editable?: boolean
  multiple?: boolean
  label?: string
  onSubmit?: (value: string, callback: () => void) => void
  onSubmitMultiple?: (label: string, value: string, callback: () => void) => void
}
const InlineEdit = (props: InlineEditProps) => {
  const { defaultValue, type, title, onSubmit, label, onSubmitMultiple } = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<any>(defaultValue)
  const [theme] = useTheme()
  const { t } = useTranslation()
  const handleCancel = () => {
    setShowModal(false)
    setValue(defaultValue)
  }
  const handleOk = () => {
    setLoading(true)
    if (onSubmitMultiple && label) {
      onSubmitMultiple(label, value, () => {
        setValue('')
        setShowModal(false)
        setLoading(false)
      })
    }
    if (onSubmit) {
      onSubmit(value, () => {
        setValue('')
        setShowModal(false)
        setLoading(false)
      })
    }
  }
  const clickEdit = () => {
    if (type === 'textarea-modal') {
      setShowModal(true)
      if (defaultValue) setValue(defaultValue)
    }
  }

  return (
    <div>
      <Row className={'dpl-flex align-items-center'}>
        {defaultValue && defaultValue.length > 30 ? (
          <Row
            className={'dpl-flex align-items-center'}
            align={'middle'}>
            <span className={`personal-note txt-size-h7 `}>{defaultValue ? defaultValue : '---'}</span>
            <Tooltip title={defaultValue}>
              <span>
                <i className="txt-size-h7 far fa-info-circle txt-color-orange2 mgl5"></i>
              </span>
            </Tooltip>
          </Row>
        ) : (
          <span className={`personal-note txt-size-h7 `}>{defaultValue ? defaultValue : '---'}</span>
        )}
        <span
          onClick={clickEdit}
          className={'mgl5 _note-icon-custom'}>
          <i className="far fa-edit txt-color-orange2 txt-size-h8"></i>
        </span>
      </Row>
      {showModal && type === 'textarea-modal' ? (
        <Modal
          open={showModal}
          title={title}
          onCancel={handleCancel}
          className="modal-inline-edit"
          closeIcon={<i className="fa-solid fa-xmark"></i>}
          footer={[
            <Button
              key="back"
              onClick={handleCancel}
              className={`m22-btn ${theme}`}
              type="text">
              {t('button.cancel')}
            </Button>,
            <Button
              type="primary"
              className="m22-btn"
              icon={<i className="fa-solid fa-check mg-r-10" />}
              key="submit"
              loading={loading}
              onClick={handleOk}>
              {t('button.submit')}
            </Button>,
          ]}>
          <Input.TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={10}
            autoFocus={true}
            placeholder={t('edit.enterPlaceholder')}
          />
        </Modal>
      ) : null}
    </div>
  )
}
InlineEdit.defaultProps = {
  type: 'input',
}
export default InlineEdit
