import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { t } from 'i18next'
import lodash, { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentStatusInterface from '../../Interface/ShipmentStatusInterface'
import ComboBox from '../../components/ComboBox'
import M22Box from '../../components/M22Box'
import { useTheme } from '../../hooks/useTheme'

const { Item } = Form
const { RangePicker } = DatePicker
interface FilterProps {
  filter?: any
  statuses?: any
  filterChangeHandler: (obj: any) => void
  handleFilter: (params: ShipmentsFilterInterface | {}) => void
}
const Filter: React.FC<FilterProps> = (props) => {
  const [theme] = useTheme()
  const { handleFilter, filter, filterChangeHandler, statuses } = props
  const [form] = Form.useForm()
  const [showFilter, setShowFilter] = useState(true)
  const [searchParams] = useSearchParams()
  const [checkedStatuses, setCheckedStatuses] = useState<string[]>(searchParams.get('statuses')?.split(',') || [])
  const [expand, setExpand] = useState(false)
  const [idConnectionSelected, setIdConnectionSelected] = useState()

  const code = Form.useWatch('code', form)
  const originalReceipt = Form.useWatch('originalReceipt', form)
  const waybill = Form.useWatch('waybill', form)
  const createdAt = Form.useWatch('createdAt', form)
  const merchantName = Form.useWatch('merchantName', form)

  useEffect(() => {
    form.setFieldsValue({
      code: searchParams.get('code'),
      originalReceipt: searchParams.get('originalReceipt'),
      merchantName: searchParams.get('merchantName'),
      providerShipmentCode: searchParams.get('providerShipmentCode'),
      waybill: searchParams.get('waybill'),
      createdAt: [
        searchParams.get('createdFrom') ? moment(searchParams.get('createdFrom')) : null,
        searchParams.get('createdTo') ? moment(searchParams.get('createdTo')) : null,
      ],
    })
  }, [searchParams, form])

  const handleChangeStatuses = (key: string, selected: boolean) => {
    if (selected) {
      setCheckedStatuses([...checkedStatuses, key])
      filterChangeHandler({ statuses: [...checkedStatuses, key] })
    } else {
      setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
      filterChangeHandler({ statuses: checkedStatuses.filter((stt: string) => stt !== key) })
    }
  }

  const cleanFilter = () => {
    form.resetFields()
    setCheckedStatuses([])
    setIdConnectionSelected(undefined)
    handleFilter({})
  }

  const getFormState = () => {
    const createdAt = form.getFieldValue('createdAt')
    const createdFrom = createdAt?.[0] && moment(createdAt[0].startOf('day')).toISOString()
    const createdTo = createdAt?.[1] && moment(createdAt[1].endOf('day')).toISOString()
    const filter = lodash.omitBy(
      {
        ...form.getFieldsValue(true),
        statuses: checkedStatuses.join(','),
        createdFrom,
        createdTo,
      },
      (o: any, key: string) => {
        return isEmpty(o) || key === 'createdAt'
      }
    )
    Object.keys(filter).forEach((k) => (filter[k] = typeof filter[k] == 'string' ? filter[k].trim() : filter[k]))

    return filter
  }

  const onFinish = () => {
    handleFilter(getFormState())
  }

  const hiddenResetFilter = useMemo(() => {
    delete filter.page
    delete filter.size
    delete filter.limit
    delete filter.offset
    return lodash.isEmpty(filter)
  }, [filter])

  return (
    <M22Box className="px-24 py-16 shipments-filter">
      <Row
        justify="space-between"
        align="middle"
        className={`cursor-pointer dpl-flex justify-content-between ${showFilter ? 'mg-bt-16 ' : ''}`}
        gutter={12}
        wrap={false}
        onClick={() => setShowFilter(!showFilter)}>
        <Col>
          <span className={`m22-typo-heading ${theme}`}>{t('shipments.filterTitle')} </span>
        </Col>

        <Col>
          <i
            className={`fa-solid fa-angle-${showFilter ? 'up' : 'down'} cursor-pointer`}
            onClick={() => setShowFilter(!showFilter)}></i>
        </Col>
      </Row>

      {showFilter && (
        <>
          <div className={`dpl-flex align-items-baseline flex-wrap ${expand ? 'mg-bt-12' : ''}`}>
            {statuses?.map(({ code, name }: ShipmentStatusInterface) => {
              const selected = checkedStatuses?.includes(code) ? true : false
              return (
                <span
                  className={`txt-capitalize ${selected ? 'm22-badge active' : 'm22-badge'} ${theme}`}
                  key={code}
                  onClick={() => {
                    handleChangeStatuses(code, !selected)
                  }}>
                  {name}
                </span>
              )
            })}
          </div>
          {expand && (
            <Form
              name="shipments-form"
              className={`shipments-form ${expand ? 'mg-bt-16' : ''}`}
              form={form}
              onFinish={onFinish}
              initialValues={{
                code: '',
                originalReceipt: '',
                merchantName: '',
                providerShipmentCode: '',
                createdAt: [],
                supplier: idConnectionSelected,
              }}
              onValuesChange={(changedValues, allValues) => {
                filterChangeHandler(allValues)
              }}>
              <Row gutter={16}>
                {/* Mã đơn hàng */}
                <Col span={8}>
                  <ComboBox
                    label={t('shipments.orderCodePlaceholder')}
                    value={code}
                    labelWidth={0}>
                    <Item
                      label=""
                      className="align-items-center"
                      labelAlign="left"
                      colon={false}
                      name="code">
                      <Input
                        placeholder=""
                        allowClear
                        size={'large'}
                      />
                    </Item>
                  </ComboBox>
                </Col>

                {/* Hóa đơn gốc */}
                <Col span={8}>
                  <ComboBox
                    label={t('shipments.originalCodePlaceholder')}
                    value={originalReceipt}
                    labelWidth={0}>
                    <Item
                      label={``}
                      className="align-items-center"
                      labelAlign="left"
                      colon={false}
                      name="originalReceipt">
                      <Input
                        placeholder={``}
                        allowClear
                        size="large"
                      />
                    </Item>
                  </ComboBox>
                </Col>

                {/* mã vận đơn */}
                <Col span={8}>
                  <ComboBox
                    label={t('shipments.wayBillPlaceholder')}
                    value={waybill}
                    labelWidth={0}>
                    <Item
                      label={``}
                      className="align-items-center"
                      labelAlign="left"
                      colon={false}
                      name="waybill">
                      <Input
                        allowClear
                        placeholder=""
                        size="large"
                      />
                    </Item>
                  </ComboBox>
                </Col>

                {/* tên shop */}
                <Col span={8}>
                  <ComboBox
                    label={t('shipments.merchantNamePlaceholder')}
                    value={merchantName}
                    labelWidth={0}>
                    <Item
                      label={``}
                      className="align-items-center"
                      labelAlign="left"
                      colon={false}
                      name="merchantName">
                      <Input
                        allowClear
                        placeholder={``}
                        size="large"
                      />
                    </Item>
                  </ComboBox>
                </Col>

                {/* Thời gian */}
                <Col span={8}>
                  <ComboBox
                    label={t('packages.fromDate')}
                    labelRange={t('packages.toDate')}
                    isRange={true}
                    value={createdAt}
                    labelWidth={0}>
                    <Item
                      label={``}
                      name="createdAt"
                      labelAlign="left"
                      className="align-items-center">
                      <RangePicker
                        size="large"
                        placeholder={['', '']}
                        className="width100pc"
                        format={'DD-MM-YYYY'}
                        suffixIcon={<i className="far fa-calendar-alt" />}
                        placement="topRight"
                      />
                    </Item>
                  </ComboBox>
                </Col>
              </Row>
            </Form>
          )}
          <div className="shipments-form__actions">
            <span
              className="m22-typo-label_collapse"
              onClick={() => setExpand(!expand)}>
              <i className={`fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
              {!expand ? t('shipments.expandFilter') : t('shipments.collapseFilter')}
            </span>
            <div className="buttons">
              {!hiddenResetFilter && (
                <span
                  className={`m22-btn-reset ${theme}`}
                  onClick={cleanFilter}>
                  <i className="fa-solid fa-arrow-rotate-right fsz-12 mg-r-6"></i>
                  <span>{t('shipments.cleanFilter')}</span>
                </span>
              )}
              <Button
                type="primary"
                className={`m22-btn ${theme}`}
                htmlType="submit"
                form="shipments-form"
                onClick={onFinish}
                icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                {t('shipments.submitFilter')}
              </Button>
            </div>
          </div>
        </>
      )}
    </M22Box>
  )
}

export default Filter
