import * as React from 'react'
import { Button, ButtonProps, Modal, ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../hooks/useTheme'
export interface ConfirmModalProps extends ModalProps {
  title?: string
  content: string
  visible: boolean
  loading: boolean
  className?: any
  classButton?: string
  btnType?: string
  iconBtn?: React.ReactNode
  okButtonProps?: ButtonProps
  onSubmit: () => void
}
const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation()
  const { title, visible, onCancel, onSubmit, content, loading, className, classButton, iconBtn, btnType, okText, okButtonProps } = props
  const [theme] = useTheme()
  return (
    <Modal
      className={`_modal-confirm ${className} ${theme}`}
      title={<span style={{ fontWeight: 500 }}>{title ? title : t('confirm.title')}</span>}
      open={visible}
      onCancel={onCancel}
      closeIcon={<i className="fa-solid fa-xmark" />}
      centered
      footer={[
        <Button
          className={`m22-btn ${theme}`}
          type={'text'}
          key="cancel"
          onClick={onCancel}>
          {t('button.cancel')}
        </Button>,
        <Button
          // @ts-ignore
          type={btnType || 'primary'}
          className={`${classButton ? classButton : ''} m22-btn`}
          key="submit"
          autoFocus={true}
          onClick={onSubmit}
          icon={iconBtn ? iconBtn : <i className="fas fa-check" />}
          loading={loading ? loading : false}
          {...okButtonProps}>
          {okText || t('button.submit')}
        </Button>,
      ]}
      {...props}>
      <p
        className={'_p-content '}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Modal>
  )
}
export default ConfirmModal
