import { Col, Row } from 'antd'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

interface ItemProps {
	label: string
	labelClass?: string
	valueClass?: string
	number?: number | string
	value: any
	subValue?: any
	currencyValue: string
	currencySubValue?: string
	loading: boolean
	className?: string
}
function Item(props: ItemProps) {
	const {
		label,
		labelClass = 'font-medium',
		number,
		value,
		subValue,
		currencyValue,
		currencySubValue,
		className,
		valueClass,
		loading,
	} = props
	return (
		<Row className={`dpl-flex justify-content-between align-items-center ${className ? className : ' mg-bt-14'} `}>
			{!loading ? (
				<Col className={'align-items-center dpl-flex'}>
					<span className={`txt-size-h7  ${labelClass}`}>{label}</span>
					<span className={'fsz-14 txt-disable mgl5'}>{number ? number : ''}</span>
				</Col>
			) : (
				<Skeleton width={80} height={20} />
			)}

			{!loading ? (
				<Col>
					{value || value === 0 ? (
						<Row className={'justify-content-end'}>
							<span className={'txt-size-h7 txt-disable mgl3'}>
								{subValue ? `(${formatMoneyByUnit(subValue, currencySubValue)})` : ''}
							</span>
							<span className={`txt-size-h7 font-medium mg-l-4 ${valueClass}`}>
								{formatMoneyByUnit(value, currencyValue)}
							</span>
						</Row>
					) : (
						<span className={'txt-size-h8'}>---</span>
					)}
				</Col>
			) : (
				<Skeleton width={120} height={20} />
			)}
		</Row>
	)
}
export default Item

