import { ThreadMessageInterface } from '../Interface/ThreadMessageInterface'

export const ThreadMessageModel: ThreadMessageInterface = {
  attachments: [{ hash: '', name: '', recall: false, recallTime: '', size: '', type: '', url: '' }],
  author: { avatar: '', email: '', fullname: '', id: 0, nickname: '', phone: '', staff: true, username: '' },
  commentType: '',
  content: '',
  customer: '',
  username: '',
  detail: '',
  id: '',
  orderCode: '',
  timestamp: '',
  createdAt: '',
  avatar: '',
  fullname: '',
  createdBy:""
}
