import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { Thread } from './Thread'
import { Message } from './Message'
import { BoxChatContext } from '../../context/BoxChatContext'
import { ThreadItemInterface } from '../../Interface/ThreadItemInterface'
import { ThreadItemModel } from '../../model/ThreadItemModel'
import { threadRepository } from '../../repositories/ThreadRepository'
import { ThreadMessageModel } from '../../model/ThreadMessageInterface'
import { ThreadMessageInterface } from '../../Interface/ThreadMessageInterface'
import { ThreadFilterInterface } from '../../Interface/ThreadFilterInterface'
import lodash from 'lodash'
import { ThreadCommentFilterInterface } from '../../Interface/ThreadCommentFilterInterface'
import { claimRepository } from '../../repositories/ClaimRepository'
import { ClaimCommentCriteriaInterface } from '../../Interface/ClaimCommentCriteriaInterface'
import { CLAIM_COMMENT, ORDER_COMMENT, SHIPMENT_COMMENT } from '../../core/config'
import { ThreadCreateInterface } from 'src/Interface/ThreadCreateInterface'
import { getThreadType } from './ThreadItem'
import M24Notification from 'src/utils/M24Notification'

interface CommentProps {
  threadCreatePayload: ThreadCreateInterface
  claimCode?: string
}

function BoxChat({ threadCreatePayload }: CommentProps) {
  const { t } = useTranslation()
  const [isExpand, setExpand] = useState<boolean>(false)
  const boxChatContext = useContext(BoxChatContext)
  const [thread, setThread] = useState<ThreadItemInterface>({ ...ThreadItemModel, ...threadCreatePayload })
  const [, setLastMessage] = useState<ThreadMessageInterface>(ThreadMessageModel)
  const [threads, setThreads] = useState<ThreadItemInterface[]>([])
  const [messages, setMessages] = useState<ThreadMessageInterface[]>([])
  const [messageLoading, setMessageLoading] = useState(false)
  const [threadLoading, setThreadLoading] = useState(false)
  const [threadTotal, setThreadTotal] = useState(0)
  const [threadCurrentPage, setThreadCurrentPage] = useState(0)
  const [threadPageSize, setThreadPageSize] = useState(25)
  const [messageCurrentPage, setMessageCurrentPage] = useState(0)
  const [messageTotal, setMessageTotal] = useState(0)
  const messagePageSize = 10
  const [isThreadCollapse, setIsThreadCollapse] = useState(true)
  const [isMessageBoxCollapse, setIsMessageBoxCollapse] = useState(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCollapsedThread, setIsCollapsedThread] = useState(true)

  const previousNumOfMessages = useRef<number>(0)

  const scrollChatListToBottom = useCallback(() => {
    if (boxChatContext?.chatListRef.current) {
      setTimeout(() => {
        boxChatContext.chatListRef.current.scrollTo({
          top: boxChatContext.chatListRef.current.scrollHeight + 1000,
          behavior: 'smooth',
        })
      }, 100)
    }
  }, [boxChatContext.chatListRef])

  useEffect(() => {
    if (previousNumOfMessages.current === 0) {
      scrollChatListToBottom()
    }
    previousNumOfMessages.current = messages.length
  }, [messages, scrollChatListToBottom])

  const getThreads = (filter: ThreadFilterInterface, append = true) => {
    return Promise.resolve({ data: [] })

    // eslint-disable-next-line no-unreachable
    setThreadLoading(true)
    return threadRepository
      .getThreads({ offset: 0, limit: 25, sort: 'timestamp:desc', ...filter })
      .then((response) => {
        if (append) {
          setThreads((prevState) => {
            const newThreads = [...prevState, ...response.data]
            return lodash.uniqBy([...newThreads], 'id')
          })
        } else {
          setThreads([...response.data])
        }

        setThreadCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
        setThreadTotal(Number(lodash.get(response, 'headers.x-total-count')))
        setThreadPageSize(Number(lodash.get(response, 'headers.x-page-size')))
      })
      .catch(() => setThreads([]))
      .finally(() => {
        setThreadLoading(false)
      })
  }

  const getThreadMessages = (params: ThreadCommentFilterInterface) => {
    setMessageLoading(true)
    return threadRepository
      .getThreadComments(threadCreatePayload.referenceCode!, {
        offset: 0,
        limit: messagePageSize,
        sort: 'timestamp:desc',
        ...params,
      })
      .then((response) => {
        setMessages((prevMessages) => {
          const newMessages = lodash.uniqBy([...prevMessages, ...response.data], 'id')
          return [...lodash.sortBy([...newMessages], (item) => item.timestamp)]
        })
        setMessageCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
        setMessageTotal(Number(lodash.get(response, 'headers.x-total-count')))
      })
      .catch(() => {
        setMessages([])
        M24Notification.error({
          message: t('chatBox.errorLoadMessageFail'),
        })
      })
      .finally(() => setMessageLoading(false))
  }

  const getThreadOfShipmentMessages = (params: ThreadCommentFilterInterface) => {
    setMessageLoading(true)
    return threadRepository
      .getShipmentComment(threadCreatePayload.referenceCode!, {
        offset: 0,
        limit: messagePageSize,
        sort: 'timestamp:desc',
        ...params,
      })
      .then((response) => {
        setMessages((prevMessages) => {
          const newMessages = lodash.uniqBy([...prevMessages, ...response.data], 'id')
          return [...lodash.sortBy([...newMessages], (item) => item.timestamp)]
        })
        setMessageCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
        setMessageTotal(Number(lodash.get(response, 'headers.x-total-count')))
      })
      .catch(() => {
        setMessages([])
        M24Notification.error({
          message: t('chatBox.errorLoadMessageFail'),
        })
      })
      .finally(() => setMessageLoading(false))
  }

  const getClaimComment = (params: ClaimCommentCriteriaInterface) => {
    setMessageLoading(true)
    return claimRepository
      .getClaimComment(threadCreatePayload.referenceCode!, {
        offset: 0,
        limit: messagePageSize,
        sort: 'createdAt:desc',
        ...params,
      })
      .then((response) => {
        setMessages((prevMessages) => {
          const newMessages = lodash.uniqBy([...prevMessages, ...response.data], 'id')
          return [...lodash.sortBy([...newMessages], (item) => item.createdAt)]
        })
        setMessageCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
        setMessageTotal(Number(lodash.get(response, 'headers.x-total-count')))
      })
      .catch(() => {
        setMessages([])
      })
      .finally(() => setMessageLoading(false))
  }

  const getComment = (params: any) => {
    if (threadCreatePayload.type === CLAIM_COMMENT) {
      getClaimComment(params)
      return
    }

    if (threadCreatePayload.type === SHIPMENT_COMMENT) {
      getThreadOfShipmentMessages(params)
      return
    }

    getThreadMessages(params)
  }

  const onSelectThread = (thread: any) => {
    if (isMessageBoxCollapse) {
      handleShowMessageBox()
    }
    setThread(thread)
    setMessages([])
    if (thread.type === CLAIM_COMMENT) {
      getClaimComment({
        provider: thread.provider,
        providerUsername: thread.providerUsername,
        ticketType: thread.ticketType,
      })
    } else if (thread.type === ORDER_COMMENT) {
      getThreadMessages({})
    } else if (thread.type === SHIPMENT_COMMENT) {
      getThreadOfShipmentMessages({})
    }
  }

  const onFilterThreads = (params: any) => {
    const newParams = { ...params }
    if (newParams.isRead === 'all') {
      lodash.unset(newParams, 'isRead')
    }
    return getThreads(newParams, false)
  }

  boxChatContext.onSelectThread = onSelectThread
  boxChatContext.onFilterThreads = onFilterThreads

  boxChatContext.onPostMessage = (message) => {
    boxChatContext.lastMessage = message
    setLastMessage(message)

    if (thread.type === ORDER_COMMENT) {
      getThreadMessages({
        ticketType: thread.ticketType,
      }).finally(() => {
        scrollChatListToBottom()
      })
      return
    }
    if (thread.type === CLAIM_COMMENT) {
      getClaimComment({
        ticketType: thread.ticketType,
      }).finally(() => {
        scrollChatListToBottom()
      })
      return
    }
    if (thread.type === SHIPMENT_COMMENT) {
      getThreadOfShipmentMessages({
        ticketType: thread.ticketType,
      }).finally(() => {
        scrollChatListToBottom()
      })
    }

    // createThread(thread)
  }

  boxChatContext.onMessageChangePage = (page) => {}

  boxChatContext.onListChatScroll = (e: any) => {
    if (e.target && e.target.scrollTop === 0 && messages.length < messageTotal) {
      if (thread.type === ORDER_COMMENT) {
        getThreadMessages({
          offset: (messageCurrentPage + 1) * messagePageSize,
          limit: messagePageSize,
        })
      } else if (thread.type === CLAIM_COMMENT) {
        getClaimComment({
          offset: (messageCurrentPage + 1) * messagePageSize,
          limit: messagePageSize,
          ticketType: thread.ticketType,
        })
      } else if (thread.type === SHIPMENT_COMMENT) {
        getThreadOfShipmentMessages({
          offset: (messageCurrentPage + 1) * messagePageSize,
          limit: messagePageSize,
        })
      }
      setTimeout(() => {
        boxChatContext.chatListRef.current.scrollTo({
          top: e.target.scrollHeight - e.target.scrollHeight * 0.88,
          behavior: 'smooth',
        })
      }, 1000)
    }
  }

  boxChatContext.onThreadScroll = (e) => {
    const element = e.target
    if (Math.round(element.scrollHeight - element.scrollTop) === Math.round(element.clientHeight) && threads.length < threadTotal) {
      getThreads({
        offset: (threadCurrentPage + 1) * threadPageSize,
        limit: threadPageSize,
        sort: 'timestamp:desc',
      })
    }
  }

  const toggle = () => {
    onExpand()
  }

  boxChatContext.toggle = toggle

  boxChatContext.onShow = (cb) => {
    if (boxChatContext.thread.referenceCode) {
      setTimeout(() => {
        scrollChatListToBottom()
      }, 300)
    }

    if (cb) cb()
  }

  useEffect(() => {
    getComment({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  boxChatContext.chatListRef = useRef<any>()
  boxChatContext.thread = {
    ...threadCreatePayload,
    id: threadCreatePayload.referenceCode!,
  } as ThreadItemInterface
  boxChatContext.threadLoading = threadLoading
  boxChatContext.threadTotal = threadTotal
  boxChatContext.threads = threads
  boxChatContext.messages = messages
  boxChatContext.messageLoading = messageLoading
  boxChatContext.visible = isExpand

  const onExpand = () => {
    setExpand((prevState) => {
      const newState = !prevState
      boxChatContext.visible = newState
      if (newState) {
        boxChatContext.onShow()
      }
      return newState
    })
    setIsThreadCollapse(true)
  }

  const handleShowMessageBox = () => {
    setIsThreadCollapse(true)
    setIsMessageBoxCollapse(false)
  }

  return (
    <div className={`box-chat`}>
      <div
        className={`box-chat-float-button ${isCollapsedThread ? 'collapsed-left' : ''} ${isExpand ? 'expand' : 'collapsed'} ${
          !isThreadCollapse ? 'show-threads' : ''
        }`}>
        <div
          className={'box-chat-header cursor-pointer'}
          onClick={onExpand}>
          <span className={'txt-color-main  box-chat-header__top-bar width80'}>
            {isCollapsedThread && getThreadType(t, thread.id ? thread : threadCreatePayload)}
            {!isCollapsedThread && (
              <>
                {t('chatBox.boxHeading')}
                <i className={'box-chat-header__badge fsz-12 robotomedium'}>{threads.filter((x) => !x.isRead).length}</i>{' '}
              </>
            )}
          </span>

          <span className={'txt-color-main comment-close-icon'}>
            {/* {isExpand && (
              <i
                className={`hidden-icon fa-solid fa-arrow-${isCollapsedThread ? 'left' : 'right'}-long-to-line`}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsCollapsedThread(!isCollapsedThread)
                }}
              />
            )} */}
            <i className={`mgl12 fas ${isExpand ? 'fa-angle-down' : 'fa-angle-up'}`} />
          </span>
        </div>

        <div className={'box-chat-body'}>
          <Thread />
          <Message />
        </div>
      </div>
    </div>
  )
}

export default BoxChat
