import {ClaimReasonInterface} from "../Interface/ClaimReasonInterface";

export const ClaimReasonModel: ClaimReasonInterface = {
    id: 0,
    code: "",
    createdAt: "",
    createdBy: "",
    description: "",
    detailType: "",
    modifiedAt: "",
    modifiedBy: "",
    name: "",
    sort: 0,
    type: ""
}
