import { Input, Spin, Tooltip, Upload, Image } from 'antd'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxChatContext } from '../../context/BoxChatContext'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { threadRepository } from '../../repositories/ThreadRepository'
import lodash from 'lodash'
import { ThreadCommentCreateInterface } from '../../Interface/ThreadCommentCreateInterface'
import { MAX_UPLOAD_SIZE } from '../../core/config'
import { AxiosError } from 'axios'
import { claimRepository } from '../../repositories/ClaimRepository'
import M24Notification from 'src/utils/M24Notification'

const isImage = (file: UploadFile) => {
  if (file.type?.startsWith('image')) {
    return true
  }
  return false
}

const isVideo = (file: UploadFile) => {
  if (file.type?.startsWith('video')) {
    return true
  }
  return false
}

const MAX_UPLOAD_FILE = 10

export const MessageInputBox = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const boxChatContext = useContext(BoxChatContext)
  const { thread, user, onPostMessage } = boxChatContext
  const [fileList, setFileList] = useState<any[]>([])
  const [comment, setComment] = useState<string | undefined>(undefined)
  const inputRef: any = useRef()

  const handleChangeFile = (info: UploadChangeParam<any>) => {
    if (info.file.type === 'image/tiff') {
      M24Notification.notifyErrorAllOptions({ description: 'Không hỗ trợ định dạng ảnh .tiff' })
      return
    }

    if (info.fileList.length > MAX_UPLOAD_FILE) {
      M24Notification.error({
        message: t('chatBox.errorMaxUploadFile').replace('%s', MAX_UPLOAD_FILE.toString()),
        key: 'upload_error',
      })
      return
    }

    if (info.file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
      M24Notification.error({
        message: t('chatBox.errorMaxUploadFileSize'),
        key: 'upload_error',
      })
      return false
    }

    setFileList([
      ...info.fileList
        .filter((f: any) => f.size <= MAX_UPLOAD_SIZE * 1024 * 1024)
        .map((f) => {
          // @ts-ignore
          f.thumbUrl = URL.createObjectURL(f.originFileObj)

          return f
        }),
    ])
  }

  const handleRemoveFile = (removedFile: UploadFile) => {
    setFileList(fileList.filter((f) => f.uid !== removedFile.uid))
  }

  boxChatContext.hasFileList = !!fileList.length
  boxChatContext.hasComment = !!comment

  const handleSubmit = useCallback(() => {
    if (!fileList.length && !comment?.trim()) {
      M24Notification.error({
        message: t('chatBox.errorCommentOrFileEmpty'),
      })
      return
    }
    if (fileList.length || comment) {
      setLoading(true)
      const postComment = lodash.clone(comment)

      let postData: ThreadCommentCreateInterface | any
      if (thread.type === 'ORDER_COMMENT' || thread.type === 'SHIPMENT_COMMENT') {
        postData = {
          comment: {
            author: user.username,
          },
        }
      } else if (thread.type === 'CLAIM_COMMENT') {
        postData = {
          comment: {
            // provider: thread.provider,
            // providerUsername: thread.providerUsername,
            comment: '',
          },
        }
      }

      if (fileList.length) {
        postData.attachments = fileList.map((f) => f.originFileObj)
      }

      if (postComment) {
        postData.comment = {
          ...postData.comment,
          content: postComment,
          comment: postComment,
        }
      }

      if (thread.type === 'ORDER_COMMENT') {
        threadRepository
          .createComment(thread.referenceCode!, postData)
          .then((response) => {
            setComment(undefined)
            setFileList([])
            onPostMessage(response.data)
          })
          .catch((error: AxiosError) => {
            if (lodash.get(error.response, 'status') === 400) {
              if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
                M24Notification.error({
                  message: t('chatBox.errorCommentBlank'),
                })
              } else {
                M24Notification.error({
                  message: t('chatBox.errorCommentCreateFail'),
                })
              }
            } else {
              M24Notification.error({
                message: t('chatBox.errorCommentCreateFail'),
              })
            }
          })
          .finally(() => setLoading(false))
        return
      }

      if (thread.type === 'SHIPMENT_COMMENT') {
        threadRepository
          .createCommentShipment(thread.referenceCode, postData)
          .then((response) => {
            setComment(undefined)
            setFileList([])
            onPostMessage(response.data)
          })
          .catch((error: AxiosError) => {
            if (lodash.get(error.response, 'status') === 400) {
              if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
                M24Notification.error({
                  message: t('chatBox.errorCommentBlank'),
                })
              } else {
                M24Notification.error({
                  message: t('chatBox.errorCommentCreateFail'),
                })
              }
            } else {
              M24Notification.error({
                message: t('chatBox.errorCommentCreateFail'),
              })
            }
          })
          .finally(() => setLoading(false))
        return
      }

      if (thread.type === 'CLAIM_COMMENT') {
        claimRepository
          .createClaimComment(thread.referenceCode!, postData)
          .then((response) => {
            setComment(undefined)
            setFileList([])
            onPostMessage(response.data)
          })
          .catch((error: AxiosError) => {
            if (lodash.get(error.response, 'status') === 400) {
              if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
                M24Notification.error({
                  message: t('chatBox.errorCommentBlank'),
                })
              } else {
                M24Notification.error({
                  message: t('chatBox.errorCommentCreateFail'),
                })
              }
            } else {
              M24Notification.error({
                message: t('chatBox.errorCommentCreateFail'),
              })
            }
          })
          .finally(() => setLoading(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, fileList.length])

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 100)
  }, [thread, boxChatContext.visible, fileList])

  return (
    <Spin spinning={loading}>
      <div className={'chat-input-box'}>
        <div className="chat-input-box__attachments">
          {fileList.map((item, index) => (
            <React.Fragment key={`file_${index}`}>
              {isImage(item) ? (
                <span className={'chat-input-box__attachments__item chat-input-box__attachments__item--image'}>
                  <Image
                    width={56}
                    height={56}
                    src={item.thumbUrl}
                  />
                  <i
                    onClick={() => handleRemoveFile(item)}
                    className="chat-attachment-remove-icon fa-regular fa-circle-xmark"
                  />
                </span>
              ) : (
                <Tooltip title={item.name}>
                  <span className={'chat-input-box__attachments__item chat-input-box__attachments__item--file'}>
                    {isVideo(item) ? <i className="fa-solid fa-circle-play file-icon" /> : <i className="fa-solid fa-memo file-icon" />}{' '}
                    <span className={'att-file-name'}>{item.name}</span>
                    <i
                      onClick={() => handleRemoveFile(item)}
                      className="chat-attachment-remove-icon fa-regular fa-circle-xmark"
                    />
                    <span className="attachment-item-backdrop" />
                  </span>
                </Tooltip>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="chat-input-box__input-container">
          <Input.TextArea
            ref={inputRef}
            autoFocus={true}
            className={'chat-input-box__input'}
            placeholder={t('chatBox.inputPlaceHolder')}
            disabled={loading}
            bordered={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSubmit()
              }
            }}
            value={comment}
            readOnly={loading}
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />
          <span className={'chat-input-box__actions'}>
            <i
              onClick={handleSubmit}
              className="chat-input-box__actions__send fas fa-paper-plane"
            />

            <Upload
              disabled={loading}
              fileList={fileList}
              beforeUpload={() => false}
              showUploadList={false}
              multiple={true}
              onChange={handleChangeFile}>
              <span className={'chat-input-box__actions__send-file mgl5'}>
                <i className="fa-regular fa-link" />
              </span>
            </Upload>
          </span>
        </div>
      </div>
    </Spin>
  )
}
