import { Select } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../hooks/useTheme'
import EmptyDataFilter from 'src/components/Empty/EmptyDataFilter'
interface SelectionItemProps {
  valueChangeHandler: (val: any) => void
  options: any
  locationForm: any
  isNation?: boolean
  level: any
}
const { Option } = Select

const SelectionItem: React.FC<SelectionItemProps> = ({ valueChangeHandler, options, locationForm, level, isNation }) => {
  const [theme] = useTheme()
  const [valueLabel, setValueLabel] = useState('')
  const [dropdownVisibleChange, setDropdownVisibleChange] = useState(false)
  const [value, setValue] = useState()

  const valueLabelChangeHandler = (val: string) => {
    setValueLabel(val)
  }

  useEffect(() => {
    setValue(lodash.get(locationForm, level, undefined))
  }, [level, locationForm])

  return (
    <Select
      notFoundContent={<EmptyDataFilter description="Không có dữ liệu" />}
      size="large"
      allowClear
      onChange={(value) => valueChangeHandler(value)}
      showSearch
      disabled={!isNation && !locationForm[level - 1]}
      optionFilterProp="children"
      filterOption={(input: string, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      placeholder={valueLabel}
      onDropdownVisibleChange={() => {
        valueLabelChangeHandler('')
        setDropdownVisibleChange(!dropdownVisibleChange)
      }}
      value={valueLabel ? undefined : value ? value : ''}
      suffixIcon={<i className={`fa-regular fa-angle-${dropdownVisibleChange ? 'up' : 'down'}`}></i>}
      popupClassName={theme}>
      {isNation
        ? options.map((nation: any) => {
            return (
              <Option
                key={nation.code}
                value={nation.code}
                name={nation.name}
                onMouseOver={() => {
                  valueLabelChangeHandler(nation.name)
                }}>
                {nation.name}
              </Option>
            )
          })
        : options && options[level]
        ? options[level].map((item: any) => {
            return (
              <Option
                key={item.code}
                value={item.code}
                onMouseOver={() => {
                  valueLabelChangeHandler(item.name)
                }}>
                {item.name}
              </Option>
            )
          })
        : null}
    </Select>
  )
}

export default SelectionItem
