import { Empty } from 'antd'
import React, { ReactNode } from 'react'
import EmptyImgLight from '../../resources/images/table-contents/empty.png'
import EmptyImgDark from '../../resources/images/table-contents/emptyDark.png'

import { useTheme } from 'src/hooks/useTheme'
interface EmptyDataFilterProps {
  description: string | ReactNode
  minHeight?: string | number
  className?: string
}
const EmptyDataFilter: React.FC<EmptyDataFilterProps> = ({ description, minHeight, className, children }) => {
  const [theme] = useTheme()
  let img = theme === 'dark' ? EmptyImgDark : EmptyImgLight
  return (
    <div
      className={`m22-empty ${className ? className : ''}`}
      style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
      <Empty
        description={description}
        image={img}
      />
      {children}
    </div>
  )
}

export default EmptyDataFilter
