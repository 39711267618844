import {TenantInterface} from "../Interface/TenantInterface";
import React from "react";

export interface AppContextInterface {
    tenant: TenantInterface | null
    currentUser: any
    fnc:any
    accountBalance: any
}

export const AppContext = React.createContext<AppContextInterface>({
    tenant: null,
    currentUser: {},
    fnc: {},
    accountBalance: {}
})

