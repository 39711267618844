import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '../../../hooks/useTheme'
interface ItemProps {
  label: any
  content: any
  className?: string
  valueClassName?: string
  minWidthLabel?: number | 'unset'
  loading?: boolean
  widthLabel?: number | string
  widthContent?: number | string
  skeletonLabelClass?: string
  skeletonContentClass?: string
  containerClassContent?: string
}
const Item: React.FC<ItemProps> = (props) => {
  const [theme] = useTheme()
  const {
    label,
    content,
    className,
    valueClassName = '',
    minWidthLabel = 160,
    loading,
    widthLabel,
    widthContent,
    skeletonLabelClass,
    skeletonContentClass,
    containerClassContent,
  } = props
  return !loading ? (
    <div className={`width100pc dpl-flex ${className ? className : ''}`}>
      <div className={`txt-nowrap txt-muted mg-r-8`} style={{ minWidth: minWidthLabel }}>{label}</div>
      <div className={`dpl-flex info-item flex-1 ${valueClassName}`}>{content}</div>
    </div>
  ) : (
    <div className={`flex align-items-center ${className ? className : ''}`}>
      <Skeleton
        height={22}
        width={widthLabel ? widthLabel : ''}
        className={`txt-nowrap txt-muted ${theme} ${skeletonLabelClass ? skeletonLabelClass : ''}`}
      />
      <Skeleton
        height={22}
        width={widthContent ? widthContent : ''}
        className={`${skeletonContentClass ? skeletonContentClass : ''}`}
        containerClassName={containerClassContent ? containerClassContent : ''}
      />
    </div>
  )
}

export default Item
