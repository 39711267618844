import {Repository} from '../core/repositories/Repository';
import {AxiosResponse} from 'axios';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";


export class TenantRepository extends Repository {
    constructor() {
        super(httpConfig, false);
        this.setBaseURL(url(API_BASE_URL, 'tenants'));
    }

    public getCurrent = (): Promise<any> => {
        return this.http
            .get(`current`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

}
export const tenantRepository: TenantRepository = new TenantRepository();
