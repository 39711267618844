import { Avatar, Badge, Steps, Table } from 'antd'
import lodash, { uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import M22Box from '../../components/M22Box'
import ParagraphWithCopy from '../../components/ParagraphWithCopyButton'
import { SkeletonTable, SkeletonTableColumnsType } from '../../components/SkeletonTable'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatNumber } from '../../core/helpers/string'
import { useTheme } from '../../hooks/useTheme'
import { packageRepository } from '../../repositories/PackagesRepository'
import { getCleanFormFilter } from '../../utils/StringUtils'
import QuickPagination from "../../components/QuickPagination";
import { ColumnsType } from 'antd/lib/table'
import { IPackage } from 'src/Interface/PackageInterface'
import { useMidmile } from 'src/hooks/fetchers/useMidmile'

const { Step } = Steps
interface ListProps {
  filter: any
  packages: IPackage[]
  statuses: any
  total: number
  page: number
  size: number
  showProgressBar: boolean
  handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [theme] = useTheme()
  const { packages, statuses, handleFilter, total, page, size, showProgressBar, filter } = props
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const [history, setHistory] = useState<{ [id: string]: any }>({})
  const [loading, setLoading] = useState<{ [id: string]: any }>({})
  const [loadingState, setLoadingState] = useState(true)
  const { midmileLogoMapping, midmileNameMapping } = useMidmile(searchParams.get('provider'))

  useEffect(() => {
    setTimeout(() => setLoadingState(false), 2000)
  })
  const emptyText = '---'

  const columns: ColumnsType<any> = [
    {
      title: <span className={`fsz-12 mg-l-20 `}>{t('packages.packageCode')}</span>,
      key: 'code',
      dataIndex: 'code',
      width: 200,
      render: (code: string) => (
        <div className="dpl-flex align-items-center">
          {!showProgressBar ? (
            <i className={`far fa-angle-${lodash.includes(expandedRows, code) ? 'down' : 'right'} w-8 line-h-22 cursor-pointer mg-r-12`}></i>
          ) : (
            <Skeleton
              width={12}
              height={22}
            />
          )}
          <ParagraphWithCopy
            text={code}
            children={code}
          />
        </div>
      ),
    },
    {
      title: <span className={`fsz-12 `}>{t('packages.orderCode')}</span>,
      key: 'orderCode',
      dataIndex: 'orderCode',
      width: 180,
      render: (orderCode: any) => (
        <ParagraphWithCopy
          className="robotoregular"
          text={orderCode}
          children={orderCode}
        />
      ),
    },
    {
      title: <span className={`fsz-12 `}>{t('packages.volume')}</span>,
      key: 'volumetric',
      dataIndex: 'volumetric',
      width: 180,
      align: 'right' as 'right',
      render: (volumetric: any) => (
        <span className=" fsz-14 line-h-22 whitespace">{volumetric ? `${formatNumber(volumetric)}cm3` : t('orderDetail.undefined')}</span>
      ),
    },
    {
      title: <span className={`fsz-12 `}>{t('packages.details')}</span>,
      key: 'details',
      width: 180,
      align: 'right' as 'right',
      render: (record: any) => {
        const length = lodash.isNull(lodash.get(record, 'length')) ? '---' : lodash.get(record, 'length', emptyText)
        const width = lodash.isNull(lodash.get(record, 'width')) ? emptyText : lodash.get(record, 'width', emptyText)
        const height = lodash.isNull(lodash.get(record, 'height')) ? emptyText : lodash.get(record, 'height', emptyText)
        return <span className="  fsz-14 line-h-22 whitespace">{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(height)} (cm)`}</span>
      },
    },
    {
      title: <span className={`fsz-12 `}>{t('packages.weight')}</span>,
      key: 'weight',
      dataIndex: 'actualWeight',
      width: 180,
      align: 'right' as 'right',
      render: (actualWeight: string) => <span className="  fsz-14 line-h-22 whitespace">{`${formatNumber(actualWeight)}kg`}</span>,
    },
    {
      title: 'Vận chuyển Midmile',
      key: 'midMileParcel',
      dataIndex: 'midMileParcel',
      width: 100,
      render: (midMileParcel: IPackage['midMileParcel']) =>
        midMileParcel ? (
          <>
            <Avatar
              src={midmileLogoMapping[midMileParcel.courier]}
              size={24}
              className="inline-block"
            />{' '}
            <span>{midmileNameMapping[midMileParcel.courier]}</span>
          </>
        ) : (
          '---'
        ),
    },
    {
      title: <span className={`fsz-12 mg-r-16  `}>{t('packages.status')}</span>,
      key: 'status',
      dataIndex: 'status',
      width: 180,
      align: 'right' as 'right',
      render: (status: any) => {
        const statusObj = statuses.find(({ code }: any) => code === status)
        const statusName = lodash.isNull(lodash.get(statusObj, 'name')) ? emptyText : lodash.get(statusObj, 'name', emptyText)
        return (
          <span
            className="m22-tag-status"
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color')) ? '#09B2AA' : lodash.get(statusObj, 'color'),
            }}>
            {statusName}
          </span>
        )
      },
    },
  ]

  const handleChangePage = (filter: any) => {
    const { page, size } = filter
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          page: (page - 1).toString(),
          size: size.toString(),
        },
        [],
        ['pageSize']
      )
    )
  }

  const customDot = (dot: any, { status, index }: any) => {
    return status !== 'finish' ? <i className="far fa-dot-circle fsz-10"></i> : <i className="far fa-dot-circle fsz-10"></i>
  }

  const expandedRowRender = (record: any) => {
    const codes = lodash.uniq(lodash.map(history[record.code], 'status'))
    let positions: Number[] = []
    for (let index = 0; index < codes.length; index++) {
      const status = statuses.find(({ code }: any) => code === codes[index])
      positions.push(status.position)
    }
    const positionMax = lodash.max(positions)
    const loadingSkeleton = loading[record.code]
    return loadingSkeleton ? (
      <div className="flex justify-content-around ">
        {Array(10)
          .fill(null)
          .map(() => (
            <div key={uniqueId()}>
              <Skeleton width={80} />
              <Skeleton width={80} />
              <Skeleton width={80} />
            </div>
          ))}
      </div>
    ) : (
      <div
        key={record.code}
        className={`package-timeline ${theme}`}>
        <Steps progressDot={customDot}>
          {history[record.code] &&
            history[record.code].length > 0 &&
            statuses.map(({ name, code, id, position }: any) => {
              const indexOfStatus = history[record.code].findIndex(({ status }: any) => code === status)
              const status = indexOfStatus > -1 ? 'finish' : 'wait'
              const txtClasses = status === 'finish' ? '' : 'txt-color-gray2'
              return (
                <Step
                  status={status}
                  key={id}
                  className={position > positionMax! ? 'tail-none' : position === positionMax ? 'tail-none last-tail' : 'tail'}
                  title={<span className={`txt-left robotomedium fsz-12 line-h-20  mg-bt-6 ${txtClasses}`}>{name}</span>}
                  description={
                    <CustomDescription
                      code={code}
                      idxHistory={record.code}
                      history={history}
                      theme={theme}
                    />
                  }
                />
              )
            })}
        </Steps>
      </div>
    )
  }

  return (
    <M22Box className="pd-0">
      <div className="dpl-flex flex-col pd-t-16 px-16 rad-6 border-card-header">
        <div className="dpl-flex align-items-center mg-bt-13 mg-l-8 justify-content-between">
          <div className={'flex align-center'}>
            <span className={`m22-typo-heading mg-r-4  ${theme}`}>{t('packages.list')}</span>
            <Badge
              count={total}
              overflowCount={99999}
              showZero={true}
              style={{
                fontWeight: '500',
                backgroundColor: !total ? '#F5F5F5 ' : '',
                color: !total ? '#707070' : '#1a1a1a',
              }}
            />
          </div>
          <QuickPagination
            filter={filter}
            pagination={{
              total,
              current: page,
              pageSize: size,
            }}
            onPageChange={handleChangePage}
          />
        </div>
        <SkeletonTable
          loading={loadingState || showProgressBar}
          rowCount={packages?.length}
          columns={columns as SkeletonTableColumnsType[]}
          className={`main-table table-expandable selected-table mg-bt-13 m22-table ${theme}`}>
          <Table
            rowKey={(record: any) => record.code}
            columns={columns}
            dataSource={packages}
            locale={{
              emptyText: (
                <EmptyDataFilter
                  description={
                    !searchParams.get('query') &&
                    !searchParams.get('cutOffStatus') &&
                    !searchParams.get('handlingTimeFrom') &&
                    !searchParams.get('handlingTimeTo') &&
                    !searchParams.get('typeSearch') &&
                    !searchParams.get('statuses')
                      ? t('packages.noData')
                      : t('packages.filterEmpty')
                  }
                />
              ),
            }}
            expandIconColumnIndex={-1}
            pagination={false}
            className={`main-table package selected-table  nested-px-36 mg-bt-13 m22-table ${theme}`}
            loading={showProgressBar}
            expandRowByClick={true}
            expandable={{
              expandedRowRender,
              expandIcon: () => null,
              columnWidth: 0,
              onExpand: (expanded, record) => {
                if (expanded && !history[record.code]) {
                  if (record.isShipment) {
                    setLoading({ ...loading, [record.code]: true })
                    packageRepository
                      .getPackageHistoryOfShipment(lodash.get(record, 'code'), searchParams.get('customer'), searchParams.get('provider'))
                      .then((res) => setHistory({ ...history, [record.code]: res }))
                      .catch((err) => {})
                      .finally(() => setLoading({ ...loading, [record.code]: false }))
                  } else {
                    setLoading({ ...loading, [record.code]: true })
                    packageRepository
                      .getPackageHistory(lodash.get(record, 'code'), searchParams.get('customer'), searchParams.get('provider'))
                      .then((res) => setHistory({ ...history, [record.code]: res }))
                      .catch((err) => {})
                      .finally(() => setLoading({ ...loading, [record.code]: false }))
                  }
                }
              },
              onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
            }}
            rowClassName={(record, idx) => {
              const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
              const rowClassByCode = lodash.includes(expandedRows, record.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
              return `${rowClassesByIdx} ${rowClassByCode}`
            }}
          />
        </SkeletonTable>
      </div>
    </M22Box>
  )
}

export default List

const CustomDescription = ({ code, idxHistory, theme, history }: { code: string; idxHistory: string, theme: string, history: any }) => {
  
  const { t } = useTranslation()

  const historyTrackByPosition = history[idxHistory].filter(({ status }: any) => status === code)
  const classes = 'description  dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20'
  return (
    <span className={`${classes}`}>
      {historyTrackByPosition.length > 0 ? (
        historyTrackByPosition.map(({ timestamp, handlingTime }: any, idx: any) => {
          const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp)
          const handlingTimeRendered = lodash.isNull(handlingTime) ? t('orderDetail.undefined') : `${handlingTime} ngày`

          return (
            <span
              className={`${classes} mg-bt-8`}
              key={idx}>
              <span className={`${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'} mg-bt-6`}>{timestampRendered}</span>
              <span className={`${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'}`}>{`(${handlingTimeRendered})`}</span>
            </span>
          )
        })
      ) : (
        <span className={`${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'}`}>{`${t('orderDetail.undefined')}`}</span>
      )}
    </span>
  )
}