import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '../../../hooks/useTheme'
interface HeadingProps {
	className: string
	loading: boolean
}

const Heading: React.FC<HeadingProps> = ({ className, loading }) => {
	const { t } = useTranslation()
	const [theme] = useTheme()

	return (
		<div className={`dpl-flex align-items-center justify-content-between ${className ? className : ''}`}>
			<h5 className={`m22-typo-heading mg-0 ${theme}`}>
				{!loading ? t('orderDetail.finance') : <Skeleton width={150} height={22} />}
			</h5>
		</div>
	)
}

export default Heading

