import { Button, Image } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../hooks/useTheme'
import saboLogo from '../../resources/images/sapo-logo.png'
import M22Box from '../M22Box'

interface ExtensionCommonProps {
  handleClose: () => void
}

const ExtensionCommon: React.FC<ExtensionCommonProps> = ({ handleClose }) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  return (
    <>
      <M22Box className="mg-bt-0">
        <div className={`flex text-center flex-col pd-24 box-shadow-desc ${theme}`}>
          <div className="flex justify-content-center mg-bt-24 mg-t-8">
            <Image
              src={saboLogo}
              alt="saboLogo"
              preview={false}
              height={58.54}
              width={60}
            />
          </div>
          <span className="robotomedium  fsz-16 mg-bt-10 line-h-24">{t('description.extension')}</span>
          <span
            className={`fsz-14 line-h-20  mg-bt-24 ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray7'}`}
            // dangerouslySetInnerHTML={{
            //   __html: t('description.extensionCommon', { tenant: 'Sabo Shopping Assistant' })
            //   ,
            // }}
          >
            {t('description.extensionCommon')}
          </span>
          <a
            className="mg-bt-10"
            href="https://chrome.google.com/webstore/detail/dgkolciljcelfnmfmegcdchbkoeninal"
            target="blank">
            <Button
              style={{ width: '100%' }}
              type="primary"
              className="m22-btn"
              icon={<i className="fa-regular fa-download mg-r-10"></i>}>
              {t('description.installNow')}
            </Button>
          </a>
          <a
            href="https://gobiz.vn/gobiz-shopping-assistant/"
            target={'_blank'}
            style={{ color: `${theme === 'dark' ? '#BDBDBD' : '#5A5A5A'}` }}
            className={`txt-underline fsz-12 line-h-20 cursor-pointer research `}
            rel="noreferrer">
            {t('description.research')}
          </a>

          <i
            className="fa-solid fa-xmark cursor-pointer"
            onClick={handleClose}></i>
        </div>
      </M22Box>
    </>
  )
}

export default ExtensionCommon
