import { Button, Col, Row, Table, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import { ClaimInterface } from '../../../Interface/ClaimInterface'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../SkeletonTable'
import lodash from 'lodash'
import { getUrlPathname } from 'src/utils/util'

const { Text } = Typography

interface IProps {
  isLoading: boolean
  claimList: ClaimInterface[]
  total: null | number
  pageSize: null | number
  currentPage: number
  onPageChange: (filter: any) => void
  providerCode: string | undefined
  accountCode: string | undefined
  orderCode: string
}

const Tickets: React.FC<IProps> = (props) => {
  const [theme] = useTheme()
  const classes = {
    txtBlue: 'txt-color-blue1',
    tHeadFont: 'robotomedium txt-size-h7 whitespace',
    statusDot: 'status-dot mg-bt-1',
    regularFontSize: 'txt-size-h7 ',
    paginationSpacing: 'mgt0 mgbt0',
    outlinedBtn: 'btn-outlined-orange txt-capitalize border-radius4',
    txtBlack: '',
    txtOrange: 'txt-primary-2',
    createdClaimBtn: 'txt-size-h7  btn-outlined-orange btn-create-claim-by-order txt-capitalize border-radius4 px-16 py-5',
  }

  const { t } = useTranslation()
  let { code } = useParams()
  const navigate = useNavigate()
  const columns = [
    {
      title: <span className="fsz-12">{t('orderDetail.claimCode')}</span>,
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (_: string, record: ClaimInterface) => <Text className={`${classes.regularFontSize} robotomedium`}>#{record.code}</Text>,
    },
    {
      title: <span className="fsz-12">{t('orderDetail.claimName')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_: string, record: ClaimInterface) => <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{record.name}</Text>,
    },
    {
      title: <span className="fsz-12">{t('orderDetail.claimCreatedAt')}</span>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, record: ClaimInterface) => (
        <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{formatDateTime(moment(record.createdAt))}</Text>
      ),
    },
    {
      title: <span className="fsz-12">{t('orderDetail.claimStatus')}</span>,
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: ClaimInterface) => {
        const statusOfClaim = record ? lodash.get(record, 'publicStateNewView.name') : '---'
        const colorOfStatus = record ? lodash.get(record, 'publicStateNewView.color') : ''
        return (
          <>
            <span
              className={classes.statusDot}
              style={{ backgroundColor: colorOfStatus }}
            />{' '}
            <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>
              {statusOfClaim}
              {record.archived && ` (${t('tickets.archived')})`}
            </Text>
          </>
        )
      },
    },
    {
      title: <span className="fsz-12">{t('tickets.resolvesSuggestion')}</span>,
      dataIndex: 'solution',
      key: 'solution',
      render: (_: string, record: ClaimInterface) => {
        return <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{record?.solutionView?.name}</Text>
      },
    },
    {
      title: <span className="fsz-12">{t('orderDetail.totalRefund')}</span>,
      dataIndex: 'totalRefund',
      key: 'totalRefund',
      align: 'right' as 'right',
      render: (_: string, record: ClaimInterface) => {
        return (
          <Row justify="end">
            <Col>
              <Text className={`${classes.regularFontSize} ${classes.txtBlack}`}>
                {record.publicStateNewView.code === 'REFUND' ? (
                  <span className="m22-typo-money-plus robotomedium">{formatMoneyByUnit(record.totalRefund)}</span>
                ) : record.estimatedRefundValue ? (
                  <span className="m22-typo-money-plus robotomedium">{formatMoneyByUnit(record.estimatedRefundValue)}</span>
                ) : (
                  '---'
                )}
              </Text>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 'detail',
      width: 160,
      align: 'right' as 'right',
      render: (record: ClaimInterface) => (
        <Row
          justify="end"
          align="middle"
          gutter={12}>
          <Col>
            <Link
              to={`/claims/${record.code}`}
              className={`txt-color-blue1 item-link fsz-14 line-h-22 `}>
              {t('orderDetail.claimDetail')}
            </Link>
          </Col>
        </Row>
      ),
    },
  ]

  const dataSource = props.claimList.map((claim) => ({ ...claim, key: `${code}_${claim.code}` }))

  return (
    <Row gutter={[12, 8]}>
      <Col xs={24}>
        <SkeletonTable
          columns={columns as SkeletonTableColumnsType[]}
          rowCount={3}
          loading={props.isLoading}
          className="order-claims-list">
          <Table
            className={`order-claims-list m22-table ${theme}`}
            locale={{
              emptyText: (
                <EmptyDataFilter
                  description={
                    <div className="flex-col dpl-flex align-items-center">
                      <span className="mg-r-6 mg-bt-16">{t('orderDetail.emptyClaims')}</span>
                      <Button
                        type="ghost"
                        className={`m22-btn ${theme}`}
                        icon={<i className="mg-r-10 fa-solid fa-plus"></i>}
                        onClick={() =>
                          navigate(
                            `/claims/create?orderCode=${props.orderCode}&providerCode=${props?.providerCode}&customerCode=${
                              props?.accountCode
                            }&back=${getUrlPathname(window.location)}`
                          )
                        }>
                        {t('tickets.createTickets')}
                      </Button>
                    </div>
                  }></EmptyDataFilter>
              ),
            }}
            loading={props.isLoading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              // total: props.total!,
              pageSize: 25,
              defaultPageSize: 25,
              hideOnSinglePage: true,
              position: ['bottomRight'],
            }}
          />
        </SkeletonTable>
      </Col>
    </Row>
  )
}
export default Tickets
