import { Badge, Button, Card, Col, DatePicker, Row, Table } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import ComboBox from '../../components/ComboBox'
import MainLayout from '../../components/Layout'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../../components/SkeletonTable'
import { TRANSACTION_DESCRIPTIONS } from '../../core/config'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { supplierRepository } from '../../repositories/SupplierRepository'
import { handleChangeDate } from '../../utils/StringUtils'

import './styles.scss'
import M22Box from '../../components/M22Box'
import { useTheme } from '../../hooks/useTheme'
import QuickPagination from '../../components/QuickPagination'
import { ColumnsType } from 'antd/lib/table/Table'
import { ITransationType } from './type'

const { RangePicker } = DatePicker

function Transactions() {
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
  const { t } = useTranslation()
  const [theme] = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const [transactionTypes, setTransactionTypes] = useState<ITransationType[]>([])
  const [filter, setFilter] = useState<any>({})
  const [pagination, setPagination] = useState<any>({})
  const [transactions, setTransactions] = useState<any[]>([])
  const [total, setTotal] = useState(0)
  const [showFilter, setShowFilter] = useState(true)
  const [expand, setExpand] = useState(false)
  const [, setLoadingTransactionTypes] = useState(false)
  const [isSkeleton, setIsSkeleton] = useState(false)

  const transactionTypesMapping = useMemo(() => {
    const obj: any = {}
    transactionTypes?.forEach((type) => {
      obj[type.code.toUpperCase()] = type.name
    })
    return obj
  }, [transactionTypes])

  useEffect(() => {
    setExpand(transactionTypes.length > 0 ? false : true)
  }, [transactionTypes.length])

  const getData = (filter?: { [key in string]: string }) => {
    setShowProgressBar(true)
    setIsSkeleton(true)
    setSearchParams(filter)
    supplierRepository
      .getTransactionsByAccount({
        page: searchParams.get('page'),
        size: searchParams.get('size'),
        ...filter,
      })
      .then((response) => {
        let currentPage = lodash.get(response, 'headers.x-page-number')
        let total = lodash.get(response, 'headers.x-total-count')
        setTotal(Number(total))
        let items: any = []
        response.data.forEach((item: any) => {
          if (!item.purchaseUnits || (item.purchaseUnits && item.purchaseUnits.length === 0))
            items.push({ ...item, rowSpan: 1 })
          else {
            item.purchaseUnits.forEach((x: any, index: number) => {
              let temp = lodash.cloneDeep(x)
              delete temp.purchaseUnits
              if (index === 0) temp.rowSpan = item.purchaseUnits.length
              else temp.rowSpan = 0
              temp.id = item.id
              temp.memo = x.memo
              temp.amount = x.amount
              temp.balanceAfter = item.balanceAfter
              temp.transactionType = x.customType
              items.push(temp)
            })
          }
        })
        setTransactions(items)
        setPagination({
          current: parseInt(currentPage) + 1,
          hideOnSinglePage: true,
          total: parseInt(total),
          pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
        })
      })
      .finally(() => {
        setShowProgressBar(false)
        setTimeout(() => setIsSkeleton(false), 2000)
      })
  }

  useEffect(() => {
    let types = searchParams.get('types')
    let query = searchParams.get('query')
    let timestampFrom = searchParams.get('timestampFrom')
    let timestampTo = searchParams.get('timestampTo')
    let page = searchParams.get('page')
    let size = searchParams.get('size')
    let temp: any = {}
    if (types) temp.types = types
    if (query) temp.query = query
    if (timestampFrom) temp.timestampFrom = timestampFrom
    if (timestampTo) temp.timestampTo = timestampTo
    if (page) temp.page = parseInt(page)
    if (size) temp.size = parseInt(size)
    setFilter(temp)
    getData(temp)
    getTransactionTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTransactionTypes = () => {
    setLoadingTransactionTypes(true)
    supplierRepository
      .getTransactionTypes()
      .then((res) => {
        setTransactionTypes(res)
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setShowProgressBar(false)
          setLoadingTransactionTypes(false)
        }, 2000)
      )
  }

  const onPageChange = (filter: any) => {
    setFilter(filter)
    let temp = lodash.cloneDeep(filter)
    if (filter?.page && filter.page > 0) {
      temp.page = temp.page - 1
    }
    getData(temp)
  }

  const resetFilter = () => {
    setFilter({})
    getData({})
  }

  const onChangeDate = (value: any, dateString: any) => {
    let startDate = handleChangeDate('timestampFrom', value[0], filter)
    let endDate = handleChangeDate('timestampTo', value[1], startDate)
    setFilter(endDate)
  }

  const hiddenResetFilter = useMemo(() => {
    delete filter.page
    delete filter.size
    return lodash.isEmpty(filter)
  }, [filter])

  const columns: ColumnsType<any> = [
    {
      title: (
        <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('transactions.createdAt')}</span>
      ),
      dataIndex: 'nominalTimestamp',
      key: 'nominalTimestamp',
      width: 200,
      render: (date: any, record: any) => {
        return (
          <Col>
            <Row>
              <span className="_package-created-at">{formatDateTime(date)}</span>
            </Row>
            <Row>
              <span className={`_id ${theme === 'dark' ? 'txt-color-dark1' : 'txt-color-gray2 '} whitespace fw-300`}>
                {record.txid}
              </span>
            </Row>
          </Col>
        )
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('transactions.type')}</span>,
      dataIndex: 'type',
      key: 'type',
      width: 150,
      render: (value: any) => {
        console.log('value', value)
        return <span className="whitespace">{transactionTypesMapping[value] ?? value}</span>
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('transactions.memo')}</span>,
      dataIndex: 'memo',
      className: '_transaction-memo whitespace-initial',
      width: 300,
      key: 'memo',
      render: (memo: any) => <span className="fsz-14 line-h-22">{memo || '---'}</span>,
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('transactions.amount')}</span>,
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      align: 'right' as 'right',
      render: (value: any, record: any) => (
        <Row className={`dpl-flex justify-content-end pdt5 pdbt5 `}>
          <span className="robotomedium fsz-14 line-h-22">{formatAmount(record.amount, true)}</span>
        </Row>
      ),
    },
    {
      title: (
        <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('transactions.balanceAfter')}</span>
      ),
      dataIndex: 'balanceAfter',
      className: '_transaction-balance txt-right',
      key: 'balanceAfter',
      width: 150,
      render: (amount: any, record: any) => {
        return <span className={'flr'}>{formatAmount(amount)}</span>
      },
    },
  ]

  return (
    <MainLayout
      title={t('menu.transactions')}
      showProgressBar={showProgressBar}
      descriptions={{
        title: t('transactions.pageTitle'),
        descriptions: t('transactions.descriptions'),
        className: 'box-shadow-descriptions',
        pageCode: TRANSACTION_DESCRIPTIONS,
        hasDescription: true,
      }}
      breadcrumbs={{ total }}>
      <M22Box>
        <Card className={' m22-box-light border-card-header filter-card px-24 py-16 '}>
          <div
            className="flex cursor-pointer justify-content-between align-items-center"
            onClick={() => setShowFilter(!showFilter)}>
            <span className={'fsz-16  robotomedium line-h-24'}>{t('transactions.filterTitle')}</span>
            <i className={`fa-solid fa-angle-${!showFilter ? 'down' : 'up'} cursor-pointer`}></i>
          </div>
          {showFilter && (
            <>
              <Row
                align="middle"
                gutter={[16, 8]}
                className={`mg-t-12 form-filter ${!expand ? 'mg-bt-20' : ''}`}>
                <Col
                  span={8}
                  className="dpl-flex align-items-center">
                  <Row
                    align={'middle'}
                    gutter={12}>
                    <Col span={24}>
                      <ComboBox
                        label={`${t('transactions.createdAt')} ${t('transactions.from').toLowerCase()}`}
                        isRange
                        labelRange={t('transactions.to')}
                        value={[
                          filter.timestampFrom ? moment(filter.timestampFrom) : null,
                          filter.timestampTo ? moment(filter.timestampTo) : null,
                        ]}>
                        <RangePicker
                          size="large"
                          placeholder={['', '']}
                          className="width100pc"
                          format={'HH:mm DD-MM-YYYY'}
                          showTime={{
                            format: 'HH:mm',
                          }}
                          value={[
                            filter.timestampFrom ? moment(filter.timestampFrom) : null,
                            filter.timestampTo ? moment(filter.timestampTo) : null,
                          ]}
                          suffixIcon={<i className="fa-solid fa-calendar-range txt-color-gray9"></i>}
                          onChange={onChangeDate}
                          placement="topRight"
                        />
                      </ComboBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={`dpl-flex align-items-center justify-content-end`}>
                <div className="flex align-items-center">
                  {!hiddenResetFilter && (
                    <span
                      className={`m22-btn-reset ${theme}`}
                      onClick={resetFilter}>
                      <i className="fa-solid fa-arrow-rotate-right fsz-12 mg-r-6"></i>
                      <span>{t('filter.refresh')}</span>
                    </span>
                  )}
                  <Button
                    type="primary"
                    onClick={() => getData({ ...filter, page: 0 })}
                    className="m22-btn flex-basis-btn-filter"
                    icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                    {t('filter.btSearch')}
                  </Button>
                </div>
              </Row>
            </>
          )}
        </Card>
      </M22Box>

      <M22Box>
        <Card
          className={'transaction-card'}
          bordered={false}>
          <Row
            className="dpl-flex align-items-center pd-bt-12"
            justify={'space-between'}>
            <Col>
              <div className={'flex'}>
                <h3 className={`m22-typo-heading mg-0  mg-r-4 ${theme}`}>{t('transactions.list')}</h3>
                <Badge
                  count={total}
                  overflowCount={99999}
                  showZero={true}
                  style={{
                    backgroundColor: !total ? '#F5F5F5' : '',
                    color: !total ? '#707070' : '#1a1a1a',
                  }}
                />
              </div>
            </Col>
            <Col>
              <QuickPagination
                filter={filter}
                pagination={pagination}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
          <SkeletonTable
            loading={showProgressBar || isSkeleton}
            columns={columns as SkeletonTableColumnsType[]}
            className={`main-table main-table-orange-hovered m22-table ${theme}`}
            rowCount={transactions.length ?? 3}>
            <Table
              rowKey={(record) => `${record.id}${record.amount}`}
              dataSource={transactions}
              locale={{
                emptyText: (
                  <EmptyDataFilter
                    description={
                      !searchParams.get('types') &&
                      !searchParams.get('query') &&
                      !searchParams.get('timestampTo') &&
                      !searchParams.get('timestampFrom')
                        ? t('transactions.empty')
                        : t('transactions.filterEmpty')
                    }
                  />
                ),
              }}
              columns={columns}
              pagination={false}
              className={`m22-table ${theme}`}
              rowClassName={(record, idx) => {
                const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
                return `${rowClassesByIdx}`
              }}
            />
          </SkeletonTable>
          {transactions && transactions.length > 0 && (
            <M24Pagination
              onPageChange={onPageChange}
              filter={filter}
              pagination={pagination}
              className="mg-bt-0 mg-t-12"
            />
          )}
        </Card>
      </M22Box>
    </MainLayout>
  )
}

export default Transactions
const formatAmount = (amount: any, showPlus?: boolean) => {
  let color = amount < 0 ? 'txt-color-pink4' : 'txt-color-green'
  return (
    <span className={`${color} fsz-14 line-h-22 robotomedium`}>{`${
      showPlus && amount > 0 ? '+' : ''
    }${formatMoneyByUnit(amount)}`}</span>
  )
}
