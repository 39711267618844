import { Badge, Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
import { useTheme } from '../../../hooks/useTheme'
import QuickPagination from "../../../components/QuickPagination";
interface HeadingListProps {
  pagination: any
  typeShowData: string
  setTypeShowData: (val: string) => void
  onPageChange:(filter:any) => void
  filter:any
}
const HeadingList: React.FC<HeadingListProps> = (props) => {
  const [theme] = useTheme()
  const { typeShowData, pagination, setTypeShowData,filter, onPageChange  } = props
  const { t } = useTranslation()
  return (
    <>
      <div className="dpl-flex align-items-center mg-l-8 justify-content-between">
        <div className={'flex items-center'}>
          <h3 className="robotomedium mg-0 fsz-16 line-h-24 mg-r-4">{t('breadcrumbs.orders')}</h3>
          <Badge
              className=" mg-r-12"
              count={pagination?.total}
              overflowCount={99999}
              showZero={true}
              style={{
                fontWeight: '500',
                backgroundColor: !pagination?.total ? '#F5F5F5 ' : '',
                color: !pagination?.total ? '#707070' : '#1a1a1a',
              }}
          />
          <Tooltip title={<span className="fsz-12 line-h-20">{t('shipments.tableLayout')}</span>}>
          <span className={`heading-data-type ${theme} ${typeShowData === DATA_TABLE_LAYOUT ? 'active' : ''}`}>
            <i
                className={`fa-light fa-table-list`}
                onClick={() => setTypeShowData(DATA_TABLE_LAYOUT)}></i>
          </span>
          </Tooltip>
          <Tooltip title={<span className=" fsz-12 line-h-20">{t('shipments.listLayout')}</span>}>
          <span className={`heading-data-type ${theme} ${typeShowData === DATA_TABLE_LIST ? 'active' : ''}`}>
            <i
                className={`fa-light  fa-table-cells`}
                onClick={() => setTypeShowData(DATA_TABLE_LIST)}></i>
          </span>
          </Tooltip>
        </div>
        <div className={'flex'}>
          <QuickPagination
              filter={filter}
              pagination={pagination}
              onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  )
}

export default HeadingList
