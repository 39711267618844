import {ClaimStateInterface} from "../Interface/ClaimStateInterface";

export const ClaimStateModel: ClaimStateInterface = {
    code: "",
    color: "",
    createdAt: "",
    createdBy: "",
    description: "",
    id: 0,
    lastState: false,
    modifiedAt: "",
    modifiedBy: "",
    name: "",
    sort: 0
}
