import lodash from 'lodash'
import React, {useCallback, useLayoutEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import {DELIVERY_NOTE_DESCRIPTIONS} from '../../../core/config'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import M24Notification from '../../../utils/M24Notification'
import {getCleanFormFilter} from '../../../utils/StringUtils'
import Filter from './Filter'
import List from './List'

const InventoryVoucher = () => {
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  // const [accounts, setAccounts] = useState([])
  const [deliveryNotes, setDeliveryNotes] = useState([])
  const [size, setSize] = useState(Number(searchParams.get('size')))
  const [page, setPage] = useState(Number(searchParams.get('page')))
  const [total, setTotal] = useState(0)
  // const [idAcc, setIdAcc] = useState<string | undefined>()
  const [filter, setFilter] = useState<any>({})
  const [hasError, setHasError] = useState<boolean | null>(null)
  const [isSkeleton, setIsSkeleton] = useState(true)

  const { t } = useTranslation()

  const getData = useCallback(() => {
    // const customer = searchParams.get('customer')
    // const provider = searchParams.get('provider')
    const statuses = searchParams.get('statuses')
    const query = searchParams.get('query')
    const exportedAtFrom = searchParams.get('exportedAtFrom')
    const exportedAtTo = searchParams.get('exportedAtTo')
    const page = searchParams.get('page')
    const size = searchParams.get('size')

    const params = {
      // provider,
      // providerUsername: customer,
      code: query,
      exportedAtFrom,
      exportedAtTo,
      page,
      size,
      statuses,
    }
    setFilter((prevState: any) => ({ ...prevState, ...getCleanFormFilter(params) }))
    const controller = new AbortController()
    const { signal } = controller
    if (1) {
      setShowProgressBar(true)
      setIsSkeleton(true)
      deliveryRepository
        .getInventoryVoucher({ ...getCleanFormFilter(params) }, signal)
        .then((response: any) => {
          const currentPage = lodash.get(response, 'headers.x-page-number')
          const total = lodash.get(response, 'headers.x-total-count')
          setDeliveryNotes(response.data)
          setPage(parseInt(currentPage) + 1)
          setTotal(total ? parseInt(total) : 0)
          setSize(parseInt(lodash.get(response, 'headers.x-page-size')))
        })
        .catch((err: any) => {
          if (err.response.data.title === 'error_from_m6') {
            setHasError(true)
            setDeliveryNotes([])
          } else {
            M24Notification.notifyError(t('message.titleFailed'), t(`message.${lodash.get(err, 'response.data.title')}`), '_notify-error', 5)
          }
        })
        .finally(() => {
          setShowProgressBar(false)
          setTimeout(() => setIsSkeleton(false), 2000)
        })
    } else {
      setTimeout(() => setIsSkeleton(false), 3000)
    }
    return () => {
      setHasError(null)
      controller.abort()
    }
  }, [searchParams, t])

  // const getAccounts = useCallback(() => {
  //   supplierRepository
  //     .getListAccount()
  //     .then((res) => {
  //       const temp = res.filter(({ status }: any) => status === 'CONNECTED')
  //       let accounts = temp.map((item: any) => {
  //         return { ...item, label: item.provider.name.toString(), value: item.id.toString() }
  //       })
  //       if (accounts && accounts.length > 0) {
  //         const indexOfAccount = accounts.findIndex((acc: any) => acc.defaultConnection)
  //         const account = indexOfAccount > -1 ? accounts[indexOfAccount] : accounts[0]
  //         setIdAcc(lodash.get(account, 'id'))
  //       }
  //       setAccounts(accounts)
  //     })
  //     .catch((err) => {})
  // }, [])

  // useLayoutEffect(() => {
    // getAccounts()
  // }, [getAccounts])

  useLayoutEffect(() => {
    getData()
    return () => {
      setHasError(null)
    }
  }, [getData])

  const handleFilter = (params: any) => {
    if(params.query) {
      params.query = params.query.trim()
    }
    setFilter({ ...params })
    setSearchParams({ ...params })
  }

  const handleProgressBar = (val: boolean) => {
    setShowProgressBar(val)
  }

  // const handleAcc = (id: string | undefined) => setIdAcc(id)

  const filterChangeHandler = (obj: any) => {
    setFilter((prevState: any) => setFilter({ ...prevState, ...obj }))
  }

  return (
    <MainLayout
      title={t('deliveryNote.list')}
      showProgressBar={showProgressBar}
      descriptions={{
        title: t('deliveryNote.pageTitle'),
        descriptions: t('deliveryNote.descriptions'),
        className: 'box-shadow-descriptions',
        pageCode: DELIVERY_NOTE_DESCRIPTIONS,
        hasDescription: true,
      }}
      breadcrumbs={{ total }}>
      <Filter
        // accounts={accounts}
        // idAcc={idAcc}
        // handleAcc={handleAcc}
        handleFilter={handleFilter}
        handleProgressBar={handleProgressBar}
        filter={filter}
        filterChangeHandler={filterChangeHandler}
        loading={showProgressBar}
      />

      <List
        filter={filter}
        // idAcc={idAcc}
        items={deliveryNotes}
        // accounts={accounts}
        handleFilter={handleFilter}
        total={total}
        page={page}
        size={size}
        showProgressBar={showProgressBar}
        hasError={hasError}
        isSkeleton={isSkeleton}
      />
    </MainLayout>
  )
}

export default InventoryVoucher
