import React, { useState } from 'react'
import M22Box from '../../../components/M22Box'

import M24Pagination from '../../../components/Pagination/M24Pagination'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
import MarketPalaceInterface from '../../../Interface/MarketPalaceInterface'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import DataTable from './DataTable'
import ShipmentList from './List'
import { useSearchParams } from 'react-router-dom'

interface ShipmentItemsProps {
  data: ShipmentInterface[]
  statuses?: ShipmentStatusInterface[]
  loading: boolean
  pagination: {
    page: number,
    size: number,
    total: number,
  } | any
  isSkeleton: boolean
  filter: ShipmentsFilterInterface | {}
  marketPalaces: MarketPalaceInterface[] | any
  getData: () => void
  handleFilter: (params: ShipmentsFilterInterface | {}) => void
}

const ShipmentItems: React.FC<ShipmentItemsProps> = (props) => {
  const { data, statuses, loading, filter, pagination , isSkeleton } = props;
  // const {page, size, total} = pagination

  const [typeDataTable, setTypeDataTable] = useState(DATA_TABLE_LAYOUT);
  const [,setSearchParams] = useSearchParams();

  const pageChangeHandler = (filter: any) => {
    let temp = { ...filter }
    delete temp.offset
    delete temp.limit
    setSearchParams({ ...getCleanFormFilter({ ...temp }) })
  }

  return (
    <div>
      {typeDataTable === DATA_TABLE_LIST && (
        <ShipmentList
          typeDataTable={typeDataTable}
          isSkeleton={isSkeleton}
          handleTypeTableData={(val: string) => setTypeDataTable(val)}
          total={pagination?.total}
          filter={filter}
          statuses={statuses}
          data={data}
          loading={loading}
          pagination={{
            current: pagination?.page,
            pageSize: pagination?.size,
            total: pagination?.total,
          }}
          onPageChange={pageChangeHandler}
        />
      )}
      {typeDataTable === DATA_TABLE_LAYOUT && (
        <DataTable
          typeDataTable={typeDataTable}
          isSkeleton={isSkeleton}
          handleTypeTableData={(val: string) => setTypeDataTable(val)}
          total={pagination?.total}
          filter={filter}
          statuses={statuses}
          data={data}
          loading={loading}
          pagination={{
            current: pagination?.page,
            pageSize: pagination?.size,
            total: pagination?.total,
          }}
          onPageChange={pageChangeHandler}
        />
      )}

      {data && data.length > 0 && (
        <M22Box className="rad-bottom-6 pd-r-24">
          <M24Pagination
            onPageChange={pageChangeHandler}
            filter={{ ...filter, page: pagination?.page, size: pagination?.size }}
            pagination={{
              current: pagination?.page,
              pageSize: pagination?.size,
              hideOnSinglePage: true,
              total: pagination?.total,
            }}
            className=""
          />
        </M22Box>
      )}
    </div>
  )
}

export default ShipmentItems
