import { Avatar, Col, Row, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import ParagraphWithCopy from '../../../components/ParagraphWithCopyButton'
import { EMPTY_INFO } from '../../../core/config'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import HeadingList from './HeadingList'
import RowExtended from './RowExtended'
import { SkeletonTable } from '../../../components/SkeletonTable'
import { ColumnsType } from 'antd/lib/table/Table'

interface DataTableProps {
  items: any
  statuses: any
  pagination: any
  typeShowData: string
  handleTypeShowData: (val: string) => void
  loadingState: boolean
  filter: any
  onPageChange: (filter: any) => void
}
const DataTable: React.FC<DataTableProps> = (props) => {
  const { items, statuses, pagination, typeShowData, loadingState, handleTypeShowData, filter, onPageChange } = props
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [theme] = useTheme()

  const columns: ColumnsType<any> = [
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('shipment.codeTitle')}</span>,
      key: 'code',
      width: '43%',
      render: (record: any) => {
        return (
          <div className="flex flex-col">
            <div className="flex mg-bt-6">
              <Link
                to={`/orders/${record.code}`}
                className="mg-r-12">
                <Avatar
                  shape={'square'}
                  size={60}
                  src={lodash.get(record, 'image', '')}
                  className={'rad-4'}
                  style={{ border: 'none' }}
                />
              </Link>
              <Row>
                <Col
                  span={24}
                  className="flex align-items-center">
                  <Link
                    to={`/orders/${record.code}`}
                    className="mg-r-12">
                    <ParagraphWithCopy
                      text={record.code}
                      children={record.code ? record.code : EMPTY_INFO}
                    />
                  </Link>

                  <span className="whitespace mg-l-20">
                    <Tooltip title={t('orders.tooltipQuantity')}>
                      <span>{`${lodash.get(record, 'orderedQuantity', EMPTY_INFO)}/${
                        record.purchasedQuantity !== null ? record.purchasedQuantity : EMPTY_INFO
                      }/${record.receivedQuantity ?? EMPTY_INFO}`}</span>
                    </Tooltip>
                  </span>

                  <span className={`${theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'} mg-l-24`}>
                    {formatDateTime(lodash.get(record, 'createdAt', EMPTY_INFO))}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        )
      },
    },

    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('shipments.financeTotalFees')} </span>,
      key: 'amount',
      align: 'right',
      width: '10%',
      render: (record: any) => {
        let currency = lodash.get(record, 'provider.currency', '')
        if (currency) {
          currency = JSON.parse(currency)
          currency = currency.code
        }
        return <span className="m22-typo-money">{`${formatMoneyByUnit(lodash.get(record, 'provisionalAmount', ''), currency)}`}</span>
      },
    },

    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}> {t('shipments.financeTotalNeedToPaid')}</span>,
      key: 'totalUnpaid',
      align: 'right',
      width: '10%',
      render: (record: any) => {
        let currency = lodash.get(record, 'provider.currency', '')
        if (currency) {
          currency = JSON.parse(currency)
          currency = currency.code
        }
        const totalUnpaidStr = record.totalUnpaid ? formatMoneyByUnit(record.totalUnpaid, currency) : formatMoneyByUnit(0)
        return <span className={'m22-typo-money m22-typo-money-minus mgl3'}>{`${record.status !== 'CANCELLED' ? totalUnpaidStr : t('orders.cancelled')}`}</span>
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('orders.note')}</span>,
      key: 'createdAt',
      align: 'left',
      width: '17%',
      render: (record: any) => {
        const personNote = record.personalNote

        return (
          <div className={`w-full dot-container cursor-pointer ${theme === 'dark' ? 'txt-color-dark-secondary' : 'm22-typo-content_black-secondary'}`}>
            {record.personalNote && <Tooltip title={record.personalNote}>{record.personalNote ? personNote : EMPTY_INFO}</Tooltip>}
          </div>
        )
      },
    },
    {
      title: <span className={`fsz-12 mg-r-16 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('shipments.statusOfShipment')} </span>,
      key: 'status',
      align: 'right',
      width: '10%',
      render: (record: any) => {
        const status = statuses.find((x: any) => x.code === record.status)
        return {
          props: {
            className: 'tdhoverna',
          },
          children: (
            <span
              style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
              className="m22-tag-status">
              {lodash.get(status, 'name', EMPTY_INFO)}
            </span>
          ),
        }
      },
    },
  ]

  const title = () => (
    <HeadingList
      pagination={pagination}
      typeShowData={typeShowData}
      setTypeShowData={handleTypeShowData}
      filter={filter}
      onPageChange={onPageChange}
    />
  )

  const components = { body: { row: RowExtended } }

  return (
    <SkeletonTable
      columns={columns}
      loading={loadingState}>
      <Table
        columns={columns}
        dataSource={items}
        rowKey={(record: any) => record.id}
        className={`table-improved m22-table orders ${theme}`}
        pagination={false}
        rowClassName={(_, idx) => {
          const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
          return `${rowClassesByIdx}`
        }}
        components={items.length > 0 ? components : undefined}
        onRow={(record, index) => {
          const rowClassesByIdx = index! % 2 === 0 ? 'row-light' : 'row-dark'
          return {
            ...record,
            index,
            rowClassesByIdx,
          }
        }}
        title={title}
        locale={{
          emptyText: (
            <EmptyDataFilter
              description={
                !searchParams.get('code') && !searchParams.get('timestampFrom') && !searchParams.get('timestampTo') && !searchParams.get('marketplaces')
                  ? t('orders.filterEmpty')
                  : t('orders.emptyData')
              }
              className="flex flex-col justify-content-center"
            />
          ),
        }}
      />
    </SkeletonTable>
  )
}

export default DataTable
