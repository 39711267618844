import { Avatar, Button, Col, List, Row, Tooltip } from 'antd'
import lodash, { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import M22Box from '../../../components/M22Box'
import ParagraphWithCopy from '../../../components/ParagraphWithCopyButton'
import Services from '../../../components/Services'
import TruncateText from '../../../components/TruncateText'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import ShipmentImageEmptyDark from '../../../resources/images/emptyDark.png'
import ShipmentImageEmptyLight from '../../../resources/images/shipment-ubox-default.png'
import SkeletonOrders from '../../Orders/SkeletonOrders'
import HeaderList from './HeaderList'
interface ListShipmentProps {
  isSkeleton: boolean
  loading: boolean
  total: number
  filter: ShipmentsFilterInterface | {}
  statuses: ShipmentStatusInterface[] | any
  data: ShipmentInterface[]
  typeDataTable: string
  handleTypeTableData: (val: string) => void
  pagination: any
  onPageChange: (filter: any) => void
}
const ShipmentList: React.FC<ListShipmentProps> = (props) => {
  const { isSkeleton, loading, handleTypeTableData, total, filter, data, statuses, typeDataTable, pagination, onPageChange } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [theme] = useTheme()
  const ShipmentImageDefault = theme === 'dark' ? ShipmentImageEmptyDark : ShipmentImageEmptyLight
  return (
    <M22Box className="pd-0 mg-bt-0 rad-6-top ">
      {!loading && !isSkeleton ? (
        <List
          className={`shipments-items ${theme}`}
          pagination={false}
          header={
            <HeaderList
              total={total}
              typeDataTable={typeDataTable}
              setTypeDataTable={(val: string) => handleTypeTableData(val)}
              filter={filter}
              pagination={pagination}
              onPageChange={onPageChange}
            />
          }
          locale={{
            emptyText:
              !searchParams.get('code') &&
              !searchParams.get('originalId') &&
              !searchParams.get('merchantName') &&
              !searchParams.get('waybill') &&
              !searchParams.get('statuses') &&
              !searchParams.get('createdFrom') &&
              !searchParams.get('createdTo') &&
              !searchParams.get('providerUsername') &&
              !searchParams.get('provider') ? (
                <EmptyDataFilter
                  className={`shipments-empty ${isSkeleton || loading ? 'dpl-none' : ''}`}
                  description={
                    <>
                      <span className="txt-color-gray8">{isEmpty(filter) ? t('shipments.noResult') : t('shipments.empty')}</span>
                      <Button
                        type="primary"
                        className={`m22-btn ${theme}`}
                        icon={<i className="fa-regular fa-plus mg-r-10"></i>}
                        disabled
                        onClick={() => navigate('/shipments/create-consignment', { replace: true })}>
                        {t('shipments.createShipments')}
                      </Button>
                    </>
                  }
                />
              ) : (
                <EmptyDataFilter description={t('shipments.filterEmpty')} />
              ),
          }}
          dataSource={data}
          renderItem={(item: ShipmentInterface, idx) => {
            const status = statuses.find((stt: ShipmentStatusInterface) => stt.code === item.status)

            return (
              <M22Box className={'mg-bt-8'}>
                <Row
                  key={item.id || item.code}
                  className={`_order-item ${theme} rad-6 flex px-16 py-12 ${
                    idx! % 2 === 0
                      ? `${theme === 'light' ? 'row-light' : ''}`
                      : `${theme === 'light' ? 'row-dark-light-mode' : 'theme-row-dark'}`
                  }`}
                  onClick={() => navigate(`/shipments/${item.code}`)}>
                  <Col
                    flex={'0 0 auto'}
                    className="mg-r-12">
                    <Link to={`/shipments/${item.code}`}>
                      <Avatar
                        shape={'square'}
                        size={84}
                        src={lodash.get(item, 'image', '') ?? ShipmentImageDefault}
                        className={'rad-6'}
                        style={{ border: 'none' }}
                      />
                    </Link>
                  </Col>

                  <div
                    className=""
                    style={{ width: 'calc(100% - 96px)' }}>
                    <Row>
                      <Col span={8}>
                        <Col flex="1 0 auto">
                          <Row
                            align="middle"
                            className={'mg-bt-4 flex-nowrap mg-bt-6'}>
                            <Col
                              flex={'0 0 350px'}
                              className="flex align-items-center">
                              <span className={'align-items-center txt-color-primary mg-r-16'}>
                                <Link to={`/shipments/${item.code}`}>
                                  <ParagraphWithCopy
                                    text={item.code}
                                    children={item.code ? item.code : '---'}
                                  />
                                </Link>
                              </span>
                              <span className={'fsz-14  mg-r-16  flex align-items-center'}>
                                <Tooltip title={t('shipments.quantifyTooltip')}>
                                  <span className="mg-r-3 whitespace">{`${
                                    item.orderedQuantity ? item.orderedQuantity : '---'
                                  } / ${item.receivedQuantity ? item.receivedQuantity : '---'}`}</span>
                                </Tooltip>
                              </span>

                              <span className={'fsz-14   flex align-items-center'}>
                                <Tooltip title={t('shipments.createAtTooltip')}>
                                  <span
                                    className={`fsz-14 line-h-22 ${
                                      theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'
                                    }`}>
                                    {item.timestamp ? formatDateTime(item.timestamp) : '---'}
                                  </span>
                                </Tooltip>
                              </span>
                            </Col>
                          </Row>

                          {/* <Row className="flex justify-content-start">
                            <Col
                              flex={'0 0 350px'}
                              className={'flex align-items-center gap-4'}>
                              <div className={'flex align-items-center justify-content-start flex-nowrap'}>
                                <Tooltip title="Nhà cung cấp">
                                  <Avatar
                                    size={20}
                                    src={account?.provider?.logo}
                                    shape={'circle'}
                                    className="bd-none-impt mg-r-4"
                                  />
                                  <span>{account?.provider.name}</span>
                                </Tooltip>
                              </div>
                              {item?.midMileView?.[0]?.name && (
                                <>
                                  |
                                  <span>
                                    <Tooltip title="Vận chuyển Midmile">
                                      <Avatar
                                        src={item?.midMileView?.[0]?.logo}
                                        size={20}
                                        className="mg-r-2"
                                      />
                                      <span>{item?.midMileView?.[0]?.name}</span>
                                    </Tooltip>
                                  </span>
                                </>
                              )}
                            </Col>
                          </Row> */}
                        </Col>
                      </Col>

                      <Col span={7}>
                        <Col flex={'1 0 auto'}>
                          <Col className={'flex align-items-center mg-bt-6'}>
                            <div className="flex align-items-center mg-bt-4">
                              <i className="fa-light fa-store mg-r-4" />
                              <span className=" fsz-12 line-h-20 whitespace">
                                {item.merchantName ? item.merchantName : '---'}
                              </span>
                            </div>
                          </Col>
                          <Col className={'flex align-items-center '}>
                            <span
                              className={` ${
                                theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'
                              } mg-r-3 whitespace`}>
                              {t('shipments.refCustomerCodeLabel')}:
                            </span>
                            <TruncateText
                              className="fsz-14 line-h-22"
                              defaultValue={item.refShipmentCode ? item.refShipmentCode : '---'}
                            />
                          </Col>
                        </Col>
                      </Col>

                      <Col span={5}>
                        <Col flex="2 0 auto">
                          <Col className={'flex align-items-center mg-bt-6'}>
                            <span className={' fsz-14 line-h-22  whitespace'}>
                              <span
                                className={` ${
                                  theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'
                                } mg-r-3 whitespace`}>
                                {t('orders.finance')}:
                              </span>
                              <span className="font-medium">{`${
                                item.totalFee ? formatMoneyByUnit(item.totalFee) : '---'
                              }`}</span>
                            </span>
                          </Col>
                          <Col className={'flex align-items-center '}>
                            <span className={'fsz-14 line-h-22  whitespace'}>
                              <span
                                className={`${
                                  theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'
                                } mg-r-3 whitespace`}>
                                {t('orders.needPaid')}:
                              </span>
                              <span
                                className={
                                  'robotomedium m22-typo-money m22-typo-money-minus mgl3 fsz-14 line-h-22'
                                }>{`${
                                item.status !== 'CANCELLED'
                                  ? formatMoneyByUnit(lodash.get(item, 'totalUnpaid', ''))
                                  : t('orders.cancelled')
                              }`}</span>
                            </span>
                          </Col>
                        </Col>
                      </Col>
                      <Col
                        span={4}
                        className="flex align-items-center justify-content-end">
                        <span
                          style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
                          className={
                            'rad-25 item-align-center txt-color-white fsz-14 line-h-22 robotomedium px-16 py-4'
                          }>
                          {lodash.get(status, 'name', '--')}
                        </span>
                      </Col>
                    </Row>
                    <div
                      style={{
                        borderTop: ` 1px solid ${theme === 'dark' ? '#333333' : '#EBEBEB'}`,
                        margin: '6px 0',
                      }}
                    />
                    <Row>
                      <span
                        className={`fsz-14 line-h-22 txt-color-${
                          theme === 'light' ? 'secondary' : 'gray2'
                        } mgr3 whitespace`}>
                        {t('orders.services')}:
                      </span>
                      <Services
                        data={item.services}
                        className="fsz-14 line-h-22"
                        wrapperClass=""
                      />
                    </Row>
                  </div>
                </Row>
              </M22Box>
            )
          }}></List>
      ) : (
        <SkeletonOrders
          data={data}
          loading={isSkeleton || loading}
        />
      )}
    </M22Box>
  )
}

export default ShipmentList
