import M22Box from '../../components/M22Box'
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { shipmentCategoriesRepository } from '../../repositories/ShipmentCategoriesRepository'
import { Button, Checkbox, Radio, RadioChangeEvent } from 'antd'
import { ServiceInterface } from '../Shipment/Create/mock-data'
import { FirstStepType } from '.'
import { IBodySaveService } from 'src/Interface/WarehoustListInterface'
import { warehouseRepository } from 'src/repositories/WarehouseRepository'
import M24Notification from 'src/utils/M24Notification'
import { ITransportShipmentType } from 'src/Interface/ServiceInterface'

interface WarehouseListStepOneProps {
  firstStepState: FirstStepType
  configSaved?: any
  setFirstStepState: Dispatch<SetStateAction<FirstStepType>>
  setSecondStepState: Dispatch<SetStateAction<any>>
  setConfigSaved: any
}

const WarehouseListStepOne: React.FC<WarehouseListStepOneProps> = ({
  configSaved = {},
  firstStepState,
  setFirstStepState,
  setSecondStepState,
  setConfigSaved,
}) => {
  const configId = configSaved?.id
  const { services } = firstStepState

  const checkServiceTransportTypeExist = (data: ITransportShipmentType, configSaved: any) => {}
  const fetchTransportTypesData = useFetch(shipmentCategoriesRepository.getTransportShipmentTypes, (data) =>
    checkServiceTransportTypeExist(data, configSaved)
  )

  const fetchOtherServices = useFetch(shipmentCategoriesRepository.getOtherProviderService)
  const [isChanged, setIsChanged] = useState(false)

  const groupOtherService = useMemo(() => {
    const otherServices: any[] = []
    fetchOtherServices?.data?.group?.forEach((group: any) => {
      group.services.forEach((service: any) => {
        otherServices.push(service)
      })
    })
    return otherServices
  }, [fetchOtherServices?.data?.group])

  const handleChangeService: any = useCallback(
    (groupCode: string, value: any) => {
      let temp = firstStepState.services
      if (groupCode) {
        temp[groupCode] = value
      }
      setFirstStepState({
        ...firstStepState,
        services: temp,
      })

      const newService = configSaved.service || {}
      if (groupCode === 'type') {
        newService['transport-shipment-type'] = fetchTransportTypesData.data.find((t: any) => t.code === value)
      } else {
        const existIndex = (newService?.groups || []).findIndex((t: any) => t.groupCode === groupCode)
        const selectedService = groupOtherService.find((t: any) => t.code === value)

        // đã check thì cập nhật
        if (existIndex !== -1) {
          if (value) {
            newService.groups[existIndex] = selectedService
          } else {
            newService.groups.splice(existIndex, 1)
          }
        } else {
          newService.groups.push(selectedService)
        }
      }
      setConfigSaved({ ...configSaved, service: newService })
      setIsChanged(true)
    },
    [configSaved, fetchTransportTypesData.data, firstStepState, groupOtherService, setConfigSaved, setFirstStepState]
  )

  const saveServices = () => {
    const body: IBodySaveService = {
      service: {
        groups: buildServiceGroups(fetchOtherServices.data?.group || [], services),
        'transport-shipment-type': buildTransportShipmentType(services.type, fetchTransportTypesData.data || []),
      },
    }

    if (configId) {
      body.id = configId
    }

    warehouseRepository.saveService(body).then(() => {
      setFirstStepState({ ...firstStepState, allow: false })
      setSecondStepState({ allow: true })
      M24Notification.notifySuccess('Thành công', 'Lưu dịch vụ thành công')
      setIsChanged(false)
    })
  }

  const renderTransportShipmentType = useMemo(() => {
    const { loading, error, data } = fetchTransportTypesData
    if (data && !loading)
      return (
        <div className="flex">
          <span
            className="mg-r-8 txt-muted"
            style={{ flex: '0 0 140px' }}>
            Hình thức:
          </span>
          {!loading && !error ? (
            <Radio.Group
              className="flex-wrap flex-grow-1 flex-impt"
              value={services.type}
              onChange={(e: RadioChangeEvent) => {
                handleChangeService('type', e.target.value)
              }}>
              {data.map((x: any, idx: any) => (
                <Radio
                  key={x.code}
                  style={{
                    flexBasis: 250,
                    flexGrow: 0,
                    marginBottom: 8,
                  }}
                  value={x.code}>
                  {x.name}
                </Radio>
              ))}
            </Radio.Group>
          ) : (
            <span>Empty</span>
          )}
        </div>
      )
  }, [fetchTransportTypesData, handleChangeService, services.type])

  const renderOtherServices = useMemo(() => {
    const { loading, data } = fetchOtherServices
    let filteredData = []
    if (data?.group) {
      filteredData = data.group
    }
    if (data && !loading)
      return (
        <>
          <div className={'font-medium mb-5 text-lg'}>Tiêu chí khác:</div>
          {filteredData.map((g: any) => {
            return (
              <div
                className={'mg-bt-18 flex'}
                key={g.name}>
                <span
                  className="mg-r-8 txt-muted"
                  style={{ flex: '0 0 140px' }}>
                  {g.name}:{' '}
                </span>
                <Checkbox.Group
                  className="flex-wrap flex-grow-1 flex-impt"
                  onChange={(e) => {
                    handleChangeService(g.code, e.at(-1))
                  }}
                  value={services[g.code] ? [services[g.code]] : []}>
                  {g.services?.map((item: ServiceInterface) => {
                    return (
                      <Checkbox
                        style={{
                          flex: '0 0 250px',
                        }}
                        value={item.code}
                        key={item.code}>
                        {item.name}
                      </Checkbox>
                    )
                  })}
                </Checkbox.Group>
              </div>
            )
          })}
        </>
      )
  }, [fetchOtherServices, handleChangeService, services])

  const renderServiceSelect = () => {
    const configTemp = configSaved.service
    let names = []
    if (configTemp['transport-shipment-type']) {
      names.push(configTemp['transport-shipment-type'].name)
    }

    configTemp.groups?.forEach((g: any) => {
      g?.name && names.push(g.name)
    })
    return <span>{names.join(', ')}</span>
  }

  return (
    <M22Box className={`pd-16 rad-6 `}>
      <div
        className={`flex gap-8 items-center cursor-pointer`}
        onClick={() => setFirstStepState({ ...firstStepState, allow: !firstStepState.allow })}>
        {!firstStepState?.allow ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-up"></i>}
        <span className={'txt-muted'}>Bước 1.</span>{' '}
        <span className={'title-body robotomedium fsz-14'}>Chọn dịch vụ</span>
        <div className={'txt-muted'}>{!firstStepState?.allow ? renderServiceSelect() : ''}</div>
      </div>

      {firstStepState.allow && (
        <div
          className={'px-18'}
          style={{ marginTop: 24 }}>
          <div>{renderTransportShipmentType}</div>
          <div style={{ marginTop: 16 }}>{renderOtherServices}</div>
          <div
            className="flex items-center gap-16"
            style={{ marginTop: 24 }}>
            <Button
              type={'primary'}
              disabled={!services.type || !isChanged}
              onClick={saveServices}>
              <i className="fa-solid fa-check" /> Lưu dịch vụ
            </Button>

            <span className="txt-muted">Bạn có thể chỉnh sửa lại dịch vụ khi cần</span>
          </div>
        </div>
      )}
    </M22Box>
  )
}

export default WarehouseListStepOne

const buildTransportShipmentType = (type: string, transportTypes: any[]) => {
  const transportShipmentType = transportTypes.find((t: any) => t.code === type)

  return {
    code: transportShipmentType?.code,
    codeDisplay: transportShipmentType?.codeDisplay,
    name: transportShipmentType?.name,
  }
}

const buildServiceGroups = (groups: any[], services: { [key: string]: string }) => {
  const serviceGroups: any[] = []

  groups.forEach((groupType: any) => {
    groupType.services.forEach((service: any) => {
      if (service.code === services[groupType.code]) {
        serviceGroups.push({
          code: service.code,
          codeDisplay: service.codeDisplay,
          groupCode: service.groupCode,
          name: service.name,
        })
      }
    })
  })

  return serviceGroups
}
