import { Button, Empty, Image, Table, Tag, Tooltip } from 'antd'
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import M24Pagination from 'src/components/Pagination/M24Pagination'
import CreateAddressModal from 'src/components/ShoppingCart/Address/CreateAddressModal'
import { cartRepository } from 'src/repositories/CartRepository'
import M24ErrorUtils from 'src/utils/M24ErrorUtils'
import M24Notification from 'src/utils/M24Notification'
import { getCleanFormFilter } from 'src/utils/StringUtils'
import EmptyImg from 'src/resources/images/table-contents/empty.png'
import { useTheme } from 'src/hooks/useTheme'
import ConfirmModal from 'src/components/Modal/ConfirmModal'
import { SkeletonTable } from 'src/components/SkeletonTable'

const AddressConfig = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [addressList, setAddressList] = useState<any>([])
  const [deliveryAddress, setDeliveryAddress] = useState<any>(null)
  const [selectedAddress, setSelectedAddress] = useState<any>()
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState(false)
  const [deleteItem, setDeleteItem] = useState<any>()
  const [total, setTotal] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(Number(searchParams.get('page')))
  const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
  const [filter, setFilter] = useState<any>()
  const [theme] = useTheme()

  const getAddressList = useCallback(
    (isReset?: boolean) => {
      setIsLoading(true)
      const page = searchParams.get('page')
      const size = searchParams.get('size')
      const temp = {
        page,
        size,
      }
      setFilter((prevState: any) => ({
        ...prevState,
        ...getCleanFormFilter(temp),
      }))
      cartRepository
        .getAddressListWithPagination(getCleanFormFilter(temp))
        .then((response) => {
          if (response.data.length > 0) {
            const defaultAddress = response.data.find((x: any) => x.isDefault)
            if (defaultAddress) {
              setAddressList([defaultAddress, ...response.data.filter((x: any) => !x.isDefault)])
            } else {
              setAddressList(response.data)
            }
            if (!deliveryAddress || isReset) {
              setDeliveryAddress(defaultAddress)
            } else {
              let temp = response.data.find((x: any) => x.id === deliveryAddress.id)
              setDeliveryAddress(temp)
            }
            const currentPage = lodash.get(response, 'headers.x-page-number')

            const total = lodash.get(response, 'headers.x-total-count')
            setPage(parseInt(currentPage) + 1)
            setTotal(parseInt(total))
            setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
          } else {
            setAddressList([])
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  useEffect(() => {
    getAddressList()
  }, [getAddressList])

  const columns1 = [
    {
      title: <span className={'txt-centermb'}>{t('address.customer-name')}</span>,
      dataIndex: 'receiver',
      key: 'receiver',
      width: 240,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotomedium'}>{record.receiver}</span>
      },
    },
    {
      title: t('address.customer-phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotoregular'}>{record.phoneNumber}</span>
      },
    },
    {
      title: t('address.address-name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotoregular'}>{record.name}</span>
      },
    },
    {
      title: t('address.address-descriptions'),
      dataIndex: 'detail',
      key: 'detail',
      width: 500,
      render: (value: any, record: any) => {
        return (
          <div className="flex flex-col">
            <div className="flex flex-col">
              <span className={'txt-size-h7 txt-primary'}>{record.address1}</span>
              <span
                className={`txt-size-h7 ${theme} txt-secondary`}
                style={theme === 'dark' ? { color: '#666666' } : {}}>
                {record.display}
              </span>
            </div>
            {record.isDefault && (
              <div className={`mg-t-8 ${theme}`}>
                <Tag
                  className={`rad-23 default-badge_${theme}`}
                  style={{ padding: '2px 12px' }}>
                  <span className={'txt-size-h7 txt-color-black robotomedium'}>{t('address.status-isDefault')}</span>
                </Tag>
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: <span className={'flr pd-r-24'}>{t('address.address-actions')}</span>,
      dataIndex: 'action',
      key: 'action',
      width: 130,
      render: (_: any, record: any) => {
        return (
          <div className="flex align-items-center justify-content-end">
            <Tooltip title={t('address.edit-action')}>
              <span
                className={`edit-action_${theme} cursor-pointer mg-r-12`}
                onClick={() => {
                  setOpenModalAdd(true)
                  setSelectedAddress(record)
                }}>
                <i className="fa-regular fa-pen-to-square"></i>
              </span>
            </Tooltip>

            <Tooltip title={t('address.delete-action')}>
              <span
                style={record.isDefault ? { pointerEvents: 'none' } : {}}
                className={`edit-action mg-r-12 ${!record.isDefault ? 'cursor-pointer txt-error' : 'txt-disabled'}`}
                onClick={() => {
                  setDeleteItem(record)
                  setIsDelete(true)
                }}>
                {!record.isDefault ? <i className="fa-regular fa-trash"></i> : <i className="fa-regular fa-trash-slash"></i>}
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const onSubmitCreateModal = useCallback(
    (body: any) => {
      setIsLoading(true)
      if (selectedAddress) {
        cartRepository
          .updateAddress(selectedAddress.id, body)
          .then((response) => {
            M24Notification.messageSuccess(t('message.success'))
            getAddressList()
            handleCloseAddressCreate()
            setSelectedAddress(null)
          })
          .catch((error) => {
            M24ErrorUtils.showError(t, error)
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        cartRepository
          .createAddress(body)
          .then((response) => {
            M24Notification.messageSuccess(t('message.success'))
            getAddressList()
            handleCloseAddressCreate()
          })
          .catch((error) => {
            M24ErrorUtils.showError(t, error)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    },
    [getAddressList, selectedAddress, t]
  )

  const handleCloseAddressCreate = () => {
    setOpenModalAdd(false)
    setSelectedAddress(null)
  }

  const deleteAddress = () => {
    setIsLoading(true)
    cartRepository
      .deleteAddress(deleteItem.id)
      .then((res) => {
        M24Notification.messageSuccess(t('message.success'))
        getAddressList(deliveryAddress?.id === deleteItem?.id)
        setDeleteItem(null)
        setIsDelete(false)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onCancelDelete = () => {
    setDeleteItem(null)
    setIsDelete(false)
  }

  const pageChangeHandler = (filter: any) => {
    const { page, size } = filter
    const params = getCleanFormFilter(
      {
        ...filter,
        page: (page - 1).toString(),
        size: size.toString(),
      },
      [],
      ['pageSize']
    )
    setFilter({ ...params })
    setSearchParams(
      getCleanFormFilter({
        ...params,
        tab: 'addresses',
      })
    )
  }

  return (
    <div className={`pd-12 m22-box-${theme} rad-6 width100pc `}>
      {openModalAdd && (
        <CreateAddressModal
          isVisible={openModalAdd}
          onCancel={handleCloseAddressCreate}
          onSubmit={onSubmitCreateModal}
          datasource={selectedAddress}
          isDefault={addressList.length === 0}
          // isProfile={true}
          title={selectedAddress ? 'Sửa Địa Chỉ Giao Hàng' : 'Thêm Địa Chỉ Giao Hàng'}
          loading={isLoading}
        />
      )}

      {isDelete && (
        <ConfirmModal
          content={t('address.delete-confirm-message')}
          visible={isDelete}
          loading={isLoading}
          btnType="danger"
          onCancel={onCancelDelete}
          onSubmit={deleteAddress}
        />
      )}
      <SkeletonTable
        columns={columns1}
        loading={isLoading}>
        <Table
          rowKey={(record: any) => record?.id}
          className={`m22-table m22-table-address ${theme}`}
          dataSource={addressList}
          columns={columns1}
          loading={isLoading}
          locale={{
            emptyText: (
              <Empty
                image={
                  <Image
                    src={EmptyImg}
                    width={80}
                    height={48}
                    preview={false}
                  />
                }
                description={
                  <div className="flex flex-col align-items-center">
                    <span className=" fsz-14 line-h-22 txt-color-gray4 mg-bt-12">Danh sách trống, hãy thêm địa chỉ mới!</span>
                  </div>
                }
              />
            ),
          }}
          title={() => (
            <div className="flex flex-nowrap align-items-center justify-content-between">
              <span className="robotomedium fsz-20 line-h-24  whitespace mg-r-12">{t('profile.addresses')}</span>
              <div className="divider-line-h-1" />
              <Button
                className={`m22-btn m22-btn-outline ${theme}`}
                onClick={() => setOpenModalAdd(true)}
                icon={<i className="fa-solid fa-plus mg-r-10"></i>}>
                {t('address.create-address-btn')}
              </Button>
            </div>
          )}
          pagination={false}
          rowClassName={(record, idx) => {
            const rowClassesByIdx = idx % 2 === 0 ? 'row-dark' : 'row-light'
            return `${rowClassesByIdx}`
          }}
        />
      </SkeletonTable>

      <div className="flex justify-content-end">
        {addressList && addressList.length > 0 && (
          <M24Pagination
            onPageChange={pageChangeHandler}
            filter={{ ...filter, page, size: pageSize }}
            pagination={{
              current: page,
              pageSize,
              hideOnSinglePage: true,
              total,
            }}
            className="mg-t-16 mg-bt-4 m22-pagination"
          />
        )}
      </div>
    </div>
  )
}

export default AddressConfig
