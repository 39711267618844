import React from 'react'
import { Select } from 'antd/lib/index'
import { useTranslation } from 'react-i18next'
import { PAGINATION_SIZE } from '../../core/config'
import { useTheme } from '../../hooks/useTheme'
const { Option } = Select
export interface SizeChangerProps {
  onChange: (size: number) => void
  size: number
}
const SizeChanger = (props: SizeChangerProps) => {
  const [theme] = useTheme()
  const { size, onChange } = props
  const { t } = useTranslation()
  const pagination = PAGINATION_SIZE.filter((size: number) => size !== 20)

  return (
    <Select
      // style={{ width: 100 }}
      className={`_size-changer ${theme}`}
      onChange={(size) => onChange(size)}
      value={size}
      popupClassName={theme}
      suffixIcon={<i className="fa-solid fa-angle-down fsz-14" />}>
      {pagination.map((size: number) => (
        <Option
          key={size}
          value={size}>{`${size}/${t('pagination.page')}`}</Option>
      ))}
    </Select>
  )
}
export default SizeChanger
