import {Avatar} from "antd";
import React, {PropsWithChildren} from "react";
import moment from 'moment';
import {NoticeItemInterface} from "../../../Interface/NoticeItemInterface";
import lodash from "lodash";
import logo from '../../../resources/images/dauhop-only.png'
import {Link} from "react-router-dom";

interface Props {
  item: NoticeItemInterface
}

interface NoticeItemLinkProps extends PropsWithChildren<any> {
  item: NoticeItemInterface
  className?: string
}

const NoticeItemLink = (props: NoticeItemLinkProps) => {
  const defaultClassName = 'notification-item-inner'
  const sourceType = props.item.sourceType
  const generateClassName = () => {
    return props.className ? `${defaultClassName} ${props.className}` : defaultClassName
  }
  switch (sourceType) {
    case 'order':
      const orderCode = lodash.get(props.item, 'refData.order.code')
      const packageCode = lodash.get(props.item, 'refData.order.updatedPackageCode')
      if (orderCode && packageCode) {
        return (
          <Link
            className={generateClassName()}
            to={'/packages'}>
            {props.children}
          </Link>
        )
      }
      return (
        <Link
          className={generateClassName()}
          to={`/orders/${orderCode}`}
          target={'_blank'}>
          {props.children}
        </Link>
      )

    case 'financial':
      return (
        <Link
          className={generateClassName()}
          to={`/transactions`}>
          {props.children}
        </Link>
      )

    case 'delivery':
      return (
        <Link
          className={generateClassName()}
          to={`/delivery`}>
          {props.children}
        </Link>
      )

    default:
      return <div className={generateClassName()}>{props.children}</div>
  }
}

export const NoticeItem = (props: Props) => {
  const { item } = props
  const noticeTime = item.publishDate ? moment(item.publishDate).format('HH:mm DD/MM/YYYY') : '--'
  const noticeImage = lodash.get(item, 'refData.order.image')
  return (
    <div className={`notification-item`}>
      <NoticeItemLink
        item={item}
        className={'notification-item-inner'}>
        <Avatar
          size={40}
          src={noticeImage || logo}
          className={`bd-none min-w-[40px] notification-img ${!noticeImage ? 'default-img' : ''}`}
          shape="circle"
        />
        <div className="notice-item-content">
          <div className="notification-item-title">{item.messageData}</div>
          <div className="notification-item-time">{noticeTime}</div>
        </div>
      </NoticeItemLink>
    </div>
  )
}
