import { ReloadOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ComboBox from '../../../components/ComboBox'
import M22Box from '../../../components/M22Box'
import { FORM_KEY_PROVIDER, FORM_KEY_PROVIDER_USER_NAME, FORM_KEY_SUPPLIER } from '../../../core/config'
import { useTheme } from '../../../hooks/useTheme'
import { getCleanFormFilter } from '../../../utils/StringUtils'

const { Item } = Form
const { RangePicker } = DatePicker
interface FilterProps {
  // accounts: any
  // idAcc: string | undefined
  filter: any
  loading: boolean
  handleFilter: (filter: any) => void
  handleProgressBar: (val: boolean) => void
  // handleAcc: (val: string | undefined) => void
  filterChangeHandler: (obj: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [theme] = useTheme()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilter, setShowFilter] = useState(true)
  const [showCleanFilter, setShowCleanFilter] = useState(false)

  const { handleFilter, filter, filterChangeHandler } = props

  useEffect(() => {
    setShowCleanFilter(
      !lodash.isEmpty(
        lodash.pickBy(filter, (val: any, key: string) => {
          // console.log(key,val)
          if (key !== FORM_KEY_PROVIDER && key !== FORM_KEY_PROVIDER_USER_NAME && key !== FORM_KEY_SUPPLIER) {
            if (isArray(val)) {
              return val.every((e) => e) && !isEmpty(val)
            }
            return !isEmpty(val)
          }
        })
      )
    )
  }, [filter, searchParams])

  useEffect(() => {
    // if (searchParams.get('customer') && searchParams.get('provider')) {
    //   const indexOfAccount = accounts.findIndex((acc: any) => acc.name === searchParams.get('customer') && acc.provider.code === searchParams.get('provider'))
    //   if (indexOfAccount > -1) {
    //     handleAcc(lodash.get(accounts[indexOfAccount], 'id'))
    //   }
    // } else {
    //   const index = accounts.findIndex((acc: any) => acc.id === idAcc)
    //   if (index > -1) {
    //     setSearchParams({
    //       customer: lodash.get(accounts[index], 'name'),
    //       provider: lodash.get(accounts[index], 'provider.code'),
    //     })
    //   }
    // }
    form.setFieldsValue({
      // supplier: idAcc,
      query: searchParams.get('query'),
      exportedAt: [
        searchParams.get('exportedAtFrom') ? moment(searchParams.get('exportedAtFrom')) : null,
        searchParams.get('exportedAtTo') ? moment(searchParams.get('exportedAtTo')) : null,
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, searchParams, setSearchParams])

  const getFormState = () => {
    // const supplier = form.getFieldValue('supplier')
    // const account = accounts.find((x: any) => x.id === supplier)
    // const customer = lodash.get(account, 'name')
    // const provider = lodash.get(account, 'provider.code')
    const exportedAt = form.getFieldValue('exportedAt')
    const exportedAtFrom = exportedAt && exportedAt[0] && moment(exportedAt[0].startOf('day')).toISOString()
    const exportedAtTo = exportedAt && exportedAt[1] && moment(exportedAt[1].endOf('day')).toISOString()

    const filter = getCleanFormFilter(
      {
        ...form.getFieldsValue(true),
        // customer,
        // provider,
        exportedAtFrom,
        exportedAtTo,
      },
      [],
      ['supplier', 'exportedAt']
    )
    return filter
  }

  // const handleChangeAcc = (val: string) => {
  // handleAcc(val)
  // handleProgressBar(true)
  // onFinish()
  // }

  const onFinish = () => {
    handleFilter(getFormState())
  }

  const clearFilter = () => {
    form.resetFields()
    // console.log(form.getFieldsValue(true))
    handleFilter({ ...getFormState() })
  }

  return (
    <M22Box className="pd-0">
      <Row
        className={`m22-box-light border-card-header px-24 py-16 ${!showFilter ? 'cursor-pointer' : ''}`}
        onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}>
        <Row
          justify="space-between"
          wrap={false}
          gutter={8}
          align="middle"
          className={`width100pc cursor-pointer ${showFilter ? 'mg-bt-16' : ''} dpl-flex justify-content-between `}
          onClick={() => setShowFilter(!showFilter)}>
          <Col>
            <span className={`m22-typo-heading ${theme}  ${!showFilter ? 'mg-0' : ''}`}>{t('deliveryNote.find')}</span>
          </Col>

          <Col className="dpl-flex justify-content-end">
            <i
              className={`fas fa-angle-${showFilter ? 'up' : 'down'}  fsz-14 cursor-pointer`}
              onClick={() => setShowFilter(!showFilter)}></i>
          </Col>
        </Row>
        {showFilter && (
          <>
            <Col span={24}>
              <Form
                name="note-delivery"
                onFinish={onFinish}
                className="form-filter"
                form={form}
                initialValues={{
                  query: '',
                  // supplier: idAcc,
                  exportedAt: [],
                }}
                onValuesChange={(changedValues, allValues) => {
                  filterChangeHandler(allValues)
                }}>
                <Row
                  align="middle"
                  gutter={[16, 8]}
                  className="mg-bt-12">
                  <Col
                    span={8}
                    className="mg-bt-0 flex align-items-center">
                    <ComboBox
                      label={t('deliveryNote.queryPlaceholder')}
                      value={form.getFieldValue('query')}
                      labelWidth={0}>
                      <Item
                        labelAlign="left"
                        className="query mg-bt-0"
                        name={'query'}>
                        <Input
                          placeholder={''}
                          allowClear
                          size="large"
                        />
                      </Item>
                    </ComboBox>
                  </Col>

                  <Col span={8}>
                    <ComboBox
                      label={`${t('deliveryNote.exportedTime')} ${t('deliveryNote.fromDate').toLowerCase()}`}
                      labelRange={t('deliveryNote.toDate')}
                      isRange
                      value={form.getFieldValue('exportedAt')}
                      labelWidth={0}>
                      <Item name="exportedAt">
                        <RangePicker
                          size="large"
                          placeholder={['', '']}
                          className="width100pc"
                          format={'DD-MM-YYYY'}
                          suffixIcon={<i className="far fa-calendar-alt" />}
                          //@ts-ignore
                          placement={'bottomRight'}
                        />
                      </Item>
                    </ComboBox>
                  </Col>
                  {/* <Col span={8}>
                    <ComboBox
                      isValue={true}
                      label={t('packages.placeHolderChooseAcc')}
                      value={idAcc}
                      labelWidth={0}>
                      <Item
                        className="width100pc supplier"
                        name="supplier"
                        valuePropName="option">
                        <Select
                          notFoundContent={<EmptyDataFilter description="Không có dữ liệu" />}
                          suffixIcon={<i className="fa-solid fa-angle-down"></i>}
                          size="large"
                          placeholder={''}
                          value={idAcc}
                          showSearch
                          filterOption={(input, option: any) => option.label.toLowerCase().includes(input.trim().toLowerCase())}
                          listHeight={300}
                          onChange={(val: string) => handleChangeAcc(val)}
                          popupClassName={theme}>
                          {accounts.map((acc: any) => (
                            <Option
                              key={acc.value}
                              value={acc.value}
                              label={acc.label}>
                              <div className="dpl-flex align-items-center">
                                <Avatar
                                  size={16}
                                  src={lodash.get(acc, 'provider.logo', '')}
                                  className="bd-none w-12 h-12 dpl-flex align-items-center mg-r-4"
                                />
                                <span className=" fsz-14 line-h-22 mg-r-4">{lodash.get(acc, 'provider.name', '')}</span>
                                {/* <Divider
																	type={'vertical'}
																	className={'bd-color-black line-h-22 mg-0 mg-r-4'}
																/>
																<span className=' fsz-14 line-h-22'>
																	{lodash.get(acc, 'name', '')}
																</span> */}
                  {/* </div> */}
                  {/* </Option> */}
                  {/* ))} */}
                  {/* </Select> */}
                  {/* </Item> */}
                  {/* </ComboBox> */}
                  {/* </Col> */}
                </Row>
                <Row className="dpl-flex justify-content-end align-items-center">
                  {showCleanFilter && (
                    <span
                      className={`m22-btn-reset ${theme} mg-t-16`}
                      onClick={clearFilter}>
                      <ReloadOutlined className="mg-r-4" />
                      <span className="mg-0">{t('packages.refreshFilter')}</span>
                    </span>
                  )}
                  <Button
                    className={`m22-btn mg-t-16 ${theme}`}
                    type="primary"
                    htmlType="submit"
                    form={'note-delivery'}
                    icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                    {t('packages.search')}
                  </Button>
                </Row>
              </Form>
            </Col>
          </>
        )}
      </Row>
    </M22Box>
  )
}

export default Filter
