import { AxiosResponse } from 'axios'
import ShipmentUpdateCommand from '../command/ShipmentUpdateCommand'
import { API_BASE_URL, DEFAULT_PAGE_SIZE } from '../core/config'
import { httpConfig } from '../core/config/http'
import { url } from '../core/helpers/string'
import { Repository } from '../core/repositories/Repository'
import ShipmentCriteria from '../Criterias/ShipmentCriteria'
import ShipmentDraftCriteria from '../Criterias/ShipmentDraftCriteria'
import ShipmentProductInterface from '../Interface/ShipmentProductInterface'

export class ShipmentRepository extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, 'shipments'))
  }

  public createDraftShipment = (body: ShipmentDraftCriteria): Promise<any> => {
    return this.http.post(`/create-draft-shipment`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }
  public createShipments = (body: ShipmentCriteria): Promise<any> => {
    return this.http.post('', { ...body, m22Source: 'WEB' }).then((response: AxiosResponse) => {
      return response
    })
  }

  public getShipments = (filter: any): Promise<any> => {
    return this.http.get(``, { params: { ...filter, sort: 'timestamp:desc' } })
  }

  public getShipmentServices = (providerUsername: string, provider: string): Promise<any> => {
    return this.http.get(`/services?providerUsername=${providerUsername}&provider=${provider}`).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }
  public getShipmentDetails = (code: string): Promise<any> => {
    return this.http.get(`/${code}`).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }
  public getShipmentMilestone = (code: string): Promise<any> => {
    return this.http.get(`/${code}/milestones`).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }

  public getShipmentProducts = (code?: string) => {
    if (!code) {
      return Promise.resolve([])
    }

    return this.http.get(`/${code}/products`).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }
  public getFees = (code: string, ): Promise<any> => {
    return this.http.get(`/${code}/fees`).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }

  public updateShipment = (code: string, body: ShipmentUpdateCommand): Promise<any> => {
    return this.http.patch(`/${code}`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }
  public cancelShipment = (code: string): Promise<any> => {
    return this.http.post(`/${code}/cancel`).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public getFinancial = (code: string): Promise<any> => {
    return this.http
      .get(`/${code}/financials`, { params: { size: 1000, sort: 'createdAt:desc' } })
      .then((response: AxiosResponse) => {
        return response.data
      })
  }
  public getMilestones = (code: string, packageCode: string): Promise<any> => {
    return this.http
      .get(`/${code}/packages/${packageCode}/milestones`, { params: { size: 1000, sort: 'lastStatusTime:desc' } })
      .then((response: AxiosResponse) => {
        return response.data
      })
  }
  public getShipmentPackages = (code: string): Promise<any> => {
    return this.http.get(`/${code}/packages/`, { params: { size: 1000, sort: 'createdAt:desc' } }).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public createProduct = (code: string, body: ShipmentProductInterface): Promise<any> => {
    return this.http.post(`/${code}/products`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public deleteProduct = (code: string, productCode: string): Promise<any> => {
    return this.http.delete(`/${code}/products/${productCode}`).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public updateProduct = (code: string, productCode?: string, body?: ShipmentProductInterface): Promise<any> => {
    return this.http.patch(`/${code}/products/${productCode}`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public createWaybill = (code: string, body: any): Promise<any> => {
    return this.http.post(`/${code}/waybills`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public getLogs = (code: string, filter: any): Promise<any> => {
    let limit = filter && filter.size ? filter.size : DEFAULT_PAGE_SIZE
    let offset = filter && filter.page ? filter.page * limit : 0
    delete filter.page
    return this.http.get(`/${code}/logs`, { params: { ...filter, offset, limit, sort: 'timestamp:desc' } }).then((response: AxiosResponse) => {
      return response
    })
  }

  public deleteWaybill = (code: string, waybillCode: string): Promise<any> => {
    return this.http.delete(`/${code}/waybills/${waybillCode}`).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public getWaybills = (code: string): Promise<any> => {
    return this.http.get(`/${code}/waybills`).then((response: AxiosResponse) => {
      return response.data
    })
  }
  public getProvidersConsignment = (code: string): Promise<any> => {
    return this.http.get(`/consignment-providers`).then((response: AxiosResponse) => {
      return response.data
    })
  }

  createComment = (threadReferenceCode: string | null, data: any): Promise<any> => {
    const formData = new FormData()
    if (Array.isArray(data.attachments)) {
      for (let file of data.attachments) {
        formData.append('attachments', new Blob([file]), file.name)
      }
    }
    if (data.comment) {
      formData.set('comment', new Blob([JSON.stringify(data.comment)], { type: 'application/json' }))
    }
    return this.http.post(`/${threadReferenceCode}/comments`, formData)
  }

  getOriginalReceipts = (shipmentCode: string): Promise<any> => {
    return this.http.get(`/${shipmentCode}/original_receipts`).then((response: AxiosResponse) => {
      return response.data
    })
  }
}
export const shipmentRepository: ShipmentRepository = new ShipmentRepository()
