import { Avatar, Button, Row, Table, Tooltip } from 'antd'
import { t } from 'i18next'
import lodash, { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface, { IMidMile } from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import ParagraphWithCopy from '../../../components/ParagraphWithCopyButton'
import { SkeletonTable } from '../../../components/SkeletonTable'
import TruncateText from '../../../components/TruncateText'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import ShipmentImageEmptyDark from '../../../resources/images/emptyDark.png'
import ShipmentImageEmptyLight from '../../../resources/images/shipment-ubox-default.png'
import HeaderList from './HeaderList'
import RowExtended from './RowExtend'
import { ColumnsType } from 'antd/lib/table/interface'

interface DataTableProps {
  isSkeleton: boolean
  loading: boolean
  total: number
  filter: ShipmentsFilterInterface | {}
  statuses: ShipmentStatusInterface[] | any
  data: ShipmentInterface[]
  typeDataTable: string
  handleTypeTableData: (val: string) => void
  pagination: any
  onPageChange: (filter: any) => void
}

const DataTable: React.FC<DataTableProps> = (props) => {
  const {
    handleTypeTableData,
    total,
    data,
    statuses,
    typeDataTable,
    isSkeleton,
    loading,
    filter,
    pagination,
    onPageChange,
  } = props
  const [theme] = useTheme()
  const [expandedRows] = useState<Array<any>>([])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const ShipmentImageDefault = theme === 'dark' ? ShipmentImageEmptyDark : ShipmentImageEmptyLight

  const columns: ColumnsType<any> = [
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('shipment.codeTitle')}</span>,
      width: '45%',
      render: (record: any) => {
        return (
          <div className="flex mg-r-25 flex-nowrap width100pc">
            <Avatar
              src={record.image ? record.image : ShipmentImageDefault}
              shape="square"
              size={80}
              className="bd-none-impt rad-6 mg-r-12"
            />
            <div className="flex flex-col">
              <div className="flex mg-bt-6">
                <Link
                  to={`/shipments/${record.code}`}
                  className="mg-r-16">
                  <ParagraphWithCopy
                    text={record.code}
                    children={`#${record.code}`}
                  />
                </Link>
                <div className="flex mg-l-22">
                  <Tooltip title={t('shipments.quantifyTooltip')}>
                    <span className="mg-r-3">{`${record.orderedQuantity ? record.orderedQuantity : '---'} / ${
                      record.receivedQuantity ? record.receivedQuantity : '---'
                    }`}</span>
                  </Tooltip>
                </div>
                <div className={`${theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'} mg-l-24`}>
                  <span className={``}>{record.timestamp ? formatDateTime(record.timestamp) : '---'} </span>
                </div>
              </div>
              <div className="flex align-items-center mg-bt-6">
                <Row>
                  <div className="flex align-items-center ">
                    {record.merchantName ? (
                      <>
                        <i className="fa-light fa-store mg-r-4" />
                        <span className=" fsz-14 line-h-20">{record.merchantName}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Row>
              </div>
              <div className="flex">
                <span
                  className={`${theme === 'dark' ? 'txt-color-dark-secondary' : 'txt-color-gray8'} mg-r-3 whitespace`}>
                  {t('shipments.refCustomerCodeLabel')}:
                </span>
                <TruncateText
                  className="fsz-14 line-h-22"
                  defaultValue={record.refShipmentCode ? record.refShipmentCode : '---'}
                />
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Vận chuyển Midmile',
      dataIndex: ['midMileView', '0'],
      width: 200,
      render: (midMileView: IMidMile) => {
        if (!midMileView?.code) return '---'

        return (
          <span className="inline-flex gap-2 items-center whitespace-nowrap">
            <Avatar
              src={midMileView.logo}
              size={24}
              className="mg-r-4"
            />
            <span>{midMileView.name}</span>
          </span>
        )
      },
    },
    {
      title: (
        <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('shipments.financeTotalFees')}</span>
      ),
      align: 'right',
      width: 180,
      render: (record: any) => {
        return <span className="m22-typo-money whitespace">{formatMoneyByUnit(record.totalFee)}</span>
      },
    },
    {
      title: (
        <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>
          {t('shipments.provisionalAmountTooltip')}
        </span>
      ),
      width: 180,
      align: 'right',
      render: (record: any) => {
        return (
          <span className="m22-typo-money whitespace">{formatMoneyByUnit(record.totalValue, record.currency)}</span>
        )
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('orderDetail.needPay')}</span>,
      align: 'right',
      width: 180,
      render: (record: any) => {
        return <span className="txt-color-red2 robotomedium whitespace">{formatMoneyByUnit(record.totalUnpaid)}</span>
      },
    },
    {
      title: (
        <span className={`fsz-12 mg-r-16 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>
          {t('shipments.statusOfShipment')}
        </span>
      ),
      align: 'right',
      width: 180,
      render: (record: any) => {
        const status = statuses?.find((stt: ShipmentStatusInterface) => stt.code === record.status)
        return (
          <span
            className="m22-tag-status whitespace"
            style={{
              backgroundColor: `${status?.color ? status.color : '#0082E6'}`,
            }}>
            {status?.name}
          </span>
        )
      },
    },
  ]

  const components = { body: { row: RowExtended } }
  const title = () => (
    <HeaderList
      total={total}
      typeDataTable={typeDataTable}
      setTypeDataTable={(val: string) => handleTypeTableData(val)}
      onPageChange={onPageChange}
      pagination={pagination}
      filter={filter}
    />
  )
  return (
    <SkeletonTable
      columns={columns}
      loading={loading}>
      <Table
        rowKey={(record: any) => record.id || record.code}
        columns={columns}
        dataSource={data}
        locale={{
          emptyText:
            !searchParams.get('code') &&
            !searchParams.get('originalId') &&
            !searchParams.get('merchantName') &&
            !searchParams.get('waybill') &&
            !searchParams.get('statuses') &&
            !searchParams.get('createdFrom') &&
            !searchParams.get('createdTo') &&
            !searchParams.get('providerUsername') &&
            !searchParams.get('provider') ? (
              <EmptyDataFilter
                className={`shipments-empty ${isSkeleton || loading ? 'dpl-none' : ''}`}
                description={
                  <>
                    <span className="txt-color-gray8">
                      {isEmpty(filter) ? t('shipments.noResult') : t('shipments.empty')}
                    </span>
                    <Button
                      type="primary"
                      disabled
                      className={`m22-btn ${theme}`}
                      icon={<i className="fa-regular fa-plus mg-r-10"></i>}
                      onClick={() => navigate('/shipments/create-consignment', { replace: true })}>
                      {t('shipments.createShipments')}
                    </Button>
                  </>
                }
              />
            ) : (
              <EmptyDataFilter description={t('shipments.filterEmpty')} />
            ),
        }}
        pagination={false}
        className={`table-improved m22-table orders ${theme}`}
        components={components}
        onRow={(record, index) => {
          const rowClassesByIdx = index! % 2 === 0 ? 'row-light' : 'row-dark'
          return { ...record, index, rowClassesByIdx }
        }}
        title={title}
        rowClassName={(record, idx) => {
          const rowClassesActiveByExpanded = lodash.includes(expandedRows, record.id)
            ? 'active-row cursor-pointer'
            : 'data-row cursor-pointer'
          const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
          return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`
        }}
      />
    </SkeletonTable>
  )
}

export default DataTable
