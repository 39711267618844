/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { Avatar, Button, Card, Checkbox, Col, Divider, List, Row, Spin } from 'antd'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import EmptyCart from '../../Empty/EmptyCart'
import M22Box from '../../M22Box'
import ConfirmModal from '../../Modal/ConfirmModal'
import M24Pagination from '../../Pagination/M24Pagination'
import ProductItem from '../ProductItem'
import { CartListHeader } from './CartListHeader'
import './styles.scss'

type Props = {
  handleClickOrderBtn: () => void
  cartItem: any
  filter: any
  pagination: any
  getCartData: (temp: any) => void
  onPageChange: (filter: any) => void
  handleDeleteSku: (merchantId: any, productId: any, skuId: any, callback: () => void) => void
  handleDeleteMerchant: (merchantId: any, callback: () => void) => void
  handleUpdateQuantitySku: (merchantId: any, quantify: number, productId: any, skuId: any, callback: () => void) => void
  isDeletingMerchant: boolean
  isDeletingSKU: boolean
  handleUpdateEmployeeNote: (skuId: any, value: string, callback: () => void) => void
  handleUpdatePersonalNote: (skuId: any, value: string, callback: () => void) => void
  selectMerchant: (merchant: any, isSelect: boolean) => void
  selectSku: (merchant: any, product: any, sku: any, isSelect: boolean) => void
  merchantSelected: any
  handleShowProgressBar: (val: boolean) => void
}

function MerchantList(props: Props) {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [merchantIdSelected, setMerchantId] = useState<string>()
  const {
    handleDeleteSku,
    cartItem,
    isDeletingMerchant,
    isDeletingSKU,
    handleDeleteMerchant,
    handleUpdateQuantitySku,
    handleUpdateEmployeeNote,
    handleUpdatePersonalNote,
    pagination,
    filter,
    merchantSelected,
    selectMerchant,
    selectSku,
    handleShowProgressBar,
  } = props

  const [totalSKU, setTotalSKU] = useState<number>(0)
  const [totalLink, setTotalLink] = useState<number>(0)

  useEffect(() => {
    if (merchantSelected && merchantSelected.products && merchantSelected.products.length > 0) {
      let total = 0
      let totalLink = 0
      merchantSelected.products.map((x: any) => {
        totalLink = totalLink + x.skus.length
        x.skus.map((sku: any) => {
          total = total + sku.quantity
        })
      })
      setTotalSKU(total)
      setTotalLink(totalLink)
    } else {
      setTotalSKU(0)
      setTotalLink(0)
    }
  }, [merchantSelected])

  const onSubmitConfirm = () => {
    handleDeleteMerchant(merchantIdSelected, () => {
      setShowConfirm(false)
      setMerchantId('')
    })
  }

  const onCancelConfirm = () => {
    setShowConfirm(false)
  }
  
  const renderMerchants = () => {
    return (
      <>
        <List
          pagination={false}
          dataSource={cartItem}
          renderItem={(item: any) => {
            if (item.skus && item.skus.length == 0) return
            let totalQuantity: number = 0
            let totalPrice: number = 0
            let merchantId = lodash.get(item, 'id', '')
            let totalSku = 0
            let totalLink = 0
            item.products.map((product: any) => {
              let total: number = 0
              let price: any = 0
              totalSku = totalSku + product.skus.length
              totalLink = totalLink + product.skus.length
              product.skus.map((xx: any) => {
                total = total + xx.quantity
              })
              if (product.pricePolicies && product.pricePolicies.length > 0) {
                let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
                if (total < pricePolicies[0].minQuantity) {
                  price = pricePolicies[0].salePrice
                } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
                  price = pricePolicies[pricePolicies.length - 1].salePrice
                } else {
                  pricePolicies.map((x: any) => {
                    if (total >= x.minQuantity && total <= x.maxQuantity) {
                      price = x.salePrice
                    }
                  })
                }
                totalPrice += total * price
              } else {
                product.skus.map((xx: any) => {
                  totalPrice += xx.salePrice * xx.quantity
                })
              }
              totalQuantity = totalQuantity + total
            })
            let currency = lodash.get(item, 'marketplace.defaultCurrency', '')
            let isSelected = false
            if (merchantSelected.merchant.id && merchantId === merchantSelected.merchant.id) {
              isSelected = true
            }
            let indeterminate = false
            if (isSelected) {
              indeterminate = true
              let totalSkuSelected = 0
              merchantSelected.products.map((x: any) => {
                totalSkuSelected = totalSkuSelected + x.skus.length
                // x.skus.map((xx: any)=>{
                //     totalSkuSelected=totalSkuSelected+xx.quantity;
                // });
              })
              if (totalSkuSelected === totalSku) indeterminate = false
            }
            let merchant = {
              id: merchantId,
              name: lodash.get(item, 'name', ''),
              icon: lodash.get(item, 'marketplace.icon', ''),
            }
            return (
              <Spin spinning={false}>
                <M22Box>
                  <Card
                    className={`${isSelected ? 'active' : ''} merchant-card-custom ${theme}`}
                    title={
                      <Row
                        align={'middle'}
                        onClick={() => selectMerchant(merchant, !isSelected)}>
                        <Col
                          span={20}
                          className="flex gap-16 align-items-center">
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={(e) => selectMerchant(merchant, e.target.checked)}
                            checked={isSelected}
                          />
                          <div>
                            <Avatar
                              className={'bd-none-impt mg-r-12'}
                              shape={'square'}
                              size={26}
                              src={lodash.get(item, 'marketplace.icon')}
                            />
                            <span className={`fsz-14 robotoregular txt-secondary ${theme}`}>{t('shopping-cart.seller')}: </span>
                            <a
                              href={item.url}
                              target={'_blank'}
                              className={`fsz-14 line-h-20 ${theme}`}
                              rel="noreferrer">
                              {lodash.get(item, 'name', '')}
                            </a>
                          </div>

                          <Divider
                            type={'vertical'}
                            style={{ borderColor: '#C7C7C7', margin: 0 }}
                          />
                          <span className={'fsz-14'}>{`${totalQuantity} ${t('shopping-cart.products')}/${totalLink} link`}</span>
                          <Divider
                            type={'vertical'}
                            style={{ borderColor: '#C7C7C7', margin: 0 }}
                          />

                          <span className={'fsz-14'}>
                            <span className="txt-secondary robotoregular">{t('shopping-cart.totalPaid')}:</span>
                            <span className={'mgl5'}>{formatMoneyByUnit(totalPrice, currency)}</span>
                          </span>
                        </Col>

                        <Col
                          span={4}
                          className="flex justify-content-end">
                          <Button
                            disabled={!merchantId}
                            className="normal-text"
                            type="text"
                            onClick={() => {
                              setShowConfirm(true)
                              setMerchantId(merchantId)
                            }}
                            icon={<i className="fa-regular fa-trash txt-error mg-r-4" />}>
                            <span className="txt-error">{t('shopping-cart.deleteMerchant')} </span>
                          </Button>
                        </Col>
                      </Row>
                    }>
                    {item.products.map((product: any, index: number) => {
                      if (product.skus && product.skus.length > 0) {
                        let total: number = 0
                        let price: any = null
                        product.skus.map((xx: any) => {
                          total = total + xx.quantity
                        })
                        let productsSelected = merchantSelected.products.find((x: any) => x.id === product.id)
                        if (product.pricePolicies && product.pricePolicies.length > 0) {
                          let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
                          if (total < pricePolicies[0].minQuantity) {
                            price = pricePolicies[0].salePrice
                          } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
                            price = pricePolicies[pricePolicies.length - 1].salePrice
                          } else {
                            pricePolicies.map((x: any) => {
                              if (total >= x.minQuantity && total <= x.maxQuantity) {
                                price = x.salePrice
                              }
                            })
                          }
                        }
                        return (
                          <List
                            key={index}
                            itemLayout="horizontal"
                            className={theme}
                            dataSource={product.skus}
                            renderItem={(sku: any, index: number) => {
                              let isSkuSelected = false
                              if (productsSelected) {
                                isSkuSelected = productsSelected.skus.find((x: any) => x.id === sku.id)
                              }
                              return (
                                <List.Item className="cursor-pointer rad-12">
                                  <ProductItem
                                    handleUpdateEmployeeNote={handleUpdateEmployeeNote}
                                    handleUpdatePersonalNote={handleUpdatePersonalNote}
                                    handleDeleteSku={(productId, skuId, callback) => handleDeleteSku(item.id, productId, skuId, callback)}
                                    handleUpdateQuantitySku={(quantity, productId, skuId, callback) =>
                                      handleUpdateQuantitySku(item.id, quantity, productId, skuId, callback)
                                    }
                                    product={sku}
                                    salePrice={price}
                                    isDeleting={isDeletingSKU}
                                    currency={currency}
                                    isSelected={isSkuSelected}
                                    selectSku={(isSelect) => {
                                      selectSku(merchant, product, sku, isSelect)
                                    }}
                                    handleShowProgressBar={(val: boolean) => handleShowProgressBar(val)}
                                  />
                                </List.Item>
                              )
                            }}
                          />
                        )
                      }
                    })}
                  </Card>
                </M22Box>
              </Spin>
            )
          }}></List>

        <M24Pagination
          filter={filter}
          pagination={pagination}
          onPageChange={props.onPageChange}
          className="mx-0 my-12"
        />
      </>
    )
  }
  return (
    <>
      {cartItem.length > 0 ? (
        <>
          <CartListHeader />
          <div className="cart-list">{renderMerchants()}</div>
        </>
      ) : (
        <EmptyCart
          className="min-h-100vh-248"
          emptyText={t('shopping-cart.emptyText')}
        />
      )}

      <div className={`${theme} merchant-card-bottom`}>
        <div className={'align-items-center'}>
          <Row className={'txt-size-h8 txt-color-secondary'}>{t('shopping-cart.orderInformation')}</Row>
          <Row className={'mgt5 txt-size-h6 robotobold'}>{`${merchantSelected?.merchant?.id ? '1' : '---'} Shop /  ${totalSKU ? totalSKU : '---'} ${t(
            'shopping-cart.products'
          )} / ${totalLink ? totalLink : '---'} Link`}</Row>
        </div>

        <div className={'align-item-center'}>
          <Button
            type="primary"
            className={`txt-capitalize m22-btn ${theme}`}
            disabled={!merchantSelected.merchant?.id}
            size={'large'}
            onClick={props.handleClickOrderBtn}
            icon={<i className="fa-solid fa-check mg-r-10" />}>
            {t('shopping-cart.orderAction')}
          </Button>
        </div>

        {showConfirm && (
          <ConfirmModal
            title={t('cart.deleteMerchant')}
            visible={showConfirm}
            loading={isDeletingMerchant}
            content={t('shopping-cart.confirmDeleteMerchant')}
            onSubmit={onSubmitConfirm}
            onCancel={onCancelConfirm}
            btnType="danger"
            iconBtn={<i className="fa-regular fa-trash" />}
            okText={t('button.delete')}
          />
        )}
      </div>
    </>
  )
}

export default MerchantList
