import { Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { SERVICE_NORMAL, SERVICE_PENDING, SERVICE_REJECT } from '../../core/config'
import { ServiceInterface } from '../../Interface/ShipmentInterface'

interface ServicesProps {
  label?: string | JSX.Element
  data?: ServiceInterface[]
  loading?: boolean
  className?: string
  wrapperClass?: string
}

const Services: React.FC<ServicesProps> = ({ label, data, loading, className, wrapperClass }) => {
  const { t } = useTranslation()

  return data && data.length > 0 ? (
    <div className={`flex gap-6 flex-wrap align-items-baseline ${wrapperClass ?? ''}`}>
      {label && <span className="txt-muted">{label}: </span>}
      
      {data
        .sort((a, b) => b.name.length - a.name.length)
        .map((service: ServiceInterface, idx: number) => {
          let serviceApproved = SERVICE_NORMAL
          if (service.needApprove) {
            if (service.approved === null || service.approved === undefined) {
              serviceApproved = SERVICE_PENDING
            } else if (service.approved === false) {
              serviceApproved = SERVICE_REJECT
            }
          }

          return !loading ? (
            <>
              <span
                className="whitespace"
                key={idx}>
                <Tooltip
                  overlayClassName="service-tooltip"
                  title={serviceApproved === SERVICE_PENDING ? <span className="pd-12">{t(`service.${serviceApproved}`)}</span> : null}>
                  <span className={`service ${className ?? ''} service-${serviceApproved} ${serviceApproved !== 'normal' ? 'cursor-pointer' : ''}`}>
                    {`${service.name}`} {serviceApproved === SERVICE_PENDING ? <i className="fa-regular fa-circle-exclamation fsz-12"></i> : ''}
                  </span>
                </Tooltip>
              </span>
              
              {idx !== data.length - 1 && <span className={className}>|</span>}
            </>
          ) : (
            <Skeleton
              width={80}
              height={20}
              className={`${idx !== data.length - 1 ? 'mg-r-4' : ''}`}
            />
          )
        })}
    </div>
  ) : (
    <span>---</span>
  )
}

export default Services
