import { List } from 'antd'
import lodash from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import MainLayout from '../../components/Layout'
import M22Box from '../../components/M22Box'
import Item from '../../components/Orders/Item'
import OrderFilter from '../../components/Orders/OrderFilter'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST, ORDER_DESCRIPTIONS } from '../../core/config'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { orderRepository } from '../../repositories/OrderRepository'
import DataTable from './List/DataTable'
import HeadingList from './List/HeadingList'
import SkeletonOrders from './SkeletonOrders'
import './styles.scss'

function Orders() {
  const [showProgressBar, setShowProgressBar] = useState(false)
  const { t } = useTranslation()
  let [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<any>({})
  const [pagination, setPagination] = useState<any>()
  const [orders, setOrders] = useState<any[]>([])
  const [statuses, setStatuses] = useState<any[]>([])
  const [marketplaces, setMarketplaces] = useState<any[]>([])
  const filterRef = useRef<HTMLDivElement | null>(null)

  const [loadingState, setLoadingState] = useState(false)
  const [typeShowData, setTypeShowData] = useState(DATA_TABLE_LAYOUT)

  useEffect(() => {
    let statuses = searchParams.get('statuses')
    let marketplaces = searchParams.get('marketplaces')
    let providerUsername = searchParams.get('providerUsername')
    let timestampFrom = searchParams.get('timestampFrom')
    let timestampTo = searchParams.get('timestampTo')
    let code = searchParams.get('code')
    let provider = searchParams.get('provider')
    let page = searchParams.get('page')
    let size = searchParams.get('size') || searchParams.get('pageSize')
    let temp: any = {}
    if (statuses) temp.statuses = statuses
    if (marketplaces) temp.marketplaces = marketplaces
    if (providerUsername) temp.providerUsername = providerUsername
    if (timestampFrom) temp.timestampFrom = timestampFrom
    if (timestampTo) temp.timestampTo = timestampTo
    if (page) temp.page = parseInt(page)
    if (size) temp.size = parseInt(size)
    if (code) temp.code = code
    if (provider) temp.provider = provider
    setFilter(temp)
    getStatuses()
    getMarketplaces()
    getData(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = (filter?: any) => {
    setShowProgressBar(true)
    setLoadingState(true)
    setSearchParams(filter)
    orderRepository
      .getOrders(filter)
      .then((response) => {
        let currentPage = lodash.get(response, 'headers.x-page-number')
        let total = lodash.get(response, 'headers.x-total-count')
        setOrders(response.data)
        setPagination({
          current: parseInt(currentPage) + 1,
          hideOnSinglePage: true,
          total: parseInt(total),
          pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
        })
      })
      .finally(() => {
        setShowProgressBar(false)
        setTimeout(() => setLoadingState(false), 500)
      })
  }
  const getStatuses = () => {
    setShowProgressBar(true)
    categoryRepository
      .getStatuses()
      .then((res) => {
        setStatuses(res)
      })
      .catch((err) => {})
  }
  const getMarketplaces = () => {
    categoryRepository
      .getMarketPlaces()
      .then((res) => {
        setMarketplaces(res)
      })
      .catch((err) => {})
  }

  const onPageChange = (filter: any) => {
    setFilter(filter)
    let temp = lodash.cloneDeep(filter)
    if (filter?.page && filter.page > 0) {
      temp.page = temp.page - 1
    }
    getData(temp)
  }

  return (
    <MainLayout
      title={t('menu.orders')}
      showProgressBar={showProgressBar}
      descriptions={{
        title: t('orders.pageTitle'),
        descriptions: t('orders.descriptions'),
        className: 'box-shadow-descriptions',
        pageCode: ORDER_DESCRIPTIONS,
        hasDescription: true,
      }}
      hasExtensionCommon={true}
      breadcrumbs={{ total: pagination?.total }}>
      <div ref={filterRef}>
        <OrderFilter
          setFilter={setFilter}
          getData={getData}
          filter={filter}
          statuses={statuses}
          marketplaces={marketplaces}
        />
      </div>
      <M22Box className="pd-0 mg-bt-0 rad-6-top">
        <div className={'mg-t-12 orders-data-shows rad-6-top'}>
          {!showProgressBar &&
            !loadingState &&
            (!searchParams.get('code') &&
            !searchParams.get('timestampFrom') &&
            !searchParams.get('timestampTo') &&
            !searchParams.get('marketplaces') &&
            !searchParams.get('providerUsername') &&
            !searchParams.get('provider') &&
            orders.length === 0 ? (
              <EmptyDataFilter
                className={`shipments-empty ${loadingState ? 'dpl-none' : ''} pd-20`}
                description={<span className="txt-color-gray8">{t('orders.emptyData')}</span>}
              />
            ) : (
              renderData()
            ))}
        </div>
      </M22Box>
      {!loadingState ||
        (!showProgressBar && (
          <SkeletonOrders
            loading={loadingState || showProgressBar}
            data={orders}
          />
        ))}
      {!loadingState && orders && orders.length > 0 && (
        <M22Box className="rad-bottom-6 pd-r-24">
          <M24Pagination
            onPageChange={onPageChange}
            filter={filter}
            pagination={pagination}
          />
        </M22Box>
      )}
    </MainLayout>
  )

  function renderData() {
    return typeShowData === DATA_TABLE_LIST ? renderListItem() : renderTable()
  }

  function renderTable() {
    return (
      <DataTable
        items={orders}
        statuses={statuses}
        pagination={pagination}
        typeShowData={typeShowData}
        handleTypeShowData={(val: string) => setTypeShowData(val)}
        loadingState={loadingState}
        filter={filter}
        onPageChange={onPageChange}
      />
    )
  }

  function renderListItem() {
    return (
      <List
        className="pd-bt-16 px-12 rad-6-top "
        header={
          <HeadingList
            pagination={pagination}
            typeShowData={typeShowData}
            setTypeShowData={(val: string) => setTypeShowData(val)}
            filter={filter}
            onPageChange={onPageChange}
          />
        }
        locale={{
          emptyText: (
            <EmptyDataFilter
              description={
                !searchParams.get('code') && !searchParams.get('timestampFrom') && !searchParams.get('timestampTo') && !searchParams.get('marketplaces')
                  ? t('orders.filterEmpty')
                  : t('orders.emptyData')
              }
              minHeight={235 + filterRef?.current?.offsetHeight!}
              className="flex flex-col justify-content-center"
            />
          ),
        }}
        dataSource={orders}
        rowKey={(item: any) => item.id}
        renderItem={(item, idx) => (
          <Item
            statuses={statuses}
            item={item}
            idx={idx}
          />
        )}
      />
    )
  }
}

export default Orders
