import {Spin} from 'antd'
import lodash from 'lodash'
import {useContext, useEffect} from 'react'
import {AppContext} from '../../context/AppContext'
import {TENANT_KEY} from '../../core/config'
import {tenantRepository} from '../../repositories/TenantRepository'
import {localStorageRead, localStorageRemoveItem, localStorageSave} from '../../utils/LocalStorageUtils'

export const Login = () => {
	const appContext = useContext(AppContext);
	const queryParam:any = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop:any) => searchParams.get(prop),
	});

	if(queryParam) {
		let ref = queryParam.ref;
		if(ref) sessionStorage.setItem('providerRef',ref)
	}
	useEffect(() => {
			tenantRepository.getCurrent().then((response) => {
				if (localStorageRead(TENANT_KEY)) {
					localStorageRemoveItem(TENANT_KEY)
				}
				localStorageSave(TENANT_KEY, response)
				let authUrl = process.env.REACT_APP_LOGIN_URL + '/' + response.code + '?redirect_uri=' + window.location.origin + '/authentication'
				if (lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')) {
					authUrl = lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')
				}
				if(sessionStorage.getItem('providerRef')) {
					authUrl += '&ref=' + sessionStorage.getItem('providerRef')
				}
				window.location.href = authUrl
				appContext.tenant = response
			}).catch(err => console.log(err))
		// }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appContext, appContext.tenant])

	return (
		<div className={'pd20'}>
			Đang chuyển trang, vui lòng đợi một chút.... <Spin />
		</div>
	)
}

