/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import MainLayout from '../../components/Layout'
import { useTranslation } from 'react-i18next'
import { Info } from './Info'
import { Status } from './Status'
import { claimRepository } from '../../repositories/ClaimRepository'
import { Spin } from 'antd'
import { useParams, useSearchParams } from 'react-router-dom'
import lodash from 'lodash'
import { Notfound } from '../../components/Orders/Notfound'
import { ClaimDetailContext } from '../../context/ClaimDetailContext'
import { ClaimInterface } from '../../Interface/ClaimInterface'
import { ClaimModel } from '../../model/ClaimModel'
import { getProviderFromConnections } from '../../utils/util'
import { supplierRepository } from '../../repositories/SupplierRepository'
import { ConnectionInterface } from '../../Interface/ConnectionInterface'
import { ProviderModel } from '../../model/ProviderModel'
import { ClaimStateInterface } from '../../Interface/ClaimStateInterface'
import BoxChat from '../../components/BoxChat'
import { categoryRepository } from 'src/repositories/CategoryRepository'
import M24Notification from 'src/utils/M24Notification'

export const ClaimDetail = () => {
  const context = useContext(ClaimDetailContext)
  const { t } = useTranslation()
  const { claimCode } = useParams()
  const [loading, setLoading] = useState(false)
  const [connections, setConnections] = useState<Array<ConnectionInterface>>([])
  const [claim, setClaim] = useState<ClaimInterface>(ClaimModel)
  const [statuses, setStatuses] = useState<ClaimStateInterface[]>([])
  const [is404, setIs404] = useState(false)
  const [searchParams] = useSearchParams()
  const providerUsername = searchParams.get('providerUsername')
  const provider = searchParams.get('provider')

  useEffect(() => {
    setLoading(true)
    claimRepository
      .getClaimDetail(claimCode!, provider!, providerUsername!)
      .then((response) => {
        setClaim(response.data)
      })
      .catch((err) => {
        if (lodash.get(err, 'response.status') === 404) {
          setIs404(true)
        }
        M24Notification.error({
          message: t('shipment.errorMessage'),
        })
      })
      .finally(() => setLoading(false))
  }, [searchParams])
  
  useEffect(() => {
    supplierRepository.getListAccount().then((response) => {
      setConnections(response)
      const connection = response.find((connect: any) => connect.provider.code === provider)
      context.provider = connection
    })
  }, [])

  useEffect(() => {
    categoryRepository.getClaimStatuses().then((response) => {
      setStatuses(response)
    })
  }, [])

  useEffect(() => {
    context.claim = { ...claim }
    context.provider = getProviderFromConnections(connections, provider!) || ProviderModel
    context.providerUsername = providerUsername!
    context.statuses = statuses
  }, [claim, statuses, connections, searchParams])

  if (is404) return <Notfound />

  return (
    <MainLayout title={`${t('tickets.detail_header')} #${claim.code}`}>
      <Spin spinning={loading}>
        <div className={'ticket-detail-container'}>
          <Info connections={connections} />
          <Status statuses={statuses} />
        </div>
      </Spin>
      
      {claim?.relatedOrder && (
        <BoxChat
          threadCreatePayload={{
            referenceCode: claimCode,
            relatedOrder: claim.relatedOrder,
            type: 'CLAIM_COMMENT',
            ticketType: claim.ticketType,
            thumbnail: claim.thumbnail,
          }}
        />
      )}
    </MainLayout>
  )
}
