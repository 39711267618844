import React, { useEffect, useState } from 'react'
import { TENANT_KEY } from '../core/config'
import { TenantUpdateConfigCommand } from '../Interface/TenantUpdateConfigCommand'
import { cartRepository } from '../repositories/CartRepository'
import { tenantRepository } from '../repositories/TenantRepository'
import { localStorageRead, localStorageRemoveItem, localStorageSave } from '../utils/LocalStorageUtils'

export interface CurrentContextI {
  config: any
  totalCart: number
  getInfoCart: () => void
}

export const CurrentContext = React.createContext<CurrentContextI | null>(null)

const CurrentConfigProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()
  const [totalCart, setTotalCart] = useState(0)

  const getInfoCart = () => {
    cartRepository
      .getProductCart({})
      .then((res) => {
        let total = 0
        res.data.forEach((item: any) => {
          item.products.forEach((product: any) => {
            product.skus.forEach((sku: any) => {
              total += sku.quantity
            })
          })
        })
        setTotalCart(total)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    const fetchCurrent = async () => {
      const response = await tenantRepository.getCurrent()
      if (localStorageRead(TENANT_KEY)) {
        localStorageRemoveItem(TENANT_KEY)
      }
      localStorageSave(TENANT_KEY, response)
      const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig, toolConfig } = response?.config
      setCurrentConfigServices((prevState: any) => ({
        ...prevState,
        shipmentConfig,
        orderConfig,
        peerPaymentConfig,
        toolConfig,
        gsaConfig,
        generalConfig,
      }))
    }
    fetchCurrent()
  }, [])

  useEffect(() => {
    getInfoCart()
  }, [])

  return <CurrentContext.Provider value={{ config: currentConfigServices, totalCart, getInfoCart }}>{children}</CurrentContext.Provider>
}

export default CurrentConfigProvider
