import { httpConfig } from '../core/config/http'
import { Repository } from '../core/repositories/Repository'
import { url } from '../core/helpers/string'
import { API_BASE_URL } from '../core/config'
import TransportProviderCriteria from '../Criterias/TransportProviderCriteria'
import { AxiosResponse } from 'axios'

export class ShipmentCategoriesRepository extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, 'transports'))
  }

  getShipmentWarehouses = () => {
    return this.http.get(`/receive-warehouses`)
  }

  getWarehouses = () => {
    return this.http.get(`/receive-warehouses`).then((response: AxiosResponse) => response)
  }

  getRouteWarehouses = (receive_warehouse: string) => {
    return this.http.get(`/receive-warehouses-route`, { params: { receive_warehouse } }).then((response: AxiosResponse) => response)
  }

  getShipmentRoutes = () => {
    return this.http.get(`/transport-roads`)
  }

  getCompensations = () => {
    return this.http.get(`/compensations`)
  }

  getTransportShipmentTypes = () => {
    return this.http.get(`/transport-shipment-types`)
  }

  getProvidersByCriteria = (data: TransportProviderCriteria) => {
    return this.http
      .get(``, {
        params: {
          ...data,
        },
      })
      .then((response: AxiosResponse) => response)
  }

  getOtherProviderService = () => {
    return this.http
      .get(`/service-groups`, {
        params: { type: 'filter' },
      })
      .then((response: AxiosResponse) => response)
  }

  getInternationalShippingFee = (id: string, weight: number, volume: number) => {
    return this.http.get(`/${id}/fees`, {
      params: {
        weight,
        volume,
      },
    })
  }
}

export const shipmentCategoriesRepository: ShipmentCategoriesRepository = new ShipmentCategoriesRepository()
