import {Repository} from '../core/repositories/Repository';
import {AxiosResponse} from 'axios';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";
import {FormInstance} from "antd";

export class UserRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(API_BASE_URL);
    }

    public getProfile = (): Promise<any> => {
        return this.http
            .get(`profile`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    };

    public saveProfile = (body: FormInstance<any>):Promise<any> => {
        return this.http
            .patch(`profile`,body)
    }

    public updateIntroductions= (introduction: string) => {
        return this.http
        .patch(`profile`,{introduction})
    }

    public uploadAvatar = (file:any):Promise<any> => {
        const formData = new FormData()
        formData.append("file", file)
        return this.http
            .post(`storages/upload`,formData).then((response:AxiosResponse) => {
                return response;
            })
    }


    // public register = (data: any) => {
    //     return this.http.post(`/user/register`,data)
    // }

}

export const userRepository: UserRepository = new UserRepository();
