import { ReloadOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ComboBox from '../../../components/ComboBox'
import M22Box from '../../../components/M22Box'
import { FORM_KEY_PROVIDER, FORM_KEY_PROVIDER_USER_NAME, FORM_KEY_SUPPLIER } from '../../../core/config'
import { useTheme } from '../../../hooks/useTheme'
import { getCleanFormFilter, handleInputChangeMany } from '../../../utils/StringUtils'
import './style.scss'

const { Item } = Form
const { RangePicker } = DatePicker
interface FilterProps {
  statuses: any
  // accounts: any
  idAcc: string | undefined
  page: number
  size: number
  filter: any
  loading: boolean
  handleFilter: (filter: any) => void
  filterChangeHandler: (obj: any) => void
  showProgressBar: boolean
}

const Filter: React.FC<FilterProps> = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [theme] = useTheme()
  const { statuses, idAcc, handleFilter, filter, filterChangeHandler } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilter, setShowFilter] = useState(true)
  const [selectedStatuses, setSelectedStatuses] = useState<{ statuses: any }>({
    statuses: searchParams.get('statuses') || '',
  })
  const [showCleanFilter, setShowCleanFilter] = useState(false)
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    setShowCleanFilter(
      !lodash.isEmpty(
        lodash.pickBy(filter, (val: any, key: string) => {
          if (key !== FORM_KEY_PROVIDER && key !== FORM_KEY_PROVIDER_USER_NAME && key !== FORM_KEY_SUPPLIER) {
            if (isArray(val)) {
              return val.every((e) => e) && !isEmpty(val)
            }
            return !isEmpty(val)
          }
        })
      ) || selectedStatuses.statuses
    )
  }, [filter, selectedStatuses.statuses])

  useEffect(() => {
    form.setFieldsValue({
      supplier: idAcc,
      query: searchParams.get('query'),
      createdAt: [
        searchParams.get('createdAtFrom') ? moment(searchParams.get('createdAtFrom')) : null,
        searchParams.get('createdAtTo') ? moment(searchParams.get('createdAtTo')) : null,
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, idAcc, searchParams, setSearchParams])

  const getFormState = () => {
    // const supplier = form.getFieldValue('supplier')
    // const account = accounts.find((x: any) => x.id === supplier)
    // const customer = lodash.get(account, 'name')
    // const provider = lodash.get(account, 'provider.code')
    const createdAt = form.getFieldValue('createdAt')
    const createdAtFrom = createdAt && createdAt[0] && moment(createdAt[0].startOf('day')).toISOString()
    const createdAtTo = createdAt && createdAt[1] && moment(createdAt[1].endOf('day')).toISOString()

    const filter = getCleanFormFilter(
      {
        ...form.getFieldsValue(true),
        statuses: selectedStatuses?.statuses,
        // customer,
        // provider,
        createdAtFrom,
        createdAtTo,
      },
      [],
      ['supplier', 'createdAt']
    )
    return filter
  }

  const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
    const temp = handleInputChangeMany(key, code, selectedStatuses)
    setSelectedStatuses({ ...temp })
  }

  // const handleChangeAcc = (val: string) => {
  //   handleAcc(val)
  //   handleProgressBar(true)
  //   onFinish()
  // }

  const onFinish = () => {
    handleFilter(getFormState())
  }

  const clearFilter = () => {
    form.resetFields()
    setSelectedStatuses({ statuses: '' })
    handleFilter({ ...getFormState(), statuses: '' })
  }

  return (
    <M22Box className="pd-0">
      <Row
        className={`m22-box-light border-card-header px-24 py-16 ${!showFilter ? 'pd-bt-0 cursor-pointer' : ''}`}
        onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}
        align="middle">
        <Row
          justify="space-between"
          className={`width100pc cursor-pointer dpl-flex ${showFilter ? ' mg-bt-16' : ''} `}
          gutter={8}
          wrap={false}
          align="middle"
          onClick={() => setShowFilter(!showFilter)}>
          <span className={`m22-typo-heading ${theme}  ${!showFilter ? 'mg-0' : ''}`}>{t('requestDelivery.find')}</span>

          <i
            className={`fas fa-angle-${showFilter ? 'up' : 'down'}  fsz-14 cursor-pointer`}
            onClick={() => setShowFilter(!showFilter)}></i>
        </Row>
        {showFilter && (
          <>
            <Col
              span={24}
              className="dpl-flex align-items-center mg-bt-9">
              <span className="dpl-flex align-items-baseline flex-wrap">
                {statuses.map(({ code, name }: any) => {
                  let isSelect = false
                  if (selectedStatuses) {
                    let temp = selectedStatuses?.statuses?.split(',')
                    if (temp && temp.includes(code)) isSelect = true
                  }
                  return (
                    <span
                      className={`${isSelect ? 'm22-badge active' : 'm22-badge'} mg-r-6 ${theme}`}
                      key={code}
                      onClick={() => {
                        onChangeFilterMany('statuses', !isSelect, code)
                      }}>
                      {name}
                    </span>
                  )
                })}
              </span>
            </Col>
            <Col span={24}>
              <Form
                name="request-delivery"
                onFinish={onFinish}
                className="form-filter"
                form={form}
                initialValues={{
                  query: '',
                  createdAt: [],
                }}
                onValuesChange={(changedValues, allValues) => {
                  filterChangeHandler(allValues)
                }}>
                {!expand && (
                  <Row
                    gutter={[16, 8]}
                    className="mg-bt-12">
                    <Col span={8}>
                      <ComboBox
                        label={t('requestDelivery.queryPlaceholder')}
                        value={form.getFieldValue('query')}
                        labelWidth={0}>
                        <Item
                          labelAlign="left"
                          className="query mg-bt-0"
                          name={'query'}>
                          <Input
                            placeholder={''}
                            allowClear
                            size="large"
                          />
                        </Item>
                      </ComboBox>
                    </Col>
                    {/* <Col span={8}>
                      <ComboBox
                        isValue={true}
                        label={t('packages.placeHolderChooseAcc')}
                        value={form.getFieldValue('supplier')}
                        labelWidth={0}>
                        <Item
                          className="width100pc"
                          name="supplier"
                          valuePropName="option">
                          <Select
                            notFoundContent={<EmptyDataFilter description="Không có dữ liệu" />}
                            suffixIcon={<i className="fa-solid fa-angle-down"></i>}
                            size="large"
                            placeholder={''}
                            value={idAcc}
                            onChange={(val: string) => handleChangeAcc(val)}
                            showSearch
                            filterOption={(input, option: any) => option.label.toLowerCase().includes(input.trim().toLowerCase())}
                            listHeight={300}
                            allowClear
                            popupClassName={theme}>
                            {accounts.map((acc: any) => (
                              <Option
                                key={acc.value}
                                value={acc.value}
                                label={acc.label}>
                                <div className="dpl-flex align-items-center">
                                  <Avatar
                                    size={16}
                                    src={lodash.get(acc, 'provider.logo', '')}
                                    className="bd-none w-12 h-12 dpl-flex align-items-center mg-r-4"
                                  />
                                  <span className=" fsz-14 line-h-22 mg-r-4">{lodash.get(acc, 'provider.name', '')}</span>
                                  {/* <Divider
                                    type={'vertical'}
                                    className={'bd-color-black line-h-22 mg-0 mg-r-4'}
                                  />
                                  <span className=" fsz-14 line-h-22">{lodash.get(acc, 'name', '')}</span> */}
                    {/* </div> */}
                    {/* </Option> */}
                    {/* ))} */}
                    {/* </Select> */}
                    {/* </Item> */}
                    {/* </ComboBox> */}
                    {/* </Col> */}
                    <Col span={8}>
                      <ComboBox
                        isRange
                        label={`${t('requestDelivery.createdAt')} ${t('requestDelivery.fromDate').toLowerCase()}`}
                        labelRange={`${t('requestDelivery.toDate')}`}
                        value={form.getFieldValue('createdAt')}
                        labelWidth={0}>
                        <Item name="createdAt">
                          <RangePicker
                            size={'large'}
                            placeholder={['', '']}
                            className="width100pc"
                            format={'DD-MM-YYYY'}
                            suffixIcon={<i className="far fa-calendar-alt" />}
                            placement="bottomRight"
                          />
                        </Item>
                      </ComboBox>
                    </Col>
                  </Row>
                )}
              </Form>

              <Row className="dpl-flex justify-content-between align-items-center">
                <span
                  className="m22-typo-label_collapse"
                  onClick={() => setExpand(!expand)}>
                  <i className={`fa-solid fa-angle-${expand ? 'down' : 'up'}`}></i>
                  <span className="line-h-20  fsz-12">{t(`shipments.${expand ? 'expandFilter' : 'collapseFilter'}`)}</span>
                </span>
                <div className="dpl-flex justify-content-end align-items-center flex-nowrap">
                  {showCleanFilter && (
                    <span
                      className={`m22-btn-reset ${theme}`}
                      onClick={clearFilter}>
                      <ReloadOutlined className="mg-r-4" />
                      <span className="mg-0">{t('packages.refreshFilter')}</span>
                    </span>
                  )}
                  <Button
                    className={`m22-btn txt-capitalize ${theme}`}
                    type="primary"
                    htmlType="submit"
                    form="request-delivery"
                    icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                    {t('packages.search')}
                  </Button>
                </div>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </M22Box>
  )
}

export default Filter
