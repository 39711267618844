import { Table, Typography } from 'antd'
import lodash from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import { ShipmentTransactionInterface } from '../../../../Interface/ShipmentTransaction'
const { Paragraph } = Typography

interface TransactionProps {
	items: ShipmentTransactionInterface[]
}
const Transactions: React.FC<TransactionProps> = (props) => {
	const { items } = props
	const { t } = useTranslation()
	const [ellipsis] = useState(true)
	const [theme,] = useTheme()

	const formatAmount = (amount: any, currency: string) => {
		let color = amount < 0 ? 'txt-color-red2' : 'txt-color-green'
		return <span className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
	}
	const columns = [
		{
			title:t('shipment.timestamp'),
			key: 'timestamp',
			width: 145,
			render: (record: ShipmentTransactionInterface) => (
				<span>{record.timestamp ? formatDateTime(record.timestamp) : '---'}</span>
			),
		},

		{
			title: t('shipment.transactionValue'),
			key: 'value',
			align: 'right' as 'right',
			width: 145,
			render: (record: ShipmentTransactionInterface) => formatAmount(record.amount, record.currency),
		},
		{
			title:t('shipment.transactionType'),
			key: 'type',
			width: 166,
			render: (record: ShipmentTransactionInterface) => <span>{record.type ? record.type.name : '---'}</span>,
		},
		{
			title: t('orderDetail.transactionMemo'),
			dataIndex: 'memo',
			key: 'memo',
			ellipsis: true,
			render: (text: string, record: any) => (
				<div className='flex flex-col'>
					<span className={'txt-color-gray2'}>{t('shipment.transactionId', { value: record.txid })}</span>
					<Paragraph ellipsis={ellipsis ? { rows: 1, tooltip: <span className='  fsz-12'>{lodash.get(record, 'memo', '--')}</span> } : false}>
						{lodash.get(record, 'memo', '--')}
					</Paragraph>
				</div>
			),
		},
	]

	return (
		<>
			<Table
				rowKey={(record: any) => record.id}
				columns={columns}
				dataSource={items}
				className={`order-transaction-list m22-table ${theme}`}
				pagination={false}
				locale={{
					emptyText: (
						<EmptyDataFilter description={t('shipment.noTransactions')} />
					),
				}}
			/>
		</>
	)
}

export default Transactions

