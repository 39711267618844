import {Repository} from '../core/repositories/Repository';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL, KUNLUN_API_URL, TENANT_KEY} from "../core/config";
import { NoticeFilterInterface } from '../Interface/NoticeFilterInterface'
import { localStorageRead } from '../utils/LocalStorageUtils'
import axios, { AxiosResponse } from 'axios'
import lodash from 'lodash'

export class NoticeRepository extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, ''))
  }

  public getNotices = (criteria: NoticeFilterInterface) => {
    return this.http.get(`/notification`, { params: { ...criteria } })
  }

  public getCategories = (): Promise<AxiosResponse> => {
    const tenant = localStorageRead(TENANT_KEY)
    return axios({
      url: `${KUNLUN_API_URL}/notification-service/categories/event_groups`,
      method: 'GET',
      headers: {
        'X-Tenant': lodash.get(tenant, 'code'),
      },
    })
  }
}
export const noticeRepository: NoticeRepository = new NoticeRepository();
