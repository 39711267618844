import React from "react";
import {ThreadItemInterface} from "../Interface/ThreadItemInterface";
import {ThreadItemModel} from "../model/ThreadItemModel";
import {localStorageRead} from "../utils/LocalStorageUtils";
import {PROFILE_KEY} from "../core/config";
import {UserInterface} from "../Interface/UserInterface";
import {UserModel} from "../model/UserModel";
import {ThreadMessageInterface} from "../Interface/ThreadMessageInterface";
import {ThreadMessageModel} from "../model/ThreadMessageInterface";
import {ThreadFilterInterface} from "../Interface/ThreadFilterInterface";

export interface BoxChatContextInterface {
    user: UserInterface
    thread: ThreadItemInterface
    lastMessage: ThreadMessageInterface
    threads: ThreadItemInterface[]
    threadTotal: number
    messages: ThreadMessageInterface[]
    chatListRef: any
    messageCurrentPage: number
    threadLoading: boolean
    messageLoading: boolean
    visible: boolean,
    hasFileList: boolean,
    hasComment: boolean,
    onPostMessage: (message: ThreadMessageInterface) => void
    onSelectThread: (thread: ThreadItemInterface) => void
    onMessageChangePage: (page: number) => void
    onFilterThreads: (params: ThreadFilterInterface) => Promise<any>
    onListChatScroll: (e: any) => void
    onThreadScroll: (e: any) => void
    toggle: () => void
    onShow: (cb?: Function) => void
}

export const BoxChatContext = React.createContext<BoxChatContextInterface>({
    user: localStorageRead(PROFILE_KEY) || UserModel,
    thread: ThreadItemModel,
    lastMessage: ThreadMessageModel,
    threads: [],
    threadTotal: 0,
    messages: [],
    chatListRef: undefined,
    messageCurrentPage: 0,
    threadLoading: false,
    messageLoading: false,
    visible: false,
    hasFileList: false,
    hasComment: false,
    onMessageChangePage: (page: number) => { return page },
    onPostMessage: () => {},
    onSelectThread: () => {},
    onFilterThreads: () => {
        return new Promise(() => {})
    },
    onListChatScroll: (e) => {},
    onThreadScroll: () => {},
    toggle: () => {},
    onShow: () => {},
})
