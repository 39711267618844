import { Spin, Steps } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { claimRepository } from '../../repositories/ClaimRepository'
import { ClaimMilestoneInterface } from '../../Interface/ClaimMilestoneInterface'
import { useParams } from 'react-router-dom'
import { ClaimStateInterface } from '../../Interface/ClaimStateInterface'
import { useTheme } from '../../hooks/useTheme'
import lodash from 'lodash'
import { formatDateTime } from '../../core/helpers/date-time'
import { STANDARD_DATE_TIME_FORMAT2 } from '../../core/config'
import { StatusClaimIcons } from '../../core/contants'
import Skeleton from 'react-loading-skeleton'
import { useContext } from 'react'
import { ClaimDetailContext } from 'src/context/ClaimDetailContext'

const { Step } = Steps

type StatusProps = {
  statuses: ClaimStateInterface[]
}

export const Status = (props: StatusProps) => {
  const { claim, provider, providerUsername } = useContext(ClaimDetailContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [milestones, setMilestones] = useState<ClaimMilestoneInterface[]>([])
  const [theme] = useTheme()
  const { claimCode } = useParams()
  const orderCode = claim.relatedOrder

  useEffect(() => {
    if (claimCode && orderCode) {
      setLoading(true)
      claimRepository
        .getMilestones(claimCode, orderCode)
        .then((items) => setMilestones(items.reverse()))
        .finally(() => setLoading(false))
    }
  }, [providerUsername, claimCode, provider, orderCode])


  return (
    <div className={`ticket-status-box pd-24 m22-box-${theme} rad-6 h-fit-content ${theme === 'dark' ? '' : 'box-shadow'}`}>
      <Spin spinning={loading}>
        <div className={'mgbt15 robotomedium  fsz-16'}>{t('tickets.ticket-status')}</div>
        <Steps
          className={`transport order-process ${theme}`}
          current={0}
          direction="vertical">
          {
            milestones?.map((item: any, index: number) => {
              const nameOfStatus = lodash.get(
                props.statuses.find(({ code }: any) => code === item?.state),
                'name',
                '---'
              )
              const timestampRendered = lodash.isNull(item.timestamp) ? t('orderDetail.undefined') : formatDateTime(item.timestamp, STANDARD_DATE_TIME_FORMAT2)
              const handlingTimeRendered = Math.floor(moment.duration(moment().diff(moment(item.timestamp), 'seconds'), 'seconds').asDays())
              return (
                <Step
                  key={item.id}
                  title={
                    <span className="robotomedium line-h-20">
                      {!loading ? (
                        <>
                          {StatusClaimIcons[item.state]}
                          {nameOfStatus}
                        </>
                      ) : (
                        <Skeleton
                          width={100}
                          height={20}
                        />
                      )}
                    </span>
                  }
                  icon={index !== 0 ? <i className="fa-regular fa-circle-check" /> : <span />}
                  description={
                    <CustomDescription
                      timestamp={timestampRendered}
                      handlingTime={handlingTimeRendered}
                      loading={loading}
                    />
                  }
                />
              )
            })}
        </Steps>
      </Spin>
    </div>
  )
}

  const CustomDescription = ({ timestamp, handlingTime, loading }: { timestamp: any; handlingTime?: any; loading: boolean }) => (
    <span className="flex-col dpl-flex align-items-start justify-content-start line-h-20">
      <span className="txt-color-gray8">
        {!loading ? (
          timestamp
        ) : (
          <Skeleton
            width={120}
            height={20}
          />
        )}
      </span>
      <span className="txt-color-gray8">
        {!loading ? (
          `(${handlingTime}) Ngày`
        ) : (
          <Skeleton
            width={50}
            height={20}
          />
        )}
      </span>
    </span>
  )
