import {useEffect, useState} from 'react'

const getTheme = () => {
	if (window.location.href.includes('/register')) {
		return 'light'
	}
	return localStorage.getItem('theme') || 'light'
}

export const useTheme = () => {
	const [theme, setTheme] = useState<any>(getTheme());

	useEffect(() => {
		const initialValue = getTheme()
		if (initialValue !== theme) {
			localStorage.setItem('theme', theme)
			window.location.reload()
		}
	}, [theme])

	return [theme, setTheme] as const
}
