import { AxiosResponse } from 'axios';
import { httpConfig } from "../core/config/http";
import { Repository } from '../core/repositories/Repository';


export class LocationRepository extends Repository {
    constructor() {
        super(httpConfig);
    }
    
    public getDivisions = (countryCode: string): Promise<any> => {
        return this.http
            .get(`divisions/${countryCode}`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getChildren = (code: string): Promise<any> => {
        return this.http
            .get(`locations/${code}/children`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getParent = (code: string): Promise<any> => {
        return this.http.get(`locations/${code}/parent`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getCountries = ():Promise<any> => {
        return this.http.get(`locations`)
            .then((response:AxiosResponse) => {
                return response.data;
            });
    }
    public getDetail = (code: string):Promise<any> => {
        return this.http.get(`locations/${code}`)
            .then((response:AxiosResponse) => {
                return response.data;
            });
    }
}
export const locationRepository: LocationRepository = new LocationRepository();
