import { useEffect, useState } from "react";
import ShipmentStatusInterface from "src/Interface/ShipmentStatusInterface";
import { categoryRepository } from "src/repositories/CategoryRepository";

function useShipmentStatus () {
    const [statuses, setStatuses] = useState<ShipmentStatusInterface[]>();

    useEffect(()=> {
        categoryRepository.getShipmentStatuses().then((response)=> {
            setStatuses(response)
        }).catch((error)=> {
            console.log(error.response)
        })
    },[])

    return {
        statuses
    }
}


export default useShipmentStatus;