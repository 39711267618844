import { Avatar, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../../components/Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../../../components/SkeletonTable'
import { formatDateTime } from '../../../../../core/helpers/date-time'
import { formatNumber } from '../../../../../core/helpers/string'
import { useTheme } from '../../../../../hooks/useTheme'
import { WayBillInterface } from '../../../../../Interface/ShipmentInterface'
import { categoryRepository } from '../../../../../repositories/CategoryRepository'
import { shipmentRepository } from '../../../../../repositories/ShipmentRepository'
import TracksPackage from './TrackPackage'
import ShipmentPackageInterface from 'src/Interface/ShipmentPackageInterface'
import { IPackage } from 'src/Interface/PackageInterface'
import { useMidmile } from 'src/hooks/fetchers/useMidmile'
import M24Notification from 'src/utils/M24Notification'
interface OrderItemsProps {
  packages: ShipmentPackageInterface[]
  loading: boolean
  waybills?: WayBillInterface[]
  shipment: any
  shipmentStatuses: any
  provider: string | undefined
  refetch: () => void
  waybillFromShipment: any
  fetchWaybills: () => void
}

const Packages: React.FC<OrderItemsProps> = (props) => {
  const [statuses, setStatuses] = useState([])
  const [theme] = useTheme()
  const { packages, loading, refetch, shipment, shipmentStatuses, waybillFromShipment, provider, fetchWaybills } = props

  const { t } = useTranslation()
  const { code } = useParams()
  const [packageTransform, setPackageTransform] = useState<any>([])
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const [visibleDeleteWaybill, setVisibleDeleteWaybill] = useState(false)
  const [loadingDeleteWaybill, setLoadingDeleteWaybill] = useState(false)
  const [curWaybillCode, setCurWaybillCode] = useState('')
  const { midmileLogoMapping, midmileNameMapping } = useMidmile(provider)

  const getStatuses = useCallback(async () => {
    try {
      const res = await categoryRepository.getShipmentPackageStatuses()
      setStatuses(res)
    } catch (err) {}
  }, [])

  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  useEffect(() => {
    let packageTransform = []

    let waybillCodes: string[] = []
    waybillFromShipment.forEach((p: any) => {
      waybillCodes.push(p.code)
    })
    if (waybillCodes.length) {
      for (const waybillCode of waybillCodes) {
        const curWaybill = waybillFromShipment.find((waybill: any) => waybill.code === waybillCode)
        const packageGrouped = packages.filter((pack: any) => pack.waybillCode === waybillCode)
        const packageUpdated = {
          packages: packageGrouped,
          total: packageGrouped?.length,
          waybillCode,
          createdAt: curWaybill?.createdAt,
        }
        packageTransform.push(packageUpdated)
      }
    } else {
      waybillFromShipment.forEach((element: any) => {
        packageTransform.push(element)
      })
    }

    setPackageTransform(packageTransform)
  }, [packages, waybillFromShipment])

  const deleteWaybillHandler = useCallback(
    async (waybillCode: string) => {
      setLoadingDeleteWaybill(true)
      try {
        await shipmentRepository.deleteWaybill(code!, waybillCode)
        setLoadingDeleteWaybill(false)
        setVisibleDeleteWaybill(false)
        M24Notification.success({ message: t('shipment.deleteWaybillSucceed') })
        refetch()
        fetchWaybills()
      } catch (err) {
        setLoadingDeleteWaybill(false)
      }
    },
    [code, fetchWaybills, refetch, t]
  )

  const packageInfoText = 'fsz-12 whitespace'
  const columnsPackages: ColumnsType<any> = [
    {
      title: t('package.code'),
      key: 'code',
      dataIndex: 'code',
      render: (code: string) => <span className={`${packageInfoText}`}>{code}</span>,
    },
    {
      title: (
        <>
          {t('package.weight')} {t('package.noteForWeight')}
        </>
      ),
      align: 'center',
      key: 'info',
      render: (record: any) => {
        const emptyTxt = '---'
        const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight')) ? emptyTxt : lodash.get(record, 'actualWeight', emptyTxt)
        const netWeight = lodash.isNull(lodash.get(record, 'netWeight')) ? emptyTxt : lodash.get(record, 'netWeight', emptyTxt)
        const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight')) ? emptyTxt : lodash.get(record, 'packagingWeight', emptyTxt)
        const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight')) ? emptyTxt : lodash.get(record, 'dimensionalWeight', emptyTxt)

        return (
          <span className={`${packageInfoText}`}>{`${formatNumber(actualWeight)}kg / ${formatNumber(netWeight)}kg / ${formatNumber(
            packagingWeight
          )} kg / ${formatNumber(dimensionalWeight)}kg`}</span>
        )
      },
    },
    {
      title: (
        <>
          {t('package.details')} {t('package.noteForDetail')}
        </>
      ),
      key: 'details',
      align: 'center',
      render: (record: any) => {
        const emptyTxt = '---'
        const length = lodash.isNull(lodash.get(record, 'length')) ? emptyTxt : lodash.get(record, 'length', emptyTxt)
        const width = lodash.isNull(lodash.get(record, 'width')) ? emptyTxt : lodash.get(record, 'width', emptyTxt)
        const height = lodash.isNull(lodash.get(record, 'height')) ? emptyTxt : lodash.get(record, 'height', emptyTxt)
        return <span className={packageInfoText}>{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(height)} (cm)`}</span>
      },
    },
    {
      title: t('package.update'),
      key: 'notice',
      align: 'right',
      dataIndex: 'lastStatusTime',
      render: (lastStatusTime: any) => (lodash.isNull(lastStatusTime) ? t('orderDetail.undefined') : formatDateTime(lastStatusTime)),
    },
    {
      title: 'Vận chuyển Midmile',
      key: 'midMileParcel',
      dataIndex: 'midMileParcel',
      render: (midMileParcel: IPackage['midMileParcel']) =>
        midMileParcel ? (
          <>
            <Avatar
              src={midmileLogoMapping[midMileParcel.courier]}
              size={24}
              className="inline-block"
            />{' '}
            <span>{midmileNameMapping[midMileParcel.courier]}</span>
          </>
        ) : (
          '---'
        ),
    },
    {
      title: t('package.status'),
      key: 'notice',
      dataIndex: 'status',
      align: 'right',
      render: (status: string) => {
        const statusObj = statuses.find(({ code }) => code === status)
        const statusName = lodash.get(statusObj, 'name', '---')

        return (
          <span
            className={`status-view`}
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color')) ? '#09B2AA' : lodash.get(statusObj, 'color'),
            }}>
            {statusName}
          </span>
        )
      },
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: `${t('shipment.packageWaybillCode')} (${packageTransform.length})`,
      key: 'code',
      dataIndex: 'code',
      render: (_: any, record: any) => {
        return record ? <span>{record?.code || record?.waybillCode}</span> : '---'
      },
    },
    {
      title: t('shipment.totalPackages'),
      key: 'total',
      dataIndex: 'total',
      align: 'right',
      render: (total: number) => formatNumber(total),
    },
    {
      title: t('shipment.createdAtWaybill'),
      dataIndex: 'createdAt',
      render: (createdAt: any, record: any) => {
        const shipmentStatus = shipmentStatuses.find((stt: any) => stt.code === shipment?.status)
        const shipmentStatusWithCodeMerchantDelivering = shipmentStatuses.find((stt: any) => stt.code === 'MERCHANT_DELIVERING')
        return (
          <div className="flex justify-content-between">
            <span className="fsz-14 line-h-22 ">{createdAt || record?.createdAt ? formatDateTime(createdAt || record?.createdAt) : 'Chưa xác định'}</span>
            {!record?.packages?.length && shipmentStatus?.position <= shipmentStatusWithCodeMerchantDelivering?.position && (
              <i
                className="fa-regular fa-trash txt-color-gray4"
                onClick={(e: any) => {
                  e.stopPropagation()
                  setVisibleDeleteWaybill(true)
                  setCurWaybillCode(record.waybillCode || record.code)
                }}></i>
            )}
          </div>
        )
      },
    },
  ]

  const localeCustom = {
    emptyText: (
      <>
        {!props.loading ? (
          <EmptyDataFilter
            description={t('shipment.waybillsEmpty')}
            className=""
          />
        ) : (
          <div className="dpl-none"></div>
        )}
      </>
    ),
  }

  const total = packages.length

  const expandedRowRender = (record: any) => {
    const components = { body: { row: TracksPackage } }
    return record?.packages?.length > 0 ? (
      <Table
        components={components}
        rowKey={(record: any) => record.id}
        columns={columnsPackages}
        dataSource={record.packages}
        locale={localeCustom}
        loading={loading}
        pagination={false}
        size="small"
        onRow={(record, index) => {
          return { ...record, total, index, statuses }
        }}
      />
    ) : (
      <EmptyDataFilter description={t('packages.empty')} />
    )
  }

  const expandIcon = ({ expanded, onExpand, record }: any) =>
    expanded ? (
      <i
        className="w-8 cursor-pointer far fa-angle-down line-h-22"
        onClick={(e) => onExpand(record, e)}></i>
    ) : (
      <i
        className="w-8 cursor-pointer far fa-angle-right line-h-22"
        onClick={(e) => onExpand(record, e)}></i>
    )

  return (
    <>
      <SkeletonTable
        columns={columns as SkeletonTableColumnsType[]}
        rowCount={3}
        loading={loading}
        className={`m22-table ${theme}`}>
        <Table
          rowKey={(record: any) => record.waybillCode || record.code}
          columns={columns}
          dataSource={packageTransform}
          locale={localeCustom}
          loading={loading}
          className={`main-table table-expandable m22-table ${theme}`}
          pagination={false}
          expandable={{
            columnWidth: 0,
            expandRowByClick: true,
            expandedRowRender,
            expandIcon,
            onExpandedRowsChange: (expandedRows) => {
              setExpandedRows([...expandedRows])
            },
          }}
          rowClassName={(record) =>
            lodash.includes(expandedRows, record.waybillCode || record.code) ? 'active-row show-expand-icon cursor-pointer' : 'data-row cursor-pointer'
          }
        />
      </SkeletonTable>

      <Modal
        title={<span className="fsz-16 line-h-24 robotomedium">{t('shipment.deleteWaybill')}</span>}
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        centered
        onCancel={() => setVisibleDeleteWaybill(false)}
        onOk={() => deleteWaybillHandler(curWaybillCode)}
        okText={t('button.submit')}
        cancelText={t('button.cancel')}
        confirmLoading={loadingDeleteWaybill}
        okButtonProps={{
          type: 'primary',
          className: 'm22-btn',
          icon: <i className="fas fa-trash mg-r-10"></i>,
          loading: loadingDeleteWaybill,
        }}
        cancelButtonProps={{
          className: 'm22-btn',
          type: 'text',
          icon: <i className="fa-solid fa-xmark mg-r-10"></i>,
        }}
        open={visibleDeleteWaybill}>
        <span className="fsz-14 line-h-22 ">{t('shipment.deleteWaybillConfirm')}</span>
      </Modal>
    </>
  )
}
export default Packages
