import './styles.scss'

import { ReloadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, DatePicker, Input, Row } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { handleChangeRangeDate, handleInputChange, handleInputChangeMany } from '../../../utils/StringUtils'
import ComboBox from '../../ComboBox'
import M22Box from '../../M22Box'
import { useTheme } from '../../../hooks/useTheme'

const { RangePicker } = DatePicker
type OrderFilterProps = {
  statuses: any
  marketplaces: any
  filter: any
  setFilter: Dispatch<SetStateAction<any>>
  getData: (filter?: any) => void
}

function OrderFilter(props: OrderFilterProps) {
  const { t } = useTranslation()
  const { statuses, marketplaces, filter, setFilter, getData } = props
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [isExpand, setExpand] = useState<boolean>(false)
  const [theme] = useTheme()

  const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
    let temp = handleInputChangeMany(key, code, filter)
    setFilter(temp)
  }
  const onChange = (key: string, value: any) => {
    let temp = handleInputChange(key, value, filter)
    setFilter(temp)
  }
  const onChangeRangeDate = (date: any) => {
    let temp = handleChangeRangeDate(date, 'timestampFrom', 'timestampTo', filter)
    setFilter(temp)
  }
  const resetFilter = () => {
    setFilter({})
    getData()
  }

  const handleEventsDown = () => {
    getData({ ...filter, page: 0 })
  }

  const hiddenResetFilter = useMemo(() => {
    delete filter.page
    delete filter.size
    delete filter.pageSize
    return lodash.isEmpty(filter)
  }, [filter])

  return (
    <M22Box
      className={`border-card-header px-24 py-16 rad-6 ${!showFilter ? 'cursor-pointer' : ''}`}
      onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}>
      <Row
        className={`align-items-center cursor-pointer dpl-flex justify-content-between ${!showFilter ? 'mg-bt-0' : 'mg-bt-16'}`}
        onClick={() => setShowFilter(!showFilter)}>
        <span className={`m22-typo-heading ${theme}`}>{t('orders.filterTitle')}</span>
        <i
          className={`fas fa-chevron-${showFilter ? 'up' : 'down'} cursor-pointer pdl5  `}
          onClick={() => {
            setShowFilter(!showFilter)
            setExpand(false)
          }}
        />
      </Row>
      {showFilter && (
        <>
          <Row
            className={'dpl-flex align-items-start flex-nowrap'}
            align={'middle'}>
            <span className="dpl-flex align-items-baseline flex-wrap">
              {statuses.map((item: any) => {
                let isSelect = false
                if (filter.statuses) {
                  let temp = filter.statuses.split(',')
                  if (temp?.includes(item.code)) isSelect = true
                }
                return (
                  <span
                    className={`${isSelect ? 'm22-badge active' : 'm22-badge'} ${theme} mg-r-15`}
                    key={item.code}
                    onClick={() => {
                      onChangeFilterMany('statuses', !isSelect, item.code)
                    }}>
                    {item.name}
                  </span>
                )
              })}
            </span>
          </Row>
          {isExpand && (
            <div className="mg-t-12 mg-bt-16">
              <Row
                align="middle"
                gutter={[16, 12]}>
                <Col span={8}>
                  <ComboBox
                    label={t('orders.order_code')}
                    value={lodash.get(filter, 'code', '')}>
                    <Input
                      size="large"
                      value={lodash.get(filter, 'code', '')}
                      placeholder=""
                      onChange={(event) => onChange('code', event.target.value)}
                      onPressEnter={handleEventsDown}
                    />
                  </ComboBox>
                </Col>

                <Col span={8}>
                  <ComboBox
                    label={`${t('orders.order_times')} ${t('orders.from_dateTime').toLowerCase()}`}
                    labelRange={t('orders.to_dateTime')}
                    value={[filter.timestampFrom ? moment(filter.timestampFrom) : null, filter.timestampTo ? moment(filter.timestampTo) : null]}
                    isRange={true}>
                    <RangePicker
                      suffixIcon={<i className="fa-solid fa-calendar-range txt-color-gray9"></i>}
                      size="large"
                      format={'DD-MM-YYYY'}
                      value={[filter.timestampFrom ? moment(filter.timestampFrom) : null, filter.timestampTo ? moment(filter.timestampTo) : null]}
                      placeholder={['', '']}
                      onChange={(value: any, dateString: any) => {
                        onChangeRangeDate(value)
                      }}
                      className={'width100'}
                    />
                  </ComboBox>
                </Col>

                <Col span={8}>
                  <span className={`mr-2 robotomedium ${theme === 'dark' ? 'txt-color-E6E6E6' : 'txt-color-gray8'} `}>{t('orders.findBy_marketPlace')}:</span>

                  {marketplaces.map((x: any) => {
                    let isSelect = false
                    if (filter.marketplaces) {
                      let temp = filter.marketplaces.split(',')
                      if (temp?.includes(x.code)) isSelect = true
                    }
                    return (
                      <Checkbox
                        key={x.code}
                        value={x.code}
                        checked={isSelect}
                        onChange={(e) => {
                          onChangeFilterMany('marketplaces', !isSelect, e.target.value)
                        }}
                        className={'_status-checkbox'}>
                        <span className={'txt-size-h7  txt-capitalize '}>{x.name}</span>
                      </Checkbox>
                    )
                  })}
                </Col>
              </Row>
            </div>
          )}
          <Row className={'align-items-center justify-content-between'}>
            <Col>
              <span
                onClick={() => {
                  setExpand(!isExpand)
                }}
                className={'m22-typo-label_collapse'}>
                <i className={`fas fa-chevron-${isExpand ? 'up' : 'down'}`}></i>
                {t(!isExpand ? 'orders.filterExpand' : 'orders.filterCollapse')}
              </span>
            </Col>
            <Col>
              {!hiddenResetFilter && (
                <span
                  onClick={resetFilter}
                  className={`mg-r-20 cursor-pointer fsz-14 line-h-20 m22-btn-reset ${theme}`}>
                  <ReloadOutlined className={''} /> &nbsp;
                  {t('filter.refresh')}
                </span>
              )}
              <Button
                // disabled={lodash.isEmpty(filter)}
                icon={<i className="fa-regular fa-magnifying-glass"></i>}
                type="primary"
                onClick={() => getData({ ...filter, page: 0 })}
                className={`m22-btn ${theme}`}>
                {t('orders.button_search')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </M22Box>
  )
}

export default OrderFilter
