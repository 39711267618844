import { Avatar, Table } from 'antd'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { useTheme } from '../../hooks/useTheme'
import ThumbClaim from '../../resources/images/claim-thumb.png'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import ParagraphWithCopy from '../ParagraphWithCopyButton'
import { SkeletonTable, SkeletonTableColumnsType } from '../SkeletonTable'
import './style.scss'
import { useNavigate } from 'react-router-dom'

type ClaimListProps = {
  ticketStatuses: any[]
  claimData: any
  loadingClaim: boolean
  isSkeleton: boolean
}

function ClaimList(props: ClaimListProps) {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const [searchParams] = useSearchParams()
  const { claimData, loadingClaim, isSkeleton } = props
  const navigate = useNavigate()

  const column = [
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.ticket-code')}</span>,
      dataIndex: 'code',
      key: 'code',
      width: 94,
      render: (code: any) => {
        return (
          <span className={'align-items-center txt-color-primary'}>
            <Link to={`/claims/${code}`}>
              <ParagraphWithCopy
                text={code}
                children={`#${code}`}
              />
            </Link>
          </span>
        )
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.ticket-name')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 288,
      render: (name: any, record: any) => {
        return (
          <div className={'flex flex-nowrap align-items-center'}>
            <Avatar
              src={record.thumbnail ?? ThumbClaim}
              size={40}
              shape={'square'}
              className={'bd-none-impt border-radius4 mg-r-5'}
            />
            <span className={'robotomedium'}>{name}</span>
          </div>
        )
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.ticket-created-at')}</span>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      render: (createdAt: any) => (
        <span className={`whitespace ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray7'} `}>{formatDateTime(createdAt)}</span>
      ),
    },

    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.refund-amount')}</span>,
      dataIndex: 'estimatedRefundValue',
      key: 'estimatedRefundValue',
      width: 120,
      align: 'right' as 'right',
      render: (estimatedRefundValue: any, record: any) => {
        return (
          <span className={`${record?.publicStateNewView?.code === 'REFUND' ? 'm22-typo-money-plus' : ''} robotomedium`}>
            {record?.publicStateNewView?.code === 'REFUND'
              ? formatMoneyByUnit(record?.totalRefund)
              : estimatedRefundValue
              ? formatMoneyByUnit(estimatedRefundValue)
              : '---'}
          </span>
        )
      },
    },
    {
      title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.resolve-methods')}</span>,
      dataIndex: 'solution',
      key: 'solution',
      width: 136,
      render: (_: any, record: any) => {
        return <span className="robotomedium">{record ? lodash.get(record, 'solutionView.name', '---') : ''}</span>
      },
    },
    {
      title: <span className={`fsz-12 mg-r-16 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('tickets.ticket-status')}</span>,
      key: 'state',
      width: 136,
      align: 'right' as 'right',
      render: (row: any) => {
        return (
          <>
            <span
              className={`m22-tag-status`}
              style={{ backgroundColor: `${lodash.get(row, 'publicStateNewView.color')}` }}>
              {lodash.get(row, 'publicStateNewView.name')}
              <span>{lodash.get(row, 'archived') === true ? ` (${t('tickets.archived')})` : ''}</span>
            </span>
          </>
        )
      },
    },
  ]
  const customLocale = {
    emptyText: (
      <EmptyDataFilter
        className={loadingClaim || isSkeleton ? 'dpl-none' : ''}
        description={
          !searchParams.get('publicState') &&
          !searchParams.get('solutionCode') &&
          !searchParams.get('code') &&
          !searchParams.get('ticketType') &&
          !searchParams.get('providerCode') &&
          !searchParams.get('accountCode')
            ? t('tickets.empty-list')
            : t('claims.filterEmpty')
        }
      />
    ),
  }

  return (
    <div className={'ticket-list mg-t-10 align-items-center'}>
      <SkeletonTable
        loading={loadingClaim || isSkeleton}
        rowCount={claimData?.length}
        columns={column as SkeletonTableColumnsType[]}
        className={`m22-table ${theme}`}>
        <Table
          className={`m22-table ${theme} table-claims cursor-pointer`}
          columns={column}
          dataSource={claimData}
          pagination={false}
          rowKey={(record) => record.id}
          locale={customLocale}
          rowClassName={(record, idx) => {
            const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
            return `${rowClassesByIdx}`
          }}
          onRow={(record, index) => {
            const rowClassesByIdx = index! % 2 === 0 ? 'row-light' : 'row-dark'
            return {
              ...record,
              index,
              rowClassesByIdx,
              onClick: () => {
                navigate(`/claims/${lodash.get(record, 'code')}`)
              },
            }
          }}
        />
      </SkeletonTable>
    </div>
  )
}

export default ClaimList
