import { Badge, Button, Divider, Skeleton } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NoticeFilterInterface } from '../../../Interface/NoticeFilterInterface'
import { NoticeItemInterface } from '../../../Interface/NoticeItemInterface'
import { noticeRepository } from '../../../repositories/NoticeRepository'
import './style.scss'
import { useTheme } from '../../../hooks/useTheme'
import { EmptyNotice } from './EmptyNotice'
import { NoticeItem } from './NoticeItem'

interface Props {
  totalUnread: number
}

const limit = 24

const TabNotification = (props: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [notices, setNotices] = useState<NoticeItemInterface[]>([])
  const [theme] = useTheme()
  const [offset, setOffset] = useState<number>(0)
  const [isGotAll, setIsGotAll] = useState<boolean>(true)

  const getNotices = useCallback(
    (criteria: NoticeFilterInterface) => {
      setLoading(true)
      noticeRepository
        .getNotices({
          sort: 'publishDate:desc',
          offset,
          limit,
          ...criteria,
        })
        .then((response) => {
          setNotices([...notices, ...response.data])
          setIsGotAll(response.data?.length === 0)
        })
        .catch(() => setNotices([]))
        .finally(() => setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset]
  )

  useEffect(() => {
    getNotices({})
  }, [getNotices])

  return (
    <>
      <div className={`notification-top mg-bt-16 ${theme}`}>
        <span className={`top-title ${theme}`}>
          <span className={`${theme}`}>{t('notification.title')}</span>
          {props.totalUnread > 0 ? <Badge className="m22-badge">{props.totalUnread}</Badge> : ''}
        </span>
      </div>

      <div className={`dpl-flex flex-col pb-4 notification-content-box-${theme} `}>
        {loading &&
          notices.length < 1 &&
          Array(3)
            .fill(null)
            .map((e) => (
              <>
                <Skeleton
                  title={false}
                  avatar={{ size: 40 }}
                  paragraph={{ rows: 2 }}
                />
                <Divider className="!m-1 !mb-3" />
              </>
            ))}

        {!loading && notices.length < 1 && <EmptyNotice />}

        <div className="notification-list">
          {notices.map((item) => (
            <NoticeItem
              key={item.id}
              item={item}
            />
          ))}

          {!isGotAll && (
            <div className="flex justify-center mt-4 mb-1">
              <Button
                type="ghost"
                onClick={() => setOffset(offset + limit + 1)}
                loading={loading && offset > 0}>
                Xem thêm
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TabNotification
