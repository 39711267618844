import { ThreadMessageInterface } from '../../Interface/ThreadMessageInterface'
import { useRef, useState } from 'react'

import { Modal, Row, Image } from 'antd'
import moment from 'moment'
import * as linkify from 'linkifyjs'
import FileSaver from 'file-saver'
import SecurityService from 'src/utils/SecurityService'

interface Props {
  message: ThreadMessageInterface
}

interface PreviewVideoInterface {
  name?: string
  url?: string
  type?: string
}

const formatContent = (content: string) => {
  let newContent = content

  if (newContent.indexOf('\n')) {
    newContent = newContent.replaceAll('\n', `<br/>`)
  }

  const findLinks = linkify.find(newContent)
  for (let item of findLinks) {
    if (item.type === 'url') {
      newContent = newContent.replaceAll(item.value, `<a target="_blank" href='${item.href}'>${item.value}</a>`)
    }
  }

  return newContent
}

export const MessageItem = (props: Props) => {
  const { message } = props
  const [isShowPreviewVideo, setIsShowPreviewVideo] = useState(false)
  const [previewVideo, setPreviewVideo] = useState<PreviewVideoInterface>({})
  const [isVideoPlay, setIsVideoPlay] = useState(false)
  const videoRef = useRef<any>()

  const handlePreviewVideo = (name: string, url: string, type: string) => {
    previewVideo.name = name
    previewVideo.url = url
    previewVideo.type = type
    setPreviewVideo({ ...previewVideo })
    setIsShowPreviewVideo(true)
  }

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsVideoPlay(true)
    }
  }

  const handleDownload = (url: string, name: string) => {
    FileSaver.saveAs(url, name)
  }

  const renderImageGroup = (sources: any[]) => {
    let className = 'chat-message-item__group chat-message-item__group__multiple'

    if (sources.length === 1) {
      className = 'chat-message-item__group chat-message-item__group__single'
    }

    if (sources.length === 2) {
      className = 'chat-message-item__group chat-message-item__group__couple'
    }

    return (
      sources.length > 0 && (
        <Row justify={message?.author?.username ? 'start' : 'end'}>
          <div className={className}>
            {sources.map((source, i) => (
              <div
                className="chat-message-item__group__item"
                key={`${source.url}_${i}`}>
                {source.type.startsWith('video') ? (
                  <div
                    onClick={() => handlePreviewVideo(source.name, source.url, source.type)}
                    className={'chat-message-item__message__video cursor-pointer'}>
                    <video
                      width={'100%'}
                      height={'100%'}>
                      <source
                        src={source.url}
                        type={'video/mp4'}
                      />
                      Your browser does not support the video tag.
                    </video>
                    <span className={'video-backdrop'} />
                    <i className="video-icon fa-solid fa-play" />
                  </div>
                ) : (
                  <Image
                    className={'order-image'}
                    src={source.url}
                    alt={source.name}
                    width={'auto%'}
                    height={'100%'}
                  />
                )}
              </div>
            ))}
          </div>
        </Row>
      )
    )
  }

  const renderFileGroup = (sources: any[]) => {
    return (
      sources.length > 0 &&
      sources.map((source, i) => (
        <div
          className="chat-message-item__message"
          key={`${source}_${i}`}>
          <div className="chat-message-item__message__text chat-message-item__message__attachment-items">
            <span className="chat-message-item__message__attachment-items__inner">
              <a
                className={'chat-message-item__message__file'}
                href={source.url}
                download={source.name}
                onClick={(e) => {
                  e.preventDefault()
                  handleDownload(source.url, source.name)
                }}>
                {source.name}
              </a>
            </span>
          </div>
        </div>
      ))
    )
  }
  const currentUser = SecurityService.getUser()
  console.log('currentUser', currentUser, message)
  return (
    <div className={`chat-message-item ${message?.author?.username !== currentUser.username ? 'friend' : 'me'}`}>
      <div className="chat-message-info justify-content-end flex items-center gap-6">
        <div className="chat-message-item__time-avatar">
          <span className={'chat-message-item__time-avatar__name'}>{message?.author?.username}</span>

          <img
            className={'chat-message-item__time-avatar__avatar'}
            src={message?.author?.avatar}
            alt=""
          />
        </div>
      </div>
      {renderImageGroup(message.attachments.filter((a) => a.type.startsWith('video') || a.type.startsWith('image')))}
      {renderFileGroup(message.attachments.filter((a) => !a.type.startsWith('video') && !a.type.startsWith('image')))}

      {message.content && (
        <div className="chat-message-item__message">
          <div className={'chat-message-item__message__text'}>
            {/(<([^>]+)>)/i.test(message.content) ? (
              <span>{message.content}</span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: formatContent(message.content) }} />
            )}
          </div>
        </div>
      )}

      <div className="chat-message-item__timestamp">
        <span className={'chat-message-item__time-avatar__dot'}>
          <i className="fa-solid fa-period" />
        </span>

        <span className={'chat-message-item__time-avatar__time'}>{moment(message?.timestamp || message?.createdAt).format('HH:mm DD/MM/YYYY')}</span>
      </div>

      {isShowPreviewVideo && (
        <Modal
          className={'box-chat-modal-preview'}
          title={previewVideo.name}
          centered
          closeIcon={<i className="fa-solid fa-xmark" />}
          open={true}
          footer={false}
          onCancel={() => setIsShowPreviewVideo(false)}>
          <div className="preview-video">
            <video
              ref={videoRef}
              width={'100%'}
              height={'100%'}
              controls={isVideoPlay}
              onPlaying={() => setIsVideoPlay(true)}
              onPause={() => setIsVideoPlay(false)}>
              <source
                src={previewVideo.url}
                type={'video/mp4'}
              />
              Your browser does not support the video tag.
            </video>

            {!isVideoPlay && (
              <>
                <span className={'video-backdrop'} />
                <i
                  onClick={() => handlePlayVideo()}
                  className="video-icon fa-solid fa-play"
                />
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}
