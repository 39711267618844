import { StepProps, Steps } from 'antd'
import lodash from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { HistoryOrder } from '../../../../containers/OrderDetail'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { useTheme } from '../../../../hooks/useTheme'
import { STANDARD_DATE_TIME_FORMAT2 } from '../../../../core/config'
import { TrackOrderIcons } from '../../../../core/contants'

interface TrackOrderProps {
  statuses: any
  orderHistory: any
  loading?: boolean
}
const CustomDescription = ({ timestamp, handlingTime, loading }: { timestamp: any; handlingTime: any; loading: boolean }) => (
  <span className="dpl-flex flex-col align-items-start justify-content-start line-h-20">
    <span>
      {!loading ? (
        timestamp
      ) : (
        <Skeleton
          width={120}
          height={20}
        />
      )}
    </span>

    <span>
      {!loading ? (
        `(${handlingTime})`
      ) : (
        <Skeleton
          width={50}
          height={20}
        />
      )}
    </span>
  </span>
)
const TrackOrder: React.FC<TrackOrderProps> = (props) => {
  const { statuses, loading, orderHistory } = props

  const { t } = useTranslation()
  const [theme] = useTheme()

  const items: StepProps[] =
    orderHistory &&
    orderHistory.map(({ status, timestamp, handlingTime }: HistoryOrder, index: number): StepProps => {
      const nameOfStatus = lodash.get(
        statuses.find(({ code }: any) => code === status),
        'name',
        '---'
      )
      const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp, STANDARD_DATE_TIME_FORMAT2)
      const handlingTimeRendered = lodash.isNull(handlingTime) ? t('orderDetail.undefined') : `${handlingTime} ngày`
      return {
        title: (
          <span className="robotomedium line-h-20">
            {TrackOrderIcons[status]}
            {nameOfStatus}
          </span>
        ),
        description: (
          <CustomDescription
            timestamp={timestampRendered}
            handlingTime={handlingTimeRendered}
            loading={loading!}
          />
        ),
        icon: index !== 0 ? <i className="fa-regular fa-circle-check" /> : <span />,
      }
    })

  return (
    <>
      <h5 className="robotomedium txt-size-h5 mg-0 line-h-24 mg-bt-24">{t('orderDetail.history')}</h5>
      <Steps
        className={`transport order-process ${theme}`}
        current={0}
        direction="vertical"
        items={items}
      />
    </>
  )
}

export default TrackOrder
