import { useTranslation } from 'react-i18next'
import lodash from 'lodash'
import { Col, Row, Table } from 'antd'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { SkeletonTable, SkeletonTableColumnsType } from '../../SkeletonTable'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import { useTheme } from '../../../hooks/useTheme'
interface TransactionProps {
	dataSource: any
	loading: boolean
}
function Transactions(props: TransactionProps) {
	const { t } = useTranslation()
	const [theme] = useTheme()
	const { dataSource, loading } = props
	const formatAmount = (amount: any, currency: string) => {
		let color = amount < 0 ? 'txt-color-red2' : 'txt-color-green'
		return <span className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
	}
	const columns = [
		{
			title: <span className='fsz-12'>{t('orderDetail.transactionTime')}</span>,
			dataIndex: 'timestamp',
			key: 'timestamp',
			render: (text: string, record: any) => <span className={''}>{formatDateTime(record.timestamp)}</span>,
		},

		{
			title: <span className='fsz-12'>{t('orderDetail.transactionAmount')}</span>,
			dataIndex: 'amount',
			key: 'amount',
			align: 'right' as 'right',
			render: (text: string, record: any) => formatAmount(record.amount, record.currency),
		},
		{
			title: <span className='fsz-12'>{t('orderDetail.transactionType')}</span>,
			dataIndex: 'type',
			key: 'type',
			render: (text: string, record: any) => <span>{lodash.get(record, 'type.name', '--')}</span>,
		},
		{
			title: <span className='fsz-12'>{t('orderDetail.transactionMemo')}</span>,
			dataIndex: 'memo',
			key: 'memo',
			render: (text: string, record: any) => (
				<Col>
					<Row>
						<span className={'txt-color-gray2'}>
							{t('orderDetail.transactionId', { value: record.txid })}
						</span>
					</Row>
					<span>{lodash.get(record, 'memo', '--')}</span>
				</Col>
			),
		},
	]
	return (
		<SkeletonTable
			loading={loading}
			columns={columns as SkeletonTableColumnsType[]}
			className='order-transaction-list'
			rowCount={3}
		>
			<Table
				className={`order-transaction-list m22-table ${theme}`}
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				locale={{ emptyText: <EmptyDataFilter description={t('order.transactionsEmpty')} /> }}
			/>
		</SkeletonTable>
	)
}
export default Transactions

