import { Avatar, Button, Divider, Modal, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import { ProductInterface } from '../../../../Interface/ShipmentInterface'
import ShipmentProductInterface from '../../../../Interface/ShipmentProductInterface'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import ShipmentImageDefault from '../../../../resources/images/shipment-ubox-default.png'
import CreateProductModal from './CreateProductModal'
import { SkeletonTable } from 'src/components/SkeletonTable'
import M24Notification from 'src/utils/M24Notification'

interface OrderItemsProps {
  items: ProductInterface[]
  currency: string
  currencyMarketPalace?: string
  refetch: () => void
  productUpdatable: boolean
  loading: boolean
}

const Products: React.FC<OrderItemsProps> = (props) => {
  const { t } = useTranslation()
  const [theme] = useTheme()

  const { code } = useParams()
  const { items, refetch, productUpdatable, loading } = props
  const [visible, setVisible] = useState(false)
  const [loadingProductDelete, setLoadingProductDelete] = useState(false)
  const [product, setProduct] = useState<ShipmentProductInterface | undefined>()
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const deleteProductHandler = async (productCode: string) => {
    setLoadingProductDelete(true)
    try {
      await shipmentRepository.deleteProduct(code!, productCode)
      M24Notification.success({ message: t('shipment.deleteSuccess') })
    } catch (err: any) {
      if (err.response?.data.title === 'shipment_status_not_product_updatable') {
        M24Notification.error({ key: 'shipment_status_not_product_updatable', message: 'Không thể xoa sản phẩm khi đơn đang ở trạng thái chờ giao' })
      } else {
        M24Notification.error({ message: err?.response.detail ?? 'Đã có lỗi xảy ra, vui lòng thử lại!' })
      }
    } finally {
      setLoadingProductDelete(false)
      setVisibleDeleteModal(false)
      refetch()
    }
  }

  const columns = [
    {
      title: t('orderDetail.productName'),
      key: 'name',
      width: 564,
      render: (record: any) => {
        const properties = lodash.get(record, 'variantProperties', [])
        return (
          <div className="dpl-flex">
            <div className="flex flex-col">
              <a
                href={lodash.get(record, 'productUrl')}
                className="cursor-pointer"
                target="_blank"
                rel="noreferrer">
                <Avatar
                  shape={'square'}
                  size={60}
                  src={record.productImage || ShipmentImageDefault}
                  className={'flex-shrink-0 border-radius-none mg-r-16'}
                  style={{ border: 'none' }}
                />
              </a>
              <div className="mg-t-4 robotomedium">#{lodash.get(record, 'code')}</div>
            </div>
            <div className="flex-col dpl-flex pd-l-2">
              <a
                href={lodash.get(record, 'productUrl')}
                className={`cursor-pointer robotomedium ${theme}`}
                target="_blank"
                rel="noreferrer">
                <span>{lodash.get(record, 'name', '--')}</span>
              </a>
              <span className=" mgr6 fsz-14">{lodash.get(record, 'translatedName', '---')}</span>
              <span className="flex-col dpl-flex pd-l-2">
                {properties.length > 0
                  ? properties.map((pro: any, idx: number) => (
                      <span
                        className={`txt-color-gray4 ${theme}`}
                        key={idx}>{`${pro.name || '---'} : ${pro.value}`}</span>
                    ))
                  : '---'}
              </span>
              <div className="flex align-items-center fsz-14 mg-bt-6">
                <i className="fa-light fa-store mg-r-4"></i>
                <span className=" mgr6 fsz-14">{record.merchantName ? record.merchantName : '---'}</span>
              </div>
              <div className="flex align-items-center fsz-14 pd-l-2">
                <i className="fa-light fa-location-dot mg-r-4"></i>
                <span className=" mgr6 fsz-14">{record.merchantContact ? record.merchantContact : '---'}</span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: t('shipment.unitPrice'),
      key: 'price',
      align: 'right' as 'right',
      width: 144,
      render: (record: any) => {

        return <span className="fsz-14 line-h-22 robotomedium">{`${formatMoneyByUnit(record.unitPrice, record.currency || 'CNY')}`}</span>
      },
    },
    {
      title: t('shipment.quantity'),
      key: 'quantity',
      width: 144,
      align: 'right' as 'right',
      render: (record: any) => <span className="fsz-14 line-h-22 robotomedium">{record.quantity ?? '---'}</span>,
    },
    {
      title: t('shipment.actions'),
      key: 'actions',
      width: 144,
      align: 'right' as 'right',
      render: (record: ProductInterface) => {
        const productUpdated = {
          code: record.code,
          productUrl: record?.productUrl,
          productImage: record?.productImage,
          name: record?.name,
          translatedName: record?.translatedName,
          merchantName: record?.merchantName,
          merchantContact: record?.merchantContact,
          quantity: record?.quantity,
          unitPrice: record?.unitPrice,
          shortName: record?.shortName,
          shortTranslatedName: record?.shortTranslatedName,
          unit: record?.unit,
          translatedUnit: product?.translatedUnit,
        }
        return (
          <div className="flex flex-nowrap justify-content-end align-items-center btn">
            <Tooltip title="Sửa">
              <Button
                disabled={!productUpdatable}
                onClick={() => {
                  setProduct((prevState: any) => {
                    setVisible(true)
                    return { ...prevState, ...productUpdated }
                  })
                }}
                type="primary"
                icon={<i className="far fa-edit txt-muted txt-size-h8"></i>}
                className="cursor-pointer fsz-14 btn-edit"></Button>
            </Tooltip>
            <Divider
              className="vertical-divider"
              type="vertical"
              style={{ height: '20px' }}
            />
            <Tooltip title="Xóa">
              <Button
                disabled={!productUpdatable}
                type="text"
                className="cursor-pointer fsz-14 txt-warning btn-edit"
                icon={<i className="fa-regular fa-trash txt-muted txt-size-h8"></i>}
                onClick={() => {
                  setProduct(record)
                  setVisibleDeleteModal(true)
                }}></Button>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <SkeletonTable
        loading={loading}
        columns={columns}>
        <Table
          rowKey={(record: any) => record.id}
          columns={columns}
          dataSource={items}
          className={`product-list m22-table ${theme}`}
          pagination={false}
          locale={{
            emptyText: <EmptyDataFilter description={t('orderDetail.noItems')} />,
          }}
        />
      </SkeletonTable>

      <CreateProductModal
        visible={visible}
        visibleHandler={setVisible}
        refetch={refetch}
        type="edit"
        product={product}
      />

      <Modal
        open={visibleDeleteModal}
        title={t('shipment.deleteProduct')}
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        onCancel={() => setVisibleDeleteModal(false)}
        okButtonProps={{
          className: 'm22-btn',
          danger: true,
          icon: <i className="fa-regular fa-trash mg-r-10"></i>,
          loading: loadingProductDelete,
        }}
        cancelButtonProps={{
          className: 'm22-btn',
          type: 'text',
        }}
        closable
        okText={t('button.submit')}
        cancelText={t('button.cancel')}
        className="shipment-delete-product"
        onOk={() => deleteProductHandler(product?.code!)}
        centered>
        <span className=" fsz-14 line-h-22">{t('shipment.delete-confirm')}</span>
      </Modal>
    </>
  )
}
export default Products
