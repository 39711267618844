import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Spin, Upload } from 'antd'
import MainLayout from '../../components/Layout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { claimRepository } from '../../repositories/ClaimRepository'
import { ClaimSolutionInterface } from '../../Interface/ClaimSolutionInterface'
import InputMoney, { InputMoneySeparatorType } from '../../components/InputMoney'
import { ClaimReasonInterface } from '../../Interface/ClaimReasonInterface'
import { UploadFile } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import lodash from 'lodash'
import { MAX_UPLOAD_SIZE } from '../../core/config'
import { ClaimCreateInterface } from '../../Interface/ClaimCreateInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import ComboBox from '../../components/ComboBox'
import { useTheme } from '../../hooks/useTheme'
import Skeleton from 'react-loading-skeleton'
import EmptyDataFilter from './../../components/Empty/EmptyDataFilter'
import M24Notification from 'src/utils/M24Notification'

const { TextArea } = Input

const MAX_FILE_UPLOAD = 10

interface ClaimUploadedFile {
  uid: string
  id: string
}

const generateClaimName = (t: any, orderCode: string, productCode: string) => {
  let name = t('ticket-create.default_name') + ' ' + orderCode
  if (productCode) name += ', ' + t('ticket-create.default_name_with_product') + ' ' + productCode
  return name
}

const defaultForm: ClaimCreateInterface = {
  attachments: [],
  description: '',
  name: '',
  owner: '',
  reason: '',
  reasonData: {},
  relatedOrder: '',
  relatedProduct: '',
  solution: '',
  suggest: 0,
  ticketType: '',
}

interface ErrorInterface {
  connectionId: any
  relatedOrder: any
  reason: any
  reasonData?: {}
  description: any
  suggest: any
  solution: any
  uploadFile?: any
  notReceived?: any
}

const defaultError: ErrorInterface = {
  connectionId: undefined,
  description: undefined,
  reason: undefined,
  relatedOrder: undefined,
  suggest: undefined,
  solution: undefined,
  uploadFile: undefined,
}

const ClaimCreate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [solutions, setSolutions] = useState<ClaimSolutionInterface[]>([])
  const [reasons, setReasons] = useState<ClaimReasonInterface[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<ClaimUploadedFile[]>([])
  const [form, setForm] = useState<ClaimCreateInterface>(defaultForm)
  const [error, setError] = useState<ErrorInterface>(defaultError)
  const [previewFile, setPreviewFile] = useState<any>()
  const [isShowPreviewFile, setIsShowPreviewFile] = useState(false)
  const [searchParams] = useSearchParams()
  const [isShowMissingQuantity, setShowMissingQuantity] = useState(false)
  const [loadingParams, setLoadingParams] = useState(true)
  const [ticketType, setTicketType] = useState<'SHIPMENT' | 'ORDER'>()
  const [theme] = useTheme()
  const [fetchReasonForProduct, setFetchReasonForProduct] = useState(false)

  useEffect(() => {
    if (
      form.reason === 'not_received' ||
      form.reason === 'shipping_not_received' ||
      form.reason === 'shipment_not_received'
    ) {
      setShowMissingQuantity(true)
    } else {
      setShowMissingQuantity(false)
    }
  }, [form.reason])

  useEffect(() => {
    setForm({ ...defaultForm, relatedOrder: searchParams.get('shipmentsCode')! })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchParams.get('back')?.split('/')[1] === 'shipments') {
      setTicketType('SHIPMENT')
    }
    if (searchParams.get('back')?.split('/')[1] === 'orders') {
      setTicketType('ORDER')
    }
  }, [searchParams])

  useEffect(() => {
    if (!ticketType) return
    console.log('ticketType', ticketType)
    categoryRepository
      .getReasonsOfClaim(ticketType.toUpperCase())
      .then((response) => setReasons(response))
      .catch(() => setReasons([]))

    categoryRepository
      .getSolutionsOfClaim([ticketType.toUpperCase()].toString())
      .then((response) => {
        let solutionFiltered
        if (ticketType === 'SHIPMENT') {
          solutionFiltered = response.filter((item: ClaimSolutionInterface) => item.code !== 'redelivery')
        } else {
          solutionFiltered = response
        }
        setSolutions(solutionFiltered)
      })
      .catch((err) => console.log(err))
  }, [ticketType])

  useEffect(() => {
    setTimeout(() => setLoadingParams(false), 250)
  }, [searchParams])

  useEffect(() => {
    setForm({
      ...form,
      relatedOrder: searchParams.get('orderCode') || searchParams.get('shipmentsCode') || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const currentReason = lodash.find(reasons, (item) => item.code === form.reason)
    if (currentReason) {
      if (form.relatedOrder && !form.relatedProduct && currentReason.type === 'PRODUCT') {
        form.reason = ''
        setForm({ ...form })
      } else if (form.relatedProduct && currentReason.type === 'ORDER') {
        form.reason = ''
        setForm({ ...form })
      }
    }

    if (form.reason !== 'faulty_product') {
      resetError('uploadFile')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const handleUploadFile = () => {
    return false
  }

  const handleChangeFile = (info: UploadChangeParam<any>) => {
    resetError('uploadFile')
    if (info.fileList.length > MAX_FILE_UPLOAD) {
      M24Notification.error({
        message: t('ticket-create.error_max_upload_file'),
        key: 'upload_error',
      })
      return false
    }

    if (info.file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
      M24Notification.error({
        message: t('ticket-create.error_max_upload_file_size'),
        key: 'upload_error',
      })
      return false
    }
    setFileList(info.fileList)
    // console.log(info.fileList)

    if (info.file.status === 'removed') {
      setUploadedFiles(uploadedFiles.filter((f) => f.uid !== info.file.uid))
    }
    return false
  }

  const handleCancelCreateTicket = () => {
    setForm({ ...defaultForm })
    if (searchParams.get('back')) {
      navigate(searchParams.get('back')!)
    } else {
      navigate('/claims')
    }
  }

  const handleChangeForm = (field: string, value: any) => {
    resetError(field)
    if (field === 'reason' && value !== 'other') {
      resetError('description')
    }

    if (field === 'solution' && value !== 'compensate') {
      resetError('suggest')
      form.suggest = undefined
    }

    lodash.set(form, field, value)
    setForm({
      ...form,
    })
  }

  const validateForm = () => {
    let hasError = false
    if (!form.relatedOrder) {
      error.relatedOrder = t('ticket-create.error_require_order_code')
      hasError = true
    }

    if (!form.reason) {
      error.reason = t('ticket-create.error_require_reason')
      hasError = true
    }

    if (form.reason === 'not_received' && !form.notReceived) {
      error.notReceived = t('ticket-create.error_require_missing_quantity')
      hasError = true
    }

    if (form.reason === 'other' && !form.description) {
      error.description = t('ticket-create.error_require_description')
      hasError = true
    }

    if (!form.solution) {
      error.solution = t('ticket-create.error_require_resolve_suggestion')
      hasError = true
    }

    if (form.solution === 'compensate' && !form.suggest) {
      error.suggest = t('ticket-create.error_suggest_must_not_be_null')
      hasError = true
    }

    if (form.reason === 'faulty_product' && !fileList.length) {
      error.uploadFile = t('ticket-create.create_error_files_must_be_uploaded')
      hasError = true
    }

    setError({ ...error })

    return hasError
  }

  const handlePreviewFile = (file: any) => {
    setPreviewFile(file)
    setIsShowPreviewFile(true)
  }

  const resetError = (key: string) => {
    lodash.set(error, key, undefined)
    setError({ ...error })
  }

  const handleSubmit = () => {
    const hasError = validateForm()
    if (!hasError) {
      setLoading(true)
      const data = {
        name: generateClaimName(t, form.relatedOrder, form.relatedProduct),
        relatedProduct: form.relatedProduct,
        relatedOrder: form.relatedOrder,
        reason: form.reason,
        reasonData: form.reasonData,
        solution: form.solution,
        suggest: form.suggest,
        description: form.description,
        ticketType: ticketType?.toLowerCase(),
        notReceived: form.notReceived,
      }

      if (['return', 'redelivery'].indexOf(data.solution) >= 0) {
        lodash.unset(data, 'suggest')
      }

      const formData = new FormData()
      formData.append('claim', new Blob([JSON.stringify(data)], { type: 'application/json' }))
      if (fileList && fileList.length > 0) {
        fileList.forEach((file: any) => {
          formData.append(
            'attachments',
            new Blob([file.originFileObj], {
              type: file.type,
            }),
            file.name
          )
        })
      }
      // console.log(formData.getAll('attachments'))

      claimRepository
        .create(formData)
        .then(() => {
          setLoading(false)
          setForm({ ...defaultForm })

          M24Notification.success({
            message: t('ticket-create.create_success'),
          })
          navigate(searchParams.get('back') || '/claims')
        })
        .catch((error) => {
          setLoading(false)
          if (lodash.get(error, 'response.status') === 400) {
            // console.log(error.response)
            if (error.response?.data.title === 'invalid_not_received_quantity') {
              let quantityExceed = lodash.get(error, 'response.data.detail').split(':')[1]
              M24Notification.error({
                key: 'invalid_not_received_quantity',
                message: t(`ticket-create.create_error_${lodash.get(error, 'response.data.title')}`, {
                  value: quantityExceed,
                }),
              })
            } else {
              M24Notification.error({
                message: t(`ticket-create.create_error_${lodash.get(error, 'response.data.title')}`),
              })
            }
          } else if (lodash.get(error, 'response.status') === 404) {
            M24Notification.error({
              message: t(`ticket-create.create_error_${lodash.get(error, 'response.data.title')}`),
            })
          } else {
            M24Notification.error({
              message: t('ticket-create.create_error'),
            })
          }
        })
    }
  }

  const handleChangeTypeOfClaim = (e: RadioChangeEvent) => {
    setTicketType(e.target.value)
    setForm({
      ...form,
      reason: '',
      solution: '',
    })
  }

  return (
    <MainLayout title={t('tickets.createTicketTittle')}>
      <div className={'ticket-container'}>
        {!loadingParams ? (
          <Row className={``}>
            <Col span={5} />
            <Col
              span={14}
              className={`pd-24 m22-box-${theme} border-card-header border-radius6`}>
              <div className="mg-bt-12">
                <span className={`m22-typo-heading mg-bt-24 txt-capitalize ${theme}`}>
                  {t('tickets.createTicketTittle')}
                </span>
              </div>
              <Form
                className={'mg-t-32 create-ticket-form-custom'}
                layout={'horizontal'}
                colon={false}>
                <Form.Item className="position-re">
                  <Radio.Group
                    onChange={handleChangeTypeOfClaim}
                    value={ticketType}>
                    <Radio value="ORDER">{t('shipment.orderClaim')}</Radio>
                    <Radio value="SHIPMENT">{t('shipment.shipmentClaim')}</Radio>
                  </Radio.Group>
                </Form.Item>
                <ComboBox
                  label={
                    <span>
                      {t('tickets.orderCode')} <span className={'txt-color-red'}>*</span>
                    </span>
                  }
                  value={form.relatedOrder}>
                  <Form.Item
                    validateStatus={error.relatedOrder ? 'error' : ''}
                    help={error.relatedOrder}>
                    <Input
                      size="large"
                      value={form.relatedOrder}
                      onChange={(e) => handleChangeForm('relatedOrder', e.target.value)}
                    />
                  </Form.Item>
                </ComboBox>
                {(ticketType === 'ORDER' || ticketType === 'SHIPMENT') && (
                  <ComboBox
                    label={t('tickets.productsCode')}
                    value={form.relatedProduct}>
                    <Form.Item>
                      <Input
                        size="large"
                        value={form.relatedProduct}
                        onChange={(e) => handleChangeForm('relatedProduct', e.target.value)}
                        onBlur={() => setFetchReasonForProduct(!fetchReasonForProduct)}
                      />
                    </Form.Item>
                  </ComboBox>
                )}
                <ComboBox
                  label={
                    <span>
                      {t('tickets.reason')} <span className={'txt-color-red'}>*</span>
                    </span>
                  }
                  value={form.reason || undefined}>
                  <Form.Item
                    validateStatus={error.reason ? 'error' : ''}
                    help={error.reason}>
                    <Select
                      notFoundContent={<EmptyDataFilter description="Không tìm thấy lý do phù hợp" />}
                      disabled={!form.relatedOrder}
                      size="large"
                      value={form.reason || undefined}
                      onChange={(value) => handleChangeForm('reason', value)}
                      suffixIcon={<i className="fa-solid fa-angle-down fsz-14"></i>}>
                      {reasons.map((item) => {
                        return (
                          <Select.Option
                            key={item.code}
                            value={item.code}>
                            {item.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </ComboBox>

                {isShowMissingQuantity && (
                  <>
                    <ComboBox
                      label={
                        <span>
                          {t('ticket-create.missing_quantity')} <span className={'txt-color-red'}>*</span>
                        </span>
                      }
                      value={lodash.get(form, 'notReceived')}>
                      <Form.Item
                        validateStatus={error.notReceived ? 'error' : ''}
                        help={error.notReceived}>
                        <Input
                          value={lodash.get(form, 'notReceived')}
                          size="large"
                          onChange={(e) =>
                            handleChangeForm('notReceived', e.target.value.replace(/(?!--)[^0-9.]/g, ''))
                          }
                        />
                      </Form.Item>
                    </ComboBox>
                    <div className={'mg-bt-12'}>
                      <span className={'txt-color-red'}>*</span> {t('ticket-create.not_received_all_messge')}
                    </div>
                  </>
                )}
                <ComboBox
                  label={
                    <span>
                      {t('tickets.description')}
                      {form.reason === 'other' && <span className={'txt-color-red'}>*</span>}
                    </span>
                  }
                  value={form.description}
                  isTextArea>
                  <Form.Item
                    validateStatus={error.description ? 'error' : ''}
                    help={error.description}>
                    <TextArea
                      rows={2}
                      className={'text-area-layout'}
                      value={form.description}
                      onChange={(e) => handleChangeForm('description', e.target.value)}
                    />
                  </Form.Item>
                </ComboBox>
                <Row gutter={[16, 0]}>
                  <Col
                    className="gutter-row"
                    span={16}>
                    <ComboBox
                      label={
                        <span>
                          {t('tickets.resolvesSuggestion')}
                          <span className={'txt-color-red'}> *</span>
                        </span>
                      }
                      value={form.solution || undefined}>
                      <Form.Item
                        validateStatus={error.solution ? 'error' : ''}
                        help={error.solution}>
                        <Select
                          notFoundContent={<EmptyDataFilter description="Không tìm thấy phương án xử lý phù hợp" />}
                          size="large"
                          disabled={!form.relatedOrder}
                          className={'select-layout'}
                          value={form.solution || undefined}
                          onChange={(value) => handleChangeForm('solution', value)}
                          suffixIcon={<i className="fa-solid fa-angle-down fsz-14"></i>}>
                          {solutions.map((item) => (
                            <Select.Option
                              key={item.code}
                              value={item.code}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </ComboBox>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={8}>
                    <ComboBox
                      label={
                        <span>
                          {t('tickets.moneySuggestion')}
                          <span className={'txt-color-red'}> *</span>
                        </span>
                      }
                      value={form.suggest}>
                      <Form.Item
                        validateStatus={error.suggest ? 'error' : ''}
                        help={error.suggest}>
                        <InputMoney
                          disabled={form.solution !== 'compensate'}
                          size="large"
                          style={{ width: '100%' }}
                          thousandSeparator={InputMoneySeparatorType.comma}
                          suffix={'đ'}
                          value={form.suggest}
                          onChange={(value) => {
                            const regex = /^[^.]+$/g
                            if ((value && regex.test(value)) || !value) {
                              handleChangeForm('suggest', value)
                            } else {
                              return
                            }
                          }}
                        />
                      </Form.Item>
                    </ComboBox>
                  </Col>
                </Row>

                <Form.Item
                  className="mg-bt-8"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={<span className={'txt-size-h7  robotomedium'}>{t('tickets.uploadFiles')}: </span>}
                  validateStatus={error.uploadFile ? 'error' : ''}
                  help={error.uploadFile}>
                  <Upload
                    className="avatar-uploader claim-upload-file "
                    listType={'picture-card'}
                    accept={'.png, .gif, .jpeg, .jpg, video/*'}
                    beforeUpload={handleUploadFile}
                    customRequest={() => {}}
                    onChange={handleChangeFile}
                    showUploadList={true}
                    onPreview={handlePreviewFile}
                    fileList={fileList}
                    multiple={true}>
                    <Spin spinning={loading}>
                      <span className={`txt-size-h7 ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray'}`}>
                        <i className={'far fa-plus'} /> Upload
                      </span>
                    </Spin>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Row
                    gutter={5}
                    className={'mgl15'}>
                    <Col span={24}>
                      <span className={'txt-color-red'}>*</span> {t('tickets.upload-required')}
                    </Col>
                    <Col span={24}>
                      <span className={'txt-color-red'}>*</span> {t('tickets.file-size--upload-maximum')}
                    </Col>
                    <Col span={24}>
                      <span className={'txt-color-red'}>*</span> {t('tickets.img-upload-maximum')}
                    </Col>
                  </Row>
                </Form.Item>

                <Row
                  justify={'end'}
                  className={'pdbt6'}>
                  <Form.Item>
                    <Button
                      disabled={loading}
                      type="text"
                      className={`m22-btn ${theme} mgr5`}
                      onClick={handleCancelCreateTicket}>
                      {t('tickets.btnCancel')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      icon={<i className="fa-regular fa-plus"></i>}
                      onClick={handleSubmit}
                      type="primary"
                      className={`mg-bt-10 m22-btn  ${theme}`}
                      loading={loading}
                      disabled={
                        loading ||
                        !ticketType ||
                        !form.relatedOrder ||
                        !form.solution ||
                        !form.reason ||
                        (form.reason === 'faulty_product' && fileList.length === 0)
                      }
                      htmlType={'submit'}>
                      <span className={'txt-size-h7 '}>{t('tickets.btnCreate')}</span>
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <>
            <Skeleton width={100}></Skeleton>
            <Skeleton width={100}></Skeleton>
          </>
        )}
        {/* </Spin> */}
      </div>
      <Modal
        open={isShowPreviewFile}
        footer={false}
        onCancel={() => {
          setIsShowPreviewFile(false)
          setPreviewFile(null)
        }}>
        <img
          className={'width100'}
          src={previewFile && previewFile.thumbUrl}
          alt=""
        />
      </Modal>
    </MainLayout>
  )
}

export default ClaimCreate
