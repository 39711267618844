import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Image, Input, InputNumber, InputRef, message, Modal, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ComboBox from '../../../../components/ComboBox'
import { TOKEN_KEY } from '../../../../core/config'
import ShipmentProductInterface from '../../../../Interface/ShipmentProductInterface'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import { localStorageRead } from '../../../../utils/LocalStorageUtils'
import M24Notification from 'src/utils/M24Notification'
// import { InputNumberCommon } from 'src/components/UI/inputs/InputNumberCommon'

const { Item } = Form

interface CreateProductModalProps {
  visible: boolean
  visibleHandler: (val: boolean) => void
  refetch: () => void
  type?: string
  product?: ShipmentProductInterface
}

const CreateProductModal: React.FC<CreateProductModalProps> = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { code } = useParams()
  const { visible, visibleHandler, refetch, type, product } = props
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [showUpload, setShowUpload] = useState(true)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const inputRef = useRef<InputRef>(null)
  const [errorImage, setErrorImage] = useState(false)

  const productUrl = Form.useWatch('productUrl', form)
  const quantity = Form.useWatch('quantity', form)
  const unitPrice = Form.useWatch('unitPrice', form)
  const name = Form.useWatch('name', form)
  const translatedName = Form.useWatch('translatedName', form)
  const merchantName = Form.useWatch('merchantName', form)
  const merchantContact = Form.useWatch('merchantContact', form)
  
  useEffect(() => {
    if (visible) {
      if (type === 'create') {
        setDisabled(true)
      }
      form.resetFields()
      setShowUpload(type === 'create' ? true : product?.productImage ? false : true)
      const initialValues =
        type === 'create'
          ? {
              productImage: [],
              productUrl: '',
              unitPrice: '',
              name: '',
              translatedName: '',
              merchantName: '',
              shopAddress: '',
              quantity: '',
              code: '',
              merchantContact: '',
            }
          : {
              ...product,
              productImage: product?.productImage ? [{ uid: 'test', name: product?.name, url: product?.productImage, status: 'done' }] : [],
            }
      form.setFieldsValue({ ...initialValues })
      setTimeout(() => inputRef.current!.focus({ cursor: type === 'create' ? 'start' : 'end' }), 0)
    }
  }, [form, product, type, visible])

  const normFile = useCallback((e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    setShowUpload(e?.fileList.length === 0)

    if (e?.fileList.length === 0) {
      setErrorImage(false)
    } else {
      const isJpgOrPng = e?.fileList[0].type === 'image/jpeg' || e?.fileList[0].type === 'image/png'
      if (!isJpgOrPng) {
        setErrorImage(true)
      }
      const isLt2M = e?.fileList[0].size / 1024 / 1024 < 10
      if (!isLt2M) {
        setErrorImage(true)
      }
      if (isJpgOrPng && isLt2M) {
        setErrorImage(false)
      }
      return e?.fileList
    }
  }, [])

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('shipment.fileType'))
      setErrorImage(true)
    } else {
      setErrorImage(false)
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error(t('shipment.fileSize'))
      setErrorImage(true)
    } else {
      setErrorImage(false)
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onFinish = useCallback(
    async (values: any) => {
      setLoadingProduct(true)
      try {
        if (type === 'create') {
          await shipmentRepository.createProduct(code!, {
            ...values,
            productImage: !isEmpty(values['productImage']) ? values['productImage'][0].response : '',
          })
          M24Notification.success({ message: t('shipment.addSuccess') })
        } else {
          await shipmentRepository.updateProduct(code!, product?.code, {
            ...values,
            productImage: !isEmpty(values['productImage']) ? values['productImage'][0].response : '',
          })
          M24Notification.success({ message: t('shipment.updateSuccess') })
        }
        
        visibleHandler(false)
        refetch()
      } catch (err) {
        M24Notification.error({ message: t('shipment.errorMessage') })
      } finally {
        setLoadingProduct(false)
      }
    },
    [type, code, t, product?.code, visibleHandler, refetch]
  )

  

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Preview(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const handleCancel = () => {
    setPreviewOpen(false)
  }

  return (
    <>
      <Modal
        title={t(`shipment.${type === 'create' ? 'createProducts' : 'editProduct'}`)}
        closeIcon={
          <i
            className="fa-solid fa-xmark"
            onClick={() => visibleHandler(false)}></i>
        }
        closable={true}
        width={700}
        centered
        onCancel={() => {
          visibleHandler(false)
          form.resetFields()
        }}
        okText={t('button.submit')}
        cancelText={t('button.cancel')}
        className="shipment-product-form m22-modal"
        cancelButtonProps={{
          type: 'text',
          className: 'm22-btn',
          // icon: <i className="fa-solid fa-xmark mg-r-10" />,
        }}
        confirmLoading={loadingProduct}
        okButtonProps={{
          type: 'primary',
          className: 'm22-btn',
          disabled,
          htmlType: 'submit',
          form: 'product-add-form',
          icon: <i className="fa-solid fa-check mg-r-10"></i>,
        }}
        open={visible}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values)
            })
            .catch((info) => {})
        }}>
        <Form
          name="product-add-form"
          autoComplete="off"
          form={form}
          onFieldsChange={() =>
            setDisabled(
              !form.getFieldValue('productUrl') ||
                !form.getFieldValue('quantity') ||
                !form.getFieldValue('unitPrice') ||
                !form.getFieldValue('name') ||
                !form.getFieldValue('translatedName') ||
                errorImage
            )
          }>
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-link')}
            value={productUrl}
            required>
            <Item
              className="relative"
              name="productUrl"
              rules={[
                { required: true, message: t('shipment.linkRequired') },
                { type: 'url', message: 'Chưa đúng định dạng link' },
              ]}>
              <Input
                ref={inputRef}
                size="large"
              />
            </Item>
          </ComboBox>
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-quantity')}
            value={quantity}
            required>
            <Item
              name="quantity"
              rules={[{ required: true, message: t('shipment.qtyRequired') }]}>
              <InputNumber
                min={1}
                size="large"
                className="width100pc"
                type="number"
              />
            </Item>
          </ComboBox>
          
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-unitPrice')}
            value={unitPrice}
            required>
            <Item
              name="unitPrice"
              rules={[{ required: true, message: t('shipment.priceRequired') }]}
              labelAlign="left">
              <InputNumber
                min={1}
                size="large"
                className="width100pc"
                type="number"
              />
            </Item>
          </ComboBox>

          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-originalName')}
            value={name}
            required>
            <Item
              name="name"
              rules={[{ required: true, message: t('shipment.originalNameRequired') }]}
              labelAlign="left">
              <Input size="large" />
            </Item>
          </ComboBox>
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-translatedName')}
            value={translatedName}
            required>
            <Item
              name="translatedName"
              rules={[{ required: true, message: t('shipment.translatedNameRequired') }]}
              labelAlign="left">
              <Input size="large" />
            </Item>
          </ComboBox>
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-shop-name')}
            value={merchantName}>
            <Item
              name="merchantName"
              labelAlign="left">
              <Input size="large" />
            </Item>
          </ComboBox>
          <ComboBox
            className="mg-bt-30"
            labelClassName="22px"
            label={t('shipment.product-shop-address')}
            value={merchantContact}>
            <Item
              name="merchantContact"
              labelAlign="left">
              <Input size="large" />
            </Item>
          </ComboBox>
          <Item
            name="productImage"
            label={t('shipment.imageProduct')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            labelAlign="left"
            className="image">
            <Upload
              name="file"
              listType="picture-card"
              accept={'image/png, image/gif, image/jpeg, image/jpg'}
              className="avatar-uploader"
              action={`${process.env.REACT_APP_API_URL}/storages/upload`}
              headers={{ Authorization: `Bearer ${localStorageRead(TOKEN_KEY)}` }}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onPreview={handlePreview}
              progress={{ strokeWidth: 3, strokeColor: { '0%': '#f0f', '100%': '#ff0' } }}>
              {showUpload && uploadButton}
            </Upload>
          </Item>
        </Form>
      </Modal>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        centered>
        <Image
          alt="example"
          style={{ width: '100%' }}
          src={previewImage}
          preview={false}
        />
      </Modal>
    </>
  )
}

export default CreateProductModal

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const getBase64Preview = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })