import { ReloadOutlined } from '@ant-design/icons'
import { Button, Col, Input, Radio, RadioChangeEvent, Row } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { EMPTY_INFO } from '../../core/config'
import { useTheme } from '../../hooks/useTheme'
import ComboBox from '../ComboBox'
import M22Box from '../M22Box'

type ClaimFilterProps = {
  loading: boolean
  selectedAccount: any
  ticketStatuses: any[]
  ticketSolutions: any[]
  handleSelectAccount: (acc: any) => void
  handleSkeleton: (val: boolean) => void
}

const defaultFilter = {
  publicStates: '',
  solutionCodes: '',
  code: '',
  provider: '',
  providerUsername: '',
  accountId: '',
}

function ClaimFilter(props: ClaimFilterProps) {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [filter, setFilter] = useState<any>(defaultFilter)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showCleanFilter, setShowCleanFilter] = useState(false)
  const [expand, setExpand] = useState(false)
  const [ticketType, setTicketType] = useState('')
  const ref = useRef<HTMLDivElement | null>(null)

  const { ticketStatuses, ticketSolutions } = props

  useEffect(() => {
    setFilter({
      ...defaultFilter,
      publicStates: searchParams.get('publicStates')?.split(',') || [],
      solutionCodes: searchParams.get('solutionCodes')?.split(',') || [],
      code: searchParams.get('code')?.trim() || '',
      provider: searchParams.get('provider') || '',
      providerUsername: searchParams.get('providerUsername') || '',
      ticketType: searchParams.get('ticketType') || '',
    })
  }, [searchParams, ticketSolutions.length, ticketStatuses.length])

  const handleChangeFilter = (param: string, value: any) => {
    const newFilter = {
      ...filter,
      [param]: value.trim(),
    }
    setFilter(newFilter)
  }

  const handleSubmit = () => {
    setSearchParams({
      ...lodash.omitBy(
        {
          ...filter,
          code: filter.code.trim(),
          publicStates: filter.publicStates.join(','),
          solutionCodes: filter.solutionCodes.join(','),
        },
        (o) => !o
      ),
    })
  }

  const handleChangeFilterAndSubmit = (param: string, value: any, isSelect?: boolean) => {
    if (param === 'publicStates' || param === 'solutionCodes') {
      const itemUpdated = isSelect
        ? [...filter[`${param}`], value]
        : filter[`${param}`].filter((item: any) => item !== value)
      setFilter({ ...filter, [param]: itemUpdated })
    } else {
      setFilter({ ...filter, [param]: value })
      setSearchParams({
        ...lodash.omitBy({ ...filter, [param]: value }, (o) => !o),
        ticketType,
      })
    }
  }

  const handleResetFilter = () => {
    setFilter(defaultFilter)
    setSearchParams({})
    setTicketType('')
  }

  useEffect(() => {
    const showFilterByTicketType = ticketType === 'ALL' || isEmpty(ticketType) ? false : true
    // const param = searchParams.get('page') || searchParams.get('limit') ? true : false

    setShowCleanFilter(
      !lodash.isEmpty(
        lodash.pickBy(filter, (val: any) => {
          if (isArray(val)) {
            return val.every((e) => e) && !isEmpty(val)
          }
          return !isEmpty(val)
        })
      ) ||
        (!isEmpty(ticketType) && showFilterByTicketType)
    )
  }, [filter, ticketType, searchParams])

  const handleChangeTypeOfClaim = (e: RadioChangeEvent) => {
    setTicketType(e.target.value)
    setFilter({ ...filter, ticketType: e.target.value })
  }

  return (
    <M22Box className={'border-card-header px-24 py-16  ticket-filter-container rad-6 '}>
      <Row
        wrap={false}
        align="middle"
        gutter={8}
        className={`cursor-pointer  dpl-flex justify-content-between ${showFilter ? 'mg-bt-16' : ''}`}
        onClick={() => setShowFilter(!showFilter)}>
        <span className={`m22-typo-heading ${theme}`}>{t('tickets.tickets-filter-tittle')}</span>
        <span
          onClick={() => {
            setShowFilter(!showFilter)
          }}
          className={'cursor-pointer pdl5 align-self-center'}>
          <i className={`fas fa-chevron-${showFilter ? 'up' : 'down'} fsz-12`} />
        </span>
      </Row>
      {showFilter && (
        <>
          <Row
            gutter={[24, 24]}
            align="middle">
            <Col span={24}>
              <Row
                gutter={[24, 12]}
                align="middle">
                <Col
                  flex={'0 0 auto'}
                  ref={ref}
                  className="flex align-items-baseline">
                  <span className={` mg-r-16 robotomedium ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'}`}>
                    {t('tickets.ticket-statuses')}:
                  </span>
                  {!!ticketStatuses.length
                    ? ticketStatuses.map((status: any) => {
                        const isSelect = filter.publicStates && filter.publicStates.includes(status.code) ? true : false

                        return (
                          <span
                            onClick={() => {
                              handleChangeFilterAndSubmit('publicStates', status.code, !isSelect)
                            }}
                            className={`m22-badge mg-bt-0 ${theme}  ${isSelect ? 'active' : ''}`}
                            key={status.code}>
                            {status.name}
                          </span>
                        )
                      })
                    : EMPTY_INFO}
                </Col>
                <Col
                  flex={'0 0 auto'}
                  className="flex align-items-baseline mg-l-50">
                  <span className={`mg-r-16 robotomedium ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'}`}>
                    {t('tickets.resolve-methods')}:
                  </span>
                  {!!ticketSolutions.length
                    ? ticketSolutions.map((solution: any) => {
                        const isSelect =
                          filter.solutionCodes && filter.solutionCodes.includes(solution.code) ? true : false

                        return (
                          <span
                            onClick={() => {
                              handleChangeFilterAndSubmit('solutionCodes', solution.code, !isSelect)
                            }}
                            className={`cursor-pointer mg-bt-0 m22-badge  ${theme} ${isSelect ? 'active' : ''}`}
                            key={solution.code}>
                            <span className={'txt-size-h8 robotomedium'}>{solution.name}</span>
                          </span>
                        )
                      })
                    : EMPTY_INFO}
                </Col>
              </Row>
            </Col>

            {expand && (
              <>
                <Col span={24}>
                  <Row
                    gutter={[20, 12]}
                    align="middle"
                    className="">
                    <Col
                      span={7}
                      className="flex align-items-baseline">
                      <ComboBox
                        label={t('tickets.searchByCode-placeholders')}
                        value={lodash.get(filter, 'code', '')}>
                        <Input
                          className="width100pc m22-input"
                          placeholder=""
                          size="large"
                          onChange={(event: any) => {
                            // const regex = /^\S+$/;
                            // let value = event.target.value;
                            // if((value && regex.test(value)) || !value) {
                            handleChangeFilter('code', event.target.value)
                            // } else return
                          }}
                          value={lodash.get(filter, 'code', '')}
                          onPressEnter={(event: any) => handleChangeFilterAndSubmit('code', event.target.value)}
                        />
                      </ComboBox>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  className=" flex align-items-baseline">
                  <div
                    className={`${
                      theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray8'
                    } mg-r-8 robotomedium mg-bt-16 whitespace`}>
                    {`${t('shipment.typeOfClaim')}:`}
                  </div>
                  <div className="flex align-items-baseline mg-l-50">
                    <Radio.Group
                      onChange={handleChangeTypeOfClaim}
                      value={ticketType}>
                      <Radio value="">{t('claim.allType')}</Radio>
                      <Radio value="ORDER">{t('shipment.orderClaim')}</Radio>
                      <Radio value="SHIPMENT">{t('shipment.shipmentClaim')}</Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row
            align="middle"
            justify={'space-between'}
            className="mg-t-16">
            {ticketSolutions.length > 0 || ticketStatuses.length > 0 ? (
              <span
                className="m22-typo-label_collapse"
                onClick={() => setExpand(!expand)}>
                <i className={`fa-solid fa-angle-${expand ? 'up' : 'down'} mg-r-6 fsz-12`}></i>
                <span className="line-h-20  fsz-12">{t(`shipment.${expand ? 'collapse' : 'noCollapse'}`)}</span>
              </span>
            ) : (
              <div></div>
            )}
            <div className={'dpl-flex align-items-center justify-content-end'}>
              {showCleanFilter && (
                <span
                  onClick={handleResetFilter}
                  className={`m22-btn-reset ${theme}`}>
                  <ReloadOutlined className="mg-r-4" />
                  {t('filter.refresh')}
                </span>
              )}
              <Button
                type="primary"
                className="m22-btn"
                onClick={handleSubmit}
                icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                {t('tickets.search-btn')}
              </Button>
            </div>
          </Row>
        </>
      )}
    </M22Box>
  )
}

export default ClaimFilter
