import { PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Image, Row, Table, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import IconDrawFailedImg from '../../../../resources/images/empty-3.png'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import TruncateText from '../../../TruncateText'
import Item from '../../Information/Item'
import ConfirmChange from './ConfirmChange'
import { orderRepository } from '../../../../repositories/OrderRepository'
import M24Notification from '../../../../utils/M24Notification'
import M24ErrorUtils from '../../../../utils/M24ErrorUtils'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../SkeletonTable'
import { useTheme } from '../../../../hooks/useTheme'

interface OrderItemsProps {
  items: any
  exchangeRate: number
  currency: any
  loading: boolean
  loadingNotConfirm: { [id: string]: boolean } | undefined
  loadingConfirm: { [id: string]: boolean } | undefined
  confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
  handleConfirm: (val: boolean) => void
}

const Products: React.FC<OrderItemsProps> = (props) => {
  const [currencyProvider, setCurrencyProvider] = useState()
  const [theme] = useTheme()
  const { t } = useTranslation()
  const { items, loading, currency, confirmProductChange, loadingConfirm, loadingNotConfirm } = props
  const { handleConfirm } = props

  useEffect(() => {
    let temp = currency
    if (temp) {
      temp = JSON.parse(temp)
      setCurrencyProvider(temp.code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePersonalNote = useCallback(
    async (code, id = '', val, key, handleState) => {
      try {
        const res = await orderRepository.updatePersonalNoteOfOrderItem(code, id, val)
        handleState(lodash.get(res, 'personalNote'))
        M24Notification.notifySuccess(t('orderDetails.updatedPersonalNoteItem'), '', '', 5)
      } catch (err) {
        M24ErrorUtils.showError(t, err)
      }
    },
    [t]
  )

  const columns = [
    {
      title: <span className="fsz-12">{t('orderDetail.productName')}</span>,
      key: 'name',
      width: '30%',
      render: (record: any) => {
        const properties = lodash.get(record, 'variantProperties', [])

        return (
          <span className="dpl-flex">
            <div className="flex flex-col items-center">
              <a
                href={lodash.get(record, 'skuUrl')}
                className="cursor-pointer "
                target="_blank"
                rel="noreferrer">
                <Avatar
                  shape={'square'}
                  size={60}
                  src={lodash.get(record, 'skuImage', '')}
                  className={'flex-shrink-0 border-radius-none mg-bt-6 '}
                  style={{ border: 'none' }}
                />
              </a>
              <div className="robotomedium">#{lodash.get(record, 'productLinkCode')}</div>
            </div>

            <div className="dpl-flex flex-col mg-l-6">
              <a
                href={lodash.get(record, 'skuUrl')}
                className={`cursor-pointer   robotomedium ${theme}`}
                target="_blank"
                rel="noreferrer">
                <span>{lodash.get(record, 'skuName', '--')}</span>
              </a>{' '}
              <span className="dpl-flex flex-col">
                {properties.length > 0
                  ? properties.map((pro: any, idx: number) => (
                      <span
                        className={`txt-color-gray4 fsz-14 line-h-22 ${theme}`}
                        key={idx}>{`${pro.name} : ${pro.value}`}</span>
                    ))
                  : '--'}
              </span>
            </div>
          </span>
        )
      },
    },
    {
      title: <span className="fsz-12">{t('orderDetail.productInformation')}</span>,
      key: 'info',
      width: '18%',
      render: (record: any) => {
        const currency = lodash.get(record, 'currency')
        const orderedQuantity = lodash.isNull(lodash.get(record, 'orderedQuantity')) ? '--' : lodash.get(record, 'orderedQuantity', '--')
        const purchasedQuantity = lodash.isNull(lodash.get(record, 'purchasedQuantity')) ? '--' : lodash.get(record, 'purchasedQuantity', '--')
        const receivedQuantity = lodash.isNull(lodash.get(record, 'receivedQuantity')) ? '--' : lodash.get(record, 'receivedQuantity', '--')
        const totalAmount = lodash.isNull(lodash.get(record, 'totalAmount')) ? '---' : lodash.get(record, 'totalAmount', '---')
        const exchangedTotalAmount = lodash.isNull(lodash.get(record, 'exchangedTotalAmount')) ? '---' : lodash.get(record, 'exchangedTotalAmount', '---')
        return (
          <span className="dpl-flex flex-col fsz-14 justify-content-start">
            <span className="dpl-flex align-items-center">
              <span className="mgr6  mgr6 fsz-14 line-h-22">{`${orderedQuantity}/${purchasedQuantity}/${receivedQuantity}/`}</span>
              <Tooltip title={t('orderDetail.quantityCount')}>
                <i className={`far fa-info-circle txt-color-gray4 txt-size-h8 mg-r-8 cursor-pointer ${theme}`}></i>
              </Tooltip>
            </span>
            <span className="robotomedium">
              <span className="mgr6">
                <span className="mgr3  fsz-14 line-h-22">{formatMoneyByUnit(lodash.get(record, 'exchangedActualPrice'), currencyProvider)}</span>
                <span className={`txt-color-gray4 fsz-12 line-h-20 ${theme}`}>{`(${formatMoneyByUnit(lodash.get(record, 'actualPrice'), currency)})`}</span>
              </span>
              <Tooltip title={t('orderDetail.priceProduct')}>
                <i className={`far fa-info-circle txt-color-gray4 txt-size-h8 mg-r-8 cursor-pointer ${theme}`}></i>
              </Tooltip>
            </span>
            <span className="robotomedium">
              <span className="mgr6">
                <span className="mgr3">{formatMoneyByUnit(exchangedTotalAmount, currencyProvider)}</span>
                <span className={`txt-color-gray4 fsz-12 ${theme}`}>{`(${formatMoneyByUnit(totalAmount, currency)})`}</span>
              </span>
              <Tooltip title={t('orderDetail.toMoney')}>
                <i className={`far fa-info-circle txt-color-gray4 txt-size-h8 mg-r-8 cursor-pointer ${theme}`}></i>
              </Tooltip>
            </span>
          </span>
        )
      },
    },
    {
      title: <span className="fsz-12">{t('orderDetail.notice')}</span>,
      key: 'notice',
      width: 400,
      render: (record: any) => {
        const noteForProduct = lodash.isNull(lodash.get(record, 'employeeNote')) ? '---' : lodash.get(record, 'employeeNote', '---')
        const personalNoteProduct = lodash.isNull(lodash.get(record, 'personalNote')) ? '---' : lodash.get(record, 'personalNote', '---')
        return (
          <>
            <Item
              label={<span className={'whitespace'}>{t('orderDetail.noteForProduct')}:</span>}
              content={
                <TruncateText
                  defaultValue={noteForProduct}
                  className="txt-color-gray"
                  editable={false}
                />
              }
            />
            <Item
              className="width100pc"
              label={<span className={'whitespace'}>{`${t('orderDetail.noteForProductPersonal')}:`}</span>}
              content={
                loading ? (
                  ''
                ) : (
                  <TruncateText
                    defaultValue={personalNoteProduct}
                    className="txt-color-gray"
                    editable={true}
                    id={lodash.get(record, 'id')}
                    cb={updatePersonalNote}
                  />
                )
              }
            />
          </>
        )
      },
    },
  ]

  const localeCustom = {
    emptyText: (
      <>
        {!loading && (
          <div className="empty fsz-14">
            <Row
              justify="center"
              className="mg-bt-8"
              gutter={[0, 6]}>
              <Col lg={24}>
                <Image
                  preview={false}
                  src={IconDrawFailedImg}
                />
              </Col>
              <Col
                lg={24}
                className="text-disabled">
                <span className="fsz-14 robotomedium ">{t('orderDetail.noItems')}</span>
              </Col>
              <Col lg={24}>
                <Button
                  icon={<PlusOutlined />}
                  className="txt-color-orange  bg-color-white bd-color-primary hover-primary">
                  {t('orderDetail.noItems')}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </>
    ),
  }

  const components = { body: { row: ConfirmChange } }
  const total = items.length
  const itemsSorted = items.sort((a: any, b: any) => (a.actualPrice > b.actualPrice ? -1 : 1))
  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
      className={`product-list m22-table ${theme}`}
      rowCount={3}>
      <Table
        components={components}
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={itemsSorted}
        locale={localeCustom}
        loading={loading}
        className={`product-list m22-table ${theme}`}
        pagination={false}
        onRow={(record, index) => {
          return {
            ...record,
            total,
            confirmProductChange,
            loadingConfirm,
            loadingNotConfirm,
            index,
            handleConfirm,
          }
        }}
      />
    </SkeletonTable>
  )
}
export default Products
