import { Button, List, Modal, Row, Skeleton } from 'antd'
import lodash from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import { parseOrderLog } from '../../../../utils/FormatLog'

interface LogModalProps {
	visibleHandler: (val: boolean) => void
	visible: boolean
	currency: any
	metadata: any
	handleMetadata: (obj: any) => void
}

function Logs(props: LogModalProps) {
  const { visibleHandler, visible, currency, metadata, handleMetadata } = props
  const [dataSource, setDataSource] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [list, setList] = useState<any[]>([])
  const refLog = useRef<HTMLDivElement | null>(null)
  let { code } = useParams()
  const { t } = useTranslation()
  useEffect(() => {
    if (visible) {
      getData(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    if (metadata && metadata.page + 1 >= metadata.pageCount) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [metadata])
  const getData = (page: number) => {
    setInitLoading(true)
    if (page > 0) {
      setLoading(true)
      setInitLoading(false)
      setList(dataSource.concat([...new Array(metadata.page)].map(() => ({ loading: true }))))
    }
    shipmentRepository
      .getLogs(code!, { page })
      .then((res) => {
        let items = parseOrderLog(t, res.data, currency, true)
        items = items.filter((item: any) => item.property !== 'staffNote')
        handleMetadata({
          pageCount: parseInt(lodash.get(res, 'headers.x-page-count')),
          page: parseInt(lodash.get(res, 'headers.x-page-number')),
          size: parseInt(lodash.get(res, 'headers.x-page-size')),
          total: parseInt(lodash.get(res, 'headers.x-total-count')),
        })
        const newData = page === 0 ? items : dataSource.concat(items)
        setDataSource(newData)
        setList(newData)
        if (page > 0) {
          window.dispatchEvent(new Event('resize'))
        }
      })
      .catch((err) => {
        setHasMore(false)
      })
      .finally(() => {
        setLoading(false)
        setInitLoading(false)
      })
  }
  const loadMoreData = () => {
    if (loading) return

    getData(metadata.page + 1)
  }

  const loadMore =
    !initLoading && hasMore ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}>
        <Button
          type="primary"
          className="m22-btn"
          loading={loading}
          onClick={loadMoreData}>
          {t('button.loadmore')}
        </Button>
      </div>
    ) : null

  return (
    <Modal
      open={visible}
      title={
        <span className="robotomedium line-h-22 fsz-16 txt-primary pd-0">
          {t('shipment.logsTitle', { code: code })}
        </span>
      }
      onCancel={() => {
        visibleHandler(false)
        handleMetadata(null)
      }}
      closeIcon={<i className="far fa-times w-9 h-16 fsz-14 line-h-16 "></i>}
      footer={false}
      width={750}
      bodyStyle={{ padding: 0 }}
      centered>
      <div
        ref={refLog}
        style={{
          maxHeight: '70vh',
          overflow: 'auto',
        }}>
        <List
          loadMore={loadMore}
          dataSource={list}
          loading={initLoading}
          locale={{ emptyText: <EmptyDataFilter description={t('shipment.logEmpty')} /> }}
          renderItem={(item: any, index: number) => {
            return (
              <Skeleton
                title={false}
                loading={item.loading}
                active
                paragraph>
                <List.Item
                  key={index}
                  className={index === 0 ? 'pd-t-0' : ''}>
                  <div>
                    <span>
                      <span className={'txt-muted'}>{t('log_order.timestamp')} </span>
                      <span className={''}>{formatDateTime(item.timestamp)}</span>
                      <span>
                        <span className={'pdl12 txt-muted'}>
                          {item.role === 'STAFF' ? `${t('log_order.staff')}` : `${t('log_order.customer')}`}
                        </span>
                        <span className={' robotomedium pdl5'}>{item.fullname}</span>
                      </span>
                    </span>

                    <Row
                      className="txt-size-h7 mg-t-6 dpl-block whitespace-pre-wrap break-word"
                      dangerouslySetInnerHTML={{
                        __html: t(`log_order.${item.property}`, { ...item }),
                      }}></Row>
                  </div>
                </List.Item>
              </Skeleton>
            )
          }}
        />
      </div>
    </Modal>
  )
}
export default Logs

