import {ProviderInterface} from "../Interface/ProviderInterface";

export const ProviderModel: ProviderInterface = {
    id: "",
    logo: "",
    name: "",
    code: "",
    description: "",
    domain: "",
    redirectUrl: ""
}
