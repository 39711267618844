import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import SaboLogo from '../../../resources/images/table-contents/empty.png'

export const EmptyNotice = () => {
	const { t } = useTranslation()
	return (
		<div className={`notification-empty-items `}>
			<div className={'notification-empty-items-img'}>
				<Image preview={false} src={SaboLogo} alt={'No items'} style={{width:'60%'}} />
			</div>
			<div className={'notification-empty-items-txt'}>{t('headerNotification.emptyItemsMessage')}</div>
		</div>
	)
}

