import { useCallback, useEffect, useState } from 'react'
import { categoryRepository } from 'src/repositories/CategoryRepository'

function useMarketplaces() {
  const [marketplaces, setMarketplaces] = useState<any[]>()

  const getMarketplaces = useCallback(async () => {
    try {
      const res = await categoryRepository.getMarketPlaces()
      setMarketplaces(res)
    } catch (err) {}
  }, []);

  useEffect(()=> {
    getMarketplaces();
  },[getMarketplaces])

  return {
    marketplaces
  }
}

export default useMarketplaces;