import { Image, Steps } from 'antd'
import lodash from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import StepIconFinishedImg from '../../../../resources/images/finish-step.png'
import StepIconImg from '../../../../resources/images/StepIcon.png'
import { useTheme } from '../../../../hooks/useTheme'
import { HistoryOrder } from '../../../../containers/OrderDetail'
import { formatDateTime } from '../../../../core/helpers/date-time'

import './style.scss'

const { Step } = Steps
interface TrackOrderProps {
  statuses: any
  orderHistory: any[]
  loading: boolean
}

const TrackPackage: React.FC<TrackOrderProps> = (props) => {
  const { orderHistory, loading = false, statuses } = props

  const orderHistoryReversed = useMemo(() => {
    return orderHistory?.reverse();
  }, [orderHistory])

  const { t } = useTranslation()
  const [theme] = useTheme()

	const customDot = (_: any, { status }: any) => {
    const nameOfStatus = lodash.get(
      statuses.find(({ code }: any) => code === status),
      'name',
      '---'
    )
    return !loading ? (
      <Image preview={false} src={status === nameOfStatus ? StepIconFinishedImg : StepIconImg} />
    ) : (
      <Skeleton circle width={10} height={10} />
    )
		}

  const CustomDescription = ({ timestamp, handlingTime }: { timestamp: any; handlingTime: any }) => (
    <span className="dpl-flex flex-col timeline fsz-12 line-h-20">
      <span className="txt-color-gray8">{!loading ? timestamp : <Skeleton width={120} height={20} />}</span>
      <span className="txt-color-gray8">{!loading ? `(${handlingTime})` : <Skeleton width={50} height={20} />}</span>
    </span>
  )

  return (
    <div className="package-timeline">
      <Steps
        className={`${theme}`}
        current={orderHistoryReversed.length - 1}
        progressDot={customDot}
        direction="horizontal"
      >
        {orderHistoryReversed && 
          orderHistoryReversed.map(({ status, timestamp, handlingTime, id }: HistoryOrder) => {
            const nameOfStatus = lodash.get(
              statuses.find(({ code }: any) => code === status),
              'name',
              '---'
            )
            const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp)
            const handlingTimeRendered = lodash.isNull(handlingTime)
              ? t('orderDetail.undefined')
              : `${handlingTime} ngày`

            return (
              <Step
                key={id}
                title={
                  <span className="robotomedium  fsz-12 line-h-20">
                    {!loading ? nameOfStatus : <Skeleton width={100} height={20} />}
                  </span>
                }
                className="tail"
                description={<CustomDescription timestamp={timestampRendered} handlingTime={handlingTimeRendered} />}
              />
            )
          })}
      </Steps>
    </div>
  )
}

export default TrackPackage
