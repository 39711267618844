import React, { useState } from 'react'
import { Breadcrumb } from 'antd'
import { breadCrumbsNameFromRoutes, exceptionRoutes } from '../../../core/config'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from '../../../hooks/useTheme'

const { Item } = Breadcrumb
interface BreadcrumbCustomProps {
  breadcrumbs: any
}

const M24Breadcrumb: React.FC<BreadcrumbCustomProps> = ({ breadcrumbs }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [theme] = useTheme()
  const [searchParams] = useSearchParams()
  const [pathName] = useState(location.pathname.split('/'))
  const { code, claimCode, back } = useParams()
  const navigate = useNavigate()

  const totalRendered = (<>
  </>
    // <Badge
    //   count={breadcrumbs?.total}
    //   overflowCount={99999}
    //   showZero={true}
    //   style={{ backgroundColor: !breadcrumbs?.total ? '#F5F5F5' : '', color: !breadcrumbs?.total ? '#707070' : '#1a1a1a' }}
    // />
  )

  return (
    <div className="flex flex-col">
      {/* ăn theo pathName */}
      <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
        {!exceptionRoutes.includes(pathName[1]) && pathName[1] && !claimCode && !searchParams.get('orderCode') && !searchParams.get('back') && (
          <>
            <Item>
              <span
                className="cursor-pointer"
                onClick={() => (window.location.href = `/#/${pathName[1]}`)}>
                {t(`breadcrumbs.${pathName[1]}CurrentPage`)}
              </span>
            </Item>
            {!breadcrumbs?.currentStep &&
              pathName
                // .filter((path) => path)
                .map((item, idx) => {
                  const linkItem = pathName
                    .slice(0, idx + 1)
                    .map((path: string) => (path.startsWith('create') ? 'create' : path))
                    .join('/')
                  return (
                    linkItem && (
                      <Item key={linkItem}>
                        {item !== '' && (
                          <Link
                            to={linkItem}
                            className={theme}>
                            {breadCrumbsNameFromRoutes.includes(item)
                              ? t(`breadcrumbs.${item.startsWith('create') ? `${item}-${pathName[idx - 1]}` : item}`)
                              : (pathName[idx - 1] === 'orders' || pathName[idx - 1] === 'claims' || pathName[idx - 1]) && item === code
                                ? `${t(`breadcrumbs.${pathName[idx - 1]}Details`)} #${item}`
                                : t(`breadcrumbs.${item}`)}
                          </Link>
                        )}
                      </Item>
                    )
                  )
                })}
            {totalRendered}
          </>
        )}

        {breadcrumbs?.currentStep && <Item>{t(`breadcrumbs.cart`)}</Item>}
        {breadcrumbs?.curTab && <Item>{t(`breadcrumbs.${breadcrumbs?.curTab}`)}</Item>}
      </Breadcrumb>
      {/* logic hiện tại ở trên : bắt theo các pathName của location - (dài nhất là route nào có child như sửa/hoặc tạo được điều hướng từ route khác) */}
      {pathName[1] === 'claims' && claimCode && (
        <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
          <Item>
            <span
              className="cursor-pointer"
              onClick={() => (window.location.href = `/#/${pathName[1]}`)}>
              {t(`breadcrumbs.${pathName[1]}CurrentPage`)}
            </span>
          </Item>
          {/* {back === 'claims' && (
            <Item>
              <Link
                className="cursor-pointer"
                to={'/claims'}>
                {t(`breadcrumbs.claims`)}
              </Link>
            </Item>
          )} */}

          {back !== 'claims' && (
            <>
              <Item>
                <Link
                  className="cursor-pointer"
                  to={`/claims`}>
                  {t(`breadcrumbs.claims`)}
                </Link>
              </Item>
              {/* <Item>
                <Link
                  className="cursor-pointer"
                  to={`/${back}s/${orderCode}`}>
                  {`${t(`breadcrumbs.detailDesc`, { value: `#${orderCode}` })} `}
                </Link>
              </Item> */}
            </>
          )}
          <Item>{`${t(`breadcrumbs.claimsDetails`)} #${claimCode}`}</Item>
        </Breadcrumb>
      )}
      {pathName[1] === 'claims' && searchParams.get('orderCode') && (
        <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
          <Item>
            <span
              className="cursor-pointer"
              onClick={() => (window.location.href = `/#/${pathName[1]}`)}>
              {t(`breadcrumbs.ordersCurrentPage`)}
            </span>
          </Item>
          <Item>
            <Link
              className={`cursor-pointer ${theme}`}
              to={`/${searchParams.get('back')?.split('/')[1]}`}>
              {t(`breadcrumbs.orders`)}
            </Link>
          </Item>
          <Item>
            <Link
              className={`cursor-pointer ${theme}`}
              to={`${searchParams.get('back')}`}>
              {t(`breadcrumbs.detailDesc`, { value: searchParams.get('orderCode') })}
            </Link>
          </Item>
          <Item>{`${t(`breadcrumbs.create-claims`)}`}</Item>
        </Breadcrumb>
      )}

      {pathName[1] === 'warehouselist' && searchParams.get('back') && (
        <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
          <Item>
            <span
              className="cursor-pointer"
              onClick={() => navigate('/')}>
              {t(`Trang Chủ`)}
            </span>
          </Item>
          <Item className='font-medium'>{`${t(`Danh sách kho`)}`}</Item>
        </Breadcrumb>
      )}

      {pathName[1] === 'shipments' && pathName[2] === 'create-consignment' && searchParams.get('back') && (
        <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
          <Item>
            <span
              className="cursor-pointer"
              onClick={() => navigate('/')}>
              {t(`Trang Chủ`)}
            </span>
          </Item>
          <Item className='font-medium'>{`${t(`Tạo đơn ký gửi`)}`}</Item>
        </Breadcrumb>
      )}

      {pathName[1] === 'warehouse' && pathName[2] === 'stored-product' && pathName[4] === 'edit' && (
        <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
          <Item>
            <span className="cursor-pointer">{t(`breadcrumbs.${pathName[1]}CurrentPage`)}</span>
          </Item>
          <Item>
            <Link
              className={`cursor-pointer ${theme}`}
              to={'/warehouse/stored-products'}>
              {t('breadcrumbs.stored-products')}
            </Link>
          </Item>
          <Item>{t('breadcrumbs.edit')}</Item>
        </Breadcrumb>
      )}

      {pathName[1] === 'warehouse' && pathName[2] === 'stored-products' && (
        <div className="flex align-items-center">
          <Breadcrumb separator={<i className="fa-solid fa-hyphen fsz-14 line-h-22"></i>}>
            <Item>
              <span
                className="cursor-pointer"
                onClick={() => (window.location.href = `/#/${pathName[1]}/stored-products`)}>
                {t(`breadcrumbs.${pathName[1]}CurrentPage`)}
              </span>
            </Item>
            <Item>
              <Link
                className={`cursor-pointer ${theme}`}
                to={'/warehouse/stored-products'}>
                {t('breadcrumbs.stored-products')}
              </Link>
            </Item>
          </Breadcrumb>
          {totalRendered}
        </div>
      )}

    </div>
  )
}

export default M24Breadcrumb
