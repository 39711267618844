import { Button, Col, Row } from 'antd'
import lodash from 'lodash'
import { GetComponentProps } from 'rc-table/lib/interface'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
interface ConfirmChangeProps extends GetComponentProps<any> {
  className?: string
  total: string
  index: string
  id: string
  items: any
  successInfo: string
  currency: string
  orderedQuantity: number
  purchasedQuantity: number
  needToConfirm: boolean
  loadingNotConfirm: { [id: string]: boolean } | undefined
  loadingConfirm: { [id: string]: boolean } | undefined
  confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
}

const ConfirmChange: React.FC<ConfirmChangeProps> = (record) => {
  const ref = useRef<HTMLTableRowElement | null>(null)
  const { t } = useTranslation()
  let { code } = useParams()
  const [needToConfirm, setNeedToConfirm] = useState(record.needToConfirm)
  const { currency, id, orderedQuantity, purchasedQuantity, index, loadingConfirm, loadingNotConfirm } = record

  const newPrice = formatMoneyByUnit(lodash.get(record, 'actualPrice'), currency)
  const isQtyChange = orderedQuantity && purchasedQuantity && orderedQuantity !== purchasedQuantity
  const successInfo = t('orderDetail.confirmSuccess', {
    property: `${t('orderDetail.unitPrice')} ${isQtyChange ? `, ${t('orderDetail.quantity')}` : ''}`,
  })

  const total = Number(lodash.get(record, 'total'))
  const infoChange = `${t('orderDetail.priceChange', { newValue: newPrice })}
                        ${isQtyChange ? t('orderDetail.quantityChange', { newQty: purchasedQuantity }) : ''}`
  useEffect(() => {
    if (!needToConfirm && ref && ref.current) {
      ref.current.style.display = 'none'
    }
  })
  return (
    <>
      <tr
        key={record.id}
        className={`${record.className} ${Number(index) === total - 1 ? 'last-row' : ''} ${needToConfirm ? 'bd-bt-none' : ''}`}>
        {record.children}
      </tr>
      {needToConfirm ? (
        <>
          <tr ref={ref}>
            <td
              colSpan={3}
              className="bg-color-pink1 confirm-pop-product">
              <Row>
                <Col
                  span={12}
                  className="dpl-flex align-items-center">
                  <i className="fsz-10 far fa-info-circle  line-h-12 mg-r-4"></i>
                  <span
                    className="  fsz-12 line-h-20"
                    dangerouslySetInnerHTML={{
                      __html: infoChange,
                    }}></span>
                </Col>
                <Col
                  span={12}>
                  <Button
                    loading={loadingConfirm && loadingConfirm[id]}
                    disabled={loadingConfirm && loadingConfirm[id]}
                    onClick={() => {
                      setNeedToConfirm(false)
                      record.confirmProductChange(code!, id, true, successInfo)
                    }}
                    size="small"
										className='mr-2'
                    type="primary">
                    {t('orderDetail.accept')}
                  </Button>
                  <Button
                    loading={loadingNotConfirm && loadingNotConfirm[id]}
                    disabled={loadingNotConfirm && loadingNotConfirm[id]}
                    onClick={() => {
                      setNeedToConfirm(false)
                      record.confirmProductChange(code!, id, false, successInfo)
                    }}
                    size="small"
                    danger>
                    {t('orderDetail.reject')}
                  </Button>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td
              colSpan={3}
              className="td-table-cell"></td>
          </tr>
        </>
      ) : (
        <tr className="h-0"></tr>
      )}
    </>
  )
}

export default ConfirmChange
