import { Badge, Button, Row, Table } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import M22Box from '../../../components/M22Box'
import M24Pagination from '../../../components/Pagination/M24Pagination'
import ParagraphWithCopy from '../../../components/ParagraphWithCopyButton'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import NestedTable from './NestedTable'
import QuickPagination from '../../../components/QuickPagination'
import { ColumnsType } from 'antd/lib/table'

interface ListProps {
  items: any
  statuses: any
  // accounts: any
  total: number
  page: number
  size: number
  filter: any
  idAcc: string | undefined
  showProgressBar: boolean
  handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [theme] = useTheme()

  const { items, statuses, handleFilter, total, page, size, showProgressBar, filter } = props
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const [packages, setPackages] = useState<{ [id: string]: [] }>({})
  const [loading, setLoading] = useState<{ [id: string]: any }>({})
  // const [logo, setLogo] = useState('')
  // const [provider, setProvider] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [custome, setCustomer] = useState('')
  const navigate = useNavigate()

  const [loadingState, setLoadingState] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoadingState(false), 3000)
  }, [])

  // useEffect(() => {
  // const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
  // if (indexAcc > -1 && !showProgressBar) {
  //   const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
  //   const provider = lodash.get(accounts[indexAcc], 'provider.name', '')
  //   const customer = lodash.get(accounts[indexAcc], 'name', '')
  //   setLogo(logo)
  //   setProvider(provider)
  //   setCustomer(customer)
  // }
  // }, [accounts, idAcc, showProgressBar])

  const emptyText = '---'
  const classes = 'fsz-14 line-h-22  whitespace'
  const columns: ColumnsType<any> = [
    {
      title: <span className={`fsz-12 mg-l-14 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.code')}</span>,
      key: 'code',
      dataIndex: 'code',
      width: 120,
      render: (code: string) => (
        <div className="dpl-flex align-items-center">
          <i className={`far fa-angle-${lodash.includes(expandedRows, code) ? 'down' : 'right'} w-8 line-h-22 cursor-pointer mg-r-6`}></i>
          <ParagraphWithCopy
            text={code}
            children={code}
          />
        </div>
      ),
    },
    // {
    //   title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.account')}</span>,
    //   key: 'account',
    //   width: 210,
    //   ellipsis: true,
    //   render: (record: any) => (
    //     <div className="dpl-flex align-items-center whitespace">
    //       <Avatar
    //         shape="circle"
    //         src={logo}
    //         size={16}
    //         className="w-12 h-12 bd-none dpl-flex align-items-center mg-r-4"
    //       />
    //       <span className={`${classes} mg-r-4`}>{provider}</span>
    //     </div>
    //   ),
    // },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.createdAt')}</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 120,
      align: 'left' as 'left',
      render: (createdAt: any) => <span className={`${classes} txt-muted`}>{formatDateTime(createdAt)}</span>,
    },
    // {
    //   title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.cod')}</span>,
    //   key: 'codAmount',
    //   dataIndex: 'codAmount',
    //   width: 100,
    //   align: 'right' as 'right',
    //   render: (codAmount: any) => {
    //     return <span className={`${codAmount ? 'txt-color-red2' : ''} fsz-14 line-h-22 whitespace`}>{`${formatMoneyByUnit(codAmount)}`}</span>
    //   },
    // },
    {
      title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.weight')}</span>,
      key: 'totalWeight',
      dataIndex: 'totalWeight',
      width: 100,
      align: 'right' as 'right',
      render: (totalWeight: any) => {
        return <span className=" fsz-14 line-h-22 whitespace">{`${formatNumber(totalWeight)} kg`}</span>
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.address')}</span>,
      key: 'address',
      width: '20%',
      ellipsis: true,
      render: (record: string) => (
        <span className=" fsz-14 line-h-22 whitespace">
          {`${lodash.get(record, 'address.fullName')}, ${lodash.get(record, 'address.address')},${lodash.get(record, 'address.location.display')}`}
        </span>
      ),
    },
    {
      title: <span className={`fsz-12 mg-r-16 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('requestDelivery.status')}</span>,
      key: 'status',
      dataIndex: 'status',
      align: 'right' as 'right',
      width: 100,
      render: (status: any) => {
        const statusObj = statuses.find(({ code }: any) => code === status)
        const statusName = lodash.isNull(lodash.get(statusObj, 'name')) ? emptyText : lodash.get(statusObj, 'name', emptyText)

        return (
          <span
            className={`m22-tag-status ${theme}`}
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color')) ? '#09B2AA' : lodash.get(statusObj, 'color'),
            }}>
            {statusName}
          </span>
        )
      },
    },
  ]

  const handleChangePage = (filter: any) => {
    const { page, size, providerUsername } = filter
    handleFilter(
      getCleanFormFilter({
        ...filter,
        customer: providerUsername,
        page: (page - 1).toString(),
        size: size.toString(),
      })
    )
  }

  const expandedRowRender = (record: any) => (
    <NestedTable
      items={packages}
      loading={loading}
      record={record}
    />
  )

  return (
    <M22Box className="pd-0">
      <div className="flex-col px-16 m22-box-light border-card-header dpl-flex pd-t-16">
        <div className="dpl-flex align-items-center justify-content-between mg-bt-13 mg-l-8">
          <div className="dpl-flex align-items-center mg-r-4">
            <span className={`m22-typo-heading  mg-r-4 ${theme}`}>{t('requestDelivery.list')}</span>
            <Badge
              count={total}
              overflowCount={99999}
              showZero={true}
              style={{
                fontWeight: '500',
                backgroundColor: !total ? '#F5F5F5 ' : '',
                color: !total ? '#707070' : '#1a1a1a',
              }}
              className="robotomedium"
            />
          </div>
          <div className={'flex items-center'}>
            <QuickPagination
              filter={filter}
              pagination={{
                total,
                pageSize: size,
                current: page,
              }}
              onPageChange={handleChangePage}
            />
            <Button
              className={`m-l-3 m22-btn m22-btn-outline ${theme}`}
              icon={<i className="far fa-plus mg-r-10"></i>}
              onClick={() => navigate('/delivery/create')}>
              {t('requestDelivery.createRequestDelivery')}
            </Button>
          </div>
        </div>
        <SkeletonTable
          loading={showProgressBar || loadingState}
          columns={columns as SkeletonTableColumnsType[]}
          className={`main-table mg-bt-${items?.length > 0 ? '13' : '0'} m22-table ${theme}`}
          rowCount={items?.length > 0 ? items?.length : 0}>
          <Table
            rowKey={(record: any) => record.code}
            columns={columns}
            dataSource={items}
            locale={{
              emptyText: (
                <EmptyDataFilter
                  className={showProgressBar || loadingState ? 'dpl-none' : ''}
                  description={
                    !searchParams.get('query') &&
                    !searchParams.get('statuses') &&
                    !searchParams.get('createdAtFrom') &&
                    !searchParams.get('createdAtTo') &&
                    !searchParams.get('customer') &&
                    !searchParams.get('provider')
                      ? t('deliveryRequest.noData')
                      : t('deliveryRequest.filterEmpty')
                  }
                />
              ),
            }}
            className={`main-table package selected-table  nested-px-36 mg-bt-13 m22-table ${theme}`}
            loading={showProgressBar}
            expandRowByClick={true}
            pagination={false}
            expandIconColumnIndex={-1}
            expandable={{
              expandedRowRender,
              expandIcon: () => null,
              columnWidth: 0,
              onExpand: (expanded, record) => {
                if (expanded && !packages[record.code]) {
                  setLoading({ ...loading, [record.code]: true })
                  deliveryRepository
                    .getPackages(lodash.get(record, 'code'), searchParams.get('customer'), searchParams.get('provider'))
                    .then((res) => {
                      setPackages({ ...packages, [record.code]: [...res] })
                    })
                    .catch((err) => {})
                    .finally(() => setLoading({ ...loading, [record.code]: false }))
                }
              },
              onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
            }}
            rowClassName={(record, idx) => {
              const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
              const rowClassByCode = lodash.includes(expandedRows, record.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
              return `${rowClassesByIdx} ${rowClassByCode}`
            }}
          />
        </SkeletonTable>
        <Row
          justify={'end'}
          className={''}>
          {items && items.length > 0 && (
            <M24Pagination
              onPageChange={handleChangePage}
              filter={{ ...getCleanFormFilter(filter), page, size }}
              pagination={{
                current: page,
                pageSize: size,
                hideOnSinglePage: true,
                total,
              }}
              className="mg-0"
            />
          )}
        </Row>
      </div>
    </M22Box>
  )
}

export default List
