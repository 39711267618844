import {localStorageRead} from "./LocalStorageUtils";
import {PROFILE_KEY, TOKEN_KEY} from "../core/config";

class SecurityService {

    static isLogged = () => {
        return localStorageRead(TOKEN_KEY);
    };

    static getUser = () => {
        if (SecurityService.isLogged()) {
            return localStorageRead(PROFILE_KEY)
        }
    };
}

export default SecurityService;
