import { useEffect, useState } from 'react'
import { Avatar, Table } from 'antd'
import lodash from 'lodash'
import { formatMoneyByUnit, formatNumber } from '../../../core/helpers/string'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../core/helpers/date-time'
import { SkeletonTable, SkeletonTableColumnsType } from '../../SkeletonTable'
import M22Box from '../../M22Box/index'
import { useTheme } from '../../../hooks/useTheme'

interface DeliveryConnectionProps {
  data: any
  statuses: any
  className?: string
  loading: boolean
  onSelectConnection: (checked: boolean) => void
  onSelectOrder: (orderCode: string, checked: boolean) => void
  onSelectPackage: (orderCode: string, packageCode: string, checked: boolean) => void
  showBalance: boolean
}

function DeliveryConnection(props: DeliveryConnectionProps) {
  const { data, statuses, onSelectOrder, onSelectPackage, loading } = props
  const { t } = useTranslation()
  const [currency, setCurrency] = useState<string>()
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const classesRe = 'whitespace'
  const classesMd = 'robotomedium  whitespace'
  const [theme] = useTheme()
  const [loadingState, setLoadingState] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoadingState(false), 1000)
  })

  useEffect(() => {
    let currency = lodash.get(data, 'account.connection.provider.currency', '')
    if (currency) {
      let temp = JSON.parse(currency)
      setCurrency(lodash.get(temp, 'code', ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const rowSelectionOrder = {
    selectedRowKeys: lodash.get(data, 'selectedOrders', []),
    hideSelectAll: true,
    onSelect: (record: any, selected: boolean, selectedRows: any) => {
      onSelectOrder(record.code, selected)
    },
    getCheckboxProps: (record: any) => {
      return {
        indeterminate: !!record.selectedPackages.length && record.selectedPackages.length < record.packages.length,
      }
    },
  }
  const columns = [
    {
      title: t('delivery.orders'),
      key: 'orderCode',
      dataIndex: 'code',
      width: 242,
      render: (code: any, record: any) => (
        <div className="flex">
          <i
            className={`far fa-angle-${
              lodash.includes(expandedRows, code) ? 'down' : 'right'
            } mg-t-14 cursor-pointer mg-r-24`}
            style={{ width: '10px' }}></i>
          <div className="dpl-flex align-items-center">
            <Avatar
              shape="square"
              size={40}
              className="border-radius4 bd-none mg-r-8"
              src={lodash.get(record, 'orderImage', '')}
            />
            <span className={`${classesMd} txt-primary`}>{code}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('availableOrders.totalPackages'),
      key: 'availablePackageCount',
      dataIndex: 'availablePackageCount',
      align: 'right' as 'right',
      width: 108,
      render: (availablePackageCount: string) => <span className={classesMd}>{availablePackageCount}</span>,
    },
    {
      title: t('availableOrders.totalWeight'),
      align: 'right' as 'right',
      width: 135,
      render: (record: any) => {
        let weight = 0
        // eslint-disable-next-line array-callback-return
        record.packages.map((x: any) => {
          weight += x.actualWeight
        })
        return <span className={classesRe}>{formatNumber(weight)}kg</span>
      },
    },
    {
      title: t('availableOrders.needForPay'),
      key: 'needForPay',
      width: 168,
      align: 'right' as 'right',
      dataIndex: 'totalUnpaid',
      render: (totalUnpaid: any) => {
        return (
          <span className={`${classesRe} ${totalUnpaid ? 'txt-color-red2 robotomedium' : ''}`}>
            {formatMoneyByUnit(totalUnpaid, currency)}
          </span>
        )
      },
    },
    {
      title: t('availableOrders.address'),
      key: 'display',
      width: 526,
      ellipsis: true,
      render: (record: any) => {
        const address = lodash.get(record, 'address.location.display', '---')
        const detail = lodash.get(record, 'address.detail', '---')
        const fullname = lodash.get(record, 'address.fullname', '---')
        return <span className={classesRe}>{`${detail}, ${fullname}, ${address}`}</span>
      },
    },
  ]
  const columnsPackage = [
    {
      title: <span className=" robotomedium fsz-12 line-h-20">{t('requestDeliveryPackage.code')}</span>,
      key: 'code',
      dataIndex: 'code',
      width: 200,
      render: (code: any) => <span>{code}</span>,
    },

    {
      title: <span className=" robotomedium fsz-12 line-h-20">{t('requestDeliveryPackage.volumetric')}</span>,
      key: 'volumetric',
      dataIndex: 'volumetric',
      width: 200,
      align: 'right' as 'right',
      render: (volumetric: any) => (
        <span>{volumetric === 0 ? `${t('requestDeliveryPackage.undefined')}` : `${formatNumber(volumetric)}cm3`}</span>
      ),
    },
    {
      title: <span className=" robotomedium fsz-12 line-h-20">{t('requestDeliveryPackage.weight')}</span>,
      key: 'weight',
      dataIndex: 'actualWeight',
      align: 'right' as 'right',
      width: 250,
      render: (actualWeight: any) => <span>{`${formatNumber(actualWeight)}kg`}</span>,
    },
    {
      title: <span className=" mg-l-40 robotomedium fsz-12 line-h-20">{t('requestDeliveryPackage.createdAt')}</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 250,
      render: (createdAt: any) => <span className="txt-muted mg-l-40">{formatDateTime(createdAt)}</span>,
    },
    {
      title: <span className=" robotomedium fsz-12 line-h-20">{t('packages.status')}</span>,
      key: 'status',
      dataIndex: 'status',
      render: (status: any) => {
        const statusObj = statuses.find(({ code }: any) => code === status)
        const statusName = lodash.isNull(lodash.get(statusObj, 'name')) ? '---' : lodash.get(statusObj, 'name', '---')
        return (
          <span
            className="fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace"
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
                ? '#09B2AA'
                : lodash.get(statusObj, 'color'),
            }}>
            {statusName}
          </span>
        )
      },
    },
  ]

  const title = () => <h3 className={`robotomedium fsz-16 line-h-24`}>{t('availableOrders.listInStock')}</h3>

  return (
    <M22Box className={`px-16 pd-t-24 rad-12 table-delivery${data.checked ? `-checked` : ''}`}>
      <SkeletonTable
        tableTitle={title}
        loading={loading || loadingState}
        className="main-table table-expandable selected-table nested-px-48"
        columns={columns as SkeletonTableColumnsType[]}
        rowCount={data?.orders}>
        <Table
          title={title}
          dataSource={data.orders}
          rowKey={(record: any) => record.code}
          rowSelection={rowSelectionOrder}
          pagination={false}
          columns={columns}
          expandIconColumnIndex={-1}
          expandRowByClick={true}
          className={`main-table selected-table nested-px-44 m22-table delivery-create ${theme} table-expandable `}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table
                  dataSource={record.packages}
                  columns={columnsPackage}
                  pagination={false}
                  rowSelection={{
                    selectedRowKeys: lodash.get(record, 'selectedPackages', []),
                    hideSelectAll: true,
                    onSelect: (item: any, selected: boolean, selectedRows: any) => {
                      onSelectPackage(record.code, item.code, selected)
                    },
                  }}
                  className={`main-table ${theme}`}
                  rowKey={(record: any) => record.code}
                />
              )
            },
            expandIcon: ({ expanded, onExpand, record }) => null,
            onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
          }}
          rowClassName={(record) =>
            lodash.includes(expandedRows, record.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
          }
        />
      </SkeletonTable>
    </M22Box>
  )
}
export default DeliveryConnection
