import { Avatar, Button, Modal } from 'antd'
import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import M22Box from '../../../../components/M22Box'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../../Interface/ShipmentStatusInterface'
import ShipmentImageDefault from '../../../../resources/images/shipment-ubox-default.png'
import Logs from './Logs'
import lodash from 'lodash'

interface HeadingProps {
  shipment?: ShipmentInterface
  statuses: ShipmentStatusInterface[]
  canceling: boolean
  currencyMarketPalace?: string
  cancelable: boolean
  cancelShipment: () => void
  loading: boolean
  showChat: () => void
  loadingCreateThread: boolean
  setVisibleCancel: Dispatch<SetStateAction<boolean>>
  visibleCancel: boolean
}
const Heading: React.FC<HeadingProps> = (props) => {
  const { shipment, statuses, cancelShipment, setVisibleCancel, visibleCancel, canceling, cancelable, loading } = props
  const [theme] = useTheme()
  const [statusName, setStatusName] = useState<string | undefined>('')
  const [statusColor, setStatusColor] = useState('')
  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [metadata, setMetadata] = useState<any>()
  const expressDelivery = lodash.get(shipment, 'expressDelivery', '')

  useEffect(() => {
    const status = statuses.find((stt: ShipmentStatusInterface) => stt.code === shipment?.status)
    setStatusName(status?.name)
    setStatusColor(status?.color ? status.color : '#0082e6')
  }, [shipment?.status, statuses])

  return (
    <>
      <M22Box className={`shipment-heading ${theme}`}>
        <div className="shipment-heading__left">
          <div className="info">
            {!loading ? (
              <Avatar
                className="bd-none-impt"
                size={60}
                shape="square"
                src={shipment?.image ? shipment?.image : ShipmentImageDefault}></Avatar>
            ) : (
              <Skeleton
                width={60}
                height={60}
              />
            )}

            <div className="info-text">
              <div className="info-text__code-status">
                {!loading ? (
                  <span
                    className="mg-bt-2 status-view"
                    style={{ backgroundColor: statusColor }}>
                    {statusName}
                  </span>
                ) : (
                  <Skeleton
                    className="mg-bt-2"
                    width={70}
                    height={22}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="finance">
            <div className="finance-item">
              {!loading ? (
                <>
                  <span className="finance-item__label">{`${t('shipments.financeTotalFees')}:`}</span>
                  <span className="finance-item__value">{formatMoneyByUnit(shipment?.totalFee)}</span>
                </>
              ) : (
                <>
                  <Skeleton
                    width={70}
                    height={22}
                    className="mg-r-4"
                  />
                  <Skeleton
                    width={80}
                    height={22}
                  />
                </>
              )}
            </div>
            <div className="finance-item">
              {!loading ? (
                <>
                  <span className="finance-item__label">{`${t('shipment.totalCashOrder')}:`}</span>
                  <span className="finance-item__value">{formatMoneyByUnit(shipment?.totalValue, 'CNY')}</span>
                </>
              ) : (
                <>
                  <Skeleton
                    width={80}
                    height={22}
                    className="mg-r-4"
                  />
                  <Skeleton
                    width={80}
                    height={22}
                  />
                </>
              )}
            </div>
          </div>

          <div className="finance">
            <div
              className="item-link finance-item cursor-pointer"
              onClick={() => {
                setVisibleLogModal(true)
                setMetadata({ page: 0, size: 15 })
              }}>
              {!loading ? (
                <i className="far fa-file-alt fsz-11 line-h-12 item-link mg-r-4" />
              ) : (
                <Skeleton
                  width={14}
                  height={14}
                  className="mg-r-4"
                />
              )}
              <span>
                {!loading ? (
                  t('orderDetail.log')
                ) : (
                  <Skeleton
                    width={113}
                    height={20}
                  />
                )}
              </span>
            </div>

            <span className="inline-flex gap-4 items-center">
              <span>Vận chuyển Midmile:</span>
              {shipment?.midMileViews?.[0]?.code ? (
                <>
                  <Avatar
                    src={shipment?.midMileViews?.[0]?.logo}
                    size={24}
                    className="mg-r-2"
                  />
                  <span>{shipment?.midMileViews?.[0]?.name}</span>
                </>
              ) : (
                '---'
              )}
            </span>
          </div>
          {expressDelivery && (
            <div className="dpl-flex flex-col align-items-start">
              {!loading ? (
                <span
                  style={{ backgroundColor: `${theme === 'dark' ? 'rgba(0, 175, 167, 0.1)' : 'rgba(77, 214, 156, 1)'} ` }}
                  className="px-16 py-4 rad-16">
                  <i
                    className="fa-solid fa-check mg-r-4"
                    style={{ color: `${theme === 'dark' ? '#00AFA7' : '#FFFFFF'}` }}
                  />
                  <span
                    className="fsz-14 font-medium "
                    style={{ color: `${theme === 'dark' ? '#00AFA7' : '#FFFFFF'}` }}>
                    Giao ngay khi hàng về
                  </span>
                </span>
              ) : (
                <Skeleton
                  width={120}
                  height={32}
                  className="mg-r-9"
                />
              )}
            </div>
          )}
        </div>

        <div className="shipment-heading__right">
          {cancelable &&
            (!loading ? (
              <Button
                type="primary"
                danger
                loading={canceling}
                onClick={() => setVisibleCancel(!visibleCancel)}>
                {t('shipment.cancelOrder')}
              </Button>
            ) : (
              <Skeleton width={120} />
            ))}
        </div>
      </M22Box>

      <Logs
        visible={visibleLogModal}
        visibleHandler={(val: boolean) => setVisibleLogModal(val)}
        currency={shipment?.currency}
        metadata={metadata}
        handleMetadata={(obj: any) => setMetadata(obj)}
      />

      <Modal
        open={visibleCancel}
        className="m22-modal"
        title={t('shipment.confirmTitleCancel')}
        cancelButtonProps={{
          className: 'm22-btn',
          type: 'text',
          icon: <i className="fa-solid fa-xmark mg-r-6"></i>,
        }}
        onCancel={() => setVisibleCancel(!visibleCancel)}
        okButtonProps={{ className: 'm22-btn', type: 'primary' }}
        onOk={cancelShipment}
        cancelText={t('shipment.cancelText')}
        okText={<span>{t('shipment.okText')}</span>}
        closable
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        centered
        maskClosable>
        <span>{t('shipment.confirmCancelContent')}</span>
      </Modal>
    </>
  )
}

export default Heading
