/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Modal, Spin } from 'antd'
import lodash from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatMoneyByUnit, formatNumber } from '../../../core/helpers/string'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import M24Notification from '../../../utils/M24Notification'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import DeliveryConnection from './DeliveryConnection'
import M22Box from '../../M22Box/index'
import { useTheme } from '../../../hooks/useTheme'
import { categoryRepository } from 'src/repositories/CategoryRepository'
import M24ErrorUtils from 'src/utils/M24ErrorUtils'
import { newLineToBr } from 'src/utils/StringUtils'
import SecurityService from 'src/utils/SecurityService'

interface ListProps {
  data: any
  statuses: any
  onSelectConnection: (checked: boolean) => void
  onSelectOrder: (orderCode: string, checked: boolean) => void
  onSelectPackage: (orderCode: string, packageCode: string, checked: boolean) => void
  totalWeight: number
  showProgressBar: boolean
  onSelectAll: (checked: boolean) => void
  showBalance: boolean
  needToPayValue: any
}

const List: React.FC<ListProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    data,
    statuses,
    onSelectConnection,
    onSelectOrder,
    onSelectPackage,
    totalWeight,
    onSelectAll,
    showProgressBar,
    showBalance,
    needToPayValue,
  } = props
  const [checkAll, setCheckAll] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [theme] = useTheme()
  const [showFaq, setShowFaq] = useState<boolean>(false)
  const [faqs, setFaqs] = useState('')
  const [usernameUseInFaqs, setUsernameUseInFaqs] = useState()
  const [loadingFaqs, setLoadingFaqs] = useState(false)

  const getFaqs = async (provider?: string) => {
    setLoadingFaqs(true)
    try {
      if (provider) {
        const res = await categoryRepository.getFaqs(provider)
        setFaqs(lodash.get(res, 'content', ''))
        setLoadingFaqs(false)
      } else {
        const res = await categoryRepository.getFaqsByShipment()
        setFaqs(lodash.get(res, 'content', ''))
        setLoadingFaqs(false)
      }
    } catch (err) {
      M24ErrorUtils.showError(t, err)
      setLoadingFaqs(false)
    }
  }

  const formatTemplateFaqs = () => {
    const user = SecurityService.getUser()
    return `<span class='fsz-14 line-h-22'>${newLineToBr(faqs.split('{username}').join(user.username))}</span>`
  }

  const handleShowFaqsChargeCashByShipment = () => {
    const userName = data?.account?.connection?.creator
    setShowFaq(true)
    setUsernameUseInFaqs(userName)
    getFaqs()
  }

  const handleShowFaqsChargeCashByOrder = (provider?: string) => {
    const userName = data?.account?.connection?.creator
    setShowFaq(true)
    setUsernameUseInFaqs(userName)
    getFaqs(provider)
  }

  useEffect(() => {
    let isAll = true
    // data.map((x: any) => {
    data?.orders?.map((xx: any) => {
      if (xx.packages.length > xx.selectedPackages.length && isAll) isAll = false
    })
    // })
    setCheckAll(isAll)
  }, [data])

  const getBodyDeliveryRequest = useCallback(() => {
    let orders: any = []
    let shipments: any = []
    const provider = lodash.get(data, 'account.connection.provider.code')
    const providerUsername = lodash.get(data, 'account.connection.name')
    const selectedOrders = lodash.get(data, 'selectedOrders')

    const selectedOrdersObj = data.orders.filter((x: any) => lodash.includes(selectedOrders, x.code))

    for (let j = 0; j < selectedOrdersObj.length; j++) {
      const selectedPackages = lodash.get(selectedOrdersObj[j], 'selectedPackages')
      const orderCode = lodash.get(selectedOrdersObj[j], 'code')
      if (selectedOrdersObj[j].isShipment) {
        shipments.push({ orderCode, packages: selectedPackages })
      } else {
        orders.push({ orderCode, packages: selectedPackages })
      }
    }
    return { provider, providerUsername, orders, shipments, totalPaid: data.needToPaid }
  }, [data])

  const [accountBalance, setAccountBalance] = useState()

  const createDeliveryRequest = () => {
    setLoading(true)
    let deliveryRequests: any = []
    const body = getBodyDeliveryRequest()

    deliveryRequests.push({ ...body })

    Promise.all(
      deliveryRequests.map(async (x: any, index: number) => {
        x.totalPaid = data.needToPaid
        try {
          await deliveryRepository.createDeliveryRequest(x)
          data.success = true
        } catch (err: any) {
          data.success = false
          data.error = err
        }
        return data
      })
    )
      .then((res: any) => {
        if (res[0]?.success) {
          M24Notification.notifySuccess(t('delivery.success'), '', '', 5)
          navigate('/delivery/create-success', {
            state: [data],
            replace: true,
          })
        } else {
          M24Notification.notifyHttpError(res[0]?.error)
        }
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false))
  }

  const allOrders = lodash.flattenDeep(lodash.map(data, 'orders')).length
  const disabled = data?.orders?.length <= 0 || !data?.checked

  const isShipment = useMemo(() => {
    let isShipment
    data?.orders?.forEach((order: any) => {
      if (order.isShipment) {
        isShipment = true
      } else {
        isShipment = false
      }
    })
    return isShipment
  }, [data])

  const showGuideline = () => {
    if (isShipment) {
      handleShowFaqsChargeCashByShipment()
    } else {
      handleShowFaqsChargeCashByOrder(data.account.connection.provider.code)
    }
  }

  useEffect(() => {
    if (!data.selectedOrders) return
    categoryRepository.getAccountBalance().then((resData) => setAccountBalance(resData))
  }, [data.selectedOrders])

  return (
    <div className={`${allOrders === 0 ? 'empty-delivery' : ''}  ${!showProgressBar ? 'align-items-center' : ''}`}>
      <Spin spinning={loading}>
        {allOrders > 0 && !showProgressBar && (
          <div className="mg-bt-200">
            {data.orders.length > 0 ? (
              <DeliveryConnection
                onSelectPackage={onSelectPackage}
                onSelectOrder={onSelectOrder}
                onSelectConnection={onSelectConnection}
                statuses={statuses}
                showBalance={showBalance}
                data={data}
                className="mg-bt-200"
                loading={showProgressBar}
              />
            ) : (
              <EmptyDataFilter
                className="mg-t-60"
                description={
                  !searchParams.get('providerUsername') && !searchParams.get('provider')
                    ? t('delivery.emptyRequest')
                    : t('deliveryCreate.filterEmpty')
                }
              />
            )}
          </div>
        )}

        {allOrders === 0 && !showProgressBar && (
          <div className="flex-col dpl-flex align-items-center">
            <EmptyDataFilter
              description={
                !searchParams.get('providerUsername') && !searchParams.get('provider')
                  ? t('delivery.emptyRequest')
                  : t('deliveryCreate.filterEmpty')
              }
            />
          </div>
        )}

        <M22Box className={`calculate-box ${theme} dpl-flex justify-content-between mg-bt-0 `}>
          <div className="dpl-flex ">
            <div className="flex-col dpl-flex align-items-start justify-content-center">
              <span className="dpl-flex">
                <Checkbox
                  checked={checkAll}
                  onChange={(e) => onSelectAll(e.target.checked)}
                  className="mg-r-12">
                  <span>{t('delivery.chooseAllPackages')}</span>
                </Checkbox>
              </span>
              <span className="dpl-flex mg-t-8 ">
                <span className="robotoregualar txt-secondary mg-r-4">{t('availableOrders.totalWeight')}:</span>
                <span className="robotomedium txt-primary">{`${formatNumber(totalWeight)}kg`}</span>
              </span>
            </div>

            {!disabled && (
              <>
                <Divider
                  orientation="center"
                  type="vertical"
                  className="mx-24"
                  style={{ height: '90%' }}
                />
                <div className="flex mg-r-24">
                  <div className="flex-col dpl-flex mg-r-20 ">
                    <div className="dpl-flex align-items-center justify-content-between mg-t-8">
                      <span className={`txt-secondary mg-r-12 `}>
                        {t('delivery.needToPaid')}{' '}
                        <span className={`txt-color-red2 robotomedium`}>{formatMoneyByUnit(needToPayValue)}</span>
                      </span>

                      <span className="txt-secondary">
                        ({t('delivery.needChardCol')}:{' '}
                        <span className={`font-medium txt-color-red2`}>
                          {(accountBalance || accountBalance === 0) &&
                            (accountBalance > needToPayValue
                              ? '0₫'
                              : formatMoneyByUnit(needToPayValue - accountBalance))}
                        </span>
                        )
                      </span>

                      <span
                        className="txt-secondary robotoregular txt-underline fsz-14 mg-l-12 hover:cursor-pointer"
                        onClick={showGuideline}>
                        Hướng dẫn nạp tiền
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex align-items-center">
            <Button
              type="primary"
              className={`cursor-pointer align-items-center mg-r-12 m22-btn ${theme}`}
              icon={<CheckOutlined />}
              loading={loading}
              disabled={disabled}
              onClick={createDeliveryRequest}>
              {t('delivery.requestSelectedPackages')}
            </Button>
          </div>
        </M22Box>
      </Spin>

      <Modal
        width={600}
        title={<span className="fsz-16 line-h-24">{t('supplier.faqsCashCharged')}</span>}
        onCancel={() => setShowFaq(false)}
        open={showFaq}
        centered
        className={`pd-12 faq-modal m22-modal ${theme}`}
        closeIcon={
          <i
            className="far fa-times"
            onClick={() => setShowFaq(false)}
          />
        }
        bodyStyle={{ overflowY: 'scroll' }}
        footer={
          !loadingFaqs && [
            <Button
              key="submit"
              type="primary"
              className={`m22-btn ${theme}`}
              onClick={() => setShowFaq(false)}>
              {t('supplier.closeFaqs')}
            </Button>,
          ]
        }>
        <Spin spinning={loadingFaqs}>
          <span
            className="white-space-pre-ln"
            dangerouslySetInnerHTML={{
              __html: formatTemplateFaqs(),
            }}
          />
        </Spin>
      </Modal>
    </div>
  )
}

export default List
// ant-btn ant-btn-primary mg-bt-10 m22-btn  dark
