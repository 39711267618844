import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import { DELIVERY_REQUEST_DESCRIPTIONS } from '../../../core/config'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import Filter from './Filter'
import List from './List'

const RequestDelivery = () => {
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  // const [accounts, setAccounts] = useState([])
  const [statuses, setStatuses] = useState([])
  const [requests, setRequests] = useState([])

  const [size, setSize] = useState(Number(searchParams.get('size')))
  const [page, setPage] = useState(Number(searchParams.get('page')))
  const [total, setTotal] = useState(0)

  const [idAcc] = useState('')
  const [filter, setFilter] = useState<any>({})
  const [loadingStatuses, setLoadingStatuses] = useState(false)

  const { t } = useTranslation()

  const getData = useCallback(() => {
    const controller = new AbortController()
    const statuses = searchParams.get('statuses')
    const query = searchParams.get('query')?.trim()
    const createdFrom = searchParams.get('createdAtFrom')
    const createdTo = searchParams.get('createdAtTo')
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const params = { query, createdFrom, createdTo, page, size, statuses }
    setFilter((state: any) => ({ ...state, ...getCleanFormFilter(params) }))
    if (1) {
      setShowProgressBar(true)
      deliveryRepository
        .getRequestsDelivery({ ...getCleanFormFilter(params) }, controller.signal)
        .then((response: any) => {
          const currentPage = lodash.get(response, 'headers.x-page-number')
          const total = lodash.get(response, 'headers.x-total-count')
          setRequests(response.data)
          setPage(parseInt(currentPage) + 1)
          setTotal(parseInt(total))
          setSize(parseInt(lodash.get(response, 'headers.x-page-size')))
        })
        .catch((err) => {
          M24ErrorUtils.showError(t, err)
        })
        .finally(() => setShowProgressBar(false))
    }
    return () => {
      controller.abort()
    }
  }, [searchParams, t])

  // const getAccounts = useCallback(() => {
  //   setLoadingStatuses(true)
  //   supplierRepository
  //     .getListAccount()
  //     .then((res) => {
  //       const temp = res.filter(({ status }: any) => status === 'CONNECTED')
  //       let accounts = temp.map((item: any) => {
  //         return { ...item, label: item.provider.name.toString(), value: item.id.toString() }
  //       })
  //       if (accounts && accounts.length > 0) {
  //         const indexOfAccount = accounts.findIndex((acc: any) => acc.defaultConnection)
  //         const account = indexOfAccount > -1 ? accounts[indexOfAccount] : accounts[0]
  //         setIdAcc(lodash.get(account, 'id'))
  //       }
  //       setAccounts(accounts)
  //     })
  //     .catch((err) => {})
  // }, [])

  const getStatuses = useCallback(() => {
    categoryRepository
      .getRequestDeliveryStatuses()
      .then((res: any) => {
        setStatuses(res)
      })
      .catch((err: any) => {})
      .finally(() =>
        setTimeout(() => {
          setLoadingStatuses(false)
        }, 150)
      )
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    getStatuses()
    // getAccounts()
  }, [getStatuses])

  const handleFilter = (params: any) => {
    setFilter({ ...params })
    setSearchParams({ ...params })
  }

  const filterChangeHandler = (obj: any) => {
    setFilter((prevState: any) => setFilter({ ...prevState, ...obj }))
  }

  return (
    <MainLayout
      title={t('menu.delivery-requests')}
      showProgressBar={showProgressBar}
      descriptions={{
        title: t('delivery.pageTitle'),
        descriptions: t('delivery.descriptions'),
        className: 'box-shadow-descriptions',
        pageCode: DELIVERY_REQUEST_DESCRIPTIONS,
        hasDescription: true,
      }}
      breadcrumbs={{ total }}>
      <Filter
        filter={filter}
        // accounts={accounts}
        idAcc={idAcc}
        statuses={statuses}
        handleFilter={handleFilter}
        page={page}
        size={size}
        filterChangeHandler={filterChangeHandler}
        loading={loadingStatuses}
        showProgressBar={showProgressBar}
      />
      <List
        filter={filter}
        idAcc={idAcc}
        items={requests}
        statuses={statuses}
        // accounts={accounts}
        handleFilter={handleFilter}
        total={total}
        page={page}
        size={size}
        showProgressBar={showProgressBar}
      />
    </MainLayout>
  )
}

export default RequestDelivery
