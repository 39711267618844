import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import { ThemeProvider } from './provider/ThemeProvider'
import App from './App'

import './resources/localizations/i18n'
import './resources/styles/custom.scss'
import CurrentConfigProvider from './context/CurrentContext'
import { StrictMode } from 'react'

ReactDOM.render(
	<StrictMode>
	<CurrentConfigProvider>
		<ThemeProvider>
			<App />
		</ThemeProvider>
	</CurrentConfigProvider>
	</StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

