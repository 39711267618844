import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainLayout from '../../components/Layout'
import './styles.scss'

// AntComponent
import lodash from 'lodash'
import ShopList from '../../components/ShoppingCart/ShopList'
import Step2 from '../../components/ShoppingCart/Step2'
import { cartRepository } from '../../repositories/CartRepository'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
import Step3 from '../../components/ShoppingCart/Step3'
import { SHOPPING_CART_DESCRIPTIONS } from '../../core/config'
import AccountBalanceInterface, { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'
import SkeletonShoppingCart from './SkeletonShoppingCart'
import { useTheme } from '../../hooks/useTheme'
import { CurrentContext } from 'src/context/CurrentContext'

function ShoppingCart() {
  const [theme] = useTheme()

  const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
  const [, setIsLoading] = useState<boolean>(false)
  const [isDeletingSKU, setDeletingSKU] = useState<boolean>(false)
  const [isDeletingMerchant, setDeletingMerchant] = useState<boolean>(false)
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [cartItem, setCartItem] = useState<any>([])
  const [filter, setFilter] = useState<any>({})
  const [pagination, setPagination] = useState<any>({})
  const [accountBalance, setAccountBalance] = useState<any>([])
  const [merchantSelected, setMerchantSelected] = useState<any>({
    merchant: {},
    products: [],
  })
  const [, setProviders] = useState<ProviderInterface[]>([])
  const [loadingState, setLoadingState] = useState(true)
  const appContext = useContext(CurrentContext)

  useEffect(() => {
    getData(filter)
    getAccountBalance()
    getPRoviders()
    setTimeout(() => setLoadingState(false), 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPRoviders = useCallback(async () => {
    try {
      const res = await categoryRepository.getProvider()
      setProviders(res)
    } catch (err) { }
  }, [])

  const getData = (filter?: any, isShowProgressBar = true) => {
    setShowProgressBar(isShowProgressBar)
    setIsLoading(true)
    cartRepository
      .getProductCart(filter)
      .then((res) => {
        let currentPage = lodash.get(res, 'headers.x-page-number')
        let total = lodash.get(res, 'headers.x-total-count')
        setCartItem(res.data)
        setPagination({
          current: parseInt(currentPage) + 1,
          hideOnSinglePage: true,
          total: parseInt(total),
          pageSize: parseInt(lodash.get(res, 'headers.x-page-size')),
        })
      })
      .catch((err) => { })
      .finally(() => {
        setShowProgressBar(false)
        setIsLoading(false)
      })
  }

  const getAccountBalance = useCallback(async () => {
    try {
      const res = await supplierRepository.getAccountBalance()
      const accountBalanceFiltered = res.filter((acc: AccountBalanceInterface) => {
        return acc.connection.provider.orderServices[0].enable && acc.connection?.provider?.status==='ACTIVE'
      })
      let accounts = accountBalanceFiltered.map((item: any) => {
        return { ...item, label: item.connection.provider.name.toString(), value: item.connection.id.toString() }
      })
      setAccountBalance(accounts)
    } catch (err) { }
  }, [])

  const onPageChange = (filter: any) => {
    setFilter(filter)
    let temp = lodash.cloneDeep(filter)
    if (filter && filter.page && filter.page > 0) {
      temp.page = temp.page - 1
    }
    window.scroll(0, 0)
    getData(temp)
  }

  const handleClickOrderButton = () => {
    setCurrentStep(1)
  }

  const deleteSku = (merchantId: any, productId: any, skuId: any, callback: () => void) => {
    setDeletingSKU(true)
    cartRepository
      .deleteSku(skuId)
      .then((res) => {
        M24Notification.messageSuccess(t('message.deleteSKUSuccess'))
        if (callback) callback()
        let temp = lodash.cloneDeep(cartItem)
        let isLastSKU = false
        temp.forEach((x: any) => {
          if (x.id === merchantId) {
            // eslint-disable-next-line eqeqeq
            let product = x.products.find((xx: any) => xx.id == productId)
            if (product && product.skus.length === 1) {
              isLastSKU = true
            }
          }
        })
        if (isLastSKU) getData({ ...filter, page: 0 })
        else getData(filter)
        if (merchantSelected && merchantSelected.merchant.id) {
          let temp = lodash.cloneDeep(merchantSelected)
          if (temp.merchant.id === merchantId) {
            temp.products.forEach((x: any) => {
              if (x.id === productId) {
                x.skus = x.skus.filter((sku: any) => sku.id !== skuId)
              }
            })
            setMerchantSelected(temp)
          }
        }
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => setDeletingSKU(false))
  }

  const deleteMerchant = (merchantId: any, callback: () => void) => {
    setDeletingMerchant(true)
    cartRepository
      .deleteMerchant(merchantId)
      .then((res) => {
        M24Notification.messageSuccess(t('message.deleteMerchantSuccess'))
        getData({ ...filter, page: 0 })
        if (merchantSelected && merchantSelected.merchant.id) {
          let temp = lodash.cloneDeep(merchantSelected)
          if (temp.merchant.id === merchantId) {
            setMerchantSelected({
              merchant: {},
              products: [],
            })
          }
        }
        if (callback) callback()
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => setDeletingMerchant(false))
  }

  const handleUpdateQuantitySku = (merchantId: any, quantity: number, productId: any, skuId: any, callback: () => void) => {
    cartRepository
      .updateQuantitySku(skuId, quantity)
      .then(() => {
        getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 }, false)
        appContext?.getInfoCart()

        if (merchantSelected && merchantSelected.merchant.id) {
          let temp = lodash.cloneDeep(merchantSelected)
          if (temp.merchant.id === merchantId) {
            // eslint-disable-next-line array-callback-return
            temp.products.map((x: any) => {
              if (x.id === productId) {
                // eslint-disable-next-line array-callback-return
                x.skus.map((sku: any) => {
                  if (sku.id === skuId) {
                    sku.quantity = quantity
                  }
                })
              }
            })
            setMerchantSelected(temp)
          }
        }

        if (callback) callback()
      })
      .finally(() => setDeletingMerchant(false))
  }

  const handleUpdateEmployeeNote = (skuId: any, value: string, callback: () => void) => {
    cartRepository
      .updateSku(skuId, { employeeNote: value })
      .then(() => {
        getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 })
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => {
        if (callback) callback()
      })
  }

  const handleUpdatePersonalNote = (skuId: any, value: string, callback: () => void) => {
    cartRepository
      .updateSku(skuId, { personalNote: value })
      .then((res) => {
        getData({ ...filter, page: filter.page > 0 ? filter.page - 1 : 0 })
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => {
        if (callback) callback()
      })
  }

  const selectMerchant = (merchant: any, isSelect: boolean) => {
    if (!isSelect)
      setMerchantSelected({
        merchant: {},
        products: [],
      })
    else {
      let item = cartItem.find((x: any) => x.id === merchant.id)
      if (item && item.products && item.products.length > 0) {
        // let skusId: string[]=[];
        // item.products.map((x: any)=>{
        //         x.skus.map((xx:any)=> {
        //             skusId.push(xx);
        //         });
        // })
        setMerchantSelected({
          merchant,
          products: item.products,
        })
      }
    }
  }

  const selectSku = (merchant: any, product: any, sku: any, isSelect: boolean) => {
    if (merchant.id !== merchantSelected.merchant.id) {
      setMerchantSelected({
        merchant,
        products: [
          {
            id: product.id,
            pricePolicies: product.pricePolicies,
            skus: [sku],
          },
        ],
      })
    } else {
      let temp = lodash.cloneDeep(merchantSelected)
      if (!isSelect) {
        temp.products.forEach((x: any) => {
          if (x.id === product.id) {
            let skus = x.skus.filter((xx: any) => xx.id !== sku.id)
            x.skus = skus
          }
        })
        let products = temp.products.filter((x: any) => x.skus.length > 0)
        if (products && products.length > 0) {
          temp.products = products
          setMerchantSelected(temp)
        } else {
          setMerchantSelected({
            merchant: {},
            products: [],
          })
        }
      } else {
        let isExist = temp.products.find((x: any) => x.id === product.id)
        if (!isExist) {
          temp.products.push({
            id: product.id,
            pricePolicies: product.pricePolicies,
            skus: [sku],
          })
        } else {
          temp.products.forEach((x: any) => {
            if (x.id === product.id) {
              x.skus.push(sku)
            }
          })
        }
        setMerchantSelected(temp)
      }
    }
  }

  const [orderSuccessView, setOrderSuccessView] = useState<any>({})

  const handleCreateOrder = (body: any, callback: any) => {
    // setIsLoading(true);
    cartRepository
      .createOrderOneShop(body)
      .then((response) => {
        setOrderSuccessView(response.data)
        setStep(2)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => {
        if (callback) callback()
      })
  }

  const initSteps = () => {
    if (currentStep === 0) {
      return (
        <ShopList
          isDeletingMerchant={isDeletingMerchant}
          isDeletingSKU={isDeletingSKU}
          handleDeleteSku={deleteSku}
          handleDeleteMerchant={deleteMerchant}
          handleUpdateQuantitySku={handleUpdateQuantitySku}
          onPageChange={onPageChange}
          pagination={pagination}
          filter={filter}
          handleClickOrderBtn={handleClickOrderButton}
          cartItem={cartItem}
          getCartData={getData}
          handleUpdateEmployeeNote={handleUpdateEmployeeNote}
          handleUpdatePersonalNote={handleUpdatePersonalNote}
          selectMerchant={selectMerchant}
          selectSku={selectSku}
          merchantSelected={merchantSelected}
          handleShowProgressBar={(val: boolean) => setShowProgressBar(val)}
        />
      )
    }
    if (currentStep === 1) {
      return (
        <Step2
          setStep={setStep}
          setMerchant={setMerchantSelected}
          merchant={merchantSelected}
          accountBalance={accountBalance}
          handleCreateOrder={handleCreateOrder}
        />
      )
    }
    if (currentStep === 2) {
      return <Step3 orderSuccessView={orderSuccessView} />
    }
  }
  const setStep = (value: number) => {
    setCurrentStep(value)
    // if (value === 0) {
    //   setMerchantSelected({
    //     merchant: {},
    //     products: [],
    //   })
    // notification.destroy();
    // }
  }

  return (
    <MainLayout
      title={t('menu.shopping-cart')}
      showProgressBar={showProgressBar}
      descriptions={
        currentStep === 0
          ? {
            title: t('shoppingCart.pageTitle'),
            descriptions: t('shoppingCart.descriptions'),
            className: 'box-shadow-descriptions',
            pageCode: SHOPPING_CART_DESCRIPTIONS,
            hasDescription: true,
          }
          : null
      }
      hasExtensionCommon={currentStep === 0 ? true : false}
      breadcrumbs={{ currentStep: currentStep + 1 }}>
      <div className={`m22-steps ${theme}`}>
        <div className={`m22-step ${currentStep >= 0 ? 'active' : ''}`} onClick={() => {
          if (currentStep > 0 && currentStep < 2)
            setStep(0)
        }}>
          <div className="m22-step-right">
            <div className="m22-step_content">
              <span>{t('shopping-cart.products')}</span>
            </div>
          </div>
        </div>

        <div className={`m22-step  ${currentStep >= 1 ? 'active ' : 'step-disable'}`}>
          <div className="m22-step-right">
            <div className="m22-step_content">
              <span>{t('shopping-cart.services')}</span>
            </div>
          </div>
        </div>
        <div className={`m22-step  ${currentStep >= 2 ? 'active' : 'step-disable'}`}>
          <div className="m22-step-right">
            <div className="m22-step_content">
              <span>{t('shopping-cart.payments')}</span>
            </div>
          </div>
        </div>
      </div>

      {currentStep === 0 ? (
        <div>
          {showProgressBar || loadingState ? (
            <SkeletonShoppingCart
              loading={loadingState}
              data={cartItem}
            />
          ) : (
            initSteps()
          )}
        </div>
      ) : (
        <div>{initSteps()}</div>
      )}
    </MainLayout>
  )
}

export default ShoppingCart
