import { MessageItem } from './MessageItem'
import React, { useContext } from 'react'
import { BoxChatContext } from '../../context/BoxChatContext'

export const MessageList = () => {
  const boxChatContext = useContext(BoxChatContext)
  const { messages, onListChatScroll } = boxChatContext

  const handleScroll = (e: any) => {
    if (messages.length > 0) {
      onListChatScroll(e)
    }
  }

  return (
    <div
      id="message-list"
      ref={boxChatContext.chatListRef}
      className={'list-chat box-chat-messages'}
      onScroll={handleScroll}>
      {messages.length < 1 && (
        <div className="flex items-center justify-content-center height100">
          <span className="txt-muted text-center">Chưa có trao đổi nào</span>
        </div>
      )}
      {messages.map((item) => (
        <MessageItem
          key={item.id}
          message={item}
        />
      ))}
    </div>
  )
}
