import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import List from '../../../components/Delivery/Create/List'
import MainLayout from '../../../components/Layout'
import { TENANT_KEY } from '../../../core/config'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { localStorageRead } from '../../../utils/LocalStorageUtils'

const CreateRequestDelivery = () => {
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [availableOrders, setAvailableOrders] = useState<Array<any>>([])
  const [statuses, setStatuses] = useState([])
  const [totalWeight, setTotalWeight] = useState(0)
  const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState(false)
  const { t } = useTranslation()
  const [needToPayValue, setNeedToPayValue] = useState<number>()

  const calculateCash = useCallback((items: any) => {
    let weight = 0
    let needToPaid = 0
    let needCharged = 0

    const ordersListSelected = items.orders.filter((xx: any) => lodash.includes(items.selectedOrders, xx.code))
    let totalBalance = 0
    ordersListSelected.forEach((order: any) => {
      const packages = order.packages.filter((x: any) => lodash.includes(order.selectedPackages, x.code))
      const totalCash = order.totalUnpaid || 0
      const isShipment = lodash.get(order, 'isShipment')
      totalBalance += ordersListSelected.totalCash

      needToPaid += totalCash
      weight += lodash.sumBy(packages, 'actualWeight')
      needCharged = isShipment ? totalBalance - needToPaid : lodash.get(items, 'account.balance', 0) - needToPaid
    })
    items.needToPaid = needToPaid
    items.needCharged = needCharged
    setTotalWeight(weight)
    setNeedToPayValue(needToPaid)
    console.log('items', items)
    return items
  }, [])

  useEffect(() => {
    const getData = async () => {
      setShowProgressBar(true)
      setAvailableOrders([])
      await deliveryRepository
        .getAvailableOrders({})
        .then((res) => {
          if (res.length > 0) {
            res.map((x: any) => {
              x.selectedPackages = lodash.map(x.packages, 'code')
              return x
            })
          }
          return {
            orders: res,
            checked: true,
            selectedOrders: lodash.map(res, 'code'),
          }
        })
        .then(async (values: any) => {
          let items = await calculateCash(values)
          setNeedToPayValue(items.needToPaid)
          setAvailableOrders(items)
        })
        .finally(() => setShowProgressBar(false))
    }
    getData()
  }, [calculateCash])

  const getStatuses = useCallback(() => {
    categoryRepository
      .getPackageStatuses()
      .then((res: any) => {
        setStatuses(res)
      })
      .catch((err: any) => {})
  }, [])

  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  useEffect(() => {
    setCurrentConfigShowBalance(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', ''))
  }, [])

  const onSelectConnection = (checked: boolean) => {
    let items: any = lodash.cloneDeep(availableOrders)
    items.checked = checked
    if (checked) {
      items.selectedOrders = lodash.map(items.orders, 'code')
      items.orders.map((x: any) => {
        x.selectedPackages = lodash.map(x.packages, 'code')
        return x
      })
    } else {
      items.selectedOrders = []
      items.orders.map((x: any) => {
        x.selectedPackages = []
        return x
      })
    }
    items = calculateCash(items)
    setAvailableOrders(items)
  }

  const onSelectOrder = (orderCode: string, checked: boolean) => {
    let items: any = lodash.cloneDeep(availableOrders)
    if (checked) {
      items.selectedOrders.push(orderCode)
      items.orders.map((x: any) => {
        if (x.code === orderCode) x.selectedPackages = lodash.map(x.packages, 'code')
        return x
      })
      if (!items.checked) items.checked = true
    } else {
      items.selectedOrders = items.selectedOrders.filter((x: string) => x !== orderCode)
      if (items.selectedOrders.length === 0) items.checked = false
      items.orders.map((x: any) => {
        if (x.code === orderCode) x.selectedPackages = []
        return x
      })
    }
    items = calculateCash(items)
    setAvailableOrders(items)
  }

  const onSelectPackage = (orderCode: string, packageCode: string, checked: boolean) => {
    let items: any = lodash.cloneDeep(availableOrders)
    items.orders.map((x: any) => {
      if (x.code === orderCode) {
        if (checked) {
          x.selectedPackages.push(packageCode)
        } else {
          x.selectedPackages = x.selectedPackages.filter((xx: any) => xx !== packageCode)
        }
        if (x.selectedPackages.length === 0) {
          items.selectedOrders = items.selectedOrders.filter((x: string) => x !== orderCode)
        } else {
          if (!items.selectedOrders.includes(orderCode)) {
            items.selectedOrders.push(orderCode)
          }
        }
        if (items.selectedOrders.length === 0) items.checked = false
        else items.checked = true
      }
      return x
    })
    items = calculateCash(items)
    setAvailableOrders(items)
  }

  const onSelectAll = (checked: boolean) => {
    let items: any = lodash.cloneDeep(availableOrders)
    items.checked = checked
    if (checked) {
      items.selectedOrders = lodash.map(items.orders, 'code')
      items.orders.map((xx: any) => {
        xx.selectedPackages = lodash.map(xx.packages, 'code')
        return xx
      })
    } else {
      items.selectedOrders = []
      items.orders.map((xx: any) => {
        xx.selectedPackages = []
        return xx
      })
    }
    items = calculateCash(items)
    setAvailableOrders(items)
  }

  return (
    <MainLayout
      title={t('menu.createDeliveryRequest')}
      showProgressBar={showProgressBar}>
      {/* <Filter
        accounts={accounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      /> */}
      {console.log('availableOrders', availableOrders)}
      <List
        onSelectPackage={onSelectPackage}
        onSelectConnection={onSelectConnection}
        onSelectOrder={onSelectOrder}
        data={availableOrders}
        statuses={statuses}
        totalWeight={totalWeight}
        onSelectAll={onSelectAll}
        showProgressBar={showProgressBar}
        showBalance={currentConfigShowBalance}
        needToPayValue={needToPayValue}
      />
    </MainLayout>
  )
}

export default CreateRequestDelivery
