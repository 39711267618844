import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { IMidMile } from '../../Interface/ShipmentInterface'
import { packageRepository } from 'src/repositories/PackagesRepository'

type MidMileMappingType = { [key in IMidMile['code']]: IMidMile['logo'] | IMidMile['name'] }

export const useMidmile = (provider: string | undefined | null) => {
  const [midmileList, setMidlileList] = useState<IMidMile[]>([])
  const [midmileLogoMapping, setMidmileLogoMapping] = useState<MidMileMappingType>({})
  const [midmileNameMapping, setMidmileNameMapping] = useState<MidMileMappingType>({})

  useEffect(() => {
    if (!provider) return

    packageRepository.getMidmileList(provider).then((response: AxiosResponse<IMidMile[]>) => {
      setMidlileList(response.data)
      const obj: MidMileMappingType = {}
      const objName: MidMileMappingType = {}
      response.data.forEach((midmile) => {
        obj[midmile.code] = midmile.logo
        objName[midmile.code] = midmile.name
      })
      setMidmileLogoMapping(obj)
      setMidmileNameMapping(objName)
    })
  }, [provider])

  return { midmileList, midmileLogoMapping, midmileNameMapping }
}
