import { Badge, Button } from 'antd'
import lodash, { parseInt } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClaimFilter from '../../components/Claim/ClaimFilter'
import ClaimList from '../../components/Claim/ClaimList'
import MainLayout from '../../components/Layout'
import M22Box from '../../components/M22Box'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { CLAIM_DESCRIPTIONS } from '../../core/config'
import { useTheme } from '../../hooks/useTheme'
import { ClaimInterface } from '../../Interface/ClaimInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { claimRepository } from '../../repositories/ClaimRepository'
import ClaimsLight from '../../resources/images/descriptions/claimslight.png'
import ClaimsDark from '../../resources/images/descriptions/claimsdark.png'
import QuickPagination from '../../components/QuickPagination'

function Claims() {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const navigate = useNavigate()
  const [loadingClaim, setLoadingClaim] = useState(false)
  const [claimData, setClaimData] = useState<ClaimInterface[]>([])
  const [pagination, setPagination] = useState<any>({})
  const [selectedAccount, setSelectedAccount] = useState<any>({})
  const [ticketStatuses, setTicketStatuses] = useState<any[]>([])
  const [ticketSolutions, setTicketSolutions] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSkeleton, setIsSkeleton] = useState(true)

  const openCreateTicketForm = () => {
    navigate(`/claims/create`)
  }

  const handleChangePage = (filter: any) => {
    delete filter.pageSize
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      ...filter,
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  React.useEffect(() => {
    const getTicketStatuses = () => {
      categoryRepository.getClaimStatuses().then((response) => {
        setTicketStatuses(response)
      })
    }
    getTicketStatuses()

    const getTicketSolutions = () => {
      categoryRepository.getSolutionsOfClaim().then((response) => {
        setTicketSolutions(response)
      })
    }
    getTicketSolutions()
  }, [selectedAccount])

  React.useEffect(() => {
    const getTickets = (filter?: any) => {
      setLoadingClaim(true)
      setIsSkeleton(true)
      claimRepository
        .getClaims(filter)
        .then((response) => {
          let currentPage = lodash.get(response, 'headers.x-page-number')
          let total = lodash.get(response, 'headers.x-total-count')
          setTotal(parseInt(total))
          setClaimData(response.data)
          setPagination({
            current: parseInt(currentPage) + 1,
            hideOnSinglePage: true,
            total: parseInt(total),
            pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
          })
        })
        .catch(() => {
          setTotal(0)
          setClaimData([])
          setPagination({
            current: 1,
            hideOnSinglePage: true,
            total: 0,
            pageSize: 25,
          })
        })
        .finally(() => {
          setLoadingClaim(false)
          setTimeout(() => setIsSkeleton(false), 2000)
        })
    }
    getTickets(Object.fromEntries(searchParams.entries()))
  }, [searchParams, selectedAccount, t])

  const handleSelectAccount = (account: any) => {
    setSelectedAccount(account)
  }

  const ImageDes = theme === 'dark' ? ClaimsDark : ClaimsLight
  return (
    <MainLayout
      title={t('menu.tickets')}
      breadcrumbs={{ total }}
      showProgressBar={loadingClaim}
      descriptions={{
        title: t('claims.pageTitle'),
        descriptions: t('claims.descriptions'),
        className: 'box-shadow-descriptions',
        pageCode: CLAIM_DESCRIPTIONS,
        hasDescription: true,
        image: ImageDes,
      }}>
      <div className="ticket-container rad-6">
        <ClaimFilter
          selectedAccount={selectedAccount}
          ticketStatuses={ticketStatuses}
          ticketSolutions={ticketSolutions}
          handleSelectAccount={handleSelectAccount}
          loading={loadingClaim}
          handleSkeleton={(val: boolean) => setIsSkeleton(val)}
        />
        <M22Box className={'border-card-header rad-6 pd-16'}>
          <div className={'flex align-item-center justify-content-between mg-l-8'}>
            <span className={'flex align-items-center'}>
              <span className={`m22-typo-heading mg-r-4 ${theme}`}>{t('breadcrumbs.claims')}</span>
              <Badge
                count={total}
                overflowCount={99999}
                showZero={true}
                style={{
                  backgroundColor: !total ? '#F5F5F5' : '',
                  color: !total ? '#707070' : '#1a1a1a',
                }}
              />
            </span>
            <div className={'flex align-center'}>
              <QuickPagination
                filter={{
                  ...Object.fromEntries(searchParams.entries()),
                }}
                pagination={pagination}
                onPageChange={handleChangePage}
              />
              <Button
                className={`m-l-3 m22-btn m22-btn-outline ${theme}`}
                onClick={openCreateTicketForm}
                icon={<i className="far fa-plus pdr5" />}>
                {t('tickets.createTickets')}
              </Button>
            </div>
          </div>
          
          <ClaimList
            ticketStatuses={ticketStatuses}
            claimData={claimData}
            loadingClaim={loadingClaim}
            isSkeleton={isSkeleton}
          />
          {claimData && claimData.length >= 1 && (
            <M24Pagination
              filter={{
                ...Object.fromEntries(searchParams.entries()),
              }}
              pagination={pagination}
              onPageChange={handleChangePage}
              className={'mgt10'}
            />
          )}
        </M22Box>
      </div>
    </MainLayout>
  )
}

export default Claims
