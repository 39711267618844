/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Result, Row, Table } from 'antd'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import EmptyDataFilter from '../../Empty/EmptyDataFilter'
import Services from '../../Services'
import deliverySuccessDark from 'src/resources/images/deliverySuccessDark.png'
import deliverySuccessLight from 'src/resources/images/deliverySuccessLight.png'

import './styles.scss'
import M22Box from 'src/components/M22Box'

type PropsStep3 = {
  orderSuccessView: any
}

function Step3(props: PropsStep3) {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const [theme] = useTheme()
  const { orderSuccessView } = props
  useEffect(() => {
    countNumber()
  }, [])

  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [totalSkus, setTotalSkus] = useState<number>(0)

  const countNumber = () => {
    setTotalSkus(orderSuccessView.orderItems.length)
    let total: number = 0
    orderSuccessView.orderItems.forEach((x: any) => {
      total = total + x.orderedQuantity
      setTotalQuantity(total)
    })
  }

  const columns = [
    {
      title: t('shopping-cart.seller'),
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 280,
      render: (_: any, record: any) => (
        <Row className={'whitespace flex-nowrap align-items-center'}>
          <Avatar
            size={20}
            shape={'square'}
            className={'bd0'}
            src={lodash.get(record, 'marketplace.icon', null)}
          />
          <span className={'mgl5 robotomedium  '}>{lodash.get(record, 'merchantName', '--')}</span>
        </Row>
      ),
    },
    {
      title: t('shopping-cart.order-id'),
      dataIndex: 'code',
      key: 'code',
      width: 160,
      render: (value: any, record: any) => (
        <Link to={`/orders/${value}`}>
          <span className={'robotomedium '}>#{lodash.get(record, 'code', '--')}</span>
        </Link>
      ),
    },
    {
      title: t('shopping-cart.products'),
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 250,
      render: () => (
        <span>
          <span className={'  txt-size-h7'}>
            {totalQuantity} {t('shopping-cart.products')}/{totalSkus} Link
          </span>
        </span>
      ),
    },
    {
      title: t('shopping-cart.totalPayment'),
      dataIndex: 'totalPayment',
      key: 'totalPayment',
      width: 130,
      render: (_: any, record: any) => {
        let currency = JSON.parse(lodash.get(record, 'provider.currency', {}))
        return (
          <span className={'robotomedium'}>
            {formatMoneyByUnit(lodash.get(record, 'provisionalAmount', '---'), lodash.get(currency, 'code', ''))}
          </span>
        )
      },
    },
    {
      title: t('shopping-cart.services'),
      dataIndex: 'services',
      key: 'services',
      width: 300,
      render: (_: any, record: any) => (
        <div className="flex align-items-center flex-wrap">
          <Services
            data={record.services}
            className="fsz-14 line-h-22"
          />
        </div>
      ),
    },
  ]

  return (
    <M22Box>
      <Result
        icon={
          <img
            src={theme === 'dark' ? deliverySuccessDark : deliverySuccessLight}
            alt="success"
          />
        }
        title={t('shopping-cart.order_success')}
        className={`success-shopping-cart pd-24 ${theme}`}
        extra={[
          <Button
            type="primary"
            onClick={() => navigate('/orders')}
            className={`m22-btn ${theme}`}
            key="console">
            <span className={'robotomedium'}>{t('shopping-cart.to-order-management')}</span>
            <i className="far fa-arrow-right fsz-18" />
          </Button>,
        ]}
      />

      <div className={`h-fit-content bd-color-transparent success-view-custom flex-shrink-1 px-32 ${theme}`}>
        <div className={'pd-bt-4'}>
          <Avatar
            src={lodash.get(orderSuccessView, 'provider.logo', '---')}
            alt={''}
            size={24}
            style={{ border: 'none' }}
          />
          <span className={'txt-capitalize mgl5 txt-size-h6 robotomedium '}>{lodash.get(orderSuccessView, 'provider.name', '---')}</span>
        </div>

        <Table
          rowKey={(record: any) => record.id}
          className={`width100pc main-table main-table-no-px flex-shrink-1 m22-table ${theme}`}
          dataSource={[orderSuccessView]}
          columns={columns}
          pagination={false}
          locale={{ emptyText: <EmptyDataFilter description={'Opps!'} /> }}
        />
      </div>
    </M22Box>
  )
}

export default Step3
