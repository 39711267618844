import { ConnectionInterface } from '../Interface/ConnectionInterface'
import { ProviderInterface } from '../Interface/ProviderInterface'
import lodash from 'lodash'
import { ConnectionModel } from '../model/ConnectionModel'

export const getProviderFromConnections = (connections: Array<ConnectionInterface>, providerCode: string): ProviderInterface | undefined => {
  const connection = connections.find((item: ConnectionInterface) => item.provider.code === providerCode)
  return lodash.get(connection, 'provider')
}

export const getConnectionFromConnections = (
  connections: Array<ConnectionInterface>,
  customerCode: string,
  providerCode: string
): ConnectionInterface | undefined => {
  return connections.find((item: ConnectionInterface) => item.provider.code === providerCode && item.name === customerCode)
}

export const getConnectionById = (connections: ConnectionInterface[], id: string) => {
  return connections.find((item: ConnectionInterface) => item.id === id) || ConnectionModel
}

export const getConnectionByNameAndProviderCode = (connections: ConnectionInterface[], name: string, providerCode: string) => {
  // console.log('connections',connections);
  // console.log('name',name);
  // console.log('providerCode',providerCode);
  return connections.find((item: ConnectionInterface) => item.name === name && item.provider.code === providerCode) || ConnectionModel
}

export const getUrlPathname = (location: Location) => {
  if (location.hash) {
    return location.hash.slice(1)
  }
  return location.pathname
}

export const checkExtension = (id: string, src: string, cb: any) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'
  const source = `chrome-extension://${id}/${src}`
  script.setAttribute('src', source)
  script.id = 'aa'
  document.body.appendChild(script)

  script.onload = () => {
    cb(1)
    script.parentNode?.removeChild(script)
    const scripts = document.getElementsByTagName('script')

    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src === source) {
        scripts[i].parentNode?.removeChild(scripts[i])
      }
    }
  }
  script.onerror = () => {
    cb(0)
  }
}

export function formatNumberWeight(value: any) {
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return parts.join(',')
}

export function hexToRgb(hex: any, opacity: any) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgb(${r}, ${g}, ${b},${opacity})`
}
export function removeDuplicateWords(address:string) {
  const words = address.split(', '); // Tách chuỗi thành mảng các từ
  
  // Sử dụng Set để loại bỏ các từ trùng lặp
  const uniqueWords = Array.from(new Set(words));
  
  // Kết hợp các từ lại thành chuỗi
  const result = uniqueWords.join(', ');
  
  return result;
}