import { message, notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode } from 'react'

interface INotificationArgs extends Omit<ArgsProps, 'message'> {
  message?: string | ReactNode
  title?: string | ReactNode
}

export default class M24Notification {
  /**
   *
   * @param messages
   * @param className
   * @param duration
   * @param onClose
   */
  static messageSuccess(messages: string, className?: string, duration = 3, onClose?: () => void) {
    message.success(messages, duration, onClose)
  }

  /**
   *
   * @param messages
   * @param className
   * @param duration
   * @param onClose
   */
  static messageError(messages: string, className?: string, duration = 5, onClose?: () => void) {
    message.error(messages, duration, onClose)
  }

  static notifyError(title: string, messages: string, className?: string, duration = 5, onClose?: () => void) {
    notification['error']({
      message: title,
      description: messages,
      className: className,
      duration: duration,
      onClose: onClose,
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
    })
  }

  static notifyErrorAllOptions({ message = t('message.erorrTitle'), ...props }: INotificationArgs) {
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message,
      ...props,
    })
  }

  static success({ title = 'Thành công', message, ...props }: INotificationArgs) {
    notification.success({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: title,
      description: message,
      key: message?.toString(),
      ...props,
    })
  }

  static error({ title = 'Đã xảy ra lỗi', message, ...props }: INotificationArgs) {
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: title,
      description: message,
      key: message?.toString(),
      ...props,
    })
  }

  static notifySuccess(title: string, messages: string | any, className?: string, duration = 3, onClose?: () => void) {
    notification['success']({
      message: title,
      description: messages,
      className: className,
      duration: duration,
      onClose: onClose,
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
    })
  }

  static notifyWarning(title: string, messages: string, className?: string, duration = 3, onClose?: () => void) {
    notification['warning']({
      message: title,
      description: messages,
      className: className,
      duration: duration,
      key: title,
      onClose: onClose,
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
    })
  }

  static notifyHttpError(error: AxiosError) {
    if (error.response && error.response?.status >= 500) return
    console.log('error', error)
    const errorKey = error.response?.data.title || 'error_unknown'
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: t('message.erorrTitle'),
      description: t('error.' + errorKey),
      key: errorKey,
    })
  }
}
