interface IProps {
  value: string
  label: string
}

export const WeightItem = ({ value, label }: IProps) => {
  return (
    <div
      className={`flex flex-col`}
      style={{ minWidth: 160, width: 160 }}>
      <span className="txt-muted">{`${label}:`}</span>
      <span className="font-medium whitespace">{value}</span>
    </div>
  )
}
