import { Avatar, Button, Empty, Image, Popover } from 'antd'
import React from 'react'
import ShopNhatLucBatDoubleImg from '../../resources/images/1688.png'
import TaoBaoImg from '../../resources/images/taobao.png'
import TMallImg from '../../resources/images/tmall.png'
import EmptyCartImg from '../../resources/images/table-contents/empty.png'
import { useTranslation } from 'react-i18next'

interface EmptyCartProps {
  className?: string
  minHeight?: number
  emptyText: string
}

const EmptyCart: React.FC<EmptyCartProps> = ({ className, minHeight, emptyText }) => {
  const { t } = useTranslation()

  const contentShop = () => {
    return (
      <div className="content-shop flex flex-col gap-8">
        <a
          href={'https://www.1688.com/'}
          target="_blank"
          className="flex align-items-center"
          rel="noreferrer">
          <Image
            width={24}
            height='auto'
            src={ShopNhatLucBatDoubleImg}
            preview={false}
          />
          <span className="mg-l-8">1688.com</span>
        </a>

        <a
          href={'https://world.taobao.com/'}
          target="_blank"
          className="flex align-items-center"
          rel="noreferrer">
          <Image
            width={24}
            height='auto'
            src={TaoBaoImg}
            preview={false}
          />
          <span className='mg-l-8'>taobao.com</span>
        </a>

        <a
          href={'https://www.tmall.com/'}
          target="_blank"
          className="flex align-items-center"
          rel="noreferrer">
          <Image
            width={24}
            height='auto'
            src={TMallImg}
            preview={false}
          />
          <span className='mg-l-8'>tmall.com</span>
        </a>
      </div>
    )
  }

  return (
    <div
      className={`${className ? className : ''} flex flex-col justify-content-center align-items-center flex-grow-1 align-items-center`}
      style={{ minHeight: minHeight ? `calc(100vh - ${minHeight}px)` : '' }}>
      <Empty
        image={
          <Avatar
            src={EmptyCartImg}
            alt="cart-empty"
            className="bd-none-impt"
            size={48}
            shape="square"
          />
        }
        description={<span className="txt-secondary">{emptyText}</span>}
      />

      <Popover
        placement="rightTop"
        title={null}
        content={contentShop}
        trigger="click"
        arrowPointAtCenter>
        <Button
          type="primary"
          className="m22-btn px-15 mg-t-16"
          icon={<i className="fa-regular fa-cart-arrow-down mg-r-10" />}>
          {t('shoppingCart.orderNow')}
        </Button>
      </Popover>
    </div>
  )
}

export default EmptyCart
