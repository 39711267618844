import { Card, Col, Row } from 'antd'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '../../hooks/useTheme'
interface SkeletonShoppingCartProps {
  loading: boolean
  data?: any
}

const SkeletonShoppingCart: React.FC<SkeletonShoppingCartProps> = ({ loading, data }) => {
  const [theme] = useTheme()
  return (
    <div className={`skeleton-cart cart-list ${theme}`}>
      {data?.map((item: any, idx: number) => (
        <Card
          key={idx}
          className={`${theme} rounded-none mg-bt-12`}
          title={
            <div className="flex justify-content-between">
              <div className="flex align-items-center">
                <Skeleton
                  width={16}
                  height={16}
                  className="mg-r-16"
                />
                <Skeleton
                  width={20}
                  height={20}
                  className="mg-r-16"
                  borderRadius={6}
                />
                <Skeleton
                  width={150}
                  height={20}
                  className="mg-r-16"
                />
                <Skeleton
                  width={120}
                  height={20}
                  className="mg-r-16"
                />
              </div>
              <Skeleton
                width={80}
                height={32}
              />
            </div>
          }>
          {item?.products?.map((product: any) => {
            return product.skus && product.skus.length > 0
              ? product.skus.map((sku: any, idx: number) => (
                  <Row
                    className="skeleton-item"
                    key={idx}>
                    <Col span={12}>
                      <Row>
                        <Col
                          span={1}
                          className="mg-r-8">
                          <Skeleton
                            width={16}
                            height={16}
                            className=""
                          />
                        </Col>
                        <Col span={22}>
                          <Row>
                            <Col>
                              <Skeleton
                                width={40}
                                height={40}
                              />
                            </Col>
                            <Col
                              className={'mgl5'}
                              span={21}>
                              <Row>
                                <Skeleton
                                  width={400}
                                  height={20}
                                />
                              </Row>
                              <Row>
                                {sku?.variantProperties?.map((x: any, index: number) => (
                                  <Skeleton
                                    key={index}
                                    width={60}
                                    height={20}
                                    className="mg-r-10"
                                  />
                                ))}
                              </Row>
                            </Col>
                          </Row>
                          <Col className={'mgt8'}>
                            <Row
                              className={'dpl-flex align-items-center'}
                              align={'middle'}>
                              <Skeleton
                                width={60}
                                height={20}
                              />
                              <Skeleton
                                width={200}
                                height={20}
                              />
                            </Row>
                            <Row className={'dpl-flex align-items-center'}>
                              <Skeleton
                                width={60}
                                height={20}
                              />
                              <Skeleton
                                width={200}
                                height={20}
                              />
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={5}
                      className={'align-items-center text-center'}>
                      <Skeleton
                        width={150}
                        height={32}
                      />
                    </Col>
                    <Col span={2}>
                      <Row className={'dpl-flex align-items-center justify-content-end'}>
                        <Skeleton
                          width={80}
                          height={20}
                        />
                      </Row>
                    </Col>
                    <Col span={2}>
                      <Row className={'dpl-flex align-items-center justify-content-end'}>
                        <Skeleton
                          width={80}
                          height={20}
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row justify={'end'}>
                        <Skeleton
                          height={24}
                          width={24}
                        />
                      </Row>
                    </Col>
                  </Row>
                ))
              : null
          })}
        </Card>
      ))}
    </div>
  )
}

export default SkeletonShoppingCart
