import { Badge, Popover } from 'antd'
import React from 'react'
import TabNotification from '../Notices'
import { useTheme } from '../../../hooks/useTheme'

interface PopoverNoticeProps {
  onVisibleChangeHandler: (visible: boolean) => void
  totalUnreadNotice: number
  isShowNotice: boolean
}
const PopoverNotices: React.FC<PopoverNoticeProps> = (props) => {
  const { totalUnreadNotice, onVisibleChangeHandler } = props
  const [theme] = useTheme()
  return (
    <Popover
      placement="bottomRight"
      trigger={['click']}
      destroyTooltipOnHide
      overlayClassName={'header-notification-popup'}
      onOpenChange={onVisibleChangeHandler}
      getPopupContainer={() => document.getElementById('header-right-content')!}
      content={<TabNotification totalUnread={totalUnreadNotice} />}>
      <Badge
        count={totalUnreadNotice}
        offset={[totalUnreadNotice > 99 ? 6 : 8, 0]}
        className={`cursor-pointer fsz-16 header-icon ${theme} mg-r-8`}
        overflowCount={99}>
        <i className="fa-light fa-bell fsz-18 "></i>
      </Badge>
    </Popover>
  )
}

export default PopoverNotices
