import { useTheme } from '../hooks/useTheme'
import { lazy, Suspense } from 'react'
import { Spin } from 'antd'

const DarkTheme = lazy(() => import('../components/Theme/DarkTheme'))
const LightTheme = lazy(() => import('../components/Theme/LightTheme'))

export const ThemeProvider = (props: any) => {
  const [theme] = useTheme()

  return (
    <>
      <Suspense fallback={<Spin />}>
        {theme === 'dark' ? <DarkTheme /> : <LightTheme />}
        {props.children}
      </Suspense>
    </>
  )
}
