/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Checkbox, Col, Form, Input, Modal, ModalProps, Row} from 'antd'
import lodash from 'lodash'
import {ReactNode, useCallback, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { TENANT_KEY } from '../../../core/config'
import { useTheme } from '../../../hooks/useTheme'
import { locationRepository } from '../../../repositories/LocationRepository'
import { localStorageRead } from '../../../utils/LocalStorageUtils'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import ComboBox from '../../ComboBox'
import SelectionItem from './SelectionItem'
import './styles.scss'

interface Props extends ModalProps {
	isVisible: any
	onCancel: () => void
	onSubmit: (body: any) => void
	datasource?: any
	isDefault?: boolean
	isProfile?: boolean
	title?: string
	requiredTitle?: string | ReactNode
	loading?: boolean
}

const formTailLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 8, offset: 0 },
}

function CreateAddressModal(props: Props) {
	const { t } = useTranslation()
	const { isVisible, onCancel, onSubmit, isDefault, datasource, isProfile, title, requiredTitle,loading } = props
	const [theme] = useTheme()
	const [form] = Form.useForm()
	const [countries, setCountries] = useState<any>([])

	// const [isLoading,setLoading ] = useState<boolean>(loading || false)
	const [selectedItem, ] = useState<any>()
	const [locationConfig, ] = useState<any>(
		lodash.get(localStorageRead(TENANT_KEY), 'config.locationConfig.countries', [])
	)
	const [countrySelected, setCountrySelected] = useState<any>()
	const [division, setDivision] = useState<any>()
	const [locationForm, setLocationForm] = useState<any>({})
	const [datasourceSelects, setDatasourceSelects] = useState<any>({})
	const [disabled, setDisabled] = useState<boolean>(true)

	const addressName = Form.useWatch('name', form)
	const receiver = Form.useWatch('receiver', form)
	const phoneNumber = Form.useWatch('phoneNumber', form)
	const address1 = Form.useWatch('address1', form)

	useEffect(() => {
		getCountries()
	}, [])

	console.log('loading',loading)

	const getDetail = useCallback(async () => {
		try {
			const res = await locationRepository.getDetail(lodash.get(datasource, 'location', ''))
			let temp: any = {}
			temp[res.level] = res.code
			let parent = res.parent
			while (parent) {
				temp[parent.level] = parent.code
				parent = parent.parent
			}
			setLocationForm(temp)
			const resDivision: any = await locationRepository.getDivisions(temp[0])
			let config = locationConfig.find((x: any) => x.code === temp[0])
			if (config) {
				let group = lodash
					.chain(resDivision)
					// Group the elements of Array based on `color` property
					.groupBy('level')
					.map((value, key) => {
						if (value.length > config.preferNumberOfDivision) {
							value.splice(config.preferNumberOfDivision)
						}
						let name = ''
						value.map((x: any, index: number) => {
							name = name + `${index > 0 ? '/' : ''}` + x.name
						})
						return {
							level: parseInt(key),
							name,
							items: value,
							require: parseInt(key) <= config.requiredLevel,
						}
					})
					.value()
				setDivision(group)
				setCountrySelected(countries.find((x: any) => x.code === temp[0]))
				let tempDatasourceList: any = {}
				let keys = Object.keys(temp)
				//level cuối cùng không cần call api danh sách ở select
				if (keys.length > 0) {
					if (keys.length > group.length) keys.splice(keys.length - 1)
				}
				Promise.all(
					keys.map(async (key: any) => {
						const res = await locationRepository.getChildren(temp[key])
						return { level: parseInt(key) + 1, values: res }
					})
				).then((values) => {
					values.map((item: any) => {
						tempDatasourceList[item.level] = item.values
					})
					setDatasourceSelects(tempDatasourceList)
				})
			}
		} catch (e) {}
	}, [countries, datasource, locationConfig])

	const getCountries = () => {
		locationRepository
			.getCountries()
			.then((res) => {
				let items: any[] = []
				locationConfig.map((x: any) => {
					let country = res.find((xx: any) => xx.code === x.code)
					if (country) {
						items.push({
							...country,
							requiredLevel: lodash.get(x, 'requiredLevel', null),
							preferNumberOfDivision: lodash.get(x, 'preferNumberOfDivision', null),
						})
					}
				})
				let hasOnlyVN = items.find((nation:any) => nation.code === 'VN');
				setCountries([hasOnlyVN])
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
	}
	useEffect(() => {
		if (countries && datasource) {
			getDetail().then(r => {})
		}
	}, [countries, datasource, getDetail])

	const getDivisions = (code: string) => {
		locationRepository
			.getDivisions(code)
			.then((res) => {
				let config = locationConfig.find((x: any) => x.code === code)
				if (config) {
					let group = lodash
						.chain(res)
						// Group the elements of Array based on `color` property
						.groupBy('level')
						.map((value, key) => {
							if (value.length > config.preferNumberOfDivision) {
								value.splice(config.preferNumberOfDivision)
							}
							let name = ''
							value.map((x: any, index: number) => {
								name = name + `${index > 0 ? '/' : ''}` + x.name
							})
							return {
								level: parseInt(key),
								name,
								items: value,
								require: parseInt(key) <= config.requiredLevel,
							}
						})
						.value()
					setDivision(group)
				}
			})
			.catch(() => {})
	}
	const validateForm = useCallback(() => {
		if (
			!countrySelected ||
			!form.getFieldValue('receiver').trim() ||
			(countrySelected && !locationForm[countrySelected.requiredLevel])
		)
			return false
		return true
	}, [countrySelected, form, locationForm])

	useEffect(() => {
		if (selectedItem) {
			form.setFieldsValue({
				receiver: lodash.get(selectedItem, 'receiver'),
				phoneNumber: lodash.get(selectedItem, 'phoneNumber'),
				location: lodash.get(selectedItem, 'location'),
				isDefault: lodash.get(selectedItem, 'isDefault'),
				name: lodash.get(selectedItem, 'name'),
				address1: lodash.get(selectedItem, 'address1'),
			})
		}
	}, [form, selectedItem])

	useEffect(()=> {
		if(!datasource) {
			setLocationForm({0: 'VN'})
			selectValueOfLevel(0,'VN')
			getDatasourceOfLevel(1,'VN')
		}
	},[datasource,countries])

	const selectValueOfLevel = (level: any, value: any) => {
		let temp = lodash.cloneDeep(locationForm)
		temp[level] = value
		Object.keys(temp).map((key: any) => {
			if (parseInt(key) > level) {
				delete temp[key]
			}
		})
		// console.log(temp)
		setLocationForm(temp)
		let country = lodash.cloneDeep(countrySelected)
		if (level === 0) {
			getDivisions(value)
			country = countries.find((x: any) => x.code === value)
			setCountrySelected(country)
		}
		if (country && country.requiredLevel <= level && disabled) {
			let allValues = form.getFieldsValue()
			if (allValues.receiver && allValues.phoneNumber && allValues.address1) setDisabled(false)
		}
	}
	const getDatasourceOfLevel = (level: number, code: string) => {
		console.log(' get data level')
		locationRepository
			.getChildren(code)
			.then((res) => {
				setDatasourceSelects({ ...datasourceSelects, [level]: res })
			})
			.catch(() => {})
	}

	const classesLabel = 'm22-typo-label_gray-tertiary-plus m22-typo-label_sm'
	return (
		<Modal
			className={`m22-modal m22-modal-address m22-modal-create-address_${theme} ${theme}`}
			title={title ?? t('address.create-address')}
			open={isVisible}
			width={'50%'}
			centered
			closeIcon={<i className='fa-solid fa-xmark' />}
			// onCancel={() => {
			// 	onCancel()
			// }}
			footer={[
				(!requiredTitle) &&
				<Button
					key='back'
					onClick={onCancel}
					className={`m22-btn ${theme}`}
					type='text'
					disabled={loading}
				>
					{t('button.cancel')}
				</Button>,
				<Button
					type='primary'
					className={`m22-btn ${theme}`}
					icon={<i className={`fa-solid fa-${datasource ? 'check' : 'plus'} mg-r-10`} />}
					key='submit'
					disabled={!validateForm() || loading || disabled}
					loading={loading}
					onClick={() => {
						let keys = Object.keys(locationForm)
						keys = keys.sort().reverse()
						let key: any = keys.find((key: string) => locationForm[key])
						if (key) onSubmit({ ...form.getFieldsValue(), location: locationForm[key] })
					}}
				>
					{' '}
					{t(`button.${datasource ? 'save' : 'submitAddress'}`)}
				</Button>,
			]}
			{...props}
		>
			{
				requiredTitle && <div className={'mb-4'}>
					{requiredTitle}
				</div>
			}

			<Form
				// {...layout}
				layout={'horizontal'}
				form={form}
				initialValues={{
					receiver: lodash.get(datasource, 'receiver', ''),
					phoneNumber: lodash.get(datasource, 'phoneNumber', ''),
					isDefault: isDefault || lodash.get(datasource, 'isDefault', false),
					name: lodash.get(datasource, 'name', ''),
					address1: lodash.get(datasource, 'address1', ''),
				}}
				colon={false}
				labelAlign={'left'}
				onValuesChange={(changedValues: any, allValues: any) => {
					if (!allValues.receiver || !allValues.phoneNumber || !allValues.address1) setDisabled(true)
					else setDisabled(false)
				}}
			>
				<Row align={'top'} gutter={[12, 0]}>
					<Col span={isProfile ? 8 : 24}>
						<ComboBox
							label={<span className={classesLabel}>{t('address.address-name')}</span>}
							value={addressName}
						>
							<Form.Item name='name' className={'_name-input'}>
								<Input size='large' autoFocus={true} placeholder='' />
							</Form.Item>
						</ComboBox>
					</Col>
					<Col span={isProfile ? 8 : 24}>
						<ComboBox
							label={<span className={classesLabel}>{t('address.customer-name')}</span>}
							value={receiver}
							required
						>
							<Form.Item name='receiver' className={'_receiver-input'}>
								<Input size='large' placeholder='' />
							</Form.Item>
						</ComboBox>
					</Col>
					<Col span={isProfile ? 8 : 24}>
						<ComboBox
							label={<span className={classesLabel}>Số điện thoại</span>}
							value={phoneNumber}
							required
						>
							<Form.Item
								name='phoneNumber'
								normalize={(value) => {
									let raw = value.replace(/[^0-9-+. ()]/g, '')
									return raw
								}}
							>
								<Input size='large' placeholder='' />
							</Form.Item>
						</ComboBox>
					</Col>
					<Col span={isProfile ? 6 : 24}>
						<ComboBox
							label={<span className={classesLabel}>{t('address.address-national')}</span>}
							required
							value={lodash.get(locationForm, '0', undefined)}
						>
							<Form.Item name='nation'>
								<SelectionItem
									valueChangeHandler={(value: any) => {
										selectValueOfLevel(0, value)
										getDatasourceOfLevel(1, value)
									}}
									options={countries}
									locationForm={locationForm}
									isNation
									level={'0'}
								/>
							</Form.Item>
						</ComboBox>
					</Col>
					{division
						? division.map((x: any, index: number) => {
								return (
									<Col span={isProfile ? 6 : (lodash.get(locationForm, '0') === 'US' ? 24 : 8)}>
										<ComboBox
											label={<span className={classesLabel}>{x.name}</span>}
											required={x.require}
											value={lodash.get(locationForm, x.level, undefined)}
										>
											<Form.Item key={index}>
												<SelectionItem
													valueChangeHandler={(value: any) => {
														selectValueOfLevel(x.level, value)
														if (index < division.length - 1)
															getDatasourceOfLevel(x.level + 1, value)
													}}
													options={datasourceSelects}
													locationForm={locationForm}
													level={x.level}
												/>
											</Form.Item>
										</ComboBox>
									</Col>
								)
						  })
						: null}
					<Col span={24}>
						<ComboBox
							label={
								<span>
									{t('address.address-location')} <span className={'txt-color-red'}> *</span>
								</span>
							}
							isTextArea
							value={address1}
						>
							<Form.Item name='address1' required>
								<Input.TextArea size='middle' rows={3} maxLength={1000} className='rad-4 pd-t-12' />
							</Form.Item>
						</ComboBox>
					</Col>
					<Col span={24}>
						<Form.Item
							{...formTailLayout}
							name='isDefault'
							valuePropName='checked'
							className='pdt0 m22-form-item_mg0'
						>
							<Checkbox disabled={isDefault || (datasource && datasource.isDefault)}>
								{t('address.set-default-address')}
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}

export default CreateAddressModal

