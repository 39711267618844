import { Badge, Button, Col, Form, Input, InputRef, Modal, Tabs } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { scrollToElementId } from 'src/core/helpers/dom'
import M22Box from '../../../../components/M22Box'
import { TAB_PRODUCT, TAB_TICKET, TAB_TRANSACTION, TAB_TRANSPORT } from '../../../../core/config'
import { useTheme } from '../../../../hooks/useTheme'
import { ClaimInterface } from '../../../../Interface/ClaimInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import ShipmentPackageInterface from '../../../../Interface/ShipmentPackageInterface'
import { ShipmentTransactionInterface } from '../../../../Interface/ShipmentTransaction'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import { getUrlPathname } from '../../../../utils/util'
import CreateProductModal from './CreateProductModal'
import Packages from './Packages'
import Products from './Products'
import Claims from './Tickets'
import Transactions from './Transactions'
import ComboBox from './../../../../components/ComboBox/index'
import { useQuery } from 'react-query'
import M24Notification from 'src/utils/M24Notification'
import { IWaybill } from './type'
import moment from 'moment'

const { TabPane } = Tabs

interface TabProps {
  currency: string
  currencyMarketPalace?: string
  transactions: ShipmentTransactionInterface[]
  loading: boolean
  packages: ShipmentPackageInterface[]
  loadingPackages: boolean
  claims: ClaimInterface[]
  loadingClaim: boolean
  shipment?: ShipmentInterface
  productUpdatable: boolean
  createAbleWaybill: boolean
  shipmentStatuses: any
}
interface PropsProductTab {
  label: string
  total?: number
  isActive: boolean
}
export const CustomerTabTitle: React.FC<PropsProductTab> = ({ label, total, isActive }) => {
  return (
    <span className="dpl-flex align-items-center">
      <span className={`mgr4 fsz-14 line-h-24`}>{label}</span>
      <Badge
        count={total}
        overflowCount={99999}
      />
    </span>
  )
}

const TabLayout: React.FC<TabProps> = (props) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { code } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loadingCreateWaybill, setLoadingCreateWaybill] = useState(false)

  const [visibleCreateProduct, setVisibleCreateProduct] = useState(false)
  const [visibleCreateWaybill, setVisibleCreateWaybill] = useState(false)
  const [error, setError] = useState('')
  const inputRef = useRef<InputRef>(null)
  const [disabled, setDisabled] = useState(true)
  const [waybillFromShipment, setWaybillFromShipment] = useState<any>([])
  const waybill = Form.useWatch('waybill', form)

  const {
    currency,
    currencyMarketPalace,
    transactions,
    packages,
    loading,
    loadingPackages,
    loadingClaim,
    claims,
    shipment,
    productUpdatable,
    createAbleWaybill,
    shipmentStatuses,
  } = props

  const [currentTabKey, setCurrentTabKey] = useState('TAB_PRODUCT')

  const productQuery = useQuery([shipment?.code], {
    queryFn: ({ queryKey }: any) => shipmentRepository.getShipmentProducts(queryKey[0]),
    refetchOnWindowFocus: false,
  })
  const products = productQuery.data ?? []
  const handleChangeTab = (activeKey: string) => {
    setCurrentTabKey(activeKey)
    setSearchParams({ tab: activeKey })
    scrollToElementId('tabs-container')
  }

  const refetch = useCallback(() => {
    setTimeout(() => {
      productQuery.refetch()
    }, 500)
  }, [productQuery])

  useEffect(() => {
    if (searchParams.get('tab')) {
      setCurrentTabKey(searchParams.get('tab')!)
    }
  }, [searchParams])

  useEffect(() => {
    if (visibleCreateWaybill) {
      setTimeout(() => inputRef.current!.focus({ cursor: form.getFieldValue('waybill') ? 'end' : 'start' }), 0)
    }
  }, [error, form, visibleCreateWaybill])

  const createProductHandler = () => {
    setVisibleCreateProduct(true)
  }

  const visibleCreateProductHandler = (val: boolean) => {
    setVisibleCreateProduct(val)
  }

  const fetchWaybills = useCallback(async () => {
    try {
      const res: IWaybill[] = await shipmentRepository.getWaybills(code!)
      setWaybillFromShipment(res?.sort((a, b) => (moment(a.createdAt) > moment(b.createdAt) ? -1 : 1)))
    } catch (err) {
      console.log('err')
    }
  }, [code])

  useEffect(() => {
    fetchWaybills()
  }, [fetchWaybills])

  const createWayBillHandler = useCallback(
    async (force: boolean, values: any) => {
      setLoadingCreateWaybill(true)
      try {
        await shipmentRepository.createWaybill(code!, { ...values, forceCreate: force })
        M24Notification.success({ message: t('shipment.createWaybillsSuccess') })
        refetch()
        setError('')
        setVisibleCreateWaybill(false)
        form.resetFields()
      } catch (err: any) {
        if (err.response.status !== 500) {
          setError(err.response.data.title)
        } else {
          M24Notification.error({ message: 'Đã có lỗi xảy ra!' })
        }
      } finally {
        setLoadingCreateWaybill(false)
        if (force) {
          setVisibleCreateWaybill(false)
        }
        fetchWaybills()
      }
    },
    [code, t, refetch, form, fetchWaybills]
  )

  useEffect(() => {
    return () => {
      setError('')
    }
  }, [])

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!error) {
          createWayBillHandler(false, values)
        }
        if (error === 'waybill_existed_in_other_user') {
          createWayBillHandler(true, values)
        }
      })
      .catch((info) => {})
  }
  return (
    <>
      <div id="tabs-container">
        <M22Box className="pd-0">
          <Col
            span={24}
            className="pd-12 box-order mgt12 txt-color-gray fsz-16 border-radius4">
            <Tabs
              className={`m22-tabs ${theme}`}
              activeKey={currentTabKey}
              onChange={handleChangeTab}
              tabBarExtraContent={{
                right:
                  currentTabKey === TAB_TICKET ? (
                    <Button
                      type="ghost"
                      icon={<i className="mg-r-10 fa-solid fa-plus"></i>}
                      onClick={() =>
                        navigate(`/claims/create?shipmentsCode=${code!}&back=${getUrlPathname(window.location)}`)
                      }>
                      {t('tickets.createTickets')}
                    </Button>
                  ) : currentTabKey === TAB_PRODUCT && productUpdatable ? (
                    <Button
                      type="ghost"
                      icon={<i className="mg-r-10 fa-solid fa-plus"></i>}
                      onClick={createProductHandler}>
                      {t('shipment.createProducts')}
                    </Button>
                  ) : currentTabKey === TAB_TRANSPORT && createAbleWaybill ? (
                    <Button
                      className={`mg-l-12`}
                      type="ghost"
                      disabled={waybillFromShipment?.length >= 10 ? true : false}
                      icon={<i className="mg-r-10 fa-solid fa-plus"></i>}
                      onClick={() => {
                        setVisibleCreateWaybill(true)
                        setError('')
                      }}>
                      {t('shipment.createWaybills')}
                    </Button>
                  ) : null,
              }}>
              <TabPane
                tab={
                  <CustomerTabTitle
                    label={t('orderDetail.tabProduct')}
                    total={products.length}
                    isActive={currentTabKey === 'TAB_PRODUCT'}
                  />
                }
                key={TAB_PRODUCT}>
                <Products
                  loading={loading}
                  items={products}
                  currency={currency}
                  currencyMarketPalace={currencyMarketPalace}
                  refetch={refetch}
                  productUpdatable={productUpdatable}
                />
              </TabPane>
              <TabPane
                tab={
                  <CustomerTabTitle
                    label={t('shipment.tabTransaction')}
                    total={transactions.length}
                    isActive={currentTabKey === TAB_TRANSACTION}
                  />
                }
                key={TAB_TRANSACTION}>
                <Transactions items={transactions} />
              </TabPane>
              <TabPane
                tab={
                  <CustomerTabTitle
                    label={t('shipment.tabPackages')}
                    total={packages.length}
                    isActive={currentTabKey === 'TAB_TRANSPORT'}
                  />
                }
                key={TAB_TRANSPORT}>
                <Packages
                  fetchWaybills={fetchWaybills}
                  waybillFromShipment={waybillFromShipment}
                  shipmentStatuses={shipmentStatuses}
                  shipment={shipment}
                  packages={packages}
                  loading={loadingPackages}
                  waybills={shipment?.waybills}
                  refetch={refetch}
                  provider={shipment?.provider}
                />
              </TabPane>
              <TabPane
                tab={
                  <CustomerTabTitle
                    label={t('shipment.tabClaims')}
                    total={claims?.length}
                    isActive={currentTabKey === 'TAB_TICKET'}
                  />
                }
                key={TAB_TICKET}>
                <Claims
                  items={claims}
                  loading={loadingClaim}
                  currency={currency}
                  shipment={shipment}
                />
              </TabPane>
            </Tabs>
          </Col>
        </M22Box>
      </div>
      <CreateProductModal
        visible={visibleCreateProduct}
        visibleHandler={visibleCreateProductHandler}
        refetch={refetch}
        type="create"
      />

      <Modal
        open={visibleCreateWaybill}
        title={t('shipment.createWaybills')}
        okText={t('button.submit')}
        cancelText={t('button.cancel')}
        onCancel={() => {
          setVisibleCreateWaybill(false)
          form.resetFields()
        }}
        centered
        cancelButtonProps={{
          className: 'm22-btn',
          type: 'text',
          icon: <i className="fa-solid fa-xmark mg-r-10" />,
        }}
        confirmLoading={loadingCreateWaybill}
        okButtonProps={{
          type: 'primary',
          icon: <i className="fa-solid fa-check mg-r-10"></i>,
          disabled,
          className: 'm22-btn',
        }}
        width={500}
        onOk={onSubmit}
        className="shipment-modal-create-waybill">
        <Form
          form={form}
          name="create-waybill"
          initialValues={{ waybill: '' }}
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 18 }}
          onFieldsChange={() => {
            setDisabled(form.getFieldsError().some((field) => field.errors.length > 0) || !!error)
          }}>
          <ComboBox
            labelClassName="22px"
            value={waybill}
            label={t('shipment.createWaybillsPlaceholder')}>
            <Form.Item
              name="waybill"
              hasFeedback
              className="width100pc"
              rules={[
                { required: true, message: 'Không được bỏ trống!' },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9_-]+$/gm),
                  message: 'Chứa kí tự không hợp lệ!',
                },
              ]}>
              <Input
                ref={inputRef}
                onChange={() => setError('')}
                size="large"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit()
                  }
                }}
              />
            </Form.Item>
          </ComboBox>
        </Form>
        {error && (
          <div className={`create-waybills-${error === 'waybill_existed' ? 'error' : 'warning'}`}>
            {error === 'waybill_existed' && <i className="fa-solid fa-circle-exclamation mg-r-4 "></i>}
            {error === 'waybill_existed_in_other_user' && (
              <i
                className="cursor-pointer fa-solid fa-triangle-exclamation mg-r-4"
                onClick={() => setError('')}></i>
            )}
            <span className="fsz-14 line-h-20">{t(`shipments.${error}`)}</span>
            {/* <i
							className={`fa-solid fa-xmark cursor-pointer ${
								error === 'waybill_existed' ? 'txt-color-red4' : 'txt-color-orange3'
							}`}
							onClick={() => setError('')}></i> */}
          </div>
        )}
      </Modal>
    </>
  )
}

export default TabLayout
