import { GetComponentProps } from 'rc-table/lib/interface'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Services from '../../../components/Services'
import { useTheme } from '../../../hooks/useTheme'
import { useNavigate } from 'react-router-dom'

interface RowExtendedProps extends GetComponentProps<any> {
  services: any
  index: number
  className: string
  rowClassesByIdx: string
  code: string
}

const RowExtended: React.FC<RowExtendedProps> = (record) => {
  const { t } = useTranslation()
  const [servicesHovered, setServicesHovered] = useState(false)
  const [theme] = useTheme()
  const navigate = useNavigate()
  const [rowClassName, setRowClassName] = useState<any>('')

  useEffect(() => {
    switch (theme) {
      case 'light':
        if (servicesHovered) {
          setRowClassName('rowLightOnHover')
        } else {
          setRowClassName('')
        }
        break
      default:
        if (servicesHovered) {
          setRowClassName('rowDarkOnHover')
        } else {
          setRowClassName('')
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesHovered])

  // @ts-ignore
  return (
    <>
      <tr
        onMouseOver={() => setServicesHovered(true)}
        onMouseLeave={() => setServicesHovered(false)}
        onClick={() => {
          navigate(`/orders/${record?.code}`)
        }}
        className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row ${theme} ${rowClassName}`}>
        {record.children}
      </tr>
      <tr
        id="row-extend"
        onClick={() => {
          navigate(`/orders/${record?.code}`)
        }}
        className={`${record.rowClassesByIdx} ${rowClassName} data-row cursor-pointer ant-table-row data-row-extended ${theme}`}
        onMouseOver={() => setServicesHovered(true)}
        onMouseLeave={() => setServicesHovered(false)}>
        <td
          colSpan={6}
          className="px-16 pd-12 pd-bt-10 row-extended pd-t-4">
          <div className="flex align-items-center">
            {record?.services?.length > 0 && (
              <Services
                label={t('orders.services')}
                data={record.services}
                className="fsz-14 line-h-22"
                wrapperClass="flex-nowrap"
              />
            )}
          </div>
        </td>
      </tr>
      <tr className="h-8"></tr>
    </>
  )
}

export default RowExtended
