import { Avatar, Popover } from 'antd'
import React, {useContext, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import lodash from 'lodash'
import { t } from 'i18next'
import { useTheme } from '../../../hooks/useTheme'
import {AppContext} from "../../../context/AppContext";
import {localStorageRead} from "../../../utils/LocalStorageUtils";
import {PROFILE_KEY} from "../../../core/config";
interface UserActionPopoverProps {
	visible: boolean
	logoutHandler: () => void
}
const DropdownUserActions: React.FC<UserActionPopoverProps> = (props) => {
	const navigate = useNavigate()
	const [theme, ] = useTheme()
	const { visible, logoutHandler } = props;
	const [popoverIsOpen,setPopoverIsOpen] = useState(visible)
	const appContext = useContext(AppContext);
	const [userProfile, setUserProfile] = useState(localStorageRead(PROFILE_KEY))
  const handleChangeVisible = (visible: any) => {
    setPopoverIsOpen(visible)
  }

  const getUserProfile = (profile: any) => {
    setUserProfile(profile)
  }

  const updateAppContextFncValue = () => {
    let contextValues = { ...appContext }
    contextValues.fnc.getUserProfile = getUserProfile
    return { ...contextValues }
  }

  return (
    <AppContext.Provider value={updateAppContextFncValue()}>
      <div className={'dpl-flex justify-content-end align-items-center'}>
        <span
          className={'_user-name txt-size-h7 cursor-pointer mg-l-8 mg-r-12'}
          onClick={() => {
            handleChangeVisible(!popoverIsOpen)
          }}
          style={{ display: 'flex', justifyContent: 'flex-end', paddingInlineStart: 24 }}>
          {lodash.get(userProfile, 'name', null)}
        </span>
        <Popover
          open={popoverIsOpen}
          onVisibleChange={handleChangeVisible}
          placement="bottomLeft"
          arrowPointAtCenter
          overlayClassName={`user-actions ${theme}`}
          content={
            <div className="flex flex-col justify-content-center pd-bt-12">
              <div
                className={`user-actions_item-${theme}`}
                onClick={() => navigate('/profile')}>
                <i className="fa-regular fa-user mg-r-10 popover-txt"></i>
                <span className="popover-txt">{t('menu.profile')}</span>
              </div>
              <div
                className={`user-actions_item-${theme} logout-action`}
                key={'logout'}>
                <span
                  onClick={logoutHandler}
                  className="_logout-btn  cursor-pointer flex align-items-center txt-primary">
                  <i className="fa-regular fa-arrow-right-from-bracket mg-r-10"></i>
                  <span className="">{t('menu.logout')}</span>
                </span>
              </div>
            </div>
          }
          trigger="click">
          <Avatar
            src={lodash.get(userProfile, 'avatar', null)}
            className={'_avatar-1 cursor-pointer'}
            size={36}
          />
        </Popover>
      </div>
    </AppContext.Provider>
  )
}

export default DropdownUserActions
