//ORDER_COMMENT
//chatBox.threadTypeOrder
import { Avatar } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxChatContext } from '../../context/BoxChatContext'
import { CLAIM_COMMENT, ORDER_COMMENT, SHIPMENT_COMMENT } from '../../core/config'
import { ThreadItemInterface } from '../../Interface/ThreadItemInterface'
import { ThreadMessageInterface } from '../../Interface/ThreadMessageInterface'

interface Props {
  thread: ThreadItemInterface
}

export const getThreadType = (t: any, thread: any) => {
  if (thread.type === ORDER_COMMENT) {
    return (
      <>
        {t('chatBox.threadTypeOrder')} #{thread.referenceCode}
      </>
    )
  } else if (thread.type === CLAIM_COMMENT) {
    return (
      <>
        {t('chatBox.threadTypeClaim')} #{thread.referenceCode}
      </>
    )
  } else if (thread.type === SHIPMENT_COMMENT) {
    return (
      <>
        {t('chatBox.threadTypeShipment')} #{thread.referenceCode}
      </>
    )
  }
  return 'Unknown'
}

export const ThreadItem = (props: Props) => {
  const { thread } = props
  const { t } = useTranslation()
  const boxChatContext = useContext(BoxChatContext)

  const handleSelectThread = () => boxChatContext.onSelectThread(thread)

  const renderContent = (message: ThreadMessageInterface) => {
    if (message.content) {
      return thread.lastMessage.customer && thread.lastMessage.username !== 'admin'
        ? t('chatBox.lastMessageMe') + ': ' + message.content
        : t('chatBox.lastMessageStaff') + ': ' + message.content
    }

    // TODO: Hot fix cho backend do chua lay duoc lastmessage ben M3. cần sửa sau khi BE sửa
    if (message?.attachments?.length > 0) {
      return thread.lastMessage.customer
        ? t('chatBox.lastMessageMeAttachment').replace('%s', message.attachments.length.toString())
        : t('chatBox.lastMessageStaffAttachment', { value: message.attachments.length.toString() })
    }

    if (!thread.lastMessage.customer) {
      return t('chatBox.lastMessageStaffAttachment', { value: 1 })
    }
  }

  return (
    <div
      className={`box-chat-thread-item ${thread.id === boxChatContext.thread.id ? 'selected' : ''} ${thread.isRead ? 'read' : ''}`}
      onClick={() => handleSelectThread()}>
      <Avatar
        src={lodash.get(thread, 'thumbnail') || 'error'}
        size={32}
      />
      <div>
        <div className="thread-order dpl-flex justify-space-between">
          <span>{getThreadType(t, thread)}</span>

          <span>{!thread.isRead && <i className="thread-dot fa-solid fa-circle-small txt-size-h7" />}</span>
        </div>

        {lodash.get(thread, 'lastMessage.timestamp') && (
          <div style={{ display: 'grid', flexDirection: 'column' }}>
            <div className="thread-message">{renderContent(thread.lastMessage)}</div>

            <div className="thread-time">{moment(thread.lastMessage.timestamp).format('HH:mm DD/MM/YYYY')}</div>
          </div>
        )}
      </div>
    </div>
  )
}
