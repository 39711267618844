import { Avatar, Col, Row, Tooltip } from 'antd'
import lodash from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { EMPTY_INFO } from '../../core/config'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { useTheme } from '../../hooks/useTheme'
import M22Box from '../M22Box'
import ParagraphWithCopy from '../ParagraphWithCopyButton'
import Services from '../Services'
import './styles.scss'

export interface ItemProps {
  statuses: any
  item: any
  idx?: number
}

function Item(props: ItemProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [theme] = useTheme()
  const { statuses, item, idx } = props
  const [status] = useState<any>(statuses.find((x: any) => x.code === item.status))

  return (
    <M22Box className={'mg-bt-8'}>
      <Row
        key={item.id}
        className={`_order-item ${theme} rad-12 flex px-16 py-16 ${
          idx! % 2 === 0 ? `${theme === 'light' ? 'row-light' : ''}` : `${theme === 'light' ? 'row-dark-light-mode' : 'theme-row-dark'}`
        }`}
        onClick={() => navigate(`/orders/${item.code}`)}>
        <Col
          flex={'0 0 auto'}
          className="mg-r-12">
          <Link to={`/orders/${item.code}`}>
            <Avatar
              shape={'square'}
              size={84}
              src={lodash.get(item, 'image', '')}
              className={'rad-6'}
              style={{ border: 'none' }}
            />
          </Link>
        </Col>
        <div
          className=""
          style={{ width: 'calc(100% - 96px)' }}>
          <Row>
            <Col span={8}>
              <Row
                align="middle"
                className={'mg-bt-4 flex-nowrap mg-bt-6'}>
                <Col
                  flex={'0 0 350px'}
                  className="flex align-items-center">
                  <span className={'align-items-center txt-color-primary mg-r-16'}>
                    <Link to={`/orders/${item.code}`}>
                      <ParagraphWithCopy
                        text={item.code}
                        children={item.code ? item.code : '---'}
                      />
                    </Link>
                  </span>
                  <span className={'fsz-14  mg-r-16  flex align-items-center'}>
                    <Tooltip
                      className={'mgl4 txt-size-h9'}
                      title={t('orders.tooltipQuantity')}>
                      <span className="fsz-14 ">{`${lodash.get(item, 'orderedQuantity', '--')}/${
                        item.purchasedQuantity !== null ? item.purchasedQuantity : '--'
                      }/${item.receivedQuantity != null ? item.receivedQuantity : '--'}`}</span>
                    </Tooltip>
                  </span>

                  <span className={`fsz-14 ${theme === 'dark' ? 'txt-color-gray7' : ''} flex align-items-center`}>
                    <Tooltip title={t('orders.tooltipCreatedAt')}>{formatDateTime(lodash.get(item, 'createdAt', '--'))}</Tooltip>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Col className={'flex align-items-center mg-bt-6'}>
                <span className={' fsz-14 line-h-22  whitespace'}>
                  <span className={` ${theme === 'dark' ? 'm22-typo-label_gray-primary' : 'txt-color-gray7'}   whitespace`}>
                    {t('orders.finance')} : &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="robotomedium mgl3">{`${formatMoneyByUnit(lodash.get(item, 'provisionalAmount', ''))}`} </span>
                </span>
              </Col>
              <Col className={'flex align-items-center '}>
                <span className={'fsz-14 line-h-22  whitespace'}>
                  <span className={` ${theme === 'dark' ? 'm22-typo-label_gray-primary' : 'txt-color-gray7'}   whitespace`}>
                    {t('orders.needPaid')}: &nbsp;
                  </span>
                  <span className={'robotomedium m22-typo-money m22-typo-money-minus fsz-14 line-h-22'}>{`${
                    item.status !== 'CANCELLED' ? formatMoneyByUnit(lodash.get(item, 'totalUnpaid', '')) : t('orders.cancelled')
                  }`}</span>
                </span>
              </Col>
            </Col>
            <Col span={7}>
              <span className={` ${theme === 'dark' ? 'm22-typo-label_gray-primary' : 'txt-color-gray7'}  mg-r-3 whitespace`}>{t('orders.note')}: </span>
              {item.personalNote && (
                <Tooltip title={item.personalNote}>
                  <span className={'fsz-14 line-h-22  personal-note  w-200'}>{item.personalNote.slice(0, 70).trim() + '...'}</span>
                </Tooltip>
              )}
              {!item.personalNote && <span className={'fsz-14 line-h-22  personal-note  w-200'}> {EMPTY_INFO}</span>}
            </Col>
            <Col
              span={4}
              className="flex align-items-center justify-content-end">
              <span
                style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
                className={'rad-25 item-align-center txt-color-white fsz-14 line-h-22  px-16 py-4'}>
                {lodash.get(status, 'name', '--')}
              </span>
            </Col>
          </Row>
          <div
            style={{
              borderTop: ` 1px solid ${theme === 'dark' ? '#333333' : '#EBEBEB'}`,
              margin: '6px 0',
            }}
          />
          <Row>
            <span className={`fsz-14 line-h-22 txt-color-${theme === 'light' ? 'secondary' : 'gray2'} mgr3 whitespace`}>{t('orders.services')}:</span>
            <Services
              data={item.services}
              className="fsz-14 line-h-22"
              wrapperClass=""
            />
          </Row>
        </div>
      </Row>
    </M22Box>
  )
}

export default Item
