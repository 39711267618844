/**
 * Scroll đến 1 element qua id
 * @param id Id của element
 * @returns void
 */
export const scrollToElementId = (id: string) => {
  const scroll = () => {
    const tabs = document.getElementById(id)
    if (tabs) {
      const y = tabs.getBoundingClientRect().top + window.scrollY - 64 - 16
      window.scroll({
        top: y,
        behavior: 'smooth',
      })
    }
  }

  // dùng setTimeout để hàm sẽ vào ngăn xếp sau khi DOM được render lại
  setTimeout(scroll, 0)
}
