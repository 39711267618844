import { Button, Form, Image, notification } from 'antd'
import axios from 'axios'
import lodash from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import { FloatingLabel } from 'src/components/FloatingLabel'
import { REGISTER_INTERNAL_URL, TENANT_KEY } from 'src/core/config'
import { localStorageRead } from 'src/utils/LocalStorageUtils'

export const Register = (props: any) => {
  const [form] = Form.useForm()
  const [isSubmit, setIsSubmit] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate()

  const onFinish = () => {
    handleRegisterUser()
  }

  const handleRegisterUser = () => {
    const tenant = localStorageRead(TENANT_KEY)
    form.validateFields().then((values) => {
      let data: any = {
        ...values,
      }
      setIsSubmit(true)
      axios
        .post(REGISTER_INTERNAL_URL!, data, {
          headers: {
            'X-Tenant': lodash.get(tenant, 'code'),
          },
        })
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err: any) => {
          let err_type = err.response?.data?.detail?.split(/:/)[0].split(/`/)[1]
          let errorContent: any = {}
          if (err_type === 'duplicate_user') {
            errorContent = { message: 'Đăng ký không thành công', description: `Username ${data.username} đã tồn tại. Vui lòng thử lại` }
          } else if (err_type === 'duplicate_email') {
            errorContent = { message: 'Đăng ký không thành công', description: `Email ${data.email} đã được sử dụng. Vui lòng thử lại` }
          } else {
            errorContent = { message: 'Đã có lỗi xảy ra. Vui lòng thử lại sau' }
          }
          notification['error']({
            ...errorContent,
            key: 'register-failed',
          })
        })
        .finally(() => {
          setIsSubmit(false)
        })
    })
  }

  return (
    <div className="register-container">
      {!isSuccess ? (
        <div className="flex flex-col gap-3 register-form">
          <div className="flex items-center">
            <Image
              src={'/logo.png'}
              width={80}
              preview={false}
            />
          </div>

          <h1 className="mb-7 text-[2rem]">Đăng ký</h1>

          <Form
            className="flex flex-col gap-2"
            layout="vertical"
            form={form}
            autoComplete="one-time-code"
            autoCorrect="off"
            autoSave="off"
            onFinish={onFinish}>
            <FloatingLabel
              name="username"
              label={'Tên đăng nhập'}
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập' },
                { max: 20, message: 'Tối đa 20 ký tự' },
                { min: 6, message: 'Tối thiểu 6 ký tự' },
                {
                  pattern: /^[A-Za-z0-9]+$/,
                  message: 'Tên đăng nhập không được chứa ký tự đặc biệt',
                },
                () => ({
                  validator(_, value) {
                    if (!value || !/^[0-9]*$/.test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Tên đăng nhập phải có ít nhất một ký tự chữ'))
                  },
                }),
              ]}
              form={form}
              inputProps={{ autoFocus: true, autoComplete: 'one-time-code' }}
            />

            <FloatingLabel
              name="email"
              label={'Email'}
              rules={[
                { required: true, message: 'Vui lòng nhập e-mail' },
                {
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: 'Sai định dạng: example@company.com',
                },
              ]}
              form={form}
            />

            <FloatingLabel
              label="Mật khẩu"
              name="password"
              required
              rules={[
                { required: true, message: 'Vui lòng nhập mật khẩu' },
                { max: 32, message: 'Mật khẩu phải có độ dài tối đa từ 6 đến 32 ký tự ' },
                { min: 6, message: 'Mật khẩu phải có độ dài tối đa từ 6 đến 32 ký tự ' },
              ]}
              form={form}
              type="password"
              inputProps={{ autoComplete: 'one-time-code' }}
            />

            <FloatingLabel
              label="Nhập lại mật khẩu"
              name="rePassword"
              required
              rules={[
                { required: true, message: 'Vui lòng nhập lại mật khẩu' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Mật khẩu phải trùng nhau!!!'))
                  },
                }),
              ]}
              form={form}
              type="password"
              inputProps={{ autoComplete: 'one-time-code' }}
            />

            <Form.Item
              shouldUpdate
              className="submit">
              {() => (
                <Button
                  size="large"
                  className="w-full h-[46px] mt-3"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmit}
                  disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}>
                  Đăng ký
                </Button>
              )}
            </Form.Item>

            <div className="text-center pb-6">
              <span>
                <span className="text-secondary-2">Tôi đã có tài khoản!!</span>{' '}
                <Link
                  to="/login"
                  className="underline">
                  Đăng nhập
                </Link>
              </span>
            </div>
          </Form>
        </div>
      ) : (
        <div className="flex flex-col register-success">
          <div className="flex items-center">
            <Image
              src={'/logo.png'}
              width={48}
            />
          </div>
          <h1 className="mb-7 text-[2rem]">Đăng ký thành công</h1>
          <p>
            Cảm ơn bạn vì đã chọn chúng tôi là đối tác đáng tin cậy trong việc mua sắm trực tuyến từ Trung Quốc. Chúc bạn có một trải nghiệm tuyệt vời và thành
            công trong kinh doanh của mình!!!
          </p>

          <Button
            size="large"
            className="w-full h-[46px] mt-4"
            type="primary"
            onClick={() => {
              navigate('/login')
            }}>
            Đăng nhập ngay
          </Button>
        </div>
      )}
    </div>
  )
}
