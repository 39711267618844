import React from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Tooltip } from 'antd'

import './style.scss'
import { useTheme } from '../../hooks/useTheme'

interface ParagraphWithCopyProps {
  children: any
  text: string
  className?: string
}

const ParagraphWithCopy: React.FC<ParagraphWithCopyProps> = ({ children, text, className }: ParagraphWithCopyProps): JSX.Element => {
  const [theme] = useTheme()
  return (
    <Paragraph
      copyable={{
        text,
        icon: [
          <Tooltip title={'Copy'}>
            <i className={`far fa-copy line-h-14 w-12 txt-color-${theme === 'light' ? 'gray8' : 'gray2'}`}></i>
          </Tooltip>,
          <Tooltip title={'Copied'}>
            <i className={`far fa-check fsz-12 line-h-14 w-12 txt-color-${theme === 'light' ? 'gray' : 'primary'}`}></i>
          </Tooltip>,
        ],
        tooltips: [false, false],
      }}
      className={`text-copy ${theme}`}>
      <span className={className ? className : ''}>{children}</span>
    </Paragraph>
  )
}

export default ParagraphWithCopy
