/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined } from '@ant-design/icons'
import { Alert, Avatar, Button, Card, Col, Divider, Drawer, List, Row, Spin, Tooltip } from 'antd'
import { Select } from 'antd/lib/index'
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TENANT_KEY } from '../../../core/config'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import ServiceGroupedInterface from '../../../Interface/ServiceGroupedInterface'
import ServiceInterface from '../../../Interface/ServiceInterface'
import { cartRepository } from '../../../repositories/CartRepository'
import { orderRepository } from '../../../repositories/OrderRepository'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import { localStorageRead } from '../../../utils/LocalStorageUtils'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import InlineEdit from '../../InlineEdit'
import M22Box from '../../M22Box'
import ConfirmModal from '../../Modal/ConfirmModal'
import Address from '../Address/AddressList'
import CreateAddressModal from '../Address/CreateAddressModal'
import Services from '../Services'
import Product from './Product'
import './styles.scss'
import EmptyDataFilter from './../../Empty/EmptyDataFilter'

export interface Step2Props {
  merchant: any
  setMerchant: (merchant: any) => void
  setStep: (value: number) => void
  accountBalance: any[]
  handleCreateOrder: (body: any, callback: () => void) => void
}

function Step2(props: Step2Props) {
  const { merchant, accountBalance, setMerchant, setStep, handleCreateOrder } = props
  const { t } = useTranslation()
  const [theme] = useTheme()
  const [accountDefault, setAccountDefault] = useState<any>()
  const [isFirst] = useState<boolean>(true)
  const [exchangeRate, setExchangeRate] = useState<any>()
  const [totalPrice, setTotalPrice] = useState<number>()
  const [services, setServices] = useState<ServiceInterface[]>([])
  const [feeCategories, setFeeCategories] = useState<any>([])
  // const [expressDelivery, setExpressDelivery] = useState(false)

  const [currentServiceSelected, setCurrentServiceSelected] = useState<ServiceInterface[]>([])
  const [serviceSelectedCache, setServiceSelectedCache] = useState<any>({})
  const [serviceGroups, setServiceGroups] = useState<ServiceGroupedInterface[]>([])
  const [favoriteServices, setFavoriteServices] = useState<any>([])
  const [favoriteUpdating, setFavoriteUpdating] = useState(false)
  const [confirmFavorite, setConfirmFavorite] = useState(false)
  const [noticeFavoriteService, setNoticeFavoriteService] = useState<any>()

  const [currency, setCurrency] = useState<string>()
  const [showDrawer, setShowDrawer] = useState(false)
  const [employeeNote, setEmployeeNote] = useState<string>('')
  const [personalNote, setPersonalNote] = useState<string>('')
  const [fees, setFees] = useState<any>()
  const [noticeWarehouse, setNoticeWarehouse] = useState<any>()
  const [openAddressList, setOpenAddressList] = useState<boolean>(false)
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)
  const [addressList, setAddressList] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false)
  const [selectedAddress, setSelectedAddress] = useState<any>()
  const [deliveryAddress, setDeliveryAddress] = useState<any>(null)
  const [addressSupported, setAddressSupported] = useState<boolean>(false)
  const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState()
  const [isAllow, setIsAllow] = useState(true)
  const [isCreateAddress,setIsCreateAddress] = useState(false)

  useEffect(() => {
    getAddressList()
    getFavoriteServices()
  }, [])

  useEffect(() => {
    setCurrentConfigShowBalance(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', ''))
  }, [])

  useEffect(() => {
    setCurrency(lodash.get(merchant, 'products[0].skus[0].currency', ''))
    if (accountBalance && accountBalance.length > 0) {
      let accDefault = accountBalance.find((x) => x.connection.defaultConnection)
      if (accDefault) {
        setAccountDefault(accDefault)
      } else {
        setAccountDefault(accountBalance[0])
      }
    }
  }, [])

  useEffect(() => {
    if (accountDefault) {
      getExchangeRate(accountDefault)
      getServices()
      getOrderFees()
    }
  }, [accountDefault])

  useEffect(() => {
    if (accountDefault) {
      let tempService = lodash.get(serviceSelectedCache, accountDefault.connection.id, [])
      if (!tempService || tempService.length === 0) {
        tempService = services.filter((xx: any) => xx.defaultApplied)
        let temp = lodash.cloneDeep(serviceSelectedCache)
        let favorite = favoriteServices.find(
          (x: any) => x.provider === accountDefault.connection.provider.code && x.customer === accountDefault.connection.name
        )
        if (favorite) {
          let isConflict = false
          let items: any = []
          favorite.services.forEach((x: string) => {
            let temp = services.find((xx: any) => xx.code === x)
            if (!temp) isConflict = true
            else items.push(temp)
          })
          if (!isConflict) {
            setCurrentServiceSelected(items)
            temp[accountDefault.connection.id] = items
            setServiceSelectedCache(temp)
          } else {
            setNoticeFavoriteService(true)
            temp[accountDefault.connection.id] = tempService
            setServiceSelectedCache(temp)
            setCurrentServiceSelected(tempService)
          }
        } else {
          temp[accountDefault.connection.id] = tempService
          setServiceSelectedCache(temp)
          setCurrentServiceSelected(tempService)
        }
      } else {
        setCurrentServiceSelected(tempService)
      }
    }
  }, [services])

  const getFavoriteServices = () => {
    cartRepository
      .getFavoriteServices()
      .then((res) => {
        setFavoriteServices(res)
      })
      .catch((err) => { })
  }
  const updateFavoriteService = () => {
    setFavoriteUpdating(true)
    let items: string[] = []
    currentServiceSelected.map((x: any) => items.push(x.code))
    let body: any = {
      services: items,
      provider: lodash.get(accountDefault, 'connection.provider.code', ''),
      providerUsername: lodash.get(accountDefault, 'connection.name', ''),
    }
    cartRepository
      .updateFavoriteServices(body)
      .then((res) => {
        M24Notification.messageSuccess(t('message.saveFavoriteServiceSuccess'))
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
        getFavoriteServices()
      })
      .finally(() => {
        setFavoriteUpdating(false)
        setConfirmFavorite(false)
      })
  }
  const validAddress = useCallback(() => {
    setIsLoading(true)
    setAddressSupported(false)
    if (!accountDefault || !deliveryAddress) return
    cartRepository
      .checkAddressSupport({
        location: deliveryAddress.location,
        provider: lodash.get(accountDefault, 'connection.provider.code', ''),
      })
      .then((response) => {
        setAddressSupported(true)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err, {
          value: lodash.get(accountDefault, 'connection.provider.name', ''),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
      
  }, [accountDefault, deliveryAddress, t])

  useEffect(() => {
    if (exchangeRate) {
      let totalPrice: number = 0
      let totalSku = 0
      merchant.products.forEach((product: any) => {
        let total: number = 0
        let price: any = 0
        product.skus.forEach((xx: any) => {
          total = total + xx.quantity
          totalSku = totalSku + xx.quantity
        })
        if (product.pricePolicies && product.pricePolicies.length > 0) {
          let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
          if (total < pricePolicies[0].minQuantity) {
            price = pricePolicies[0].salePrice
          } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
            price = pricePolicies[pricePolicies.length - 1].salePrice
          } else {
            pricePolicies.forEach((x: any) => {
              if (total >= x.minQuantity && total <= x.maxQuantity) {
                price = x.salePrice
              }
            })
          }
          totalPrice += total * price
        } else {
          product.skus.forEach((xx: any) => {
            totalPrice += xx.salePrice * xx.quantity
          })
        }
        setTotalPrice(totalPrice)
      })
    }
  }, [exchangeRate])
  const [totalSKU, setTotalSKU] = useState<number>(0)
  const [totalLink, setTotalLink] = useState<number>(0)

  const getExchangeRate = (account: any) => {
    if (currency) {
      supplierRepository
        .getExchangeRateByAccount(currency, account?.connection?.id)
        .then((res) => {
          if (res.length > 0) setExchangeRate(res[0])
        })
        .catch((err) => {
          M24ErrorUtils.showError(t, err)
        })
    }
  }
  const getOrderFees = () => {
    orderRepository
      .getOrderFees(lodash.get(accountDefault, 'connection.name', ''), lodash.get(accountDefault, 'connection.provider.code', ''))
      .then((res) => {
        setFeeCategories(res)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
  }
  const getServices = () => {
    orderRepository
      .getServices(lodash.get(accountDefault, 'connection.name', ''), lodash.get(accountDefault, 'connection.provider.code', ''))
      .then((res) => {
        let otherServices = lodash.sortBy(
          res.filter((x: any) => !x.serviceGroup),
          'position'
        )
        let services = lodash.sortBy(
          res.filter((x: any) => x.serviceGroup),
          'position'
        )
        let items = lodash
          .chain(services)
          .groupBy('serviceGroup.code')
          .map((value, key) => {
            let group = value[0].serviceGroup
            return { ...group, services: value }
          })
          .value()
        let temp: any = []
        temp.push({
          code: 'other',
          name: t('shopping-cart.otherService'),
          services: otherServices,
        })
        temp = temp.concat(items)
        setServices(res)
        setServiceGroups(temp)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
  }
  const estimateFee = useCallback(() => {
    if (!accountDefault) return
    setNoticeWarehouse(null)
    let body: any = {
      merchantId: lodash.get(merchant, 'merchant.id'),
    }
    let services: string[] = []
    currentServiceSelected.forEach((x: any) => {
      services.push(x.code)
    })
    body.services = services
    let skus: any = []
    merchant.products.forEach((product: any) => {
      let total: number = 0
      let price: any = 0
      product.skus.forEach((sku: any) => {
        total = total + sku.quantity
      })
      if (product.pricePolicies && product.pricePolicies.length > 0) {
        let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
        if (total < pricePolicies[0].minQuantity) {
          price = pricePolicies[0].salePrice
        } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
          price = pricePolicies[pricePolicies.length - 1].salePrice
        } else {
          pricePolicies.forEach((x: any) => {
            if (total >= x.minQuantity && total <= x.maxQuantity) {
              price = x.salePrice
            }
          })
        }
        product.skus.forEach((xx: any) => {
          skus.push({
            skuId: xx.id,
            orderedQuantity: xx.quantity,
            actualPrice: price,
          })
        })
      } else {
        product.skus.forEach((xx: any) => {
          skus.push({
            skuId: xx.id,
            orderedQuantity: xx.quantity,
            actualPrice: xx.salePrice,
          })
        })
      }
    })
    body.skus = skus
    const location = deliveryAddress?.location
    if (!location) {
      return
    } else {
      body.location = deliveryAddress.location
      orderRepository
        .estimateFee(lodash.get(accountDefault, 'connection.name', ''), lodash.get(accountDefault, 'connection.provider.code', ''), body)
        .then((res) => {
          setFees(res)
          if (res.notices) {
            let temp = res.notices.find((x: any) => x.title === 'not_preferred_warehouse')
            if (temp) {
              setNoticeWarehouse(lodash.get(temp, 'reference.name', ''))
            }
          }
        })
        .catch((err) => { })
    }
  }, [accountDefault, currentServiceSelected, deliveryAddress?.location, merchant])

  useEffect(() => {
    estimateFee()
  }, [estimateFee])

  useEffect(() => {
    if (deliveryAddress) {
      if (!isFirst) estimateFee()
    }
  }, [deliveryAddress, estimateFee, isFirst])

  useEffect(() => {
    validAddress()
  }, [validAddress])

  useEffect(() => {
    if (merchant && merchant.products && merchant.products.length > 0) {
      let totalSKU = 0
      let totalLink = 0
      let totalPrice: number = 0
      merchant.products.forEach((product: any) => {
        let total: number = 0
        let price: any = 0
        totalLink = totalLink + product.skus.length
        product.skus.forEach((sku: any) => {
          totalSKU = totalSKU + sku.quantity
          total = total + sku.quantity
        })
        if (product.pricePolicies && product.pricePolicies.length > 0) {
          let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
          if (total < pricePolicies[0].minQuantity) {
            price = pricePolicies[0].salePrice
          } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
            price = pricePolicies[pricePolicies.length - 1].salePrice
          } else {
            pricePolicies.forEach((x: any) => {
              if (total >= x.minQuantity && total <= x.maxQuantity) {
                price = x.salePrice
              }
            })
          }
          totalPrice += total * price
        } else {
          product.skus.forEach((xx: any) => {
            totalPrice += xx.salePrice * xx.quantity
          })
        }
      })
      setTotalPrice(totalPrice)
      setTotalSKU(totalSKU)
      setTotalLink(totalLink)
    } else {
      setTotalLink(0)
      setTotalSKU(0)
      setTotalPrice(0)
    }
    estimateFee()
  }, [merchant])
  const handleChange = (code: string) => {
    let temp = accountBalance.find((x: any) => x.connection.id === code)
    if (temp) {
      setAccountDefault(temp)
    }
  }
  const formatAmount = (amount: any, unit?: string) => {
    if (!amount && amount !== 0) return ''
    let color = amount < 0 ? 'txt-error' : 'txt-success'
    return <span className={color}>{`${formatMoneyByUnit(amount, unit)}`}</span>
  }
  const changeService = (items: any) => {
    if (accountDefault && accountDefault.connection) {
      setCurrentServiceSelected(items)
      let temp = lodash.cloneDeep(serviceSelectedCache)
      temp[accountDefault.connection.id] = items
      setServiceSelectedCache(temp)
      // if(!showWarning){
      //     setShowWarning(true);
      //     notification['warning']({
      //         message: t('message.waning'),
      //         description: t('message.calculateFee'),
      //         duration:10,
      //         onClose:()=>{
      //             setShowWarning(false);
      //         },
      //     });
      // }
    }
  }
  const onCloseDrawer = () => {
    setShowDrawer(false)
  }
  const updateQuantity = (quantity: number, productId: string, skuId: string) => {
    let temp = lodash.cloneDeep(merchant)
    temp.products.forEach((product: any) => {
      if (product.id === productId) {
        product.skus.forEach((sku: any) => {
          if (sku.id === skuId) {
            sku.quantity = quantity
          }
        })
        product.skus = product.skus.filter((sku: any) => sku.quantity)
      }
    })
    temp.products = temp.products.filter((product: any) => product.skus.length > 0)
    if (temp.products.length === 0) {
      setStep(0)
    } else {
      setMerchant(temp)
    }
  }
  const updateEmployeeNote = (value: string, callback: () => void) => {
    setEmployeeNote(value)
    callback()
  }
  const updatePersonalNote = (value: string, callback: () => void) => {
    setPersonalNote(value)
    callback()
  }

  const handleCloseAddressList = () => {
    setOpenAddressList(false)
  }
  const handleCloseAddressCreate = () => {
    setOpenModalAdd(false)
    setSelectedAddress(null)
    if (addressList && addressList.length > 0) setOpenAddressList(true)
    else setOpenAddressList(false)
  }
  const openAddressCreate = () => {
    setOpenAddressList(false)
    setOpenModalAdd(true)
  }
  const showModalEditAddress = (item: any) => {
    setOpenAddressList(false)
    setSelectedAddress(item)
    setOpenModalAdd(true)
  }
  const getAddressList = (isReset?: boolean) => {
    setIsLoading(true)
    cartRepository
      .getAddressList()
      .then((response) => {
        setAddressList(response)
        if (!deliveryAddress || isReset) {
          let temp = response.find((x: any) => x.isDefault)
          setDeliveryAddress(temp)
        } else {
          let temp = response.find((x: any) => x.id === deliveryAddress.id)
          setDeliveryAddress(temp)
        }
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false)
      })
    
  }

  const onSubmitCreateModal = (body: any) => {
    setIsCreateAddress(true)
    if (selectedAddress) {
      cartRepository
        .updateAddress(selectedAddress.id, body)
        .then((response) => {
          M24Notification.messageSuccess(t('message.success'))
          getAddressList()
          handleCloseAddressCreate()
          setSelectedAddress(null)
          setOpenAddressList(true)
        })
        .catch((error) => {
          M24ErrorUtils.showError(t, error)
        })
        .finally(() => {
          setIsCreateAddress(false)
        })
    } else {
      cartRepository
        .createAddress(body)
        .then((response) => {
          M24Notification.messageSuccess(t('message.success'))
          if (addressList.length > 0) {
            setOpenAddressList(true)
          }
          getAddressList()
          handleCloseAddressCreate()
        })
        .catch((error) => {
          M24ErrorUtils.showError(t, error)
        })
        .finally(() => {
          setIsCreateAddress(false)
        })
    }
    
  }

  const makeDeliveryAddress = (address: any) => {
    setDeliveryAddress(address)
  }

  console.log(isLoading)

  useEffect(() => {
    let isAllow
    if (!addressSupported || (currentServiceSelected && currentServiceSelected.length === 0) || addressList.length === 0 || !deliveryAddress) isAllow = false
    else {
      let groups = serviceGroups.filter((x: ServiceGroupedInterface) => x.required)
      if (groups && groups.length > 0) {
        groups.forEach((group: ServiceGroupedInterface) => {
          let temp = currentServiceSelected.find((x: ServiceInterface) => x.serviceGroup && x.serviceGroup.code === group.code)
          if (!temp) {
            isAllow = false
          } else {
            const requireGroups = temp.requireGroups
            if (requireGroups && requireGroups.length > 0) {
              requireGroups.forEach((requireGroup: string[]) => {
                const requireServiceSelect = currentServiceSelected.find(
                  (curServiceSelected: ServiceInterface) => curServiceSelected.serviceGroup?.code === requireGroup
                )
                isAllow = !!requireServiceSelect
              })
            } else {
              isAllow = true
            }
          }
        })
      } else {
        isAllow = true
      }
    }

    setIsAllow(!!isAllow)
  }, [addressList.length, addressSupported, currentServiceSelected, deliveryAddress, serviceGroups])

  const createOrder = () => {
    if (creatingOrder) return
    let body: any = {}
    let selectedService: any = []
    currentServiceSelected.forEach((x: any) => {
      selectedService.push(x.code)
    })
    let selectedSkus: any = []
    merchant.products.forEach((product: any) => {
      if (product.skus && product.skus.length > 0) {
        product.skus.forEach((sku: any) => {
          let item: any = {}
          item.skuId = sku.id
          item.orderedQuantity = sku.quantity
          item.actualPrice = sku.salePrice
          selectedSkus.push(item)
        })
      }
    })
    body.merchantId = lodash.get(merchant, 'merchant.id')
    body.customer = lodash.get(accountDefault, 'connection.name')
    body.provider = lodash.get(accountDefault, 'connection.provider.code')
    body.currency = currency
    body.services = selectedService
    body.address = {
      fullname: deliveryAddress?.receiver,
      detail: deliveryAddress?.address1,
      phone: deliveryAddress?.phoneNumber,
      location: deliveryAddress?.location,
    }
    body.employeeNote = employeeNote
    body.personalNote = personalNote
    body.skus = selectedSkus
    // body.expressDelivery = expressDelivery
    setCreatingOrder(true)
    handleCreateOrder(body, () => {
      setCreatingOrder(false)
    })
  }

  const isNotEnough =
    accountDefault &&
    totalPrice &&
    exchangeRate &&
    exchangeRate.rate &&
    exchangeRate.exchange &&
    fees &&
    fees.emdPercent &&
    accountDefault.balance < (totalPrice * exchangeRate.rate * fees.emdPercent) / 100

  const merchantt = lodash.get(merchant, 'merchant.name', '')
  const merchantName = merchantt.length > 7 ? lodash.get(merchant, 'merchant.name', '').slice(0, 7).trim() + '...' : merchantt

  return (
    <>
      <Card
        bodyStyle={{ padding: 0, borderRadius: 6 }}
        className={`box-step-service ${theme}`}
        bordered={false}>
        <Spin spinning={creatingOrder}>
          <div className="flex flex-row gap-16">
            <M22Box className="box-step-service__left">
              <Row className={'dpl-flex align-items-center justify-content-between'}>
                <Col span={13}>
                  <Avatar
                    className={'rad-4 bd-none-impt mg-r-8'}
                    shape={'square'}
                    size={24}
                    src={lodash.get(merchant, 'merchant.icon')}></Avatar>
                  <span className={'txt-secondary'}>{t('shopping-cart.seller')}:</span>
                  &nbsp;
                  <Tooltip
                    className="cursor-pointer"
                    title={lodash.get(merchant, 'merchant.name', '')}>
                    <span>{merchantName}</span>
                  </Tooltip>
                  <Divider
                    type={'vertical'}
                    className={'mx-12 w-2 h-16'}
                  />
                  <Button
                    type="link"
                    className={'pdl0 edit-address'}
                    onClick={() => setShowDrawer(true)}>
                    <span className="txt-color-primary fsz-14">{t('shopping-cart.orderDetail')}</span>
                  </Button>
                </Col>

                <Col span={11}>
                  <Select
                    suffixIcon={<i className="fa-solid fa-angle-down"></i>}
                    placeholder={t('shopping-cart.selectAccount')}
                    value={accountDefault?.connection?.id}
                    className={'width100'}
                    size="large"
                    showSearch
                    notFoundContent={<EmptyDataFilter description="Không có dữ liệu" />}
                    filterOption={(input, option: any) => option.label.toLowerCase().includes(input.trim().toLowerCase())}
                    listHeight={300}
                    onChange={handleChange}
                    popupClassName={theme}>
                    {accountBalance &&
                      accountBalance.length > 0 &&
                      accountBalance.map((x: any) => {
                        let currencyItem = JSON.parse(x.connection.provider.currency)
                        return (
                          <Select.Option
                            key={x.connection.id}
                            value={x.connection.id}
                            label={x.connection.provider.name}>
                            <Row className={'dpl-flex align-items-center'}>
                              <Avatar
                                size={20}
                                src={lodash.get(x, 'connection.provider.logo', '')}
                                shape={'circle'}></Avatar>
                              <span className={'mgl5  txt-size-h7 '}>{lodash.get(x, 'connection.provider.name', '')}</span>
                              <Divider
                                type={'vertical'}
                                className={'bd-color-black'}
                                style={{ borderLeft: `1px solid ${theme === 'light' ? '#1a1a1a' : ''}` }}
                              />
                              {/*<span className={' txt-size-h7 '}>{lodash.get(x, 'connection.name', '')}</span> */}
                              {currentConfigShowBalance && (
                                <span className={'  txt-size-h7'}>
                                  Số dư: <span>{formatAmount(lodash.get(x, 'balance', ''), lodash.get(currencyItem, 'code', ''))}</span>
                                </span>
                              )}
                            </Row>
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Col>
              </Row>

              <div className={'label-row mg-t-28'}>
                <span className={'txt-size-h5 robotomedium  line-h-24 whitespace'}>{t('shopping-cart.orderService')}</span>

                <Button
                  type="ghost"
                  icon={<i className="fa-regular fa-bookmark"></i>}
                  onClick={() => setConfirmFavorite(true)}
                  className="font-medium">
                  {t('shopping-cart.saveFavoriteServices')}
                </Button>
              </div>

              <Services
                setCheckedList={changeService}
                checkedList={currentServiceSelected}
                services={services}
                serviceGroups={serviceGroups}
                setIsAllow={(val: boolean) => setIsAllow(val)}
              />

              {/* <Row className='mg-t-12' style={{ width: '100%' }}>
                <Col span={4}>
                  <span className=" txt-secondary whitespace">Giao hàng:</span>{' '}
                </Col>
                <Col span={20}>
                  <Checkbox onChange={() => setExpressDelivery(!expressDelivery)}>Giao ngay khi hàng về</Checkbox>
                </Col>
              </Row> */}
              {noticeFavoriteService && (
                <Row className={'notice-warehouse'}>
                  <Col span={23}>
                    <Row>
                      <Col>
                        <span className={'txt-color-primary'}>
                          <i className="fas fa-exclamation-circle"></i>
                        </span>
                      </Col>
                      <Col span={22}>
                        <span className="whitespace-pre-wrap mgl12 txt-size-h7 dpl-block break-word">{t('message.warningFavoriteServiceConflict')}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className={'dpl-flex justify-content-end'}
                    span={1}>
                    <span onClick={() => setNoticeFavoriteService(null)}>
                      <i className="cursor-pointer fas fa-times"></i>
                    </span>
                  </Col>
                </Row>
              )}

              <Row className={'label-row'}>
                <span className={'txt-size-h5 robotomedium'}>{t('shopping-cart.orderAddress')}</span>

                {addressList && addressList.length > 0 && (
                  <span
                    onClick={() => {
                      setOpenAddressList(true)
                    }}
                    className=" btn-edit-address hover:cursor-pointer mg-r-14">
                    <i className="fa-regular fa-location-pen mg-r-6" />
                    {t('shopping-cart.editAddress')}
                  </span>
                )}
              </Row>

              {addressList && addressList.length <= 0 ? (
                <EmptyDataFilter
                  description={
                    <div className="flex flex-col align-items-center txt-secondary">
                      <span>{t('address.empty-address-content')}</span>
                      <Button
                        type="ghost"
                        className="font-medium mg-t-16"
                        onClick={() => {
                          setOpenModalAdd(true)
                        }}
                        icon={<i className="fa-solid fa-plus" />}>
                        {t('address.add-address')}
                      </Button>
                    </div>
                  }
                // image={
                //   <Avatar
                //     size={48}
                //     shape="square"
                //     className={'icon-empty'}
                //     src={EmptyImage}
                //   />
                // }
                />
              ) : (
                <Col className={'_delivery-address mg-bt-12'}>
                  <Row
                    style={{ height: 22 }}
                    align={'middle'}>
                    <span>
                      <i className="fas fa-user txt-size-h8 txt-secondary" />
                    </span>
                    <span className={'txt-size-h7 robotomedium mgl10'}>{lodash.get(deliveryAddress, 'receiver', '---')}</span>
                  </Row>
                  <Row
                    style={{ height: 22 }}
                    align={'middle'}
                    className="mg-t-6">
                    <span className={'dpl-block'}>
                      <i className="fas fa-home-lg-alt txt-size-h8 txt-secondary" />
                    </span>
                    <span className={'txt-size-h7  mgl10'}>
                      {lodash.get(deliveryAddress, 'address1', '')} {lodash.get(deliveryAddress, 'display', '---')}
                    </span>
                  </Row>
                  <Row
                    style={{ height: 22 }}
                    align={'middle'}
                    className="mg-t-6">
                    <span className={'dpl-block'}>
                      <i className="fas fa-phone txt-size-h8 txt-secondary" />
                    </span>
                    <span className={'txt-size-h7 robotoregular mgl10'}>{lodash.get(deliveryAddress, 'phoneNumber', '---')}</span>
                  </Row>
                  {deliveryAddress && deliveryAddress.isDefault ? (
                    <Row
                      style={{ height: 22 }}
                      align={'middle'}
                      className="mg-t-6">
                      <span>
                        <i className="fas fa-cog txt-size-h8 txt-secondary" />
                      </span>
                      <span className={'txt-size-h7  mgl10'}>{t('address.status-isDefault')}</span>
                    </Row>
                  ) : null}

                  {noticeWarehouse && isFirst && (
                    <Alert
                      description={
                        <span
                          className="whitespace-pre-wrap break-word"
                          dangerouslySetInnerHTML={{
                            __html: t('cart.not_preferred_warehouse', {
                              code: noticeWarehouse,
                            }),
                          }}></span>
                      }
                      type="warning"
                      showIcon
                      closable
                      className="mg-t-16"
                      icon={<i className="fa-sharp fa-solid fa-circle-exclamation" />}
                      closeIcon={<i className="far fa-times" />}
                    />
                  )}
                </Col>
              )}
              <div className={'label-row'}>
                <span className={'txt-size-h5 robotomedium '}>{t('shopping-cart.orderNote')}</span>
              </div>

              <Row className={'dpl-flex align-items-center'}>
                <span className={`txt-secondary mgr6 ${theme}`}>{t('shopping-cart.personalNote')}:</span>
                <InlineEdit
                  onSubmit={updatePersonalNote}
                  title={t('shopping-cart.personalNote')}
                  type={'textarea-modal'}
                  defaultValue={personalNote}
                  className={'_note-custom'}></InlineEdit>
              </Row>

              <Row className={'dpl-flex align-items-center mg-t-6'}>
                <span className={`txt-secondary mgr6 ${theme}`}>{t('shopping-cart.employeeNote')}:</span>
                <InlineEdit
                  onSubmit={updateEmployeeNote}
                  title={t('shopping-cart.employeeNote')}
                  type={'textarea-modal'}
                  defaultValue={employeeNote}></InlineEdit>
              </Row>
            </M22Box>

            <M22Box className="box-step-service__right">
              <div className={`freeContainer ${theme}`}>
                <div className={'robotomedium line-h-24 mg-bt-12'}>{t('shopping-cart.productInformation')}</div>

                <div className="flex justify-content-between mg-bt-12">
                  <span className="txt-secondary">{`${t('shopping-cart.productLink')}:`}</span>

                  <span>{`${totalSKU ? totalSKU : '...'} ${t('shopping-cart.products')}/${totalLink ? totalLink : '...'} Link`}</span>
                </div>

                <div className="flex justify-content-between">
                  <span className={'txt-secondary'}>{`${t('shopping-cart.exchangeRate')}:`}</span>

                  <span>
                    {(exchangeRate?.rate || exchangeRate?.rate === 0) && exchangeRate?.exchange
                      ? `${formatMoneyByUnit(1, 'CNY')} = ${formatMoneyByUnit(exchangeRate.rate, exchangeRate.exchange)}`
                      : '--'}
                  </span>
                </div>

                <div className={'robotomedium line-h-24 mg-t-16 mg-bt-12'}>{t('shopping-cart.feeInformation')}</div>

                <div className="total-money">
                  <div className="flex justify-content-between">
                    <span className={'txt-secondary'}>{t('shopping-cart.totalMoneyGoods')}</span>
                    <span>
                      {totalPrice && (exchangeRate?.rate || exchangeRate?.rate === 0) && exchangeRate?.exchange
                        ? formatMoneyByUnit(totalPrice * exchangeRate.rate, exchangeRate.exchange)
                        : '--'}
                    </span>
                  </div>

                  {fees && Array.isArray(fees.estimatedFees)
                    ? fees.estimatedFees.map((fee: any) => {
                      let feeCategory = feeCategories.find((x: any) => x.code === fee.type)
                      return (
                        <div className="flex justify-content-between">
                          <span className={'txt-secondary'}>{lodash.get(feeCategory, 'name', fee.type)}</span>
                          <span>{formatMoneyByUnit(fee.provisionalAmount, exchangeRate?.exchange)}</span>
                        </div>
                      )
                    })
                    : null}

                  <Row>
                    <Col span={12}>
                      <span className={'txt-size-h5 robotomedium'}>
                        {t('shopping-cart.emdPercent', {
                          value: fees && fees.emdPercent ? `${fees.emdPercent}%` : '--',
                        })}
                      </span>
                    </Col>

                    <Col span={12}>
                      <span className={'robotomedium flr txt-error'}>
                        {totalPrice && (exchangeRate?.rate || exchangeRate?.rate === 0) && exchangeRate.exchange && fees && fees.emdPercent
                          ? formatMoneyByUnit((totalPrice * exchangeRate.rate * fees.emdPercent) / 100, exchangeRate.exchange)
                          : '--'}
                      </span>
                    </Col>
                  </Row>

                  {currentConfigShowBalance && (
                    <Row>
                      <Col span={12}>
                        <span className={'txt-size-h5 robotomedium'}>{t('shopping-cart.balance')}</span>
                      </Col>
                      <Col span={12}>
                        <span className={'robotomedium flr'}>{formatAmount(lodash.get(accountDefault, 'balance', ''), lodash.get(currency, 'code', ''))}</span>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>

              {isNotEnough ? (
                <>
                  <Alert
                    description={t('message.waningDepositMoney')}
                    type="error"
                    closable
                    showIcon
                    className="mg-t-12"
                    icon={
                      <i
                        className="fa-sharp fa-solid fa-circle-exclamation"
                        color="#f54255"
                      />
                    }
                    closeIcon={<i className="far fa-times" />}
                  />
                  <Row className="mg-t-12">
                    <Col span={12}>
                      <span className={'txt-size-h7'}>{t('shopping-cart.needMoney')}</span>
                    </Col>

                    <Col span={12}>
                      <span className={'txt-size-h7 flr font-medium txt-error'}>
                        {formatMoneyByUnit(
                          Math.abs(accountDefault.balance - (totalPrice * exchangeRate.rate * fees.emdPercent) / 100),
                          lodash.get(currency, 'code', '')
                        )}
                      </span>
                    </Col>
                  </Row>

                  <Row
                    justify={'center'}
                    align={'middle'}
                    className="mg-t-12">
                    <Button
                      size={'large'}
                      icon={<CheckOutlined />}
                      disabled={true}
                      type="primary"
                      className={`width100 m22-btn ${theme}`}>
                      {t('shopping-cart.orderActionPayment')}
                    </Button>
                  </Row>
                </>
              ) : (
                <Row
                  justify={'center'}
                  align={'middle'}
                  className="mg-t-24">
                  <Button
                    disabled={!isAllow || isLoading}
                    icon={<CheckOutlined />}
                    type="primary"
                    size={'large'}
                    className={`width100 m22-btn ${theme} `}
                    onClick={() => {
                      createOrder()
                    }}>
                    {t('shopping-cart.orderActionPayment')}
                  </Button>
                </Row>
              )}
            </M22Box>
          </div>
        </Spin>

        <Drawer
          closable={false}
          bodyStyle={{ padding: 0 }}
          width={800}
          title={
            <Row className={'dpl-flex align-items-center justify-content-between'}>
              <Col span={12}>
                <Row
                  justify={'start'}
                  align={'middle'}>
                  <span
                    onClick={() => setShowDrawer(false)}
                    className={'txt-size-h7 cursor-pointer'}>
                    <i className="fas fa-arrow-right" />
                  </span>

                  <Avatar
                    className={'mg-l-12 border-radius6 bd-none-impt'}
                    shape={'square'}
                    size={20}
                    src={lodash.get(merchant, 'merchant.icon')}
                  />

                  <span className={'mgl12 mgr8 txt-secondary'}>{t('shopping-cart.seller')}: </span>
                  <span>{lodash.get(merchant, 'merchant.name', '')}</span>
                </Row>
              </Col>
            </Row>
          }
          placement="right"
          onClose={onCloseDrawer}
          open={showDrawer}>
          <Row style={{ padding: 16 }}>
            <Col
              span={7}
              className={'txt-size-h7 robotomedium'}>
              <span className={''}>{t('shopping-cart.products')}</span>
            </Col>
            <Col
              span={6}
              className={'txt-center txt-size-h7 robotomedium'}>
              <span>{t('shopping-cart.quantity')}</span>
            </Col>
            <Col
              span={5}
              className={'txt-left txt-size-h7 robotomedium'}>
              {' '}
              <span className={''}>{t('shopping-cart.variantProperties')}</span>
            </Col>
            <Col
              span={3}
              className={'txt-right txt-size-h7 robotomedium'}>
              <span>{t('shopping-cart.unitPrice')}</span>
            </Col>
            <Col
              span={3}
              className={'txt-right txt-size-h7 robotomedium'}>
              <span>{t('shopping-cart.totalPrice')}</span>
            </Col>
          </Row>
          <div className="px-16">
            {merchant?.products?.map((product: any, index: number) => {
              let total: number = 0
              let price: any = null
              product.skus.forEach((xx: any) => {
                total = total + xx.quantity
              })
              if (product.pricePolicies && product.pricePolicies.length > 0) {
                let pricePolicies = lodash.sortBy(product.pricePolicies, ['minQuantity'])
                if (total < pricePolicies[0].minQuantity) {
                  price = pricePolicies[0].salePrice
                } else if (total > pricePolicies[pricePolicies.length - 1].maxQuantity) {
                  price = pricePolicies[pricePolicies.length - 1].salePrice
                } else {
                  pricePolicies.forEach((x: any) => {
                    if (total >= x.minQuantity && total <= x.maxQuantity) {
                      price = x.salePrice
                    }
                  })
                }
              }
              return (
                <List
                  key={product.id}
                  itemLayout="horizontal"
                  dataSource={product.skus}
                  renderItem={(sku: any) => {
                    return (
                      <List.Item className="py-0">
                        <Product
                          exchangeRate={exchangeRate}
                          handleUpdateQuantitySku={updateQuantity}
                          product={sku}
                          salePrice={price}
                          currency={lodash.get(sku, 'currency', '')}
                        />
                      </List.Item>
                    )
                  }}
                />
              )
            })}
          </div>
          <Col
            span={24}
            className="pd-16">
            <Row
              align="middle"
              className="align-items-baseline">
              <span className={'fsz-14 line-h-28 '}>
                Thông tin:{' '}
                <span className={'robotomedium  line-h-2 fsz-14'}>{`${totalSKU ? totalSKU : '...'} ${t('shopping-cart.products')}/ ${totalLink ? totalLink : '...'
                  } Link`}</span>
              </span>
              <span className={'fsz-14 line-h-28 mgl10 '}>
                Thành tiền:{' '}
                <span className={'robotomedium fsz-20 line-h-28 txt-color-orange1'}>{`${totalPrice
                    ? exchangeRate && (exchangeRate.rate || exchangeRate.rate === 0) && exchangeRate.exchange
                      ? formatMoneyByUnit(totalPrice * exchangeRate.rate, exchangeRate.exchange)
                      : formatMoneyByUnit(totalPrice, currency)
                    : '--'
                  }`}</span>
              </span>
            </Row>
          </Col>
        </Drawer>

        {openAddressList && (
          <Address
            isVisible={openAddressList}
            onCancel={handleCloseAddressList}
            openAddressCreate={openAddressCreate}
            addressList={addressList}
            updateAddress={showModalEditAddress}
            getAddressList={getAddressList}
            makeDeliveryAddress={makeDeliveryAddress}
            deliveryAddress={deliveryAddress}
          />
        )}
        {openModalAdd && (
          <CreateAddressModal
            isVisible={openModalAdd}
            onCancel={handleCloseAddressCreate}
            onSubmit={onSubmitCreateModal}
            datasource={selectedAddress}
            isDefault={addressList.length === 0}
            loading={isCreateAddress}
          />
        )}
        {confirmFavorite && (
          <ConfirmModal
            loading={favoriteUpdating}
            onCancel={() => setConfirmFavorite(false)}
            onSubmit={updateFavoriteService}
            visible={confirmFavorite}
            content={t('message.cofirmSaveFavoriteService')}
          />
        )}
        {/* {console.log(isLoading)} */}
      </Card>
    </>
  )
}

export default Step2
