import { Badge, Button, Modal, Radio, Row, Table, Tag, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../../hooks/useTheme'
import { cartRepository } from '../../../repositories/CartRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import ConfirmModal from '../../Modal/ConfirmModal'

import './styles.scss'

export type Props = {
  isVisible: any
  onCancel: () => void
  addressList: any
  getAddressList: (isReset?: boolean) => void
  openAddressCreate: any
  makeDeliveryAddress: (record: any) => void
  deliveryAddress: any
  updateAddress: (item: any) => void
}

function Address(props: Props) {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const { isVisible, onCancel, addressList, getAddressList, openAddressCreate, makeDeliveryAddress, deliveryAddress, updateAddress } = props
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(deliveryAddress)
  const [deleteItem, setDeleteItem] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setSelectedItem(deliveryAddress)
  }, [deliveryAddress])
  const onCancelDelete = () => {
    setDeleteItem(null)
    setIsDelete(false)
  }

  const deleteAddress = () => {
    setIsLoading(true)
    cartRepository
      .deleteAddress(deleteItem.id)
      .then((res) => {
        M24Notification.messageSuccess(t('message.success'))
        getAddressList(deliveryAddress?.id === deleteItem?.id)
        setDeleteItem(null)
        setIsDelete(false)
      })
      .catch((err) => {
        M24ErrorUtils.showError(t, err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      render: (value: any, record: any) => {
        return (
          <div>
            <Radio
              checked={selectedItem?.id === value}
              onChange={(e) => {
                setSelectedItem(record)
              }}
            />
          </div>
        )
      },
    },
    {
      title: <span className={'txt-centermb'}>{t('address.customer-name')}</span>,
      dataIndex: 'receiver',
      key: 'receiver',
      width: 240,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotomedium'}>{record.receiver}</span>
      },
    },
    {
      title: t('address.customer-phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotoregular'}>{record.phoneNumber}</span>
      },
    },
    {
      title: t('address.address-name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (value: any, record: any) => {
        return <span className={'txt-size-h7 robotoregular'}>{record.name}</span>
      },
    },
    {
      title: t('address.address-descriptions'),
      dataIndex: 'detail',
      key: 'detail',
      width: 500,
      render: (value: any, record: any) => {
        return (
          <div className="flex flex-col">
            <div className="flex flex-col">
              <span className={'txt-size-h7 txt-primary'}>{record.address1}</span>
              <span
                className={`txt-size-h7 ${theme} txt-secondary`}
                style={theme === 'dark' ? { color: '#666666' } : {}}>
                {record.display}
              </span>
            </div>
            {record.isDefault && (
              <div className={`mg-t-8 ${theme}`}>
                <Tag
                  className={`rad-23 default-badge_${theme}`}
                  style={{ padding: '2px 12px' }}>
                  <span className={'txt-size-h7 txt-primary robotomedium'}>{t('address.status-isDefault')}</span>
                </Tag>
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: <span className={'flr pd-r-24'}>{t('address.address-actions')}</span>,
      dataIndex: 'action',
      key: 'action',
      width: 130,
      render: (_: any, record: any) => {
        return (
          <div className="flex align-items-center justify-content-end">
            <Tooltip title={t('address.edit-action')}>
              <span
                className={`edit-action_${theme} cursor-pointer mg-r-12`}
                onClick={() => {
                  updateAddress(record)
                }}>
                <i className="fa-regular fa-pen-to-square"></i>
              </span>
            </Tooltip>

            <Tooltip title={t('address.delete-action')}>
              <span
                style={record.isDefault ? { pointerEvents: 'none' } : {}}
                className={`edit-action mg-r-12 ${!record.isDefault ? 'cursor-pointer txt-error' : 'txt-disabled'}`}
                onClick={() => {
                  if (!record.isDefault) {
                    setDeleteItem(record)
                    setIsDelete(true)
                  }
                }}>
                {!record.isDefault ? <i className="fa-regular fa-trash"></i> : <i className="fa-regular fa-trash-slash"></i>}
              </span>
            </Tooltip>

            <Button
              type={'text'}
              disabled={record.isDefault}
              onClick={() => {
                setDeleteItem(record)
                setIsDelete(true)
              }}
              className={'_btn-delete-address address-btn-custom txt-color-red  fsz-14 line-h-22 px-0'}></Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Modal
        className={`_address-table-custom m22-modal ${theme}`}
        open={isVisible}
        centered
        title={
          <>
            <span className={'txt-size-h5 line-h-24  mg-r-5'}>{t('address.address-list')}</span>
            {/* <span className={'count fsz-12 px-8 bg-color-pink2 rad-66 txt-color-orange3'}>{addressList.length}</span> */}
            <Badge
              className="fsz-12 line-h-20"
              count={addressList.length}
              overflowCount={99999}
              showZero={true}
              style={{
                backgroundColor: !addressList.length ? '#F5F5F5' : '',
                color: !addressList.length ? '#707070' : '',
              }}
            />
          </>
        }
        closeIcon={<i className="fa-solid fa-xmark" />}
        onCancel={onCancel}
        width={'70%'}
        footer={
          <Row
            justify={'space-between'}
            align={'middle'}
            className={'mg-t-12 pd-t-12'}>
            <Button
              className={`m22-btn m22-btn-outline ${theme}`}
              onClick={() => {
                openAddressCreate(true)
              }}
              icon={<i className="fa-solid fa-plus mg-r-10" />}>
              {t('address.create-address-btn')}
            </Button>
            <Row justify={'end'}>
              <Button
                key="back"
                onClick={onCancel}
                type="text"
                className={`m22-btn ${theme}`}>
                {t('button.cancel')}
              </Button>
              <Button
                icon={<i className="fa-solid fa-check mg-r-10"></i>}
                key="submit"
                loading={isLoading}
                onClick={() => {
                  makeDeliveryAddress(selectedItem)
                  onCancel()
                }}
                type="primary"
                className={`m22-btn ${theme}`}>
                {t('button.submit')}
              </Button>
            </Row>
          </Row>
        }>
        <Table
          rowKey={(record: any) => record.id}
          className={`width100pc address-table address-table-${theme}`}
          dataSource={addressList}
          columns={columns}
          pagination={false}
          scroll={{ y: 400, scrollToFirstRowOnChange: true, x: 'auto' }}
          rowClassName={(record, idx) => {
            const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
            return `${rowClassesByIdx}`
          }}
          onRow={(record: any, rowIndex: any) => {
            return {
              onClick: (event) => {
                setSelectedItem(record)
              },
            }
          }}
        />
      </Modal>
      {isDelete && (
        <ConfirmModal
          content={t('address.delete-confirm-message')}
          visible={isDelete}
          loading={isLoading}
          onCancel={onCancelDelete}
          onSubmit={deleteAddress}
        />
      )}
    </>
  )
}

export default Address
