import { Avatar, Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import LogModal from '../LogModal'
import ConfirmModal from '../../Modal/ConfirmModal'
import { getUrlPathname } from '../../../utils/util'
import Skeleton from 'react-loading-skeleton'
import ParagraphWithCopy from '../../ParagraphWithCopyButton'
import M22Box from '../../M22Box'
import { useTheme } from '../../../hooks/useTheme'

interface HeadingProps {
  orderDetail: any
  reOrder: () => void
  isReOrdering: boolean
  statuses: any
  cancelOrder: (callback: () => void) => void
  isCancelOrdering: boolean
  loadingCreateThread: boolean
  loading: boolean
}

const Heading: React.FC<HeadingProps> = (props) => {
  const { orderDetail, statuses, reOrder, isReOrdering, cancelOrder, isCancelOrdering, loading } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const codeOrder = lodash.get(orderDetail, 'code', '--')
  const productImage = lodash.get(orderDetail, 'image', '')
  const expressDelivery = lodash.get(orderDetail, 'expressDelivery', '')

  const providerName = lodash.get(orderDetail, 'provider.name')
  const logo = lodash.get(orderDetail, 'provider.logo', '')
  const [isCancellable, setCancellable] = useState<boolean>(false)
  const [isConfirm, setConfirm] = useState<boolean>(false)
  const [theme] = useTheme()
  const statusOj = statuses.find(({ code }: any) => code === lodash.get(orderDetail, 'status'))

  const handleVisibleLogOrderModal = () => {
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (orderDetail) {
      let status = statuses.find((x: any) => x.code === orderDetail.status)
      setCancellable(lodash.get(status, 'cancellable', false))
    }
  }, [orderDetail, statuses])

  const onCancel = () => {
    cancelOrder(() => {
      setConfirm(false)
    })
  }


  return (
    <M22Box className={`pd-0 ${theme}`}>
      <Row
        gutter={[0, 12]}
        className="pd16 dpl-flex justify-content-between align-items-center rad-4">
        <Col className="dpl-flex justify-content-between align-items-center gap-32">
          <div className="dpl-flex gap-16">
            {!loading ? (
              <Avatar
                shape="square"
                size={60}
                src={productImage}
                className="border-radius3 bd-none-impt"
              />
            ) : (
              <Skeleton
                width={60}
                height={60}
              />
            )}

            <div className="dpl-flex flex-col justify-content-center gap-4">
              {!loading ? (
                <span className="dpl-flex align-items-center gap-4">
                  {codeOrder && (
                    <>
                      <span className="txt-color-gray2">ID:</span>
                      <ParagraphWithCopy
                        text={codeOrder}
                        children={`#${codeOrder}`}
                      />
                    </>
                  )}
                </span>
              ) : (
                <Skeleton
                  width={150}
                  height={22}
                />
              )}

              {!loading ? (
                <span
                  className="status-view"
                  style={{ backgroundColor: statusOj?.color ? statusOj?.color : '#09B8AF' }}>
                  {lodash.get(statusOj, 'name')}
                </span>
              ) : (
                <Skeleton
                  borderRadius={25}
                  width={150}
                  height={24}
                />
              )}
            </div>
          </div>

          <div className="dpl-flex flex-col align-items-start gap-4">
            <span className="dpl-flex align-items-center txt-size-h7 line-h-22 ">
              {!loading ? (
                <Avatar
                  size={20}
                  src={logo}
                  shape={'circle'}
                  style={{ border: 'none' }}
                  className="mg-r-4"
                />
              ) : (
                <Skeleton
                  width={12}
                  height={12}
                  circle
                  className="mg-r-4"
                />
              )}
              <span className="mg-r-4 fsz-14">
                {!loading ? (
                  providerName
                ) : (
                  <Skeleton
                    width={150}
                    height={22}
                  />
                )}
              </span>
            </span>

            <span className="dpl-flex align-items-center txt-color-blue1 txt-size-h7 cursor-pointer">
              {!loading ? (
                <i className="far fa-file-alt fsz-11 line-h-12 item-log mg-r-4" />
              ) : (
                <Skeleton
                  width={14}
                  height={14}
                  className="mg-r-4"
                />
              )}
              <span
                onClick={handleVisibleLogOrderModal}
                className="txt-underline line-h-22 item-log">
                {!loading ? (
                  t('orderDetail.log')
                ) : (
                  <Skeleton
                    width={80}
                    height={20}
                  />
                )}
              </span>
            </span>
          </div>

          {expressDelivery &&
            <div className="dpl-flex flex-col align-items-start">
              {!loading ? (
                <span style={{ backgroundColor: `${theme === 'dark' ? 'rgba(0, 175, 167, 0.1)' : 'rgba(77, 214, 156, 1)'} ` }} className='px-16 py-4 rad-16'>
                  <i className="fa-solid fa-check mg-r-4" style={{ color: `${theme === 'dark' ? '#00AFA7' : '#FFFFFF'}` }} />
                  <span className='fsz-14 font-medium ' style={{ color: `${theme === 'dark' ? '#00AFA7' : '#FFFFFF'}` }}>Giao ngay khi hàng về</span>
                </span>
              ) : (
                <Skeleton
                  width={120}
                  height={32}
                  className="mg-r-9"
                />
              )}
            </div>
          }

        </Col>

        <Col className="dpl-flex align-items-center gap-16">
          {!loading ? (
            <Button
              loading={isReOrdering}
              disabled={isReOrdering}
              onClick={reOrder}
              type="primary"
              className={`dpl-flex align-items-center m22-btn ${theme} `}>
              {t('orderDetail.reOrder')}
            </Button>
          ) : (
            <Skeleton
              width={120}
              height={32}
              className=" mg-r-6"
            />
          )}

          {isCancellable &&
            (!loading ? (
              <Button
                disabled={isCancelOrdering}
                danger
                type="primary"
                onClick={() => setConfirm(true)}
                className={`dpl-flex align-items-center m22-btn ${theme} txt-size-h7`}>
                {t('orderDetail.cancel')}
              </Button>
            ) : (
              <Skeleton
                width={120}
                height={32}
              />
            ))}

          <div className="dpl-flex flex-col align-items-start">
            {!loading ? (
              <Button
                className={`m22-btn m22-btn-rounded__red pdl8 pdr8 ${theme}`}
                icon={<i className="fa-regular fa-circle-exclamation" />}
                onClick={() =>
                  navigate(
                    `/claims/create?orderCode=${codeOrder}&providerCode=${lodash.get(orderDetail, 'provider.code')}&customerCode=${lodash.get(
                      orderDetail,
                      'providerUsername'
                    )}&back=${getUrlPathname(window.location)}`
                  )
                }>
                {t('orderDetail.btCreateTicket')}
              </Button>
            ) : (
              <Skeleton
                width={120}
                height={32}
                className="mg-r-9"
              />
            )}
          </div>
        </Col>

        {isConfirm &&
          (!loading ? (
            <ConfirmModal
              iconBtn={<i className="fa-solid fa-file-slash" />}
              onCancel={() => setConfirm(false)}
              visible={isConfirm}
              okButtonProps={{ danger: true, icon: <i className="fa-solid fa-file-slash" /> }}
              onSubmit={onCancel}
              loading={isCancelOrdering}
              okText={t('orderDetail.okTextCancelOrder')}
              content={t('orderDetail.confirmCancel')}
            />
          ) : (
            <Skeleton
              width={120}
              height={32}
            />
          ))}
        {showModal && (
          <LogModal
            orderCurrency={lodash.get(orderDetail, 'currency', '')}
            visible={showModal}
            onCancel={handleCancel}
          />
        )}
      </Row>
    </M22Box>
  )
}

export default Heading
