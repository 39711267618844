import lodash, { isArray } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import { formatNumber } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import { Col } from 'antd'
import { Row } from 'antd'

interface NestedData {
  loading: any
  items: any
  record: any
}

const NestedTable: React.FC<NestedData> = ({ loading, items, record }) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const classes = ' fsz-14 line-h-22 '

  const data = items && items.length > 0 ? items : []

  return (
    <>
      {data.length > 0 ? (
        data.map((item: any) => {
          const renderPackage = () => {
            const codes = item.package.code
            return isArray(codes) && codes.length > 0 ? (
              <div className="flex flex-col">
                {codes.map((code: string) => (
                  <span className={`${classes}`}>{code}</span>
                ))}
              </div>
            ) : (
              <span className={classes}>{codes}</span>
            )
          }
          return (
            // <div
            //     className="flex"
            //     style={{ marginLeft: '12%' }}>
            //     <div style={{ width: '20%' }}>
            //         <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
            //             {t('requestDeliveryPackage.orderCode')}
            //         </div>
            //         <div className=" fsz-14 line-h-22 ">{lodash.get(item, 'order.code')}</div>
            //     </div>
            //     <div style={{ width: '20%' }}>
            //         <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
            //             {t('requestDeliveryPackage.code')}
            //         </div>
            //         <div className=" fsz-14 line-h-22 ">{renderPackage()}</div>
            //     </div>
            //     <div>
            //         <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
            //             {t('requestDeliveryPackage.weight')}
            //         </div>
            //         <div className=" fsz-14 line-h-22 ">{`${formatNumber(lodash.get(item, 'package.weight_net'))}kg`}</div>
            //     </div>
            // </div>
            <Row
              style={{ marginLeft: '10%' }}
              className="mg-bt-2">
              <Col span={6}>
                <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
                  {t('requestDeliveryPackage.orderCode')}
                </div>
                <div className=" fsz-14 line-h-22 ">{lodash.get(item, 'order.code')}</div>
              </Col>
              <Col span={6}>
                <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
                  {t('requestDeliveryPackage.code')}
                </div>
                <div className=" fsz-14 line-h-22 ">{renderPackage()}</div>
              </Col>
              <Col span={6}>
                <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} mg-bt-16 robotomedium fsz-12 line-h-20`}>
                  {t('requestDeliveryPackage.weight')}
                </div>
                <div className=" fsz-14 line-h-22 ">{`${formatNumber(lodash.get(item, 'package.weight_net'))}kg`}</div>
              </Col>
            </Row>
          )
        })
      ) : (
        <EmptyDataFilter
          description={t('packages.noData')}
          className={loading[record.code] ? 'dpl-none' : ''}
        />
      )}
    </>
  )
}

export default NestedTable
