import { Badge, Row, Table } from 'antd'
import lodash, { groupBy, sumBy, values } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import M22Box from '../../../components/M22Box'
import { textFromData } from '../../../components/OrderDetail/Information'
import M24Pagination from '../../../components/Pagination/M24Pagination'
import ParagraphWithCopy from '../../../components/ParagraphWithCopyButton'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import NestedTable from './NestedTable'
import QuickPagination from '../../../components/QuickPagination'

interface ListProps {
  items: any
  filter: any
  // accounts: any
  total: number
  page: number
  size: number
  // idAcc: string | undefined
  showProgressBar: boolean
  hasError: boolean | null
  isSkeleton: boolean
  handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [theme] = useTheme()
  const { items, handleFilter, total, page, size, showProgressBar, filter, hasError, isSkeleton } = props
  // const [logo, setLogo] = useState('')
  // const [provider, setProvider] = useState('')
  // const [customer, setCustomer] = useState('')
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const [loading] = useState<{ [id: string]: any }>({})
  const [tableExpand] = useState(true)
  const classes = ' fsz-14 line-h-22  whitespace'

  // useEffect(() => {
  // const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
  // if (indexAcc > -1 && !showProgressBar && !hasError) {
  //   const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
  //   const provider = lodash.get(accounts[indexAcc], 'provider.name', '')
  // const customer = lodash.get(accounts[indexAcc], 'name', '')
  // setLogo(logo)
  // setProvider(provider)
  // setCustomer(customer)
  // }
  // }, [accounts, idAcc, showProgressBar, hasError])

  const columns = [
    {
      title: <span className={`fsz-12 mg-l-16 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.code')}</span>,
      key: 'code',
      width: 180,
      render: (record: any) => {
        return (
          <div className="dpl-flex align-items-center fsz-14">
            <i
              className={`far fa-angle-${lodash.includes(expandedRows, record.delivery_note.code) ? 'down' : 'right'} w-8 line-h-22 cursor-pointer mg-r-8`}></i>
            <ParagraphWithCopy
              text={lodash.get(record, 'delivery_note.code')}
              children={lodash.get(record, 'delivery_note.code')}
              className="mg-r-4"
            />
          </div>
        )
      },
    },
    // {
    //   title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.supplier')}</span>,
    //   key: 'account',
    //   width: 260,
    //   render: (record: any) => {
    //     return (
    //       <div className="dpl-flex align-items-center whitespace">
    //         <Avatar
    //           size={16}
    //           src={logo}
    //           className="w-12 h-12 bd-none dpl-flex align-items-center mg-r-4"
    //         />
    //         <span className={`${classes}  mg-r-4`}>{provider}</span>
    //         {/* <Divider
    //                         type={'vertical'}
    //                         className={'bd-color-black line-h-22 mg-0 mg-r-4'}
    //                     />
    //                     <span className={classes}>{customer}</span> */}
    //       </div>
    //     )
    //   },
    // },
    {
      title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.exportedTime')}</span>,
      key: 'exportedTime',
      width: 180,
      render: (record: any) => {
        const exportedAt = lodash.isNil(lodash.get(record, 'delivery_note.exported_at'))
          ? '---'
          : formatDateTime(lodash.get(record, 'delivery_note.exported_at'))
        return <span className={`${classes} txt-muted`}>{exportedAt}</span>
      },
    },
    {
      title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.totalPackages')}</span>,
      key: 'totalPackage',
      width: 150,
      align: 'right' as 'right',
      render: (record: any) => <span className={classes}>{lodash.get(record, 'delivery_note.package_number')}</span>,
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.totalWeight')}</span>,
      key: 'totalWeight',
      width: 150,
      align: 'right' as 'right',
      render: (record: any) => {
        return <span className={classes}>{`${formatNumber(lodash.get(record, 'delivery_note.package_weight'))}kg`}</span>
      },
    },
    {
      title: <span className={`fsz-12  ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.COD')}</span>,
      key: 'COD',
      width: '12%',
      align: 'right' as 'right',
      render: (record: any) => {
        return (
          <span className={`${classes} `}>{lodash.get(record, 'delivery_note.cod') === 0 ? '---' : formatNumber(lodash.get(record, 'delivery_note.cod'))}</span>
        )
      },
    },
    {
      title: <span className={`fsz-12 ${theme === 'dark' ? 'txt-color-gray2' : ''}`}>{t('deliveryNote.address')}</span>,
      key: 'address',
      align: 'left' as 'left',
      ellipsis: tableExpand,
      render: (record: any) => {
        // console.log('        lodash.includes(expandedRows, record.delivery_note.code)        ', lodash.includes(expandedRows, record.delivery_note.code))
        return (
          <span className="fsz-14">{`${textFromData(record, 'delivery_note.customer_receiver', '---')} - ${textFromData(
            record,
            'delivery_note.customer_address',
            '---'
          )}`}</span>
        )
      },
    },
  ]

  const handleChangePage = (filter: any) => {
    const { page, size, providerUsername } = filter
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          customer: providerUsername,
          page: (page - 1).toString(),
          size: size.toString(),
        },
        [],
        ['pageSize']
      )
    )
  }
  const totalRendered = hasError ? 0 : total
  return (
    <M22Box className="flex-col px-16 dpl-flex">
      <div className="dpl-flex align-items-center mg-bt-13 mg-l-8 mg-t-16 justify-content-between">
        <div className={'flex items-center'}>
          <span className={`m22-typo-heading mg-r-4 ${theme}`}>{t('deliveryNote.list')}</span>
          <Badge
            count={totalRendered}
            overflowCount={99999}
            showZero={true}
            //   style={{ backgroundColor: !totalRendered ? '#F5F5F5' : '', color: !totalRendered ? '#707070' : '' }}
            style={{
              fontWeight: '500',
              backgroundColor: !totalRendered ? '#F5F5F5 ' : '',
              color: !totalRendered ? '#707070' : '#1a1a1a',
            }}
          />
        </div>
        <QuickPagination
          filter={filter}
          pagination={{
            total,
            pageSize: size,
            current: page,
          }}
          onPageChange={handleChangePage}
        />
      </div>
      <SkeletonTable
        loading={showProgressBar || isSkeleton}
        columns={columns as SkeletonTableColumnsType[]}
        className={`main-table m22-table ${theme} ${items?.length === 0 ? 'empty' : ''}`}
        rowCount={items?.length ? items?.length : 3}>
        <Table
          rowKey={(record: any) => record.delivery_note.code}
          columns={columns}
          dataSource={items}
          locale={{
            emptyText: (
              <EmptyDataFilter
                className={isSkeleton || showProgressBar ? 'dpl-none' : ''}
                description={
                  !searchParams.get('query') && !searchParams.get('exportedAtFrom') && !searchParams.get('exportedAtTo')
                    ? t('deliveryNote.empty')
                    : t('deliverynotes.filterEmpty')
                }
              />
            ),
          }}
          pagination={false}
          className={`main-table package selected-table  nested-px-36 mg-bt-13 m22-table ${theme}`}
          loading={showProgressBar}
          expandRowByClick={true}
          expandIconColumnIndex={-1}
          expandable={{
            expandedRowRender: (record) => {
              const items =
                lodash.get(record, 'delivery_note_packages') && lodash.get(record, 'delivery_note_packages').length > 0
                  ? lodash.get(record, 'delivery_note_packages')
                  : []
              const itemsFiltered = values(groupBy(items, (item: any) => item.order.code)).map((item: any) => {
                if (item.length === 1) {
                  return item[0]
                } else {
                  const packagesGrouped = {
                    order: { ...item[0].order },
                    package: {
                      code: lodash.map(item, 'package.code'),
                      weight_net: sumBy(item, (o: any) => o.package.weight_net),
                    },
                  }
                  return packagesGrouped
                }
              })
              return (
                <NestedTable
                  items={itemsFiltered}
                  loading={loading}
                  record={record}
                />
              )
            },
            expandIcon: ({ expanded, onExpand, record }) => null,
            columnWidth: 0,
            onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
          }}
          rowClassName={(record, idx) => {
            const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
            const rowClassByCode = lodash.includes(expandedRows, record.delivery_note.code) ? 'active-row cursor-pointer' : 'data-row cursor-pointer'
            return `${rowClassesByIdx} ${rowClassByCode}`
          }}
        />
      </SkeletonTable>
      <Row
        justify={'end'}
        className={''}>
        {items && items.length > 0 && (
          <M24Pagination
            onPageChange={handleChangePage}
            filter={{ ...filter, page, size }}
            pagination={{
              current: page,
              pageSize: size,
              hideOnSinglePage: true,
              total,
            }}
            className="mg-0"
          />
        )}
      </Row>
    </M22Box>
  )
}

export default List
