import { ReloadOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import lodash, { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ComboBox from '../../components/ComboBox'
import M22Box from '../../components/M22Box'
import { FORM_KEY_CUSTOMER, FORM_KEY_PROVIDER } from '../../core/config'
import { useTheme } from '../../hooks/useTheme'
import { handleInputChangeMany } from '../../utils/StringUtils'
import './style.scss'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
interface FilterProps {
  statuses: any
  filter: any
  showProgressBar: boolean
  handleFilter: (filter: any) => void
  handleProgressBar: (val: boolean) => void
  filterChangeHandler: (obj: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [theme] = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const [expand, setExpand] = useState(false)
  const [showCleanFilter, setShowCleanFilter] = useState(false)

  const [showFilter, setShowFilter] = useState(true)
  const { statuses, handleFilter, filterChangeHandler, filter } = props
  const [typeSearch, setTypeSearch] = useState<string | null>(searchParams.get('typeSearch') || 'range')
  const [selectedStatuses, setSelectedStatuses] = useState<{ statuses: any }>({
    statuses: searchParams.get('statuses') || '',
  })

  useEffect(() => {
    form.setFieldsValue({
      typeSearch: searchParams.get('typeSearch') || 'range',
      orderCode: searchParams.get('orderCode')?.trim(),
      packageCode: searchParams.get('packageCode')?.trim(),
      timeInEqual: searchParams.get('typeSearch') === 'equal' ? searchParams.get('handlingTimeTo') : '',
      fromInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeFrom') : '',
      toInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeTo') : '',
      timeOnlyFrom: searchParams.get('typeSearch') === 'from' ? searchParams.get('handlingTimeFrom') : '',
      timeOnlyTo: searchParams.get('typeSearch') === 'to' ? searchParams.get('handlingTimeTo') : '',
      createdAt: [
        searchParams.get('createdFrom') ? moment(searchParams.get('createdFrom')) : null,
        searchParams.get('createdTo') ? moment(searchParams.get('createdTo')) : null,
      ],
      cutOffStatus: searchParams.get('cutOffStatus'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, searchParams, setSearchParams])

  const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
    const temp = handleInputChangeMany(key, code, selectedStatuses)
    setSelectedStatuses({ ...temp })
    filterChangeHandler(temp)
  }

  const getFormState = () => {
    const packageCode = form.getFieldValue('packageCode')?.trim()
    const orderCode = form.getFieldValue('orderCode')?.trim()
    const createdAt = form.getFieldValue('createdAt')
    const createdFrom = createdAt?.[0] && moment(createdAt[0].startOf('day')).toISOString()
    const createdTo = createdAt?.[1] && moment(createdAt[1].endOf('day')).toISOString()
    const handlingTimeFrom =
      typeSearch === 'equal'
        ? form.getFieldValue('timeInEqual')
        : typeSearch === 'range'
        ? form.getFieldValue('fromInRange')
        : typeSearch === 'from'
        ? form.getFieldValue('timeOnlyFrom')
        : ''
    const handlingTimeTo =
      typeSearch === 'equal'
        ? form.getFieldValue('timeInEqual')
        : typeSearch === 'range'
        ? form.getFieldValue('toInRange')
        : typeSearch === 'to'
        ? form.getFieldValue('timeOnlyTo')
        : ''
    const filter = lodash.omitBy(
      {
        ...form.getFieldsValue(true),
        statuses: selectedStatuses?.statuses,
        createdFrom,
        createdTo,
        handlingTimeFrom,
        handlingTimeTo,
        packageCode,
        orderCode,
      },
      (o: any, key: string) => {
        return isEmpty(o) || ['supplier', 'createdAt', 'timeInEqual', 'fromInRange', 'toInRange', 'timeOnlyFrom', 'timeOnlyTo'].includes(key)
      }
    )
    // console.log(filter)
    return filter
  }
  const onFinish = () => {
    handleFilter(getFormState())
    // handleProgressBar(true)
  }

  const clearFilter = () => {
    form.resetFields()
    setSelectedStatuses({ statuses: '' })
    setTypeSearch('range')
    handleFilter({ ...lodash.omit(getFormState(), 'statuses') })
  }

  const handleChangeTypeSearch = (value: string) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      timeInEqual: '',
      fromInRange: '',
      toInRange: '',
      timeOnlyFrom: '',
      timeOnlyTo: '',
    })
    setTypeSearch(value)
  }

  useEffect(() => {
    setShowCleanFilter(
      !lodash.isEmpty(
        lodash.pickBy(filter, (val: any, key: string) => {
          if (key !== FORM_KEY_CUSTOMER && key !== FORM_KEY_PROVIDER && !['supplier'].includes(key)) {
            if (isArray(val)) {
              return val.every((e) => e) && !isEmpty(val)
            }
            return !isEmpty(val)
          }
        })
      ) || selectedStatuses?.statuses
    )
  }, [filter, selectedStatuses?.statuses])

  const labelClasses = '  fsz-14 line-h-22'

  const onChange = (key: string, value: any) => {
    const res = value.replace(/[^\d]/g, '')
    form.setFieldValue(key, res)
  }

  return (
    <M22Box className="pd-0">
      <Row
        className={`m22-box-light border-card-header px-24 py-16 ${!showFilter ? 'cursor-pointer' : ''}`}
        onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}>
        <Row
          wrap={false}
          gutter={8}
          align="middle"
          onClick={() => setShowFilter(!showFilter)}
          className={`width100pc cursor-pointer dpl-flex justify-content-between ${showFilter ? 'mg-bt-16' : ''}`}>
          <Col>
            <span className={`m22-typo-heading ${theme} ${!showFilter ? 'mg-bt-0' : ''}`}>{t('packages.searchHead')}</span>
          </Col>
          <Col className="dpl-flex justify-content-end align-items-center">
            <i
              className={`fas fa-angle-${showFilter ? 'up' : 'down'}  fsz-14 cursor-pointer`}
              onClick={() => setShowFilter(!showFilter)}></i>
          </Col>
        </Row>
        {showFilter && (
          <>
            <Col
              span={24}
              className="dpl-flex align-items-center mg-bt-9">
              <span className={`dpl-flex align-items-baseline flex-wrap`}>
                {statuses.map(({ code, name }: any) => {
                  let isSelect = false
                  if (selectedStatuses) {
                    let temp = selectedStatuses?.statuses?.split(',')
                    if (temp && temp.includes(code)) isSelect = true
                  }
                  return (
                    <span
                      className={`txt-capitalize mg-r-6 ${isSelect ? 'm22-badge active' : 'm22-badge'} ${theme}`}
                      key={code}
                      onClick={() => {
                        onChangeFilterMany('statuses', !isSelect, code)
                      }}>
                      {name}
                    </span>
                  )
                })}
              </span>
            </Col>
            <Col span={24}>
              {!expand && (
                <Form
                  name="package-filter"
                  onFinish={onFinish}
                  className="package-filter"
                  form={form}
                  initialValues={{
                    orderCode: '',
                    packageCode: '',
                    createdAt: [],
                    cutOffStatus: undefined,
                    handlingTimeFrom: '',
                    handlingTimeTo: '',
                    handlingTime: '',
                    fromInRange: '',
                    toInRange: '',
                    timeInEqual: '',
                    fromOnlyFrom: '',
                    timeOnlyTo: '',
                    typeSearch: 'range',
                  }}
                  onValuesChange={(changedValues, allValues) => {
                    filterChangeHandler(allValues)
                  }}>
                  <Row
                    gutter={[12, 0]}
                    className="mg-bt-12">
                    <Col span={8}>
                      <ComboBox
                        form={form}
                        inputName="orderCode"
                        label={t('packages.placeHolderOrderCode')}
                        labelWidth={0}>
                        <Item name={'orderCode'}>
                          <Input
                            allowClear
                            size="large"
                          />
                        </Item>
                      </ComboBox>
                    </Col>
                    <Col span={8}>
                      <ComboBox
                        form={form}
                        inputName="packageCode"
                        label={t('packages.placeHolderPackageCode')}
                        labelWidth={0}>
                        <Item name={'packageCode'}>
                          <Input
                            allowClear
                            size="large"
                          />
                        </Item>
                      </ComboBox>
                    </Col>

                    <Col span={8}>
                      <ComboBox
                        form={form}
                        label={t('packages.fromDate')}
                        labelRange={t('packages.toDate')}
                        isRange
                        inputName="createdAt"
                        labelWidth={0}>
                        <Item
                          label={''}
                          name="createdAt">
                          <RangePicker
                            style={{ height: '44px' }}
                            size="large"
                            placeholder={['', '']}
                            className="width100pc"
                            format={'DD-MM-YYYY'}
                            suffixIcon={<i className="far fa-calendar-alt" />}
                            //@ts-ignore
                            placement="topRight"
                          />
                        </Item>
                      </ComboBox>
                    </Col>
                    <Col span={8}>
                      <ComboBox
                        form={form}
                        label={t('packages.statusStopped')}
                        inputName="cutOffStatus"
                        labelWidth={0}>
                        <Item
                          labelAlign="left"
                          className="status-cutoff align-items-center"
                          name="cutOffStatus">
                          <Select
                            suffixIcon={<i className="fa-solid fa-angle-down"></i>}
                            placeholder={''}
                            allowClear
                            size="large"
                            popupClassName={theme}>
                            {statuses.map((stt: any) => (
                              <Option
                                key={stt.code}
                                value={stt.code}
                                className={''}>
                                <span className={' fsz-14 line-h-22'}>{lodash.get(stt, 'name', '')}</span>
                              </Option>
                            ))}
                          </Select>
                        </Item>
                      </ComboBox>
                    </Col>
                    <Col span={16}>
                      <Row gutter={[6, 12]}>
                        <Col span={8}>
                          <ComboBox
                            form={form}
                            label={'Khoảng thời gian, số ngày ...'}
                            inputName="typeSearch">
                            <Item
                              name="typeSearch"
                              className="mg-r-4"
                              valuePropName="option">
                              <Select
                                suffixIcon={<i className="fa-solid fa-angle-down"></i>}
                                size="large"
                                showSearch={false}
                                value={typeSearch}
                                onChange={(val) => handleChangeTypeSearch(val)}
                                popupClassName={theme}>
                                <Option value="range">
                                  <span className={labelClasses}>{t('packages.rangeHandlingTime')}</span>
                                </Option>
                                <Option value="equal">
                                  <span className={labelClasses}>{t('packages.equalHandlingTime')}</span>
                                </Option>
                                <Option value="from">
                                  <span className={labelClasses}>{t('packages.fromHandlingTime')}</span>
                                </Option>
                                <Option value="to">
                                  <span className={labelClasses}>{t('packages.toHandlingTime')}</span>
                                </Option>
                              </Select>
                            </Item>
                          </ComboBox>
                        </Col>
                        <Col span={16}>
                          {typeSearch === 'range' ? (
                            <Row
                              // className="width100pc"
                              // gutter={6}
                              align="middle"
                              // justify={'end'}
                              // wrap={false}>
                            >
                              <Col
                                span={12}
                                flex="1 0 auto">
                                <ComboBox
                                  form={form}
                                  label={t('packages.placeHolderHandlingTimeFrom')}
                                  inputName="fromInRange">
                                  <Item
                                    colon={false}
                                    name="fromInRange"
                                    className="mg-r-4 mg-bt-0"
                                    style={{ marginBottom: 0 }}>
                                    <Input
                                      onChange={(e) => onChange('fromInRange', e.target.value)}
                                      allowClear
                                      size="large"
                                      name="fromInRange"
                                      suffix={<span className=" fsz-14 line-h-20 txt-color-gray4">({t('packages.date')})</span>}
                                    />
                                  </Item>
                                </ComboBox>
                              </Col>

                              <Col span={12}>
                                <ComboBox
                                  form={form}
                                  label={t('packages.placeHolderHandlingTimeTo')}
                                  inputName="toInRange">
                                  <Item
                                    name="toInRange"
                                    className="mg-r-4 mg-bt-0">
                                    <Input
                                      onChange={(e) => onChange('toInRange', e.target.value)}
                                      allowClear
                                      size="large"
                                      name="toInRange"
                                      suffix={<span className=" fsz-14 line-h-20 txt-color-gray4">({t('packages.date')})</span>}
                                    />
                                  </Item>
                                </ComboBox>
                              </Col>

                              {/*<Col flex={'0 0 auto'}>*/}
                              {/*  <span className=" fsz-14 line-h-20 txt-color-gray4">{t('packages.date')}</span>*/}
                              {/*</Col>*/}
                            </Row>
                          ) : typeSearch === 'equal' ? (
                            <ComboBox
                              label={t('packages.placeHolderForHandlingTimeNotRange')}
                              form={form}
                              inputName={'timeInEqual'}>
                              <Item name={'timeInEqual'}>
                                <Input
                                  onChange={(e) => onChange('timeInEqual', e.target.value)}
                                  allowClear
                                  size="large"
                                />
                              </Item>
                            </ComboBox>
                          ) : typeSearch === 'from' ? (
                            <>
                              <ComboBox
                                form={form}
                                label={t('packages.placeHolderForHandlingTimeNotRange')}
                                inputName={'timeOnlyFrom'}>
                                <Item name={'timeOnlyFrom'}>
                                  <Input
                                    onChange={(e) => onChange('timeOnlyFrom', e.target.value)}
                                    allowClear
                                    size="large"
                                  />
                                </Item>
                              </ComboBox>
                            </>
                          ) : (
                            <ComboBox
                              form={form}
                              label={t('packages.placeHolderForHandlingTimeNotRange')}
                              inputName={'timeOnlyTo'}>
                              <Item name={'timeOnlyTo'}>
                                <Input
                                  onChange={(e) => onChange('timeOnlyTo', e.target.value)}
                                  allowClear
                                  size="large"
                                />
                              </Item>
                            </ComboBox>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
              <div className="flex justify-content-between align-items-center">
                <span
                  className="m22-typo-label_collapse"
                  onClick={() => setExpand(!expand)}>
                  <i className={`fa-solid fa-angle-${expand ? 'down' : 'up'}`}></i>
                  <span className="line-h-20 fsz-12">{!expand ? t('shipments.collapseFilter') : t('shipments.expandFilter')}</span>
                </span>
                <div className="dpl-flex justify-content-end align-items-center flex-nowrap">
                  {showCleanFilter && (
                    <span
                      className={`m22-btn-reset ${theme}`}
                      onClick={clearFilter}>
                      <ReloadOutlined className="mg-r-4" />
                      <span className="mg-0">{t('packages.refreshFilter')}</span>
                    </span>
                  )}

                  <Button
                    className="m22-btn"
                    type="primary"
                    htmlType="submit"
                    form="package-filter"
                    onClick={onFinish}
                    icon={<i className="fa-regular fa-magnifying-glass"></i>}>
                    {t('packages.search')}
                  </Button>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </M22Box>
  )
}

export default Filter
