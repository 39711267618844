import { Button, Image, Table } from 'antd'
import { t } from 'i18next'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import MainLayout from '../../../components/Layout'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../components/SkeletonTable'
import M22Box from 'src/components/M22Box'
import { useTheme } from '../../../hooks/useTheme'
import deliverySuccessDark from '../../../resources/images/deliverySuccessDark.png'
import deliverySuccessLIght from '../../../resources/images/deliverySuccessLight.png'

const Success: React.FC = () => {
  const location = useLocation()
  const data = location.state || 'aa'
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [theme] = useTheme()
  const orders = lodash.flattenDeep(lodash.map(data, 'orders'))
  const selectedOrders = lodash.flattenDeep(lodash.map(data, 'selectedOrders'))
  const orderSucceed = orders.filter((x: any) => lodash.includes(selectedOrders, x?.code))
  
  const columnsOrderList = [
    {
      title: <span className="robotomedium  fsz-14 line-h-22 whitespace">{t('delivery.orders')}</span>,
      key: 'orderCode',
      render: (record: any) => {
        return <span className="txt-primary-2 robotomedium fsz-14 line-h-22">{record?.code}</span>
      },
    },
    {
      title: <span className="robotomedium  fsz-14 line-h-22 whitespace">{t('delivery.statusSuccessPage')}</span>,
      key: 'success',
      align: 'right' as 'right',
      render: (record?: any) => {
        const requestByAccount = data.find((x: any) => lodash.includes(x.selectedOrders, record?.code))
        return (
          <span
            className={`${
              lodash.get(requestByAccount, 'success') ? 'bg-success-request-delivery' : 'bg-error-request-delivery'
            } fsz-14 line-h-22  border-radius25 px-16 py-6`}>
            {lodash.get(requestByAccount, 'success') ? (
              <span className="txt-color-success">{t('delivery.successOrder')}</span>
            ) : (
              <span className="txt-color-red2">{t('delivery.failedOrder')}</span>
            )}
          </span>
        )
      },
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const image = theme === 'dark' ? deliverySuccessDark : deliverySuccessLIght
  return (
    <MainLayout title={t('delivery.success')}>
      <M22Box className="pd-24 rad-12 h-96pc">
        <div className="txt-center">
          <Image
            height={200}
            src={image}
            preview={false}
          />
          <div className="robotomedium txt-primary-2 fsz-30 line-h-24 mg-t-24">{t('delivery.successfully')}</div>
          <Button
            onClick={() => navigate('/delivery')}
            type="primary"
            className={`m22-btn mg-t-44 ${theme}`}>
            {t('delivery.successBack')}
            {<i className="far fa-arrow-right mg-l-6"></i>}
          </Button>
        </div>
        <div className="flex justify-content-center mg-t-44">
          <div className={`table-create-success ${theme}`}>
            <SkeletonTable
              rowCount={columnsOrderList.length}
              loading={loading}
              columns={columnsOrderList as SkeletonTableColumnsType[]}
              className="main-table"
              style={{ width: '500px' }}>
              <Table
                locale={{ emptyText: <EmptyDataFilter description={t('delivery.orderRequest')} /> }}
                dataSource={orderSucceed}
                columns={columnsOrderList}
                className={`main-table m22-table ${theme} `}
                pagination={false}
                style={{ width: '500px' }}
              />
            </SkeletonTable>
          </div>
        </div>
      </M22Box>
    </MainLayout>
  )
}

export default Success