import axios, {AxiosResponse} from 'axios'
import lodash from 'lodash'
import {API_BASE_URL, DEFAULT_TENANT, TENANT_KEY, TOKEN_KEY} from '../core/config'
import {httpConfig} from '../core/config/http'
import {url} from '../core/helpers/string'
import {Repository} from '../core/repositories/Repository'
import {ClaimReasonInterface} from '../Interface/ClaimReasonInterface'
import {ClaimSolutionInterface} from '../Interface/ClaimSolutionInterface'
import { localStorageRead } from 'src/utils/LocalStorageUtils'

export class CategoryRepository extends Repository {
    constructor() {
        super(httpConfig)
        this.setBaseURL(url(API_BASE_URL, 'categories'))
    }

    public getConnectionsStatus = (): Promise<any> => {
        return this.http.get(`connection-statuses`, {}).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }
    public getStatuses = (): Promise<any> => {
        return this.http.get(`order-statuses`, {params: {size: 1000}}).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }
    public getMarketPlaces = (): Promise<any> => {
        return this.http.get(`/marketplaces`, {params: {size: 1000}}).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }
    public getProvider = (): Promise<any> => {
        return this.http.get('providers', {params: {size: 1000}}).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getPackageStatuses = (): Promise<any> => {
        return this.http.get(`package-statuses`).then((response: AxiosResponse<any>) => {
            return lodash.sortBy(response.data, 'position')
        })
    }

    public getProviderByCode = (providerCode: string): Promise<any> => {
        return this.http.get(`providers/${providerCode}`)
    }
    public getProviderByRefCode = (refCode: string): Promise<any> => {
        return this.http.get(`providers/ref/${refCode}`)
    }
    public getRequestDeliveryStatuses = (): Promise<any> => {
        return this.http.get(`delivery-request-statuses`).then((response: AxiosResponse<any>) => {
            return lodash.sortBy(response.data, 'position')
        })
    }
    public getFaqs = (provider: string): Promise<any> => {
        return this.http.get(`/faqs/${provider}/recharge_instructions`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getFaqsByShipment = (): Promise<any> => {
        return this.http.get(`/faqs/shipment/recharge_instructions`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getPaymentStatuses = (): Promise<any> => {
        return this.http.get(`/payment-statuses`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getPaymentMethods = (): Promise<any> => {
        return this.http.get(`/payment-methods`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }
    public getTransactionStatuses = (): Promise<any> => {
        return this.http.get(`/transaction-statuses`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }
    public getTransactionTypes = (): Promise<any> => {
        return this.http.get(`/transaction-types`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getPaymentRequestTypes = (): Promise<any> => {
        return this.http.get(`/payment-request-types`).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    public getShipmentStatuses = (): Promise<any> => {
        const tenant = localStorageRead(TENANT_KEY)
        return this.http.get(`/shipment-statuses`,{
            headers: {
                'Authorization': `Bearer ` + localStorageRead(TOKEN_KEY),
                'X-Tenant': lodash.get(tenant, 'code', DEFAULT_TENANT),
            }
        }).then((response: AxiosResponse<any>) => {
            return response.data
        })
    }

    getReasonsOfClaim = (ticketTypes: any): Promise<ClaimReasonInterface[]> => {
        return this.http
            .get(`/claim-reasons`, {
                params: {offset: 0, limit: 100, ticketTypes},
            })
            .then((response: AxiosResponse) => {
                return response.data
            })
    }
    getSolutionsOfClaim = (ticketTypes?: any): Promise<ClaimSolutionInterface[]> => {
        let ticketsTypeTemp:any = ['order'];
        if(ticketTypes) {
            ticketsTypeTemp = ticketTypes
        }
        return this.http.get(`/claim-solutions`, {
            params: {
                offset: 0,
                limit: 100,
                ticketTypes : ticketsTypeTemp
            }
        }).then((response: AxiosResponse) => {
            return response.data
        })
    }
    public getShipmentPackageStatuses = (): Promise<any> => {
        return this.http.get(`/parcel-statuses`, {params: {size: 1000}}).then((response: AxiosResponse) => {
            return lodash.sortBy(response.data, 'position')
        })
    }

    public getIndustryGroup = (): Promise<any> => {
        return this.http.get(`/harmonized-commodities`).then((response: AxiosResponse) => {
            return response.data
        })
    }
   
    public getClaimStatuses = (): Promise<any> => {
        return this.http
            .get(`/claim-states`, {
                params: {offset: 0, limit: 100},
            })
            .then((response: AxiosResponse) => {
                return response.data
            })
    }

    public getAccountBalance = () => {
        const tenant = localStorageRead(TENANT_KEY)
        return axios.get(`${API_BASE_URL}/connections/account-balance`,{
            headers: {
                'Authorization': `Bearer ` + localStorageRead(TOKEN_KEY),
                'X-Tenant': lodash.get(tenant, 'code', DEFAULT_TENANT),
            }
        }).then((response)=> {
            return response.data
        }).catch((error)=> {
        })
    }
}

export const categoryRepository: CategoryRepository = new CategoryRepository()
