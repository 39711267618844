import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { formatNumber } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import { categoryRepository } from '../../../../repositories/CategoryRepository'
import EmptyDataFilter from '../../../Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../SkeletonTable'
import TrackPackage from './TracksPackage'
interface OrderItemsProps {
	packages: any
	loading: boolean
	orderDetail: any
}

const Packages: React.FC<OrderItemsProps> = (props) => {
	const [theme] = useTheme()
	const [statuses, setStatuses] = useState([])
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const { packages, loading } = props
	const { t } = useTranslation()

	useEffect(() => {
		getStatuses()
	}, [])

	const getStatuses = () => {
		categoryRepository.getPackageStatuses().then((res) => {
			setStatuses(res)
		})
	}

	const packageInfoText = 'line-h-22 whitespace'

	const columns: ColumnsType<any> = [
		{
			title: '#',
			key: 'index',
			width: '5%',
			render: (_: string, __: any, index: number) => (
				<>
					<span className={packageInfoText}>{++index}</span>
				</>
			),
		},
		{
			title: t('package.code'),
			key: 'code',
			dataIndex: 'code',
			width: '15%',
			render: (code: string) => <span className={`${packageInfoText} font-medium`}>{code}</span>,
		},
		{
			title: (
				<span>
					{t('package.weight')} {t('package.noteForWeight')}
				</span>
			),
			align: 'right',
			key: 'info',
			width: '30%',
			render: (record: any) => {
				const emptyTxt = '---'
				const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
					? emptyTxt
					: lodash.get(record, 'actualWeight', emptyTxt)
				const netWeight = lodash.isNull(lodash.get(record, 'netWeight'))
					? emptyTxt
					: lodash.get(record, 'netWeight', emptyTxt)
				const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
					? emptyTxt
					: lodash.get(record, 'packagingWeight', emptyTxt)
				const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
					? emptyTxt
					: lodash.get(record, 'dimensionalWeight', emptyTxt)
				return (
					<span className={`${packageInfoText} font-medium txt-color-gray8`}>{`${formatNumber(
						actualWeight
					)}kg / ${formatNumber(netWeight)}kg / ${formatNumber(packagingWeight)} kg / ${formatNumber(
						dimensionalWeight
					)}kg`}</span>
				)
			},
		},
		{
			title: (
				<>
					<span className='mg-r-4 fsz-12'>{t('package.details')}</span>
					<span className='fsz-12'>{t('package.noteForDetail')}</span>
				</>
			),
			key: 'details',
			width: '20%',
			render: (record: any) => {
				const emptyTxt = '---'
				const length = lodash.isNull(lodash.get(record, 'length'))
					? emptyTxt
					: lodash.get(record, 'length', emptyTxt)
				const width = lodash.isNull(lodash.get(record, 'width'))
					? emptyTxt
					: lodash.get(record, 'width', emptyTxt)
				const height = lodash.isNull(lodash.get(record, 'height'))
					? emptyTxt
					: lodash.get(record, 'height', emptyTxt)
				return (
					<span className={`${packageInfoText} font-medium txt-color-gray8`}>{`${formatNumber(
						length
					)} x ${formatNumber(width)} x ${formatNumber(height)} (cm)`}</span>
				)
			},
		},
		{
			title: <span className='fsz-12'>{t('package.update')}</span>,
			key: 'notice',
			dataIndex: 'lastStatusTime',
			width: '15%',
			render: (lastStatusTime: any) => (
				<span className={`${packageInfoText} txt-color-gray8`}>
					{lodash.isNull(lastStatusTime) ? t('orderDetail.undefined') : formatDateTime(lastStatusTime)}
				</span>
			),
		},
		// {
		// 	title: t('common.codAmount'),
		// 	key: 'codAmount',
		// 	dataIndex: 'codAmount',
		// 	align: 'right',
		// 	render: (codAmount: number, record: any) => record.isCod && <span className="txt-color-red2 font-medium whitespace">{formatMoneyByUnit(codAmount)}</span>,
		// },
		{
			title: <span className='fsz-12'>{t('package.status')}</span>,
			key: 'notice',
			dataIndex: 'status',
			align: 'right',
			width: '15%',
			render: (status: string) => {
				const statusObj = statuses.find(({ code }) => code === status)
				const statusName = lodash.get(statusObj, 'name', '---')

				return (
					<span
						className={`whitespace txt-color-white status-view`}
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}
					>
						{statusName}
					</span>
				)
			},
		},
	]

	const localeCustom = {
		emptyText: <>{!props.loading ? <EmptyDataFilter description={t('orderDetail.packagesEmpty')} /> : null}</>,
	}
	const total = packages.length
	return (
		<SkeletonTable
			columns={columns as SkeletonTableColumnsType[]}
			rowCount={3}
			loading={loading}
			className={`package-list`}
		>
			<Table
				rowKey={(record: any) => record.id}
				columns={columns}
				dataSource={packages}
				locale={localeCustom}
				loading={loading}
				className={`main-table table-expandable selected-table m22-table  detail-table  ${theme}`}
				pagination={false}
				size='small'
				onRow={(record, index) => {
					return { ...record, total, index, statuses }
				}}
				expandRowByClick={true}
				expandable={{
					expandedRowRender: (record) => {
						return record.milestones.length > 0 ? (
							<TrackPackage statuses={statuses} loading={loading} orderHistory={record.milestones} />
						) : (
							<EmptyDataFilter description={t('package.noData')} /> // <EmptyDataFilter description={t('packages.empty')} />
						)
					},
					columnWidth: 0,
					expandIcon: ({ expanded, onExpand, record }) =>
						expanded ? (
							<i
								className='mg-l-10 far fa-angle-down cursor-pointer'
								onClick={(e) => onExpand(record, e)}
							></i>
						) : (
							<i
								className='mg-l-10 far fa-angle-right cursor-pointer'
								onClick={(e) => onExpand(record, e)}
							></i>
						),
					onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
				}}
				rowClassName={(record, idx) => {
					const rowClassesByIdx = idx % 2 === 0 ? 'row-light' : 'row-dark'
					const rowClassByCode = lodash.includes(expandedRows, record.id)
						? 'active-row cursor-pointer show-expand-icon'
						: 'data-row cursor-pointer'
					return `${rowClassesByIdx} ${rowClassByCode}`
				}}
			/>
		</SkeletonTable>
	)
}
export default Packages

