import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import { ClaimInterface } from '../../../Interface/ClaimInterface'
import ShipmentFeeInterface from '../../../Interface/ShipmentFeeInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentPackageInterface from '../../../Interface/ShipmentPackageInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { claimRepository } from '../../../repositories/ClaimRepository'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import { threadRepository } from '../../../repositories/ThreadRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import Finance from './Finance'
import Heading from './Heading'
import Information from './Information'
import './shipment.styles.scss'
import TabLayout from './TabLayout'
import { BoxChatContext } from '../../../context/BoxChatContext'
import BoxChat from '../../../components/BoxChat'
import { SHIPMENT_COMMENT } from '../../../core/config'
import { useTheme } from '../../../hooks/useTheme'
import TrackOrder from 'src/components/UI/molecules/TrackOrder'
import { scrollToElementId } from 'src/core/helpers/dom'

export interface PaginationInterface {
  pageSize: number
  current: number
  total: number
}

export interface UpdatableInterface {
  refShipment: boolean
  refCustomer: boolean
  industryGroup: boolean
  personalNote: boolean
  productInfo: boolean
  other: boolean
}

const Shipment = () => {
  const { t } = useTranslation()
  const { code } = useParams()
  const [theme] = useTheme()

  const [shipment, setShipment] = useState<ShipmentInterface>()
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState<ShipmentStatusInterface[]>([])
  const [milestones, setMilestones] = useState([])
  const [fees, setFees] = useState<ShipmentFeeInterface[]>([])
  const [expand, setExpand] = useState(true)
  const [updatable, setUpdatable] = useState<UpdatableInterface>()
  const [canceling, setCanceling] = useState(false)
  const [cancelable, setCancelable] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [loadingFees, setLoadingFees] = useState(false)
  const [packages, setPackages] = useState<ShipmentPackageInterface[]>([])
  const [loadingPackages, setLoadingPackages] = useState(false)
  const [claims, setClaims] = useState<ClaimInterface[]>([])
  const [loadingClaims, setLoadingClaims] = useState(false)
  const [productUpdatable, setProductUpdatable] = useState(true)
  const [createAbleWaybill, setCreateAbleWaybill] = useState(false)
  const [loadingCreateThread, setLoadingCreateThread] = useState(false)
  const boxChatContext = useContext(BoxChatContext)
  const [visibleCancel, setVisibleCancel] = useState<boolean>(false)

  const fetchDetails = useCallback(async () => {
    setLoading(true)
    try {
      const res = await shipmentRepository.getShipmentDetails(code!)
      setShipment(res)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [code])

  const getStatuses = useCallback(async () => {
    try {
      const res = await categoryRepository.getShipmentStatuses()
      setStatuses(res)
    } catch (err) {}
  }, [])

  const handleTrackOrder = useCallback(async () => {
    try {
      const res = await shipmentRepository.getShipmentMilestone(code!)
      setMilestones(res)
    } catch (err) {}
  }, [code])

  const getFees = useCallback(async () => {
    setLoadingFees(true)
      try {
        const res = await shipmentRepository.getFees(code!)
        setFees(res)
        setLoadingFees(false)
      } catch (err) {
        setLoadingFees(false)
      }
  }, [code])

  const cancelShipment = useCallback(async () => {
    setCanceling(true)
    try {
      await shipmentRepository.cancelShipment(code!)
      M24Notification.notifySuccess('', t('shipment.cancelSuccess'), '', 6)
      fetchDetails()
      setVisibleCancel(false)
      handleTrackOrder()
      getFees()
      setCanceling(false)
    } catch (err) {
      M24ErrorUtils.showError(t, err)
      setCanceling(false)
    }
  }, [code, fetchDetails, handleTrackOrder, t, getFees])
  console.log(milestones)
  useEffect(() => {
    handleTrackOrder()
    getFees()
  }, [handleTrackOrder, getFees])

  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  useEffect(() => {
    fetchDetails()
  }, [fetchDetails])

  useEffect(() => {
    const statusObj = statuses.find((stt: ShipmentStatusInterface) => stt.code === shipment?.status)

    if (shipment?.status !== 'CANCELLED') {
      const updatable = !!statusObj?.updatable
      setUpdatable({
        refShipment: updatable,
        refCustomer: updatable,
        industryGroup: updatable,
        personalNote: updatable,
        productInfo: updatable,
        other: updatable,
      })
    } else {
      setUpdatable({
        refShipment: true,
        refCustomer: true,
        industryGroup: false,
        personalNote: true,
        productInfo: false,
        other: false,
      })
    }
    setCancelable(statusObj?.cancellable)
    setProductUpdatable(!!statusObj?.productUpdatable)
    setCreateAbleWaybill(!!statusObj?.updatable)
  }, [shipment?.status, statuses])

  const getTransactions = useCallback(async () => {
      try {
        const res = await shipmentRepository.getFinancial(code!)
        setTransactions(res)
      } catch (err) {}
  }, [code])

  const getShipmentPackages = useCallback(async () => {
    setLoadingPackages(true)
    try {
      const res = await shipmentRepository.getShipmentPackages(code!)
      setPackages(res)
      setLoadingPackages(false)
    } catch (err) {
      setLoadingPackages(false)
    }
  }, [code])

  const getClaimOfShipment = useCallback(async () => {
    if (shipment?.code) {
      setLoadingClaims(true)
      try {
        const res = await claimRepository.getClaimsOrderAndShipment(shipment?.code)
        setClaims(res.data)
        setLoadingClaims(false)
      } catch (err: any) {
        setLoadingClaims(false)
      }
    }
  }, [shipment?.code])

  useEffect(() => {
    getTransactions()
    getShipmentPackages()
    getClaimOfShipment()
  }, [getShipmentPackages, getTransactions, getClaimOfShipment])

  const showChatChangeHandler = useCallback(() => {
    setLoadingCreateThread(true)
    threadRepository
      .createThread({
        referenceCode: code!,
        type: SHIPMENT_COMMENT,
      })

      .then((response) => {
        if (!boxChatContext.visible) {
          boxChatContext.toggle()
        }
        boxChatContext.onFilterThreads({}).then(() => {
          if (boxChatContext.thread.id !== response.data.id) {
            boxChatContext.onSelectThread(response.data)
          }
        })
      })
      .finally(() => setLoadingCreateThread(false))
  }, [boxChatContext, code])


  return (
    <div id="shipmentContainer">
      <MainLayout title={`${t('menu.shipmenDetails')} #${code}`}>
        <Heading
          shipment={shipment}
          statuses={statuses}
          cancelShipment={cancelShipment}
          visibleCancel={visibleCancel}
          setVisibleCancel={setVisibleCancel}
          canceling={canceling}
          currencyMarketPalace={shipment?.currency}
          cancelable={cancelable}
          loading={loading}
          showChat={showChatChangeHandler}
          loadingCreateThread={loadingCreateThread}
        />

        <div className={`shipment-details ${theme}`}>
          <div className="shipment-details__text">
            <div className={`shipment-details__text-top ${expand ? 'expand' : 'collapsed'}`}>
              <Information
                shipment={shipment}
                expand={expand}
                updatable={updatable}
                loading={loading}
                shipmentCode={code!}
              />
              <Finance
                shipment={shipment}
                fees={fees}
                expand={expand}
                loading={loading}
                loadingFees={loadingFees}
              />

              <div
                className={`shipment-collapse txt-muted-2 ${expand ? 'expand' : 'collapsed'}`}
                onClick={() => {
                  setExpand(!expand)
                  scrollToElementId('shipmentContainer')
                }}>
                {t(`shipment.${expand ? 'collapse' : 'noCollapse'}`)}
                <i className={`mg-l-8 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>{' '}
              </div>
            </div>

            <TabLayout
              shipmentStatuses={statuses}
              currency={shipment?.currency as string}
              currencyMarketPalace={shipment?.currency}
              transactions={transactions}
              loading={loading}
              packages={packages}
              loadingPackages={loadingPackages}
              claims={claims}
              loadingClaim={loadingClaims}
              shipment={shipment}
              productUpdatable={productUpdatable}
              createAbleWaybill={createAbleWaybill}
            />
          </div>

          <div className="shipment-details__milestone">
            <TrackOrder
              statuses={statuses}
              orderHistory={milestones}
              loading={loading}
            />
          </div>
        </div>

        {code && shipment && (
          <BoxChat
            threadCreatePayload={{
              referenceCode: code,
              type: SHIPMENT_COMMENT,
              thumbnail: shipment.image,
            }}
          />
        )}
      </MainLayout>
    </div>
  )
}

export default Shipment
