import React from 'react'
import SizeChanger from "../Pagination/SizeChanger";
import {DEFAULT_PAGE_SIZE} from "../../core/config";


interface M22QuickPaginationProps {
    filter: any
    pagination: any
    onPageChange: (filter: any) => void
}

const QuickPagination = (props: M22QuickPaginationProps) => {
    const {
        filter, pagination, onPageChange,
    } = props;

    const handleNextPage = () => {
        onPageChange({
            ...filter,
            page: +(pagination.current) + 1,
            size: pagination.pageSize || pagination.size
        })
    }

    const handlePreviousPage = () => {
        if (pagination.current > 1)
            onPageChange({
                ...filter,
                page: +(pagination.current) - 1,
                size: pagination.pageSize || pagination.size
            })
    }
    if (pagination?.total < 1) {
        return null
    }

    return (
        <>
            {pagination?.total > 0 &&
                <div className={'m22-quick-pagination'}>
                    {
                        pagination.pageSize <= pagination.total && <div className={'m-r-2'}>
                        <span className={'badge'}>
                             <span>Trang &nbsp;{(pagination.total > 0 && pagination.current) || 0}/{Math.ceil(pagination.total / pagination.pageSize) || 0}</span>
                            {
                                pagination.current > 1 ? <span
                                        className={'mg-l-10 mg-r-6 previous'}
                                        onClick={() => handlePreviousPage()}
                                    ><i className="fa-solid fa-angle-left"></i></span>
                                    : <span className={'mg-l-10 mg-r-6'}>
                                <i className="fa-duotone fa-angle-left"></i>
                            </span>
                            }
                            {
                                pagination.current < (pagination.total / pagination.pageSize) ? <span
                                        className={'mg-l-6 mg-r-6 next'}
                                        onClick={() => handleNextPage()}
                                    ><i className="fa-solid fa-angle-right"></i></span>
                                    : <span className={'mg-l-10 mg-r-6'}>
                                        <i className="fa-duotone fa-angle-right"></i>
                                    </span>
                            }
                        </span>
                        </div>
                    }
                    <SizeChanger onChange={(size) => onPageChange({
                        ...filter, size, page: 1, pageSize: size
                    })} size={+(filter?.size) || +(filter?.size) || +(pagination?.pageSize) || DEFAULT_PAGE_SIZE}/>
                </div>
            }
        </>

    )
}

export default QuickPagination;
