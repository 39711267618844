import { Drawer, Image, Layout, Menu, Switch } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { TENANT_KEY } from '../../core/config'
import { useTheme } from '../../hooks/useTheme'
import Logo from '../../resources/images/sapo-logo.png'
import LogoDark from '../../resources/images/sapo-logo-dark.png'
import { localStorageRead } from '../../utils/LocalStorageUtils'
import LightAvt from '../../resources/images/icon/twemoji_sun-behind-cloud.png'
import DarkAvt from '../../resources/images/icon/noto_crescent-moon.png'

const { Sider } = Layout

interface SidebarProps {
  activeKey: string
  currentConfigServices: any
  handleShowSubMenu: (val: boolean) => void
  openSubmenu: boolean
  handleOpenNestedDrawer: (val: boolean) => void
  openNestedDrawer: boolean
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const [theme] = useTheme()
  const { activeKey, openSubmenu, currentConfigServices, handleShowSubMenu, openNestedDrawer, handleOpenNestedDrawer } = props
  const [, setCurrentConfigShowBalance] = useState()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [menu, setMenu] = useState<any>()
  const [currentMenuItems, setCurrentMenuItems] = useState<any>([])
  const [currentChildrenDrawer, setCurrentChildrenDrawer] = useState<any>()
  const location = useLocation()

  const handleChangeDrawer = (items: any) => {
    if (!openSubmenu) {
      handleShowSubMenu(true)
    }
    setCurrentMenuItems(items)
    handleOpenNestedDrawer(false)
  }

  useEffect(() => {
    let connectItems = {
      title: t('menu.connect'),
      items: [
        {
          key: 'warehouselist',
          icon: (
            <i
              className={`fa-${activeKey === 'warehouselist' ? 'regular' : 'light'} fa-map-location-dot fsz-14 ${activeKey === 'warehouselist' ? '' : 'txt-color-gray8'}`}
            />
          ),
          label: t('menu.warehouselist'),
        },
      ],
    }

    let productItems = {
      title: t('menu.products'),
      items: [
        {
          key: 'warehouse/stored-products',
          icon: <i className={`fa-light fa-shop fsz-14 ${activeKey === 'stored-products' ? '' : 'txt-color-gray8'}`}></i>,
          label: t('menu.storedProductList'),
        },
      ],
    }

    let financeItems = {
      title: t('menu.finance'),
      items: [
        {
          key: 'transactions',
          icon: (
            <i
              className={`fa-${activeKey === 'transactions' ? 'regular' : 'light'} fa-arrow-right-arrow-left fsz-14 ${
                activeKey === 'transactions' ? '' : 'txt-color-gray8'
              }`}
            />
          ),
          label: t('menu.transactions'),
        },
      ],
    }

    let deliveryItems = {
      title: t('menu.packagesGroup'),
      items: [
        {
          key: 'packages',
          icon: (
            <i
              className={`fa-${activeKey === 'packages' ? 'regular' : 'light'} fa-cart-flatbed-boxes fsz-14 ${
                activeKey === 'packages' ? '' : 'txt-color-gray8'
              }`}
            />
          ),
          label: t('menu.packages'),
        },
        {
          key: 'delivery',
          icon: (
            <i
              className={`fa-${activeKey === 'delivery' ? 'regular' : 'light'} fa-hand-holding-box fsz-14 ${activeKey === 'delivery' ? '' : 'txt-color-gray8'}`}
            />
          ),
          label: t('menu.requestDelivery'),
        },
        {
          key: 'delivery-notes',
          icon: (
            <i
              className={`fa-${activeKey === 'delivery-notes' ? 'regular' : 'light'} fa-list-alt fsz-14 ${
                activeKey === 'delivery-notes' ? '' : 'txt-color-gray8'
              }`}
            />
          ),
          label: t('menu.delivery-notes'),
        },
      ],
    }

    let ordersItems = {
      title: t('menu.ordersGroup'),
      items: [
        {
          key: 'orders',
          icon: (
            <i
              className={`fa-${activeKey === 'orders' || activeKey.startsWith('orders') ? 'regular' : 'light'} 
          fa-paste fsz-14 ${activeKey === 'orders' || activeKey.startsWith('orders') ? '' : 'txt-color-gray8'}`}
            />
          ),
          label: t('menu.orders'),
        },
        {
          key: 'claims',
          icon: (
            <i
              className={`fa-${activeKey === 'claims' ? 'regular' : 'light'} fa-message-exclamation fsz-14 ${activeKey === 'claims' ? '' : 'txt-color-gray8'}`}
            />
          ),
          label: t('menu.tickets'),
        },

        {
          key: 'shipments',
          icon: (
            <i
              className={`fa-${activeKey === 'shipments' ? 'regular' : 'light'} fa-cart-flatbed-boxes fsz-14 ${
                activeKey === 'shipments' ? '' : 'txt-color-gray8'
              }`}></i>
          ),
          label: t('menu.shipments'),
        },
      ],
    }

    if (!currentConfigServices?.enableGsa) {
      connectItems?.items.filter((item) => item.key !== 'market-connect')
      productItems?.items.filter((item) => item.key !== 'warehouse/stored-products')
    }

    if (!currentConfigServices?.enableOrder) {
      ordersItems = { ...ordersItems, items: ordersItems.items.filter((item) => item.key !== 'orders') }
    }
    if (!currentConfigServices?.enableShipment) {
      ordersItems.items.filter((item) => item.key !== 'shipments')
      ordersItems = { ...ordersItems, items: ordersItems.items.filter((item) => item.key !== 'shipments') }
    }

    let items: any[] = [
      // ************************ ORDERS ************************
      {
        title: t('menu.home'),
        key: 'home',
        children: [],
        icon: (
          <Link
            to={'/'}
            className={`${activeKey === '' ? `menu-active ${theme}` : 'link-inactive'}`}>
            <i className={`fa-${activeKey === '' ? 'regular' : 'light'} fa-house ${activeKey === '' ? '' : 'txt-secondary-2'} `}></i>
          </Link>
        ),
        label: (
          <Link
            className={`txt-color-${activeKey === '' ? `black menu-text-active ${theme}` : 'gray7'} fsz-12 menu-item-label__${theme}`}
            to={'/'}>
            Trang chủ
          </Link>
        ),
      },
      {
        title: t('menu.ordersGroup'),
        key: 'orders-group',
        children: ordersItems?.items,
        icon: (
          <i
            className={`fa-${['orders', 'claims', 'shipments', 'shopping-cart'].includes(activeKey) ? 'regular' : 'light'} fa-ballot-check 
            ${
              ['orders', 'claims', 'shipments', 'shopping-cart'].includes(activeKey) || location.pathname.startsWith('/orders') ? `menu-active ${theme}` : ''
            } `}
            onMouseEnter={() => {
              handleChangeDrawer(ordersItems)
            }}></i>
        ),
        label: (
          <span
            className={`${
              activeKey === 'orders' ||
              activeKey === 'shipments' ||
              activeKey === 'peerpayments' ||
              activeKey === 'claims' ||
              activeKey === 'shopping-cart' ||
              location.pathname.startsWith('/orders')
                ? `menu-text-active ${theme}`
                : ''
            }`}>
            {t('menu.ordersGroup')}
          </span>
        ),
      },
      // ************************ FINANCE ************************

      {
        title: t('menu.finance'),
        key: 'finance',
        children: financeItems?.items,
        icon: (
          <i
            className={`fa-${activeKey === 'transactions' ? 'regular' : 'light'} fa-list-dropdown ${
              activeKey === 'transactions' ? `menu-active ${theme}` : ''
            }`}
            onMouseEnter={() => {
              handleChangeDrawer(financeItems)
            }}></i>
        ),
        label: <span className={`${activeKey === 'transactions' ? `menu-text-active ${theme}` : ''}`}>{t('menu.finance')}</span>,
      },

      // ************************ PACKAGES ************************
      {
        title: t('menu.packagesGroup'),
        key: 'delivery-group',
        children: deliveryItems?.items,
        icon: (
          <i
            className={`fa-${['packages', 'delivery', 'delivery-notes', 'lastmilebags'].includes(activeKey) ? 'regular' : 'light'} fa-truck ${
              ['packages', 'delivery', 'delivery-notes', 'lastmilebags'].includes(activeKey) ? `menu-active ${theme}` : ''
            }`}
            onMouseEnter={() => {
              handleChangeDrawer(deliveryItems)
            }}></i>
        ),
        label: (
          <span className={`${['packages', 'delivery', 'delivery-notes', 'lastmilebags'].includes(activeKey) ? `menu-text-active ${theme}` : ''}`}>
            {t('menu.packagesGroup')}
          </span>
        ),
      },

      // ************************ CONNECT ************************
      // {
      //   title: t('menu.packagesGroup'),
      //   label: (
      //     <span className={`${activeKey === 'connect-supplier' || activeKey === 'warehouselist' ? `menu-text-active ${theme}` : ''}`}>{t('menu.connect')}</span>
      //   ),
      //   key: 'connect',
      //   children: connectItems?.items,
      //   icon: (
      //     <i
      //       className={`fa-${activeKey === 'connect-supplier' || activeKey === 'warehouselist' ? 'regular' : 'light'}	
			// 		 fa-link ${activeKey === 'connect-supplier' || activeKey === 'warehouselist' ? `menu-active ${theme}` : ''}`}
      //       onMouseEnter={() => {
      //         handleChangeDrawer(connectItems)
      //       }}></i>
      //   ),
      // },
    ]
    const showBalance = lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '')
    if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder && !currentConfigServices?.enableGsa) {
      items = items.filter((item: any) => item?.key !== 'connect')
    }

    if (!currentConfigServices?.enableGsa) {
      items = items.filter((item: any) => item?.key !== 'warehouse')
    }
    if (!currentConfigServices?.enableGsa) {
      items = items.filter((item: any) => item?.key !== 'linked-channels')
    }
    setCurrentConfigShowBalance(showBalance)
    const menu = !showBalance ? items.filter((item) => item?.key !== 'transactions') : items
    setMenu(menu)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, currentConfigServices, t])

  return (
    <Sider
      collapsible
      collapsed={true}
      className={`sidebar-left ${theme}`}
      theme={theme}
      trigger={
        <div className="">
          <div className={`theme-icon-display ${theme}`}>
            <img
              src={theme === 'dark' ? DarkAvt : LightAvt}
              alt={theme === 'dark' ? 'dark-icon' : 'light-icon'}
              style={{ marginBottom: 8 }}
            />
            <Switch
              size={'small'}
              onChange={() => {
                localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light')
                window.location.reload()
              }}
              checked={theme === 'dark'}
            />
          </div>
        </div>
      }
      collapsedWidth={88}
      width={240}>
      <div className={`flex flex-col gap-4 align-items-center py-20 ${theme}`}>
        <Image
          width={34}
          height={32}
          preview={false}
          src={theme === 'dark' ? LogoDark : Logo}
          alt="sabo-logo"
          className="cursor-pointer"
          onClick={() => navigate('/')}
        />
      </div>
      <Drawer
        title={currentChildrenDrawer?.label}
        placement={'left'}
        closable={false}
        open={openNestedDrawer}
        className={`menu-drawer-child ${theme}`}
        width={308}
        mask={false}
        onClose={() => handleOpenNestedDrawer(false)}>
        <div className="flex flex-col">
          {currentChildrenDrawer?.items?.map((item: any) => (
            <Link
              to={`/${item.key}`}
              key={item.key}
              className={`flex align-items-center px-15 py-10 rad-16 ${activeKey === item.key ? 'bg-color-orange2' : 'bg-color-white'} icon menu-hovered`}>
              <span className="flex mg-r-10 align-items-center">{item.icon}</span>
              <span className={`txt-color-${activeKey === item.key ? 'black' : 'gray8'} fsz-14 line-h-22 robotolight`}>{item.label}</span>
            </Link>
          ))}
        </div>
      </Drawer>

      <Drawer
        className="menu-drawer"
        open={openSubmenu}
        title={currentMenuItems.title}
        placement={'left'}
        width={308}
        mask={false}
        closable={false}
        onClose={() => handleShowSubMenu(false)}
        contentWrapperStyle={{ left: 88, zIndex: 998 }}>
        <div className="flex flex-col mg-t-15">
          {currentMenuItems?.items?.map((item: any, idx: number) => {
            return item.dropdownItems ? (
              <div key={idx}>
                <div
                  className={`flex align-items-center px-20 py-10 rad-16 mg-bt-4 cursor-pointer ${
                    activeKey.includes(item.key) ? 'menu-expand-active' : ''
                  } menu-hovered icon px-20 ${idx === 0 ? 'mg-bt-4' : ''} ${theme}`}>
                  <span className=" mg-r-10">{item.icon}</span>
                  <span className={`txt-color-${activeKey.includes(item.key) ? 'black' : 'gray8'} fsz-14 line-h-22 robotolight`}>{item.label}</span>
                </div>
                <div className="flex flex-col px-18">
                  {item.dropdownItems.map((item: any, idx: number) => (
                    <div
                      key={idx}
                      className={`flex align-items-center px-20 py-10 rad-16 mg-bt-4 cursor-pointer menu-hovered ${
                        activeKey.includes(item.key) ? 'menu-expand-active bg-color-orange2' : 'bg-color-white'
                      } icon ${idx === 0 ? 'mg-bt-4' : ''} ${theme}`}
                      onMouseEnter={() => {
                        if (item.items?.length > 0) {
                          setCurrentChildrenDrawer(item)
                          handleOpenNestedDrawer(true)
                        } else {
                          setCurrentChildrenDrawer(null)
                          handleOpenNestedDrawer(false)
                        }
                      }}
                      onMouseLeave={() => {
                        if (currentChildrenDrawer?.key !== item.key) {
                          handleOpenNestedDrawer(false)
                          setCurrentChildrenDrawer(null)
                        }
                      }}>
                      <span className=" mg-r-10">{item.icon}</span>
                      <span className={`txt-color-${activeKey === item.key ? 'black' : 'gray8'} fsz-14 line-h-22 robotolight`}>{item.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Link
                to={`/${item.key}`}
                key={item.key}
                className={`flex align-items-center px-20 py-10 rad-16 mg-bt-4  menu-hovered ${
                  activeKey === item.key ? 'menu-expand-active bg-color-orange2' : 'bg-color-white'
                } icon ${theme}`}
                onMouseEnter={() => {
                  handleOpenNestedDrawer(false)
                  setCurrentChildrenDrawer(null)
                }}>
                <span className="flex mg-r-10 align-items-center">{item.icon}</span>
                <span
                  className={`txt-color-${activeKey === item.key ? 'black3' : 'gray8'} fsz-14 line-h-22 roboto${
                    activeKey === item.key ? 'medium' : 'regular'
                  }`}>
                  {item.label}
                </span>
              </Link>
            )
          })}
        </div>
      </Drawer>

      <Menu
        items={menu}
        mode="vertical"
        theme={theme}
        activeKey={activeKey}
      />
    </Sider>
  )
}

export default Sidebar
