import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import ShipmentFeeInterface from '../../../../Interface/ShipmentFeeInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import Item from './Item'

interface FinanceProps {
  shipment?: ShipmentInterface
  fees: ShipmentFeeInterface[]
  expand: boolean
  loading: boolean
  loadingFees: boolean
}

const Finance: React.FC<FinanceProps> = (props) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const { shipment, fees, expand, loading, loadingFees } = props
  const [currencyMarketplace, setCurrencyMarketplace] = useState('')
  const [totalFees, setTotalFees] = useState(0)
  const [totalUnpaid, setTotalUnpaid] = useState(0)
  const [totalRefund, setTotalRefund] = useState('')
  const [totalPaid, setTotalPaid] = useState(0)

  useEffect(() => {
    setCurrencyMarketplace(shipment?.currency!)
    let totalFees = 0

    fees.forEach((fee: ShipmentFeeInterface, idx: number) => {
      if (!fee.free) {
        if (fee.manual && fee.actualAmount) {
          totalFees += fee.actualAmount
        } else if (!fee.manual && fee.provisionalAmount) {
          totalFees += fee.provisionalAmount
        }
      }
    })
    setTotalFees(totalFees)
    const totalPaid = shipment?.totalPaid ? shipment?.totalPaid : 0
    setTotalPaid(shipment?.totalPaid)
    const totalRefund = shipment?.totalRefund ? shipment?.totalRefund : 0
    setTotalRefund(shipment?.totalRefund)
    setTotalUnpaid(totalFees - totalPaid + totalRefund)
  }, [fees, shipment?.currency, shipment?.totalPaid, shipment?.totalRefund])

  if (!expand) return null

  const renderFees = () => {
    console.log('fees', fees)
    return fees.map((fee: ShipmentFeeInterface, idx: number) => (
      <div
        className="fee-items"
        key={idx}>
        <span className="label flex align-items-center">
          {!loadingFees ? fee.type?.name || '---' : <Skeleton width={60} />}
          {!loadingFees && <span className="label-notice mg-l-6">{`(2.${idx + 1})`}</span>}
        </span>
        {fee.manual || fee.free ? (
          <div className="flex justify-content-end">
            {fee.provisionalAmount && (
              <span className={'txt-size-h8 txt-color-secondary txt-linethrough mgr5'}>
                {!loadingFees ? formatMoneyByUnit(fee.provisionalAmount) : <Skeleton width={80} />}
              </span>
            )}
            <span className={'txt-size-h8 '}>
              {!loadingFees ? (
                fee.free ? (
                  t('shipment.feeFree')
                ) : (
                  formatMoneyByUnit(fee.actualAmount)
                )
              ) : (
                <Skeleton width={80} />
              )}
            </span>
          </div>
        ) : (
          <span>{!loadingFees ? formatMoneyByUnit(fee.provisionalAmount) : <Skeleton width={80} />}</span>
        )}
      </div>
    ))
  }

  return (
    <div className={`finance ${expand ? '' : 'finance-collapse'}`}>
      {expand && (
        <div className="finance-details">
          <span className={`m22-typo-heading ${theme}`}>
            {!loading ? t('shipment.finance') : <Skeleton width={150} />}
          </span>
          <div className="finance-details-fees">
            <Item
              label={t('shipment.cash')}
              position={'(1)'}
              currencyMarketplace={currencyMarketplace}
              value={shipment?.declareValue!}
              loading={loading}
            />
            <Item
              label={t('shipment.totalFees')}
              currencyMarketplace={currencyMarketplace}
              position="(2)"
              value={totalFees}
              loading={loading}
            />
            {renderFees()}
          </div>

          <div className="summary">
            <Item
              label={t('shipment.paid')}
              value={totalPaid}
              valueClass="txt-success"
              position={`(3)`}
              loading={loading}
            />
            <Item
              position={'(4)'}
              label={t('shipment.totalRefund')}
              value={totalRefund}
              loading={loading}
            />
            {shipment && shipment.status !== 'CANCELLED' ? (
              <Item
                position={'(5)'}
                label={t(`shipment.${totalUnpaid >= 0 ? 'totalUnpaid' : 'totalCash'}`)}
                value={totalUnpaid ? Math.abs(totalUnpaid) : totalUnpaid}
                valueClass="txt-error"
                labelClass="robotomedium"
                loading={loading}
              />
            ) : null}
            {shipment?.totalClaim && (
              <Item
                position={'(7)'}
                label={t('shipment.totalClaim')}
                value={lodash.get(shipment, 'totalClaim', 0)}
                loading={loading}
              />
            )}
            {shipment?.totalCollect && (
              <Item
                position={'(8)'}
                label={t('shipment.totalCollect')}
                value={Math.abs(lodash.get(shipment, 'totalCollect', 0))}
                loading={loading}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Finance
