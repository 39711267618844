/* tslint:disable:variable-name */
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { createHttpService } from '../helpers/http'
import { DEFAULT_TENANT, PROFILE_KEY, TENANT_KEY, TOKEN_KEY } from '../config'
import { localStorageRead, localStorageSave } from '../../utils/LocalStorageUtils'
import lodash from 'lodash'
import { t } from 'i18next'
import M24Notification from 'src/utils/M24Notification'

export class Repository {
  private static _defaultRequestInterceptor: (v: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>

  private static _defaultResponseInterceptor: (v: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>

  protected http: AxiosInstance

  constructor(
    config: AxiosRequestConfig,
    isAuthen: boolean = true,
    requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig,
    responseInterceptor?: <T>(response: AxiosResponse<T>) => AxiosResponse<T>
  ) {
    this.http = createHttpService(config, requestInterceptor, responseInterceptor)
    if (typeof Repository._defaultRequestInterceptor === 'function') {
      this.http.interceptors.request.use(async (config) => {
        let token: string = localStorageRead(TOKEN_KEY)
        const tenant = localStorageRead(TENANT_KEY)

        config.headers = {
          ...config.headers,
          'Accept-Language': 'vi',
        }

        if (token && isAuthen && config) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            'X-Tenant': lodash.get(tenant, 'code', DEFAULT_TENANT),
          }
        }
        // console.log('config',config)
        return config
      })
    }
    if (typeof Repository._defaultResponseInterceptor === 'function') {
      this.http.interceptors.response.use(Repository._defaultResponseInterceptor, (error: AxiosError) => {
        if (error?.response?.status) {
          switch (error.response.status) {
            case 401:
              localStorageSave(TOKEN_KEY, null)
              localStorageSave(PROFILE_KEY, null)
              if (!window.location.href.includes('/register')) {
                window.location.href = `/#/login?redirect_uri=${window.location.origin}/authentication`
              }
              break
            case 500:
              console.log('server error, config', config, this)
              M24Notification.notifyErrorAllOptions({
                key: 'error_500',
                message: t('message.server_error_title'),
                description: t('message.server_error_description'),
              })
              break
            default:
              break
          }
          return Promise.reject(error)
        }

        M24Notification.notifyErrorAllOptions({
          key: 'no_response',
          message: t('message.no_response_title'),
          description: t('message.no_response_des'),
          duration: 10,
        })

        return Promise.reject(error)
      })
    }
  }

  public setBaseURL(baseURL: string) {
    this.http.defaults.baseURL = baseURL
  }

  public getHttpInstance(): AxiosInstance {
    return this.http
  }

  static set defaultRequestInterceptor(value: (v: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>) {
    this._defaultRequestInterceptor = value
  }

  static set defaultResponseInterceptor(value: (v: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>) {
    this._defaultResponseInterceptor = value
  }
}
