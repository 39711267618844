import { Divider, Tooltip } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import Item from './Item'
import TruncateText from '../../TruncateText'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { orderRepository } from '../../../repositories/OrderRepository'
import M24Notification from '../../../utils/M24Notification'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import Services from '../../Services'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '../../../hooks/useTheme'
import { WeightItem } from './WeightItem'
interface OrderDetailProps {
  orderDetail: any
  expand: boolean
  loading: boolean
}

export const textFromData = (obj: any, key: string, empty: any) => {
  return lodash.isNil(lodash.get(obj, key, empty)) || lodash.isEmpty(lodash.get(obj, key, empty)) ? empty : lodash.get(obj, key, empty)
}

const Information: React.FC<OrderDetailProps> = ({ orderDetail, expand, loading }) => {
  const { t } = useTranslation()
  const refLabel = useRef<HTMLSpanElement | null>(null)
  const [theme] = useTheme()
  const emptyTxt = '---'
  const orderNote = textFromData(orderDetail, 'employeeNote', emptyTxt)
  const personalNote = textFromData(orderDetail, 'personalNote', emptyTxt)
  const displayAddress = textFromData(orderDetail, 'address.display', emptyTxt)
  const [currency] = useState<string>(textFromData(orderDetail, 'currency', emptyTxt))
  const listServices = textFromData(orderDetail, 'services', [])
  const exchangeRateStr = `${formatMoneyByUnit(1, lodash.get(orderDetail, 'currency', emptyTxt))}
                          = ${formatMoneyByUnit(lodash.get(orderDetail, 'exchangeRate', ''), currency)}`
  const merchantNameStr = textFromData(orderDetail, 'merchantName', emptyTxt)
  const depositRateStr = `${lodash.isNull(lodash.get(orderDetail, 'emdPercent')) ? emptyTxt : lodash.get(orderDetail, 'emdPercent', emptyTxt)}%`
  const netWeight = lodash.isNil(lodash.get(orderDetail, 'netWeight')) ? emptyTxt : `${lodash.get(orderDetail, 'netWeight')} kg`
  const packingWeight = lodash.isNil(lodash.get(orderDetail, 'packagingWeight')) ? emptyTxt : `${lodash.get(orderDetail, 'packagingWeight')} kg`

  const dimensionalWeight = lodash.isNil(lodash.get(orderDetail, 'dimensionalWeight')) ? emptyTxt : `${lodash.get(orderDetail, 'dimensionalWeight')} kg`
  const chargedWeight = lodash.isNil(lodash.get(orderDetail, 'actualWeight')) ? emptyTxt : `${lodash.get(orderDetail, 'actualWeight')} kg`

  const classes = 'flex-basis-gap-120 flex-shrink-0 mg-r-28 mgbt8'

  const updatePersonalNote = useCallback(
    async (code, id = '', val, key, handleState) => {
      try {
        const res = await orderRepository.updatePersonalNoteOfOrder(code, val)
        handleState(res.personalNote ? res.personalNote : '---')
        M24Notification.notifySuccess(t('orderDetails.updatedPersonalNoteOrder'), '', '', 5)
      } catch (err) {
        M24ErrorUtils.showError(t, err)
      }
    },
    [t]
  )

  return (
    <div className={`dpl-flex flex-col flex-basis-half flex-grow-0 ${!expand ? 'orderDetail-collapse__item h-fit-content' : ''}`}>
      <span className={`m22-typo-heading ${theme} ${expand ? 'mg-bt-8' : ''}`}>
        {!loading ? (
          t('orderDetail.info')
        ) : (
          <Skeleton
            width={150}
            height={22}
          />
        )}
      </span>
      {expand && (
        <div className={`dpl-flex flex-col ${theme}`}>
          <div className="flex-col  mg-t-16 mg-bt-16">
            <Item
              label={`${t('orderDetail.receiver')}:`}
              content={<span className="robotomedium">{lodash.get(orderDetail, 'address.fullname', '--')}</span>}
              className="mg-bt-8"
              loading={loading}
              widthContent={80}
              widthLabel={60}
            />
            <Item
              label={`${t('orderDetail.phone')}:`}
              content={lodash.get(orderDetail, 'address.phone', '--')}
              className="mg-bt-8"
              loading={loading}
              widthContent={80}
              widthLabel={120}
            />
            <Item
              label={`${t('orderDetail.address')}:`}
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(orderDetail, 'address.fullname', '---')}, {displayAddress}
                </span>
              }
              className="mg-bt-8"
              loading={loading}
              widthContent={80}
              widthLabel={120}
            />
          </div>
          <Divider className="mg-0" />
          <div className="flex-wrap mg-bt-16 mg-t-16">
            <Item
              label={`${t('orderDetail.seller')}:`}
              content={merchantNameStr}
              className={classes}
              loading={loading}
              widthLabel={100}
              containerClassContent="flex-grow-1"
            />
            <Item
              label={`${t('orderDetail.depositRate')}:`}
              content={depositRateStr}
              className={classes}
              loading={loading}
              widthLabel={100}
              containerClassContent="flex-grow-1"
            />
            <Item
              label={`${t('orderDetail.exchangeRate')}:`}
              content={exchangeRateStr}
              className={classes}
              widthLabel={100}
              loading={loading}
              containerClassContent="flex-grow-1"
            />
          </div>

          <Divider className="mg-0" />

          <div className="flex-wrap gap-8 dpl-flex mg-bt-16 mg-t-16">
            <WeightItem
              label={t('orderDetail.chargedWeight')}
              value={chargedWeight}
            />
            <WeightItem
              label={t('orderDetail.netWeight')}
              value={netWeight}
            />
            <WeightItem
              label={t('orderDetail.weightConversion')}
              value={dimensionalWeight}
            />
            <WeightItem
              label={t('orderDetail.packingWeight')}
              value={packingWeight}
            />
          </div>

          <Divider className="mg-0" />
          <div className="my-16">
            <Services
              label={`${t('orderDetail.service')}`}
              data={listServices}
              className="font-medium fsz-14"
            />
          </div>
          <Divider className="mg-0 mg-bt-8" />

          <Item
            label={
              <span
                className={`line-h-22 h-22 txt-nowrap  txt-color-gray4 mg-r-3 ${theme}`}
                ref={refLabel}>
                <span>{`${t('orderDetail.personalNote')}: `}</span>
                <Tooltip title={t('orderDetail.noticePersonal')}>
                  <i className={`far fa-info-circle txt-color-primary txt-size-h8 cursor-pointer ${theme}`}></i>
                </Tooltip>
              </span>
            }
            minWidthLabel={'unset'}
            content={
              <TruncateText
                defaultValue={personalNote}
                editable={true}
                cb={updatePersonalNote}
              />
            }
            loading={loading}
            containerClassContent="flex-grow-1"
            widthLabel={100}
            className="mg-t-16"
          />

          <Item
            label={<span className="mg-r-3">{`${t('orderDetail.orderNote')}:`}</span>}
            minWidthLabel={'unset'}
            content={
              <TruncateText
                defaultValue={orderNote}
                editable={false}
              />
            }
            loading={loading}
            widthLabel={100}
            valueClassName="mg-0"
            className="mg-t-16 flex-col items-start mg-bt-24"
          />
        </div>
      )}
    </div>
  )
}

export default Information
