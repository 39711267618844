import { Col, Result, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import UserInformation from '../../components/Profile/UserInformation'
import { TENANT_KEY } from '../../core/config'
import { TenantUpdateConfigCommand } from '../../Interface/TenantUpdateConfigCommand'
import { localStorageRead } from '../../utils/LocalStorageUtils'
import AddressConfig from './AddressConfig'
import { useTheme } from '../../hooks/useTheme'

function Profile() {
	const { t } = useTranslation()
	const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false)
	const [currentMenu, setCurrentMenu] = React.useState<string>('info')
	const [searchParams, setSearchParams] = useSearchParams()

	const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()
	const [subInfo, setSubInfo] = useState(searchParams.get('details') ?? 'account')
	const [theme] = useTheme()

	React.useEffect(() => {
		setShowProgressBar(true)
		setShowProgressBar(false)
	}, [searchParams])

	const renderBody = () => {
		if (currentMenu === 'info' && subInfo === 'account' && currentConfigServices?.enableGeneral) {
			return <UserInformation />
		}
		else if (currentMenu === 'info' && subInfo === 'addresses') {
			return <AddressConfig />
		}
		else
			return (
				<Result
					status='404'
					title='404'
					subTitle={t('page.notFound')}
					extra={<Link to={'/'}>{t('page.backToHomePage')}</Link>}
				/>
			)
	}

	useEffect(() => {
		const tenantInfo = localStorageRead(TENANT_KEY)
		if (tenantInfo) {
			const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = tenantInfo.config
			setCurrentConfigServices((prevState: any) => ({
				...prevState,
				enableShipment: shipmentConfig?.enable,
				enableOrder: orderConfig?.enable,
				enableGeneral: generalConfig?.enable,
				enablePeerPayment: peerPaymentConfig?.enable,
				enableGsa: gsaConfig?.enable,
			}))
		}
	}, [])

	return (
		<MainLayout title={t('menu.profile')} showProgressBar={showProgressBar}>
			<Row className={'profile-container'}>
				<Col span={4} className={'menu-item'}>
					{currentConfigServices?.enableGeneral && (
						<div
							className={`flex flex-col`}
							key={'info'}
							onClick={() => {
								setCurrentMenu('info')
								setSearchParams({ tab: 'info' })
							}}
						>
							<span
								className={`px-16 py-12 m22-typo-label_upper m22-typo-label_md m22-typo-label_md-large m22-vertical-tab-radius ${
									currentMenu === 'info' ? `m22-component-active` : ``
								}`}
							>
								{t('menu.profile')}
							</span>

							<div className={`flex flex-col px-12 py-8`}>
								<span
									className={`mg-bt-8 cursor-pointer robotoregular ${
										subInfo === 'account' ? 'txt-sub-tab' : 'txt-secondary'
									}`}
									onClick={(e) => {
										e.stopPropagation()
										setCurrentMenu('info')
										setSubInfo('account')
										setSearchParams({ tab: 'info', details: 'account' })
									}}
								>
									Thông tin tài khoản
								</span>
								<span
									className={`mg-bt-8 cursor-pointer ${
										subInfo === 'addresses' ? 'txt-sub-tab' : 'txt-secondary'
									}`}
									onClick={(e) => {
										e.stopPropagation()
										setCurrentMenu('info')
										setSubInfo('addresses')
										setSearchParams({ tab: 'info', details: 'addresses' })
									}}
								>
									Địa chỉ
								</span>
							</div>
						</div>
					)}
					{/* {PACKAGE_MENU_ITEMS.map((m) => {
						const displayFromTenantConfig =
							m.code === 'services' || m.code === 'payments'
								? currentConfigServices?.enableGsa
								: currentConfigServices?.enableGeneral
						if (m.display && m.display === packagePurchaseConfig && displayFromTenantConfig) {
							return (
								<div
									className={`item ${currentMenu === m.code ? `__current` : ``}`}
									key={m.code}
									onClick={() => {
										setCurrentMenu(m.code)
										setSearchParams({ tab: m.code })
									}}>
									<span className={'txt-size-h5 robotomedium txt-capitalize'}>{t(m.name)}</span>
								</div>
							)
						} else return null
					})} */}
				</Col>
				<Col
					span={20}
					className={`  ${theme} ${theme === 'dark' ? 'content-container-dark' : 'content-container'}`}
				>
					{renderBody()}
				</Col>
			</Row>
		</MainLayout>
	)
}

export default Profile

