import { Alert, Layout, Modal } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { checkExtension } from 'src/utils/util'
import { CurrentContext } from '../../context/CurrentContext'
import {
  ACC_GSA_PROFILE,
  ID_GO_BIZ_EXTENSION,
  PAGE_DESCRIPTIONS,
  PAGE_DESCRIPTIONS_NEVER_SHOW,
  PROFILE_KEY,
  TENANT_KEY,
  TOKEN_KEY,
} from '../../core/config'
import useWindowDimensions from '../../hooks'
import { useTheme } from '../../hooks/useTheme'
import DescriptionsInterface from '../../Interface/DescriptionsInterface'
import { TenantUpdateConfigCommand } from '../../Interface/TenantUpdateConfigCommand'
import { noticeRepository } from '../../repositories/NoticeRepository'
import { userRepository } from '../../repositories/UserRepository'
import { localStorageRead, localStorageRemoveItem, localStorageSave } from '../../utils/LocalStorageUtils'
import Descriptions from '../Descriptions'
import ExtensionCommon from '../ExtensionCommon'
import ConfirmModal from '../Modal/ConfirmModal'
import ProgressBar from '../Spinner/ProgressBar'
import Navigation from './Navigation'
import Sidebar from './Sidebar'
import './styles.scss'
import lodash from 'lodash'
import CreateAddressModal from '../ShoppingCart/Address/CreateAddressModal'
import { cartRepository } from '../../repositories/CartRepository'
import M24Notification from '../../utils/M24Notification'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import { AppContext } from 'src/context/AppContext'

const { Content } = Layout
const PERCENT_COMPLETE = 100

export interface MainLayoutProps {
  children: any
  showProgressBar?: boolean
  descriptions?: DescriptionsInterface | null
  title?: any
  hasExtensionCommon?: boolean
  breadcrumbs?: any
  totalCart?: number
}

function MainLayout(props: MainLayoutProps) {
  const [theme] = useTheme()
  const { children, showProgressBar, title, descriptions, hasExtensionCommon, breadcrumbs } = props
  const [collapsed, setCollapsed] = useState(true)
  const [percent, setPercent] = useState<number>(-1)
  const [autoIncrement, setAutoIncrement] = useState<boolean>(false)
  const [isShowMenuDropDown] = useState<boolean>(false)
  const [intervalTime, setIntervalTime] = useState<number>(200)
  const [activeKey, setActiveKey] = useState<string>('')
  const [profile, setProfile] = useState(localStorageRead(PROFILE_KEY))
  const location = useLocation()
  const { t } = useTranslation()
  const { height } = useWindowDimensions()
  const [isShowNotice, setIsShowNotice] = useState(false)
  const [totalUnreadNotice, setTotalUnreadNotice] = useState(0)
  const [showDescriptions, setShowDescriptions] = useState(false)
  const [showExtension, setShowExtension] = useState(true)

  const [confirmLogOut, setConfirmLogOut] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [installed, setInstalled] = useState(true)
  const [currentConfigServices, setCurrentConfigServices] = useState<TenantUpdateConfigCommand>()

  const [openSubmenu, setOpenSubmenu] = useState(false)
  const [openNestedDrawer, setOpenNestedDrawer] = useState(false)
  const currentCtx = useContext(CurrentContext)
  const appContext = useContext(AppContext)
  const pageCode = descriptions?.pageCode
  const [createAddressModalVisible, setCreateAddressModalVisible] = useState(false)
  const [isCreatingAddress, setIsCreatingAddress] = useState(false)

  useEffect(() => {
    if (!profile?.introductions?.includes(pageCode)) return

    if (localStorageRead(PAGE_DESCRIPTIONS)?.includes(pageCode)) return

    if (localStorageRead(PAGE_DESCRIPTIONS_NEVER_SHOW)?.includes(pageCode)) return

    setShowDescriptions(true)
  }, [pageCode, profile?.introductions])

  useEffect(() => {
    const readLocalStorageProfile = () => {
      setProfile(localStorageRead(PROFILE_KEY))
    }
    window.addEventListener('storage', readLocalStorageProfile)
    return () => {
      window.removeEventListener('storage', readLocalStorageProfile)
    }
  }, [])

  useEffect(() => {
    if (showProgressBar) {
      startWithAutoIncrement()
    } else {
      setPercentValue(PERCENT_COMPLETE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProgressBar])

  useEffect(() => {
    if (location.pathname && location.pathname !== '/') {
      if (location.pathname.slice(1).startsWith('orders')) {
        setActiveKey('orders')
      } else if (location.pathname.slice(1).startsWith('claims')) {
        setActiveKey('claims')
      } else if (location.pathname.slice(1).startsWith('shipments')) {
        setActiveKey('shipments')
      } else {
        setActiveKey(location.pathname.slice(1))
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (hasExtensionCommon) {
      checkExtension(ID_GO_BIZ_EXTENSION, 'injector.js', (ok: any) => {
        setInstalled(!!ok)
      })
    }
  }, [hasExtensionCommon])

  const onConfirmLogOut = () => {
    setLoading(true)
    localStorageSave(PROFILE_KEY, null)
    localStorageSave(TOKEN_KEY, null)
    localStorageSave(TENANT_KEY, null)
    localStorageSave(PAGE_DESCRIPTIONS, null)
    localStorageSave(ACC_GSA_PROFILE, null)
    window.location.href = process.env.REACT_APP_LOGOUT_URL!
    setLoading(false)
  }

  const onCancelLogOut = () => {
    setConfirmLogOut(false)
    setLoading(false)
  }

  /**
   * hiển thị progressbar
   **/
  const startWithAutoIncrement = () => {
    setPercent(0)
    setAutoIncrement(true)
    setIntervalTime(Math.random() * 150)
  }

  /**
   * Dừng progressbar
   **/
  const stopWithAutoIncrement = () => {
    setPercent(-1)
    setAutoIncrement(false)
    setIntervalTime(150)
  }

  const setPercentValue = (percent: number) => {
    setPercent(percent)
    if (percent === PERCENT_COMPLETE) {
      setTimeout(() => {
        stopWithAutoIncrement()
      }, 500)
    }
  }

  const getProfile = async () => {
    await userRepository.updateIntroductions(descriptions?.pageCode!)
    const profile = await userRepository.getProfile()
    localStorageRemoveItem(PROFILE_KEY)
    localStorageSave(PROFILE_KEY, profile)
    appContext.fnc.getUserProfile?.(profile)
  }

  const handleClickHideDescriptionsForever = async () => {
    setShowDescriptions(false)
    try {
      await getProfile()
      const pageDescriptions = localStorageRead(PAGE_DESCRIPTIONS_NEVER_SHOW) || []
      localStorageRemoveItem(PAGE_DESCRIPTIONS_NEVER_SHOW)
      localStorageSave(PAGE_DESCRIPTIONS_NEVER_SHOW, [...pageDescriptions, descriptions?.pageCode])
    } catch (err: any) {}
  }

  const closeHandler = () => {
    setShowExtension(false)
  }

  const handleShowDescription = () => {
    setShowDescriptions(false)
    const pageDescriptions = localStorageRead(PAGE_DESCRIPTIONS) || []
    localStorageRemoveItem(PAGE_DESCRIPTIONS)
    localStorageSave(PAGE_DESCRIPTIONS, [...pageDescriptions, descriptions?.pageCode])
  }

  useEffect(() => {
    const favicon = document.querySelector('[rel="icon"]') as HTMLAnchorElement | null
    const tenantInfo = localStorageRead(TENANT_KEY)
    if (favicon && tenantInfo) {
      favicon.href = tenantInfo.config.favicon
    }
  }, [])

  useEffect(() => {
    if (currentCtx && currentCtx.config) {
      const { generalConfig, peerPaymentConfig, shipmentConfig, gsaConfig, orderConfig } = currentCtx.config
      setCurrentConfigServices((prevState: any) => ({
        ...prevState,
        enableShipment: shipmentConfig?.enable,
        enableOrder: orderConfig?.enable,
        enableGeneral: generalConfig?.enable,
        enablePeerPayment: peerPaymentConfig?.enable,
        enableGsa: gsaConfig?.enable,
      }))
    }
  }, [currentCtx])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleOutSideMenu = useCallback(() => {
    if (openSubmenu && openNestedDrawer) {
      setOpenNestedDrawer(false)
      setTimeout(() => setOpenSubmenu(false), 500)
    } else {
      setOpenSubmenu(false)
    }
  }, [openNestedDrawer, openSubmenu])

  useEffect(() => {
    let tenantRequireDefaultAddress = lodash.get(localStorageRead('TENANT_KEY'), 'config.requireAddress')
    if (tenantRequireDefaultAddress) {
      userRepository
        .getProfile()
        .then((res) => {
          let userHasDefaultAddress = lodash.get(res, 'hasDefaultAddress')
          if (tenantRequireDefaultAddress && !userHasDefaultAddress) {
            setTimeout(() => {
              setCreateAddressModalVisible(true)
            }, 750)
          }
        })
        .catch((err) => {})
    }
  }, [])

  const handleCreateAddress = (body: any) => {
    getProfile()
    setIsCreatingAddress(true)
    cartRepository
      .createAddress(body)
      .then(() => {
        M24Notification.messageSuccess(t('message.success'))
        setCreateAddressModalVisible(false)
      })
      .catch((error) => {
        M24ErrorUtils.showError(t, error)
      })
      .finally(() => {
        setIsCreatingAddress(false)
      })
  }

  const handleCancelCreateDefaultAddress = () => {
    Modal.confirm({
      icon: null,
      title: t('address.confirm_cancel_create_address'),
      content: t('address.confirm_cancel_create_address_content'),
      okText: t('menu.logout'),
      okButtonProps: {
        icon: <i className="fa-solid fa-arrow-right-from-bracket"></i>,
        className: `btn-logout m22-btn ${theme}`,
      },
      cancelText: t('button.cancel'),
      cancelButtonProps: {
        icon: null,
        className: `m22-btn ${theme}`,
        type: 'text',
      },
      onCancel: () => {},
      onOk: () => {
        localStorageSave(PROFILE_KEY, null)
        localStorageSave(TOKEN_KEY, null)
        localStorageSave(TENANT_KEY, null)
        localStorageSave(PAGE_DESCRIPTIONS, null)
        localStorageSave(ACC_GSA_PROFILE, null)
        window.location.href = process.env.REACT_APP_LOGOUT_URL!
      },
      centered: true,
      className: `_modal-confirm ${theme}`,
    })
  }

  return (
    <>
      <DocumentTitle title={`${title ? title : 'Sabo'}`}>
        <Layout className={'main-layout'}>
          <Sidebar
            handleShowSubMenu={(val) => setOpenSubmenu(val)}
            activeKey={activeKey}
            currentConfigServices={currentConfigServices}
            openSubmenu={openSubmenu}
            openNestedDrawer={openNestedDrawer}
            handleOpenNestedDrawer={(val) => setOpenNestedDrawer(val)}
          />

          <Layout
            onClick={!collapsed ? () => setCollapsed(true) : undefined}
            className="inside-layout"
            onMouseEnter={handleOutSideMenu}>
            <Navigation
              currentConfigServices={currentConfigServices}
              title={title}
              logoutHandler={() => setConfirmLogOut(true)}
              totalUnreadNotice={totalUnreadNotice}
              isShowNotice={isShowNotice}
              breadcrumbs={breadcrumbs}
              onVisibleChangeNoticesHandler={(visible: boolean) => setIsShowNotice(visible)}
              visibleDropdownUserActions={isShowMenuDropDown}
              totalCart={currentCtx && currentCtx.totalCart ? currentCtx.totalCart : undefined}
            />
            <ProgressBar
              percent={percent}
              autoIncrement={autoIncrement}
              intervalTime={intervalTime}
              spinner={false}
            />
            <Content
              style={{ minHeight: height - 64 }}
              className="content">
              {children}
              {createAddressModalVisible && (
                <CreateAddressModal
                  isVisible={createAddressModalVisible}
                  onCancel={() => handleCancelCreateDefaultAddress()}
                  onSubmit={handleCreateAddress}
                  maskClosable={false}
                  destroyOnClose={true}
                  isDefault={true}
                  requiredTitle={
                    <Alert
                      type={'warning'}
                      showIcon
                      message={<span className={'ml-2 fsz-14'}>{t('address.requiredAddressWarning')}</span>}></Alert>
                  }
                  loading={isCreatingAddress}
                />
              )}
            </Content>
            <ConfirmModal
              btnType="danger"
              className={'itto_logout'}
              okText={'Đăng Xuất'}
              title={t('dashboard.confirmLogoutTitle')}
              content={t('dashboard.confirmLogoutContent')}
              visible={confirmLogOut}
              loading={isLoading}
              iconBtn={<i className="fa-regular fa-arrow-right-from-bracket mg-r-10s"></i>}
              onCancel={onCancelLogOut}
              onSubmit={onConfirmLogOut}
            />
          </Layout>
        </Layout>
      </DocumentTitle>
      <div className="box-description">
        {showDescriptions && (
          <Descriptions
            {...{
              ...descriptions,
              className: `${!(hasExtensionCommon && showExtension && !installed) ? 'mg-bt-12' : ''} ${
                descriptions?.className
              } ${theme}`,
            }}
            profile={profile}
            notShowAgain={handleClickHideDescriptionsForever}
            showLater={handleShowDescription}
          />
        )}
        {hasExtensionCommon && showExtension && !installed && <ExtensionCommon handleClose={closeHandler} />}
      </div>
    </>
  )
}

export default MainLayout
