import { Row, Col } from 'antd'
import M22Box from 'src/components/M22Box'

export const CartListHeader = () => {
  return (
    <M22Box className="shopping-cart-table-head">
      <Row
        className="h-54 px-28"
        align={'middle'}>
        <Col
          span={12}
          className="pd-l-32 txt-secondary">
          Sản phẩm
        </Col>

        <Col span={5}>
          <Row className={'dpl-flex justify-content-center align-items-center txt-secondary'}>Số lượng</Row>
        </Col>

        <Col span={2}>
          <Row className={'dpl-flex align-items-center justify-content-end'}>
            <span className={'txt-secondary'}>Đơn giá</span>
          </Row>
        </Col>

        <Col span={2}>
          <Row className={'dpl-flex align-items-center justify-content-end'}>
            <span className={'txt-secondary'}>Thành tiền</span>
          </Row>
        </Col>

        <Col span={3}>
          <Row justify={'end'}>Tác vụ</Row>
        </Col>
      </Row>
    </M22Box>
  )
}
