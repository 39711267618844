import lodash from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Finance from './Finance'
import Heading from './Heading'
import Information from './Information'
import TabLayout from './TabLayout'
import TrackOrder from '../UI/molecules/TrackOrder'
import { useTheme } from '../../hooks/useTheme'
import { scrollToElementId } from 'src/core/helpers/dom'

export interface OrderInfoProps {
  orderDetail?: any
  statuses: any
  showProgressBar: any
  finalcials: any
  reOrder: () => void
  isReOrdering: boolean
  cancelOrder: (callback: () => void) => void
  isCancelOrdering: boolean
  confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
  loadingNotConfirm: { [id: string]: boolean } | undefined
  loadingConfirm: { [id: string]: boolean } | undefined
  claim: any
  onClaimPageChange: (filter: any) => void
  providerCode: string | undefined
  accountCode: string | undefined
  handleConfirm: (val: boolean) => void
  showBoxChat: () => void
  loadingCreateThread: boolean
  handleTrackOrder: () => void
  orderHistory: any
  loadingTransaction: boolean
}

function OrderInfo(props: OrderInfoProps) {
  const {
    orderDetail,
    statuses,
    showProgressBar,
    finalcials,
    reOrder,
    isReOrdering,
    cancelOrder,
    isCancelOrdering,
    confirmProductChange,
    loadingConfirm,
    loadingNotConfirm,
    loadingCreateThread,
    orderHistory,
    loadingTransaction,
  } = props
  const { handleConfirm, claim, onClaimPageChange, providerCode, accountCode } = props
  const { t } = useTranslation()
  const orderItems = lodash.get(props.orderDetail, 'orderItems', [])
  const exchangeRate = lodash.get(props.orderDetail, 'exchangeRate', [])
  const currencyProvider = lodash.get(props, 'provider.currency', '')
  const [expand, setExpand] = useState(true)
  const [theme] = useTheme()
  return (
    <>
      <Heading
        statuses={statuses}
        isCancelOrdering={isCancelOrdering}
        cancelOrder={cancelOrder}
        isReOrdering={isReOrdering}
        reOrder={reOrder}
        orderDetail={orderDetail}
        loadingCreateThread={loadingCreateThread}
        loading={showProgressBar}
      />

      <div className={`shipment-details ${theme}`}>
        <div className="shipment-details__text ">
          <div
            className={`shipment-details__text-top ${expand ? 'expand' : 'collapsed'}`}
            >
            <Information
              orderDetail={orderDetail}
              expand={expand}
              loading={showProgressBar}
            />
            <Finance
              orderDetail={orderDetail}
              expand={expand}
              loading={showProgressBar}
            />
            <div
              className={`txt-muted-2 ${expand ? `orderDetail-expand ${theme}` : `orderDetail-collapse ${theme}`} ${
                showProgressBar ? 'flex align-items-center' : ''
              }`}
              onClick={() => {
                setExpand(!expand)
                scrollToElementId('orderDetailContainer')
              }}>
              {t(`shipment.${expand ? 'collapse' : 'noCollapse'}`)} <i className={`mg-l-8 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
            </div>
          </div>

          <TabLayout
            loadingConfirm={loadingConfirm}
            loadingNotConfirm={loadingNotConfirm}
            confirmProductChange={confirmProductChange}
            finalcials={finalcials}
            orderDetail={orderDetail}
            items={orderItems}
            exchangeRate={exchangeRate}
            currency={currencyProvider}
            loading={showProgressBar}
            claim={claim}
            onClaimPageChange={onClaimPageChange}
            providerCode={providerCode}
            accountCode={accountCode}
            handleConfirm={handleConfirm}
            loadingTransaction={loadingTransaction}
          />
        </div>

        <div className="shipment-details__milestone">
          <TrackOrder
            orderHistory={orderHistory}
            statuses={statuses}
            loading={showProgressBar}
          />
        </div>
      </div>
    </>
  )
}
export default OrderInfo
