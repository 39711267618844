import './styles.scss'
import {Pagination, Row} from "antd";
import SizeChanger from "./SizeChanger";
import {DEFAULT_PAGE_SIZE} from "../../core/config";
import {useTheme} from "../../hooks/useTheme";

export interface M24PaginationProps {
  filter: any
  pagination: any
  onPageChange: (filter: any) => void
  className?: string
}
const M24Pagination = (props: M24PaginationProps) => {
  const {
    pagination, filter, onPageChange
  } = props;
  const theme = useTheme();

  return (
    <Row className={`dpl-flex justify-content-end pagination-container pd-bt-16 pd-t-8`}>
      {pagination?.total ? (
        <SizeChanger
          size={+(filter?.size) || +(filter.pageSize) || +(pagination.pageSize) || DEFAULT_PAGE_SIZE}
          onChange={(size) => {
            onPageChange({ ...filter, size, page: 1, pageSize: size })
          }}
        />
      ) : null}
      {pagination && pagination.total > pagination.pageSize ? (
        <Pagination
          className={`m22-pagination customized ${theme}`}
          {...pagination}
          // showTotal={(total, range) => `${range[0]}-${range[1]} của ${total}`}
          defaultCurrent={6}
          showSizeChanger={false}
          total={pagination.total || 0}
          onChange={(page, pageSize) => {
            window.scrollTo(0, 0)
            // this.pushQueryParameters({...filter, page: page})
            onPageChange({ ...filter, page, pageSize })
          }}
        />
      ) : null}
    </Row>
  )
}
export default M24Pagination
