import { Badge, Button, Col, Row, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../../components/SkeletonTable'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { useTheme } from '../../../../hooks/useTheme'
import { ClaimInterface } from '../../../../Interface/ClaimInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import lodash from 'lodash'
import { getUrlPathname } from 'src/utils/util'

interface ClaimInterfaceProps {
  items: ClaimInterface[]
  loading: boolean
  shipment?: ShipmentInterface
  currency: string
}
const Claims: React.FC<ClaimInterfaceProps> = (props) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const { items, loading } = props
  const [isSkeleton, setIsSkeleton] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => setIsSkeleton(false), 1000)
  }, [])

  const columns = [
    {
      key: 'code',
      title: t('shipment.codeOfClaim'),
      width: 100,
      dataIndex: 'code',
      render: (code: string) => <span className="robotomedium fsz-14 line-h-22">{`#${code}`}</span>,
    },
    {
      key: 'name',
      title: t('shipment.nameOfClaim'),
      dataIndex: 'name',
      width: 300,
      render: (name: string) => <span className=" fsz-14 line-h-22 ">{name}</span>,
    },
    {
      key: 'timestamp',
      dataIndex: 'createdAt',
      title: t('shipment.timestampOfClaim'),
      width: 200,
      render: (createdAt: string) => <span className=" fsz-14 line-h-22 ">{formatDateTime(moment(createdAt))}</span>,
    },
    {
      key: 'status',
      title: t('shipment.statusOfClaim'),
      width: 200,
      render: (record: any) => {
        const statusOfClaim = record ? lodash.get(record, 'publicStateNewView.name') : '---'
        const colorOfStatus = record ? lodash.get(record, 'publicStateNewView.color') : ''
        return (
          <Badge
            color={colorOfStatus}
            text={`${statusOfClaim}   ${record.archived ? ` (${t('tickets.archived')})` : ''}`}
          />
        )
      },
    },
    {
      key: 'refund',
      title: t('shipment.refundOfClaim'),
      width: 160,
      align: 'right' as 'right',
      render: (record: ClaimInterface) => (
        <Row justify={'end'}>
          <Col>
            <span className={`fsz-14 line-h-22 `}>
              {record.publicStateNewView.code === 'REFUND' ? (
                <span className="m22-typo-money-plus robotomedium">{formatMoneyByUnit(record.totalRefund)}</span>
              ) : record.estimatedRefundValue ? (
                <span className="m22-typo-money-plus robotomedium">{formatMoneyByUnit(record.estimatedRefundValue)}</span>
              ) : (
                '---'
              )}
            </span>
          </Col>
        </Row>
      ),
    },
    {
      key: 'detail',
      width: 160,
      align: 'right' as 'right',
      render: (record: ClaimInterface) => (
        <Row
          justify="end"
          align="middle">
          <Col>
            <Link
              to={`/claims/${record.code}`}
              className={`txt-color-blue1 item-link fsz-14 line-h-22 `}>
              {t('orderDetail.claimDetail')}
            </Link>
          </Col>
        </Row>
      ),
    },
  ]
  return (
    <>
      <SkeletonTable
        loading={isSkeleton || loading}
        columns={columns as SkeletonTableColumnsType[]}
        rowCount={!loading ? items?.length : 0}>
        <Table
          columns={columns}
          dataSource={items}
          className={`package-list extended mg-bt-12 m22-table ${theme}`}
          pagination={{
            pageSize: 25,
            defaultPageSize: 25,
            hideOnSinglePage: true,
            position: ['bottomRight'],
          }}
          locale={{
            emptyText: (
              <EmptyDataFilter
                description={
                  <div className="flex-col dpl-flex align-items-center">
                    <span className="mg-r-6 mg-bt-16">{t('shipment.ticketsEmpty')}</span>
                    <Button
                      type="ghost"
                      className={`m22-btn ${theme}`}
                      icon={<i className="mg-r-10 fa-solid fa-plus"></i>}
                      onClick={() =>
                        navigate(
                          `/claims/create?shipmentsCode=${props.shipment?.code}&back=${getUrlPathname(window.location)}`
                        )
                      }>
                      {t('tickets.createTickets')}
                    </Button>
                  </div>
                }
              />
            ),
          }}
        />
      </SkeletonTable>
    </>
  )
}

export default Claims
