import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'

interface NestedData {
  loading: any
  items: any
  record: any
}

interface CustomItemProps {
  label: any
  item: any
}
export const CustomeItem: React.FC<CustomItemProps> = ({ label, item }) => {
  const [theme] = useTheme()

  return (
    <div style={{ width: '15%' }}>
      <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'}  mg-bt-16 robotomedium fsz-12 line-h-20`}>
        {/* {t('requestDeliveryPackage.code')} */}
        {label}
      </div>
      <div className=" fsz-14 line-h-22 ">{item}</div>
    </div>
  )
}

const NestedTable: React.FC<NestedData> = ({ loading, items, record }) => {
  const { t } = useTranslation()
  const data = items[record.code] && items[record.code].length > 0 ? items[record.code] : []
  const [theme] = useTheme()

  return data.length > 0 ? (
    <>
      <div
        className="flex "
        style={{ marginLeft: '9%' }}>
        <div style={{ width: '15%' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'}  mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('requestDeliveryPackage.orderCode')}
          </div>
        </div>
        <div style={{ width: '10%' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'}  mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('requestDeliveryPackage.code')}
          </div>
        </div>
        <div style={{ width: '10%', marginLeft: '10px' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} txt-right  mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('requestDeliveryPackage.volumetric')}
          </div>
        </div>
        <div style={{ width: '15%', marginRight: '10%' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} txt-right mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('requestDeliveryPackage.weight')}
          </div>
        </div>
        {/* <div style={{ width: '15%', marginRight: '10%' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'} txt-right mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('common.codAmount')}
          </div>
        </div> */}
        <div style={{ width: '15%' }}>
          <div className={`${theme === 'dark' ? 'txt-color-primary' : 'txt-color-blue6'}  mg-bt-16 robotomedium fsz-12 line-h-20`}>
            {t('requestDeliveryPackage.createdAt')}
          </div>
        </div>
      </div>
      <div>
        {data.map((item: any, index: number) => {
          return (
            <>
              <div
                className="flex mg-bt-12"
                style={{ marginLeft: '9%' }}>
                <div
                  style={{
                    width: '15%',
                  }}>
                  {item.orderCode}
                </div>
                <div
                  style={{
                    width: '10%',
                  }}>
                  {item.code}
                </div>
                <div
                  style={{
                    width: '10%',
                    marginLeft: '10px',
                  }}>
                  <div className=" fsz-14 line-h-22 txt-right">
                    {item.volumetric === 0 ? `${t('requestDeliveryPackage.undefined')}` : `${formatNumber(item.volumetric)}cm3`}
                  </div>
                </div>
                <div
                  style={{
                    width: '15%',
                    marginRight: '10%',
                  }}>
                  <div className=" fsz-14 line-h-22 txt-right">{`${formatNumber(item.actualWeight)}kg`}</div>
                </div>
                {/* <div
                  style={{
                    width: '15%',
                    marginRight: '10%',
                  }}>
                  <div className=" fsz-14 line-h-22 txt-right">
                    <span className="txt-color-red2 font-medium whitespace">{`${formatMoneyByUnit(item.codAmount)}`}</span>
                  </div>
                </div> */}
                <div style={{ width: '15%' }}>{formatDateTime(item.createdAt)}</div>
              </div>
            </>
          )
        })}
      </div>
    </>
  ) : (
    <EmptyDataFilter
      description={t('packages.noData')}
      className={loading[record.code] ? 'dpl-none' : ''}
    />
  )
}

export default NestedTable
