import React from 'react'
import { useTheme } from '../../hooks/useTheme'
interface BoxProps {
  className?: string
  onClick?: () => void
}
const M22Box:React.FC<BoxProps> = ({children, className, onClick}) => {
    const [theme, ] = useTheme()
  return (
    <div className={`m22-box-${theme} ${className??''}`} onClick={onClick}>{children}</div>
  )
}

export default M22Box