import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from 'src/components/Layout'
import M22Box from 'src/components/M22Box'
import WarehouseListItem from './WarehouseListItem'
import './styles.scss'
import { shipmentCategoriesRepository } from 'src/repositories/ShipmentCategoriesRepository'
import { WarehouseInterface } from 'src/Interface/WarehoustListInterface'
import EmptyDataFilter from 'src/components/Empty/EmptyDataFilter'
import WarehouseListStepOne from './WarehouseListStepOne'
import { AxiosResponse } from 'axios'
import { warehouseRepository } from 'src/repositories/WarehouseRepository'
import { Button, Steps } from 'antd'
import M24Notification from 'src/utils/M24Notification'

export type FirstStepType = {
  allow: boolean
  services: {
    type: string
    [key: string]: string
  }
}

const WarehousesList = () => {
  const [wareHouse, setWareHouse] = useState<WarehouseInterface[]>([])
  const [loading, setLoading] = useState(false)
  const [firstStepState, setFirstStepState] = useState<FirstStepType>({
    allow: true,
    services: {} as FirstStepType['services'],
  })
  const [secondStepState, setSecondStepState] = useState<any>({
    allow: false,
  })
  const [configSaved, setConfigSaved] = useState<any>({service: {
    'transport-shipment-type': {},
    groups: []
  }})
  const [loadingConfig, setLoadingConfig] = useState(true)

  const getDataWarehouse = useCallback(async () => {
    setLoading(true)
    await shipmentCategoriesRepository
      .getWarehouses()
      .then((res) => {
        if (res.data[0]) {
          res.data[0] = { ...res.data[0] }
        }
        setWareHouse(res.data)
      })
      .catch((err) => {})
      .finally(() => setLoading(false))
  }, [])

  const getServiceSaved = () => {
    setLoadingConfig(true)
    warehouseRepository
      .getServiceSaved()
      .then((res: AxiosResponse<any>) => {
        const { data } = res
        setConfigSaved({
          ...data,
          service: JSON.parse(data.service),
        })
        if (!data.service) {
          return
        }

        const serviceParsed = JSON.parse(data.service)
        const temp: FirstStepType['services'] = {
          type: serviceParsed['transport-shipment-type'].code,
        }
        serviceParsed.groups?.forEach((other: any) => {
          temp[other.groupCode] = other.code
        })
        setFirstStepState({ ...firstStepState, services: temp })
      })
      .finally(() => {
        setLoadingConfig(false)
      })
  }

  useEffect(() => {
    getServiceSaved()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getDataWarehouse()
  }, [getDataWarehouse])

  const renderWarehouseList = useCallback(
    () =>
      wareHouse?.map((item: WarehouseInterface, index) => (
        <WarehouseListItem
          key={`warehouse_${index}`}
          wareHouse={item}
          expandedDefault={index === 0}
          loading={loading}
          configSaved={configSaved}
        />
      )),
    [configSaved, loading, wareHouse]
  )

  const isCanAllowSecondStep = firstStepState.services?.type && secondStepState.allow

  return (
    <MainLayout
      title={t('menu.warehouselist')}
      showProgressBar={loading}>
      <M22Box className={`pd-16 rad-6 `}>
        <div className={'title-body  robotomedium fsz-16 line-h-22'}>Gửi hàng trực tiếp đến kho</div>
        <div className="mg-t-8 txt-muted">Vui lòng thực hiện theo các bước dưới đây để lấy thông tin địa chỉ kho nhận.</div>
      </M22Box>

      <div className="m22-box-light pd-16 rad-6 ">
        <Steps
          progressDot
          current={10}
          size='small'
          items={[
            {
              title: 'Gửi địa chỉ',
              description: 'Bạn copy và gửi địa chỉ cho shop',
            },
            {
              title: 'Tạo đơn',
              description: 'Kho nhận hàng và tạo đơn',
            },
            {
              title: 'Theo dõi',
              description: 'Theo dõi đơn hàng trên SABO',
            },
            {
              title: 'Yêu cầu giao',
              description: 'Yêu cầu giao khi hàng về',
            },
          ]}
        />
      </div>
      {!loadingConfig && (
        <WarehouseListStepOne
          firstStepState={firstStepState}
          configSaved={configSaved}
          setFirstStepState={setFirstStepState}
          setSecondStepState={setSecondStepState}
          setConfigSaved={setConfigSaved}
        />
      )}

      <M22Box className={`pd-16 rad-6`}>
        <div
          className={`flex items-center justify-between cursor-pointer`}
          onClick={() => {
            if (firstStepState.services?.type) {
              setSecondStepState({ ...secondStepState, allow: !secondStepState.allow })
            } else {
              M24Notification.notifyWarning('Cảnh báo', 'Vui lòng chọn dịch vụ')
            }
          }}>
          <div className="flex items-center gap-8">
            {!isCanAllowSecondStep ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-up"></i>}
            <span className={'txt-muted'}>Bước 2.</span> <span className={'title-body robotomedium fsz-14'}>Gửi hàng đến kho</span>
          </div>
          {!firstStepState.allow && (
            <Button
              type="ghost"
              icon={<i className="fa-regular fa-sliders"></i>}
              onClick={(e) => {
                e.stopPropagation()
                setFirstStepState({ ...firstStepState, allow: true })
              }}>
              Tôi muốn đổi dịch vụ
            </Button>
          )}
        </div>
        {isCanAllowSecondStep && (
          <div className={'mt-3'}>
            {wareHouse.length > 0 ? (
              renderWarehouseList()
            ) : !loading ? (
              <EmptyDataFilter
                className="mt-52"
                description="Không có thông tin danh sách kho"
              />
            ) : (
              ''
            )}
          </div>
        )}
      </M22Box>
    </MainLayout>
  )
}
export default WarehousesList
