export const API_BASE_URL: string = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''

export const INPUT_DEBOUNCE_TIME: number = 400
export const STANDARD_DATE_FORMAT: string = 'DD/MM/YYYY'
export const STANDARD_TIME_FORMAT: string = 'HH:mm:ss'
export const STANDARD_TIME_FORMAT2: string = 'HH:mm'
export const STANDARD_DATE_TIME_FORMAT: string = `${STANDARD_TIME_FORMAT2} ${STANDARD_DATE_FORMAT}`
export const STANDARD_DATE_TIME_FORMAT2: string = `DD/MM/YYYY | HH:mm`
export const DEFAULT_PAGE_SIZE: number = 25
export const PAGINATION_SIZE = [20, DEFAULT_PAGE_SIZE, 50, 100]
export const X_PAGE_NUMBER = 'headers.x-page-number'
export const X_TOTAL_COUNT = 'headers.x-total-count'
export const X_PAGE_SIZE = 'headers.x-page-size'
export const PROFILE_KEY = 'PROFILE'
export const TENANT_KEY = 'TENANT_KEY'
export const TOKEN_KEY = 'M22_TOKEN_KEY'
export const M1_CONNECTION_INFO = 'M1_CONNECTION_INFO'

export const TAB_PRODUCT = 'TAB_PRODUCT'
export const TAB_TICKET = 'TAB_TICKET'
export const TAB_TRANSACTION = 'TAB_TRANSACTION'
export const TAB_TRANSPORT = 'TAB_TRANSPORT'
export const MAX_UPLOAD_SIZE = 10 // MB
export const LANGUAGE_STORAGE_KEY = 'lang'
export const DEFAULT_TENANT = 'vn'
export const KUNLUN_API_URL = process.env.REACT_APP_KUNLUN_API

export const CASH_PAYMENT_METHOD = 'CASH'
export const BANK_TRANSFER_PAYMENT_METHOD = 'BANK_TRANSFER'
export const COD_PAYMENT_METHOD = 'COD'
export const ID_GO_BIZ_EXTENSION = 'dgkolciljcelfnmfmegcdchbkoeninal'
export const CLAIM_DESCRIPTIONS = 'CLAIM_DESCRIPTIONS'
export const DELIVERY_NOTE_DESCRIPTIONS = 'DELIVERY_NOTE_DESCRIPTIONS'
export const DELIVERY_REQUEST_DESCRIPTIONS = 'DELIVERY_REQUEST_DESCRIPTIONS'
export const PACKAGES_DESCRIPTIONS = 'PACKAGES_DESCRIPTIONS'
export const TRANSACTION_DESCRIPTIONS = 'TRANSACTION_DESCRIPTIONS'
export const STORED_DESCRIPTIONS = 'STORED_DESCRIPTIONS'
export const SHOPPING_CART_DESCRIPTIONS = 'SHOPPING_CART_DESCRIPTIONS'
export const ORDER_DESCRIPTIONS = 'ORDER_DESCRIPTIONS'
export const PAGE_DESCRIPTIONS = 'PAGE_DESCRIPTIONS'
export const PAGE_DESCRIPTIONS_NEVER_SHOW = 'PAGE_DESCRIPTIONS_NEVER_SHOW'
export const REDIRECT_AFTER_LOGIN_URL = 'REDIRECT_AFTER_LOGIN_URL'
export const breadCrumbsNameFromRoutes = [
  'claims',
  'market-connect',
  'connect-supplier',
  'price-config',
  'shopping-cart',
  'transactions',
  'shopping-cart',
  'orders',
  'orders',
  'packages',
  'delivery',
  'create',
  'create-success',
  'delivery-notes',
  'profile',
  'stored-products',
  'connect-market',
  'shipments',
  'breadcrumbsOrdersDetails',
  'breadcrumbsClaimsDetails',
  'warehouselist',
  'lastmilebags',
]

export const exceptionRoutes: string[] = []

export const tabItems = [
  {
    key: 'ORDER',
    name: 'notification.order',
    sort: 1,
  },
  {
    key: 'SHIPMENT',
    name: 'notification.shipment',
    sort: 2,
  },
  {
    key: 'FINANCIAL',
    name: 'notification.finance',
    sort: 3,
  },
  {
    key: 'DELIVERY_REQ',
    name: 'notification.delivery',
    sort: 4,
  },
  {
    key: 'COMPLAIN',
    name: 'notification.claim',
    sort: 5,
  },
  {
    key: 'PEER_PAYMENT',
    name: 'notification.peerPayment',
    sort: 6,
  },
]

export const SERVICE_NORMAL = 'normal'
export const SERVICE_PENDING = 'pending'
export const SERVICE_REJECT = 'reject'

export const FORM_KEY_CUSTOMER = 'customer'
export const FORM_KEY_PROVIDER = 'provider'
export const FORM_KEY_PROVIDER_USER_NAME = 'providerUsername'
export const FORM_KEY_SUPPLIER = 'supplier'
export const SHIPMENT_TRANSACTIONS_REQUEST = 'SHIPMENT_TRANSACTIONS_REQUEST'
export const SHIPMENT_TRANSACTIONS_SUCCESS = 'SHIPMENT_TRANSACTIONS_SUCCESS'
export const SHIPMENT_TRANSACTIONS_FAIL = 'SHIPMENT_TRANSACTIONS_FAIL'
export const NO_BREAD_CRUMB = 'NO_BREAD_CRUMB'
export const SHIPMENT_STATUS_UPDATE = 'SHIPMENT_STATUS_UPDATE'
export const SHIPMENT_PACKAGE_UPDATE = 'SHIPMENT_PACKAGE_UPDATE'
export const EMPTY_INFO = '---'
export const DATA_TABLE_LIST = 'DATA_TABLE_LIST'
export const DATA_TABLE_LAYOUT = 'DATA_TABLE_LAYOUT'
export const SHIPMENT_PRODUCT_CREATE = 'SHIPMENT_PRODUCT_CREATE'
export const SHIPMENT_PRODUCT_UPDATE = 'SHIPMENT_PRODUCT_UPDATE'
export const SHIPMENT_DECLARE_VALUE_UPDATE = 'SHIPMENT_DECLARE_VALUE_UPDATE'
export const SHIPMENT_WAYBILL_CREATE = 'SHIPMENT_WAYBILL_CREATE'
export const SHIPMENT_RECEIPT_DELETE = 'SHIPMENT_RECEIPT_DELETE'
export const SHIPMENT_FEE_UPDATE = 'SHIPMENT_FEE_UPDATE'
export const SHIPMENT_UPDATE = 'SHIPMENT_UPDATE'
export const SHIPMENT_DECLARE_VALUE_CREATE = 'SHIPMENT_DECLARE_VALUE_CREATE'
export const SHIPMENT_WAYBILL_DELETE = 'SHIPMENT_WAYBILL_DELETE'
export const SHIPMENT_DECLARE_VALUE_DELETE = 'SHIPMENT_DECLARE_VALUE_DELETE'
export const ACC_GSA_PROFILE = 'ACC_GSA_PROFILE'
export const ORDER_COMMENT = 'ORDER_COMMENT'
export const CLAIM_COMMENT = 'CLAIM_COMMENT'
export const SHIPMENT_COMMENT = 'SHIPMENT_COMMENT'
export const TYPE_LASTMILE ="LASTMILE"
export const TYPE_M32 ="M32"
export const REGISTER_INTERNAL_URL=process.env.REACT_APP_REGISTER_URL
