import {AxiosResponse} from 'axios'
import {API_BASE_URL, DEFAULT_PAGE_SIZE} from '../core/config'
import {httpConfig} from '../core/config/http'
import {url} from '../core/helpers/string'
import {Repository} from '../core/repositories/Repository'
import {ClaimCommentCriteriaInterface} from '../Interface/ClaimCommentCriteriaInterface'

export class ClaimRepository extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, ''))
  }
  // orderCode: string,
  getClaimDetail = (claimCode: string, providerCode: string, customerCode: string) => {
    return this.http.get(`claims/${claimCode}`, { params: { provider: providerCode, providerUsername: customerCode } })
  }

  archive = (providerCode: string, customerCode: string, ticketCode: string) => {
    return this.http.put(`claims/${ticketCode}/archive`)
  }

  rating = (ticketCode: string, data: { rating: number; comment: string }) => {
    return this.http.put(`claims/${ticketCode}/rating`, data)
  }

  getClaimsOrderAndShipment = (shipmentCode: string, filter?: any): Promise<any> => {
    return this.http.get(`/claims/shipment/${shipmentCode}`, { params: { ...filter, limit: 1000, sort: 'createdAt:desc' } }).then((response: AxiosResponse) => {
      return response
    })
  }

  getClaims = (filter: any): Promise<any> => {
    let limit = filter?.size ? filter.size : DEFAULT_PAGE_SIZE
    let page = filter?.page ? (filter.page > 0 ? filter.page - 1 : filter.page) : 0
    let offset = page * limit

    return this.http
      .get(`/claims`, { params: { ...filter, offset, limit, sort: 'createdAt:desc' } })
      .then((response: AxiosResponse) => {
        return response
      })
  }

  create = (formData:FormData) => {
    return this.http.post(`/claims`, formData)
  }

  getClaimsByOrder = (providerCode: string | undefined, accountCode: string | undefined, orderCode: string | undefined, filter: any): Promise<any> => {
    const { page, size: limit, sort, order } = filter
    const offset = limit ? (page - 1) * limit : 0
    const params = { offset, limit, sort: sort || 'createdAt', order: order || 'DESC', ticketType: 'ORDER' }
    return this.http
      .get(`/providers/${providerCode}/accounts/${accountCode}/claims?orderCode=${orderCode}`, { params })
      .then((response: AxiosResponse<any>) => {
        return response
      })
  }

  //   getMilestones = (providerCode: string, accountCode: string, orderCode: string, claimCode: string) => {
  //     return this.http
  //       .get(`/providers/${providerCode}/accounts/${accountCode}/orders/${orderCode}/claims/${claimCode}/milestones`)
  //       .then((response: AxiosResponse) => {
  //         return response.data
  //       })
  //   }
  createClaimComment = (threadReferenceCode: string | null, data: any): Promise<any> => {
    const formData = new FormData()
    if (Array.isArray(data.attachments)) {
      for (let file of data.attachments) {
        formData.append('attachments', new Blob([file]), file.name)
      }
    }
    if (data.comment) {
      formData.set('comment', new Blob([JSON.stringify(data.comment)], { type: 'application/json' }))
    }
    return this.http.post(`claims/${threadReferenceCode}/comments`, formData)
  }

  getClaimComment = (threadReferenceCode: string | null, filter: ClaimCommentCriteriaInterface): Promise<any> => {
    return this.http.get(`claims/${threadReferenceCode}/comments`, { params: { ...filter } }).then((response: AxiosResponse) => {
      return response
    })
  }

  public getClaimsV1 = (code: string, provider: string, providerUsername: string): Promise<any> => {
    return this.http.get(`/claims`, { params: { size: 1000, sort: 'createdAt:desc', provider, providerUsername } }).then((response: AxiosResponse) => {
      return response.data
    })
  }

  public getMilestones = (claimCode: string, order: string): Promise<any> => {
    return this.http
      .get(`/claims/${claimCode}/milestones`, {
        params: { offset: 0, limit: 100, order },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
  }
}

export const claimRepository: ClaimRepository = new ClaimRepository()
