export const TrackOrderIcons: any = {
  ACCEPTED: <i className="fa-regular fa-clipboard-check" />,
  CANCELLED: <i className="fa-regular fa-ban" />,
  DELIVERED: <i className="fa-regular fa-box-circle-check" />,
  DELIVERING: <i className="fa-regular fa-truck" />,
  DELIVERY_CANCELLED: <i className="fa-regular fa-user-large-slash" />,
  DEPOSITED: <i className="fa-regular fa-credit-card-front" />,
  MERCHANT_DELIVERING: <i className="fa-solid fa-moped" />,
  MIA: <i className="fa-solid fa-ufo-beam" />,
  OUT_OF_STOCK: <i className="fa-regular fa-dolly-empty" />,
  PENDING: <i className="fa-regular fa-timer" />,
  PROCESSING: <i className="fa-regular fa-user-clock" />,
  PURCHASED: <i className="fa-regular fa-bag-shopping" />,
  PUTAWAY: <i className="fa-regular fa-cart-flatbed-boxes" />,
  READY_FOR_DELIVERY: <i className="fa-regular fa-truck-clock" />,
  TRANSPORTING: <i className="fa-regular fa-earth-asia" />,
}

export const StatusClaimIcons: any = {
  PROCESSING: <i className="fa-regular fa-user-clock" />,
  NEW: <i className="fa-regular fa-hourglass" />,
  REJECT: <i className="fa-regular txt-color-red2 fa-user-clock" />,
  REFUND: <i className="fa-solid fa-envelope-open-dollar" />,
  ACCEPTED: <i className="fa-regular txt-color-red2 fa-user-clock" />,
}
