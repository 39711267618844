import { Alert, Spin } from 'antd'
import { useContext } from 'react'
import { MessageList } from './MessageList'
import { MessageInputBox } from './MessageInputBox'
import { useTranslation } from 'react-i18next'
import { BoxChatContext } from '../../context/BoxChatContext'

export const Message = () => {
  const { t } = useTranslation()
  let { messageLoading } = useContext(BoxChatContext)

  return (
    <Spin
      className={'box-chat-conversions-loading'}
      spinning={messageLoading}>
      <div className="box-chat-conversations">
        <div className={'box-chat-list-container'}>
          <Alert
            description={t('chatBox.alertMessageFixed').replace('%s', '4-6h')}
            type="warning"
            showIcon
            closable={false}
            className="mg-12 alert-small"
            icon={<i className="fa-sharp fa-solid fa-circle-exclamation" />}
            closeIcon={<i className="far fa-times" />}
          />
          <MessageList />
          <MessageInputBox />
        </div>
      </div>
    </Spin>
  )
}
