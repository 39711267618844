import lodash from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import useMarketplaces from 'src/hooks/fetchers/categories/useMarketplaces'
import useShipmentStatus from 'src/hooks/fetchers/categories/useShipmentStatus'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../Interface/ShipmentInterface'
import MainLayout from '../../components/Layout'
import { shipmentRepository } from '../../repositories/ShipmentRepository'
import M24Notification from '../../utils/M24Notification'
import { getCleanFormFilter } from '../../utils/StringUtils'
import ShipmentItems from './Data'
import Filter from './Filter'
import './shipments.style.scss'

const Shipments = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<ShipmentsFilterInterface | {}>({})
  const [shipments, setShipments] = useState<ShipmentInterface[]>([])
  const [pagination, setPagination] = useState<any>()

  const [showProgressBar, setShowProgressBar] = useState(true)
  const filterRef = useRef<HTMLDivElement | null>(null)
  const [isSkeleton, setIsSkeleton] = useState(true)

  const {statuses} = useShipmentStatus();
  const {marketplaces} = useMarketplaces();

  const getShipments = async (filter?: any) => {
    setShowProgressBar(true)
    delete filter.page
    delete filter.size
    try {
      const response = await shipmentRepository.getShipments(filter)
      console.log('response', response)
      setShipments(response.data)
      setPagination({
        page: +lodash.get(response, 'headers.x-page-number') + 1,
        size: +lodash.get(response, 'headers.x-page-size'),
        total: +lodash.get(response, 'headers.x-total-count'),
      })
      setShowProgressBar(false)
      setTimeout(() => setIsSkeleton(false), 1000)
    } catch (err: any) {
      M24Notification.messageError(t(err), '', 3)
      M24Notification.notifyErrorAllOptions({
        description: t('shipment.errorMessage'),
      })
      setShowProgressBar(false)
      setTimeout(() => setIsSkeleton(false), 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    const temp: any = {}
    searchParams.forEach((value, key) => {
      temp[key] = value
    })

    temp['limit'] = temp.size ? temp.size : 25
    temp['offset'] = temp.page ? (+temp.page - 1)*temp.size : 0

    setFilter({
      ...temp
    })
    getShipments(temp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const filterChangeHandler = (obj: any) => {
    setFilter((prevState: any) => ({ ...prevState, ...obj }))
  }

  const handleFilter = (params: any) => {
    setFilter({ ...params })
    setSearchParams(
      getCleanFormFilter({
        ...params,
      })
    )
  }

  return (
    <MainLayout
      title={t('menu.shipmentsTitle')}
      showProgressBar={showProgressBar}
      breadcrumbs={{ total: pagination?.total }}>
      <div ref={filterRef}>
        <Filter
          filter={filter}
          filterChangeHandler={filterChangeHandler}
          handleFilter={handleFilter}
          statuses={statuses}
        />
      </div>

      <ShipmentItems
        isSkeleton={isSkeleton}
        data={shipments}
        getData={getShipments}
        statuses={statuses}
        loading={showProgressBar}
        filter={filter}
        handleFilter={handleFilter}
        marketPalaces={marketplaces}
        pagination={pagination}
      />
    </MainLayout>
  )
}

export default Shipments
