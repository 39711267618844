import { Avatar, Button, Col, DatePicker, DatePickerProps, Form, Input, message, Row, Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PROFILE_KEY } from '../../core/config'
import { UserProfileInterface } from '../../Interface/UserProfileInterface'
import { userRepository } from '../../repositories/UserRepository'
import { localStorageSave } from '../../utils/LocalStorageUtils'
import M24Notification from '../../utils/M24Notification'
import { categoryRepository } from '../../repositories/CategoryRepository'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import { useTheme } from '../../hooks/useTheme'
import ComboBox from '../ComboBox'
import lodash from 'lodash'
import { AppContext } from '../../context/AppContext'

function UserInformation() {
  const { t } = useTranslation()
  const [userInfo] = Form.useForm()
  const [disabledSave, setDisabledSave] = React.useState<boolean>(true)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [image, setImage] = React.useState<string | null>('')
  const [refCode, setRefCode] = React.useState<string>('')
  const [isConfirm, setConfirm] = useState<boolean>(false)
  const [theme] = useTheme()
  const [currentUser, setCurrentUser] = useState<UserProfileInterface | any>()
  const [loadingAvt, setLoadingAvt] = useState(false)
  const appContextValues = useContext(AppContext)

  React.useEffect(() => {
    // setIsLoading(true)
    userRepository
      .getProfile()
      .then((response: UserProfileInterface) => {
        localStorageSave(PROFILE_KEY, response)
        setImage(response.avatar)
        setRefCode(response.refCode)
        let data = {
          email: response.email,
          username: response.username,
          name: response.name,
          dob: response.dob ? moment(response.dob) : '',
          mobile: response.mobile,
          avatar: response.avatar,
          refCode: response.refCode,
        }
        userInfo.setFieldsValue(data)
        setCurrentUser(data)
      })
      .catch((err) => {
        // M24Notification.messageError(t('message.try_again'))
      })
      .finally(() => setIsLoading(false))
    return () => {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFormChange = () => {
    // console.log(userInfo.getFieldsValue(true))
    const hasErrors = userInfo.getFieldsError().some(({ errors }) => errors.length)
    setDisabledSave(hasErrors)
  }

  const handleSave = async () => {
    userInfo.validateFields().then((values) => {
      if (values.refCode !== null && values.refCode !== '') {
        // console.log('values.refCode', values.refCode)
        // console.log('refCOde', refCode)

        categoryRepository
          .getProviderByRefCode(values.refCode.trim())
          .then((response) => {
            let nameProvider = response.data.name
            if (response.status === 200) {
              userRepository
                .saveProfile({
                  ...values,
                  avatar: image,
                  name: values.name.trim(),
                  refCode: values.refCode.trim(),
                })
                .then((response) => {
                  setCurrentUser(response.data)
                  localStorageSave(PROFILE_KEY, response.data)
                  setRefCode(response.data.refCode)
                  M24Notification.messageSuccess(t('profile.success-message'))
                  appContextValues.fnc.getUserProfile(response.data)

                  if (values.refCode !== refCode) {
                    M24Notification.notifySuccess(
                      'Thành công',
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('profile.refCodeSuccess', {
                            refCode: response.data.refCode,
                            name: nameProvider,
                          }),
                        }}></span>,
                      '',
                      3
                    )
                  }
                })
                .catch(() => {
                  M24Notification.messageError(t('profile.error-message'))
                })
                .finally(() => {
                  setIsLoading(false)
                  setDisabledSave(true)
                })
            }
          })
          .catch((err) => {
            if (err.response.status && values.refCode !== refCode) {
              setConfirm(true)
            } else {
              userInfo
                .validateFields()
                .then((values) => {
                  userRepository
                    .saveProfile({
                      ...values,
                      avatar: image,
                      name: values.name.trim(),
                    })
                    .then((response) => {
                      localStorageSave(PROFILE_KEY, response.data)
                      setRefCode(response.data.refCode)
                      appContextValues.fnc.getUserProfile(response.data)
                      // window.location.reload()
                    })
                })
                .then(() => {
                  M24Notification.messageSuccess(t('profile.success-message'))
                })
                .catch((err) => {
                  M24Notification.messageError(t('profile.error-message'))
                })
                .finally(() => {
                  setIsLoading(false)
                  setDisabledSave(true)
                })
            }
          })
      } else {
        userInfo
          .validateFields()
          .then((values) => {
            userRepository
              .saveProfile({
                ...values,
                avatar: image,
                name: values.name.trim(),
              })
              .then((response) => {
                localStorageSave(PROFILE_KEY, response.data)
                setRefCode(response.data.refCode)
                // window.location.reload()
                appContextValues.fnc.getUserProfile(response.data)
              })
          })
          .then(() => {
            M24Notification.messageSuccess(t('profile.success-message'))
          })
          .catch((err) => {
            M24Notification.messageError(t('profile.error-message'))
          })
          .finally(() => {
            setIsLoading(false)
            setDisabledSave(true)
          })
      }
    })
  }

  const handleAddRefCode = () => {
    userInfo
      .validateFields()
      .then((values) => {
        userRepository
          .saveProfile({
            ...values,
            avatar: image,
            name: values.name.trim(),
            refCode: values.refCode.trim(),
          })
          .then((response) => {
            localStorageSave(PROFILE_KEY, response.data)
            setRefCode(response.data.refCode)
          })
          .then(() => {
            M24Notification.messageSuccess(t('profile.success-message'))
            setConfirm(false)
          })
          .catch(() => {
            M24Notification.messageError(t('profile.error-message'))
          })
          .finally(() => {
            setIsLoading(false)
            setDisabledSave(true)
          })
      })
      .catch(() => {
        // console.log('err')
      })
  }

  const handleCancel = () => {
    setConfirm(false)
    // userInfo.resetFields(['refCode'])
    // userInfo.setFieldsValue({
    //     ...userInfo,
    //     refCode: '',
    // })
  }

  const beforeUpload = (file: RcFile) => {
    if (!file.type.startsWith('image')) {
      M24Notification.messageError(t('profile.upload-avatar-error'))
      return false
    }
    // setIsLoading(true);
    setLoadingAvt(true)
    userRepository
      .uploadAvatar(file)
      .then((response) => {
        setImage(response.data)
        setDisabledSave(false)
        userInfo.validateFields().then((values) => {
          userRepository
            .saveProfile({
              ...values,
              avatar: response.data,
            })
            .then((res) => {
              localStorageSave(PROFILE_KEY, res.data)
              setCurrentUser(res.data)
              appContextValues.fnc.getUserProfile(res.data)
              message.success('Thay đổi ảnh đại diện thành công!').then((r) => {})
              // window.location.reload()
            })
        })
      })
      .catch((error) => {
        if (error.response.data.title === 'file_not_image_file') {
          M24Notification.notifyError('Thất bại', t('productForSell.file-upload-wrong-type'))
        } else {
          M24Notification.notifyError('Thất bại', t('profile.error_max_upload_file_size'))
        }
      })
      .finally(() => {
        // setIsLoading(false);
        setLoadingAvt(false)
      })
    return false
  }

  const onPhoneChange = (e: any) => {
    let raw = typeof e === 'string' ? e : ''
    raw = raw.replace(/[^\d,]/g, '')
    setCurrentUser({
      ...currentUser,
      mobile: raw,
    })
  }

  const handleChangeValueInput = (key: string, e: any) => {
    let value: string = e.target.value
    if (key === 'refCode' && value.includes(' ')) {
      return
    }
    setCurrentUser({
      ...currentUser,
      [key]: value,
    })
  }

  const onChangeDatePicker: DatePickerProps['onChange'] = (date, dateString) => {
    setCurrentUser({
      ...currentUser,
      dob: moment(date).toISOString(),
    })
  }

  useEffect(() => {
    let currentFormValues = userInfo.getFieldsValue(true)
    if (!lodash.isEqual(currentFormValues, currentUser)) {
      userInfo.setFieldsValue(currentUser)
      setDisabledSave(false)
    } else {
      setDisabledSave(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <div>
        <div className={'profile-title'}>
          <span className={'txt-size-h3  robotomedium'}>{t('profile.user-info')}</span>
        </div>
        <div className={'py-16'}>
          <Spin spinning={isLoading}>
            <Form
              className={`user-info ${theme}`}
              form={userInfo}
              // labelCol={{span: 4}}
              // wrapperCol={{span: 14}}
              onFieldsChange={handleFormChange}>
              <Row gutter={12}>
                <Col lg={16}>
                  <div className={'width100pc'}>
                    <Form.Item
                      name="email"
                      labelCol={{ span: 0 }}>
                      <ComboBox
                        value={currentUser?.email}
                        className={`disabled-input ${theme} mg-bt-8`}
                        // className={`mg-bt${isSubmitted && !valueOfProduct ? '-4' : '-18'}`}
                        label={<span className={''}> {t('profile.email')}</span>}>
                        <Input
                          value={currentUser?.email}
                          placeholder=""
                          size="large"
                          disabled
                        />
                      </ComboBox>
                    </Form.Item>
                    <Form.Item
                      name="username"
                      labelCol={{ span: 0 }}>
                      <ComboBox
                        value={currentUser?.username}
                        className={`disabled-input ${theme} mg-bt-8`}
                        label={<span className=""> {t('profile.username')}</span>}>
                        <Input
                          value={currentUser?.username}
                          placeholder=""
                          size="large"
                          disabled
                        />
                      </ComboBox>
                    </Form.Item>
                    <Form.Item
                      name="name"
                      rules={[
                        // eslint-disable-next-line no-template-curly-in-string
                        {
                          required: true,
                          message: t('profile.require-message', { value: t('profile.fullName') }),
                        },
                        {
                          //    pattern: new RegExp('^\\S+[a-zA-Z0-9-_\\\\s]'),
                          message: 'Họ tên không được để trống',
                        },
                      ]}>
                      <ComboBox
                        required
                        value={currentUser?.name}
                        className={`mg-bt-8`}
                        label={<span className=""> {t('profile.fullName')}</span>}>
                        <Input
                          value={currentUser?.name}
                          placeholder=""
                          size="large"
                          onPressEnter={handleSave}
                          maxLength={25}
                          onChange={(e) => handleChangeValueInput('name', e)}
                        />
                      </ComboBox>
                    </Form.Item>

                    <Form.Item name="dob">
                      <ComboBox
                        value={currentUser?.dob}
                        className={`float-date-picker mg-bt-8`}
                        label={<span className="">{t('profile.dob')}</span>}
                        // isRange
                      >
                        <DatePicker
                          value={currentUser?.dob ? moment(currentUser?.dob) : undefined}
                          placeholder={''}
                          size="large"
                          className={'width100pc'}
                          format={'DD/MM/YYYY'}
                          allowClear={false}
                          disabledDate={(current) => {
                            let customDate = moment().format('YYYY-MM-DD')
                            return current && current > moment(customDate, 'YYYY-MM-DD')
                          }}
                          onChange={onChangeDatePicker}
                          suffixIcon={
                            <i
                              className="far fa-calendar-alt txt-color-gray4"
                              style={{ fontSize: 14 }}
                            />
                          }
                        />
                      </ComboBox>
                      {/*<Button onClick={removeDob}>Remove DOB</Button>*/}
                    </Form.Item>

                    <Form.Item name="mobile">
                      <ComboBox
                        value={currentUser?.mobile}
                        className={`mg-bt-8`}
                        label={<span className="">{t('profile.mobile')}</span>}>
                        <Input
                          value={currentUser?.mobile}
                          placeholder=""
                          size="large"
                          maxLength={25}
                          onPressEnter={handleSave}
                          onChange={(e) => onPhoneChange(e.target.value)}
                        />
                      </ComboBox>
                    </Form.Item>

                    <Form.Item name="refCode">
                      {/*<Input*/}
                      {/*	onPressEnter={handleSave}*/}
                      {/*	disabled={refCode === null || refCode === '' ? false : true}*/}
                      {/*/>*/}
                      <ComboBox
                        value={currentUser?.refCode}
                        className={`mg-bt-8 disabled-input ${theme}`}
                        label={<span className=""> {t('profile.refCode')}</span>}>
                        <Input
                          value={currentUser?.refCode}
                          placeholder=""
                          size="large"
                          maxLength={25}
                          onPressEnter={handleSave}
                          disabled={!!refCode}
                          onChange={(e) => handleChangeValueInput('refCode', e)}
                        />
                      </ComboBox>
                    </Form.Item>
                  </div>
                  <div
                    className={'flex justify-content-flex-end'}
                    style={{ marginTop: '-12px' }}>
                    <Form.Item>
                      <Button
                        className={`m22-btn  ${theme} flr`}
                        type="primary"
                        disabled={disabledSave}
                        onClick={handleSave}>
                        {t('profile.save-info')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    // label={<span className={'pdt35 m22-typo-label'}>{t('profile.avatar')}</span>}
                    // name="avatar"
                    colon={false}>
                    <div className={'flex flex-col align-items-center'}>
                      <Spin spinning={loadingAvt}>
                        <Avatar
                          size={100}
                          src={image}
                          className={'bd-none-impt cursor-pointer'}
                        />
                      </Spin>
                      <Upload
                        className={'mg-t-24 txt-size-h7 cursor-pointer upload-btn'}
                        accept={'image/png, image/gif, image/jpeg, image/jpg'}
                        showUploadList={false}
                        beforeUpload={beforeUpload}>
                        <Button className={`m22-btn m22-btn-outline ${theme}`}>{t('profile.change-avatar')}</Button>
                      </Upload>
                      <div className={'txt-secondary mg-t-12 txt-center'}>Dụng lượng file tối đa 10 MB; Định dạng:.JPEG, .PNG</div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <ConfirmModal
                onCancel={handleCancel}
                visible={isConfirm}
                onSubmit={handleAddRefCode}
                loading={isLoading}
                content={t('profile.refCodeConfirm')}
              />
            </Form>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default UserInformation
