import { Repository } from '../core/repositories/Repository'
import { AxiosResponse } from 'axios'
import { url } from '../core/helpers/string'
import { httpConfig } from '../core/config/http'
import { API_BASE_URL, DEFAULT_PAGE_SIZE } from '../core/config'

class PackagesRepository extends Repository {
  constructor() {
    super(httpConfig)
    this.setBaseURL(url(API_BASE_URL, ''))
  }

  public getPackages = (filter: any, signal: AbortSignal): Promise<any> => {
    let page = filter?.page || 0
    let size = filter?.size || DEFAULT_PAGE_SIZE
    return this.http.get(`/packages`, { params: { ...filter, page, size, sort: 'createdAt:desc' }, signal: signal }).then((response: AxiosResponse<any>) => {
      return response
    })
  }

  public getPackageHistory = (packageCode: string, customer: string | null, provider: string | null): Promise<any> => {
    return this.http.get(`packages/${packageCode}/milestones`, { params: { customer, provider } }).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }
  public getPackageHistoryOfShipment = (packageCode: string, customer: string | null, provider: string | null): Promise<any> => {
    return this.http.get(`shipments/packages/${packageCode}/milestones`, { params: { customer, provider } }).then((response: AxiosResponse<any>) => {
      return response.data
    })
  }
  public getMidmileList = (provider: string) => {
    return this.http.get(`/midmile?offset=0&limit=1000&provider=${provider}`)
  }
}
export const packageRepository: PackagesRepository = new PackagesRepository()
