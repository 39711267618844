import {Button, Image} from 'antd'
import {AxiosError} from 'axios'
import lodash from 'lodash'
import {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {ClaimDetailContext} from '../../context/ClaimDetailContext'
import {formatMoneyByUnit} from '../../core/helpers/string'
import {ConnectionInterface} from '../../Interface/ConnectionInterface'
import {claimRepository} from '../../repositories/ClaimRepository'
import emptyImage from '../../resources/images/claim-thumb.png'
import {Feedback} from './Feedback'
import {useTheme} from '../../hooks/useTheme'
import IconFile from '../../resources/images/icon/file-icon.png'
import M24Notification from 'src/utils/M24Notification'

type InfoProps = {
  connections: Array<ConnectionInterface>
}

export const Info = (props: InfoProps) => {
  const { claim, provider, providerUsername } = useContext(ClaimDetailContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const isShowBtnArchive = ['REJECT', 'REFUND'].indexOf(claim.publicStateNewView.code) >= 0 && !claim.archived

  const [theme] = useTheme()

  const handleArchive = () => {

    claimRepository
      .archive(provider.code!, providerUsername!, claim.code)
      .then(() => {
        M24Notification.success({
          message: t('tickets.archive_success'),
        })
      })
      .catch((error: AxiosError) => {
        if (lodash.get(error.response, 'status') === 400) {
          M24Notification.error({
            message: t(`tickets.archive_error_${lodash.get(error.response, 'data.title')}`),
          })
        } else {
          M24Notification.error({
            message: t('tickets.archive_failed'),
          })
        }
      })
      .finally(() => {
        window.location.reload()
        setLoading(false)
      })
  }
  console.log(parseInt(claim?.notReceived) === 0 ? t('tickets.missing_quantity_all') : claim?.notReceived);
  
  return (
    <div className={`ticket-infos-box ${theme === 'dark' ? '' : 'box-shadow'} `}>
      <div className={`m22-box-${theme} px-24 py-16 rad-6 mg-bt-0 `}>
        <div className={'mgbt20 dpl-flex claim-name-heading'}>
          <span className={'claim-name-status'}>
            <div className="mg-bt-12">
              <span className={`${theme === 'dark' ? 'm22-typo-label_gray-primary' : 'txt-color-gray8'} fsz-12 robotomedium txt-capitalize`}>
                {t('tickets.ticket-code')}
              </span>
            </div>
            <span className="dpl-flex">
              <span className={`fsz-20 robotobold ${theme === 'dark' ? 'txt-color-primary' : 'txt-color-red2'}`}>#{lodash.get(claim, 'code')}</span>
              <span
                className={'mg-l-24 fsz-14 border-radius25 txt-color-white align-self-center align-items-center px-12 py-2'}
                style={{ backgroundColor: lodash.get(claim, 'publicStateNewView.color') }}>
                {lodash.get(claim, 'publicStateNewView.name')}
                {claim.archived && ` (${t('tickets.archived')})`}
              </span>
            </span>
          </span>
          <span className="dpl-flex">
            {isShowBtnArchive && (
              <Button
                loading={loading}
                disabled={loading}
                onClick={() => handleArchive()}
                className={`m22-btn m22-btn-rounded__red ${theme}`}>
                {t('tickets.close')}
              </Button>
            )}
          </span>
        </div>

        <div className={'ticket-infos'}>
          <div className={'info-group'}>
            <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.order')}</div>

            <div className={'dpl-flex align-items-stretch claim-detail-order-info-box'}>
              <div className="order-image-box">
                <Image
                  width={70}
                  height={70}
                  className={'order-image'}
                  src={lodash.get(claim, 'thumbnail') || 'error'}
                  alt={''}
                  fallback={emptyImage}
                />
              </div>
              <div className={'align-self-center'}>
                <div className="claim-detail-order-info-box claim-detail-order-info-box__codes">
                  <Link
                    className="mg-bt-4"
                    target={'_blank'}
                    to={`/${claim?.ticketType === 'order' ? 'orders' : 'shipments'}/${claim.relatedOrder}`}>
                    <span className={`robotomedium fsz-16 ${theme === 'dark' ? 'txt-color-E6E6E6 ' : 'txt-color-gray8'}`}>
                      #{lodash.get(claim, 'relatedOrder')}
                    </span>
                  </Link>
                  {claim.relatedProduct && (
                    <span className={'fsz-12 mg-t-4'}>
                      <span className={'mgr5'}>{t('ticket-create.default_name_with_product')}</span>
                      {lodash.get(claim, 'relatedProduct')}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex mg-t-20">
              <div className={'info-group align-self-start'}>
                <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.reason')}: </div>
                <div className={'info-group-value fsz-12'}>
                  <div className={'robotomedium '}>{lodash.get(claim, 'reasonView.name')}</div>
                </div>
              </div>
              {claim?.reasonView?.code === 'not_received' || claim?.reasonView?.code === 'shipment_not_received' ? (
                <div className={'info-group align-self-start'}>
                  <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.missing_quantity')}: </div>
                  <div className={'info-group-value fsz-14 robotomedium '}>
                    {parseInt(claim?.notReceived) === 0 ? t('tickets.missing_quantity_all') : claim?.notReceived}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className={'info-group align-self-start'}>
                <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.resolve-methods')}: </div>
                <div className={'info-group-value fsz-14 robotomedium '}>{lodash.get(claim, 'solutionView.name')}</div>
              </div>
              <div className={'info-group align-self-start'}>
                <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.moneyOffer')}: </div>
                <div className={'fsz-14 robotobold '}>
                  {lodash.get(claim, 'suggest') ? <span className="">{formatMoneyByUnit(claim.suggest)}</span> : '---'}
                </div>
              </div>
              <div className={'info-group align-self-start'}>
                <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.refund_amount')}: </div>
                <div className={'info-group-value fsz-14 robotomedium '}>
                  {lodash.get(claim, 'publicStateNewView.code') === 'REFUND' ? (
                    <span>
                      {lodash.get(claim, 'totalRefund') ? (
                        <span className="robotobold m22-typo-money-plus">{formatMoneyByUnit(claim.totalRefund)}</span>
                      ) : (
                        '' || '---'
                      )}
                    </span>
                  ) : (
                    '---'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ticket-description">
          <div className={`mg-bt-16 	${theme}`}>
            <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12`}>{t('tickets.description')}: </div>
            <div className={''}>{lodash.get(claim, 'description') ? lodash.get(claim, 'description') : '---'}</div>
          </div>
          <div className={`${theme === 'dark' ? 'm22-typo-label_gray' : 'txt-color-gray8'} robotomedium fsz-12 mg-bt-12 `}>
            {t('tickets.attachment_image')}:
          </div>
          <div className={`claim-attachment-list ${claim.attachments.length <= 0 ? 'mg-bt-60' : ''}`}>
            <Image.PreviewGroup>
              {Array.isArray(claim.attachments) &&
                claim.attachments.map((item, idx) => {
                  if (item.type.toString().startsWith('image')) {
                    return (
                      <div className={'mgr8 mgbt8'}>
                        <Image
                          key={idx}
                          width={60}
                          height={60}
                          fallback={emptyImage}
                          className={'image-attachment attachment-item mgr8 mgbt8'}
                          src={item.presignedUrl || 'error'}
                          alt={claim.thumbnail}
                          preview={{
                            // visible: false,
                            mask: (
                              <div className={'image-attachment-mask'}>
                                <i className="fa-regular fa-eye" />
                              </div>
                            ),
                          }}
                          // onClick={() => setPreviewVisible(true)}
                        />
                      </div>
                    )
                  }
                  else {
                    return (
                      <a style={{width: 60,height: 60, display:'flex',alignItems:'center',justifyContent:'center',background:'white'}}
                        className={'attachment-item attachment-item--file mgr8 mgbt8'}
                        href={item.presignedUrl}
                        target={'_blank'}
                        rel="noreferrer"
                        title={item.name}>
                        <img src={IconFile} alt={item.name} style={{width: 60,height: 60}}/>
                        {/*<i className="fa-regular fa-file-arrow-down" /> {item.name}*/}
                      </a>
                    )
                  }
                })}
            </Image.PreviewGroup>
          </div>
        </div>
        <Feedback />
      </div>
    </div>
    // <></>
  )
}
