import { Card, Row, Table, Tooltip, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { IBodySaveService, WarehouseInterface, WarehouseRouteInterface } from 'src/Interface/WarehoustListInterface'
import EmptyDataFilter from 'src/components/Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from 'src/components/SkeletonTable'
import { PROFILE_KEY } from 'src/core/config'
import { useTheme } from 'src/hooks/useTheme'
import { shipmentCategoriesRepository } from 'src/repositories/ShipmentCategoriesRepository'
import { localStorageRead } from 'src/utils/LocalStorageUtils'
import lodash from 'lodash'

interface WarehouseListItemProps {
  wareHouse: WarehouseInterface
  loading: boolean
  expandedDefault: boolean
  configSaved: any
}

const WarehouseListItem: React.FC<WarehouseListItemProps> = (props) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  const { wareHouse, loading, expandedDefault = false, configSaved } = props
  const [expandWarehouseRoute, setExpandWarehouseRoute] = useState(expandedDefault)
  const [warehouseRoute, setWareHouseRoute] = useState<WarehouseRouteInterface[]>([])
  const [loadingTable, setLoadingTable] = useState(false)

  const serviceString = useMemo(() => {
    const groups: any[] = configSaved.service?.groups || []
    const service: IBodySaveService['service'] = configSaved.service || {}
    let serviceString = ''
    if (service['transport-shipment-type']?.codeDisplay) {
      serviceString += service['transport-shipment-type']?.codeDisplay
    }

    groups.forEach((s) => {
      serviceString += s.codeDisplay
    })

    return serviceString
  }, [configSaved])

  const getDataWarehouseRoute = useCallback(() => {
    setLoadingTable(true)
    shipmentCategoriesRepository
      .getRouteWarehouses(wareHouse.code)
      .then((res) => setWareHouseRoute(res.data))
      .catch(() => {})
      .finally(() => setLoadingTable(false))
  }, [wareHouse.code])

  useEffect(() => {
    if (!expandWarehouseRoute) {
      return
    }
    getDataWarehouseRoute()
  }, [expandWarehouseRoute, getDataWarehouseRoute])

  const columns = [
    {
      title: t('warehouse.route'),
      dataIndex: 'routerName',
      key: 'routerName',
      width: '10%',
      render: (routerName: any) => {
        return (
          <span className={'txt-size-h7 robotomedium fsz-14'}>
            {wareHouse.name} - {routerName}
          </span>
        )
      },
    },
    {
      title: t('warehouse.name'),
      dataIndex: 'receiver',
      key: 'receiver',
      width: '14%',
      render: (receiver: any) => (
        <Typography.Paragraph
          className="warehouselist"
          copyable={{
            text: receiver,
            icon: [
              <Tooltip
                title={'Copy'}
                key={'copy'}>
                <i className={`fa-light fa-copy line-h-14 w-12 mg-l-6 txt-color-${theme === 'light' ? 'gray8' : 'gray2'}`}></i>
              </Tooltip>,
              <Tooltip
                title={'Copied'}
                key={'copied'}>
                <i className={`fa-light fa-check fsz-12 line-h-14 mg-l-6 w-12 txt-color-${theme === 'light' ? 'gray' : 'primary'}`}></i>
              </Tooltip>,
            ],
            tooltips: [false, false],
          }}>
          <span className={'txt-size-h7 whitespace-normal  '}>{receiver}</span>
        </Typography.Paragraph>
      ),
    },
    {
      title: t('warehouse.phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: '10%',
      render: (phone: any) => (
        <Typography.Paragraph
          className="warehouselist"
          copyable={{
            text: phone,
            icon: [
              <Tooltip
                title={'Copy'}
                key={'copy'}>
                <i className={`fa-light fa-copy line-h-14 w-12 mg-l-6 txt-color-${theme === 'light' ? 'gray8' : 'gray2'}`}></i>
              </Tooltip>,
              <Tooltip
                title={'Copied'}
                key={'copied'}>
                <i className={`fa-light fa-check fsz-12 line-h-14 mg-l-6 w-12 txt-color-${theme === 'light' ? 'gray' : 'primary'}`}></i>
              </Tooltip>,
            ],
            tooltips: [false, false],
          }}>
          <span className={'txt-size-h7 whitespace-normal  '}>{phone}</span>
        </Typography.Paragraph>
      ),
    },
    {
      title: t('warehouse.address'),
      dataIndex: 'address',
      key: 'address',
      width: '25%',
      render: (address: any) => {
        const username = lodash.get(localStorageRead(PROFILE_KEY), 'username')
        return (
          <Typography.Paragraph
            className="warehouselist"
            copyable={
              !!address && {
                text: address?.replace('{username}', username) + (address && serviceString && `:${serviceString}`),
                icon: [
                  <Tooltip
                    title={'Copy'}
                    key={'copy'}>
                    <i className={`fa-light fa-copy line-h-14 w-12 mg-l-6 txt-color-${theme === 'light' ? 'gray8' : 'gray2'}`}></i>
                  </Tooltip>,
                  <Tooltip
                    title={'Copied'}
                    key={'copied'}>
                    <i className={`fa-light fa-check fsz-12 line-h-14 w-12 mg-l-6 txt-color-${theme === 'light' ? 'gray' : 'primary'}`}></i>
                  </Tooltip>,
                ],
                tooltips: [false, false],
              }
            }>
            <span className={'txt-size-h7 whitespace-normal'}>
              {address?.replace('{username}', username)}
              {address && serviceString && `:${serviceString}`}
            </span>
          </Typography.Paragraph>
        )
      },
    },
  ]

  return (
    <Card
      style={{ border: 'none', borderRadius: '6px' }}
      bodyStyle={{ padding: '0px 24px', paddingBottom: `${expandWarehouseRoute ? '16px' : '0px'}` }}
      headStyle={{ alignItems: 'center', padding: '0px 24px', paddingBottom: '0px', borderBottom: 'none' }}
      className={`m22-box-${theme} border-card-header rad-6 mg-bt-16`}
      title={
        <>
          <Row
            className={'justify-content-between  align-items-center hover:cursor-pointer'}
            onClick={() => {
              setExpandWarehouseRoute(!expandWarehouseRoute)
            }}>
            <Row
              className={`mgbt16mb ${theme}`}
              align="middle">
              {!loading ? (
                <span className={`flex align-items-center ${theme} mg-l-4 `}>
                  <i
                    className={`fa-solid fa-angle-${expandWarehouseRoute ? 'down' : 'right'} fsz-14 w-6 mg-r-12`}
                    onClick={() => setExpandWarehouseRoute(!expandWarehouseRoute)}></i>
                </span>
              ) : (
                <Skeleton
                  width={32}
                  className="w-6 mg-r-8"
                  height={20}
                />
              )}

              {!loading ? (
                <span className={`${theme} txt-size-h5 robotomedium`}>{wareHouse.name}</span>
              ) : (
                <Skeleton
                  width={70}
                  height={22}
                />
              )}
            </Row>
          </Row>
          <Row>
            {expandWarehouseRoute && (
              <span className="font-normal mg-t-8 txt-muted fsz-14">
                Hãy gửi hàng đến một trong những kho dưới đây, ngay khi nhận được hàng nhân viên SABO sẽ lên đơn hàng cho bạn
              </span>
            )}
          </Row>
        </>
      }>
      {expandWarehouseRoute && (
        <SkeletonTable
          loading={loadingTable}
          columns={columns as SkeletonTableColumnsType[]}
          className={`main-table ${theme}`}
          rowCount={warehouseRoute.length}>
          <Table
            rowKey={(record: any) => record.id}
            dataSource={warehouseRoute}
            columns={columns}
            pagination={false}
            className={`main-table m22-table ${theme} tableWareHouse`}
            locale={{
              emptyText: (
                <EmptyDataFilter
                  description={
                    <div className="flex-col dpl-flex align-items-center">
                      <span className="mg-r-6 mg-bt-16">{t('warehouse.empty')}</span>
                    </div>
                  }
                />
              ),
            }}
          />
        </SkeletonTable>
      )}
    </Card>
  )
}

export default WarehouseListItem
