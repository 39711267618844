import { Divider, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { t } from 'i18next'
import React, { useCallback, useMemo, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { UpdatableInterface } from '.'
import Services from '../../../components/Services'
import TruncateText from '../../../components/TruncateText'
import { EMPTY_INFO } from '../../../core/config'
import { formatNumber } from '../../../core/helpers/string'
import { useTheme } from '../../../hooks/useTheme'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import { useQuery } from 'react-query'

interface InformationProps {
  shipment?: ShipmentInterface
  expand: boolean
  updatable?: UpdatableInterface
  loading: boolean
  shipmentCode: string
}

const Information: React.FC<InformationProps> = (props) => {
  const { shipment, expand, loading, shipmentCode } = props
  const [theme] = useTheme()
  const refLabel = useRef<null | HTMLSpanElement>(null)

  const { data: originalReceiptData } = useQuery({ queryFn: () => shipmentRepository.getOriginalReceipts(shipmentCode), refetchOnWindowFocus: false})
  const deliveryAddress = shipment?.address?.location?.display ?? EMPTY_INFO
  const refCustomerCode = shipment?.refCustomerCode ? shipment.refCustomerCode : EMPTY_INFO
  const refShipmentCode = shipment?.refShipmentCode ? shipment?.refShipmentCode : EMPTY_INFO
  const actualWeight = shipment?.actualWeight ? `${formatNumber(shipment?.actualWeight)} kg` : EMPTY_INFO
  const volumetric = shipment?.volumetric ? `${formatNumber(shipment?.volumetric)} cm3` : EMPTY_INFO
  const employeeNote = shipment?.employeeNote ? shipment?.employeeNote : EMPTY_INFO
  const personalNote = shipment?.note ?? EMPTY_INFO

  const receivingWarehouseAddressReplace = shipment?.receivingWarehouse?.address || EMPTY_INFO

  const receiver = shipment?.address.fullname ?? EMPTY_INFO
  const phone = shipment?.address.phone ?? EMPTY_INFO
  const originalReceipts = useMemo(
    () => (originalReceiptData?.length > 0 ? originalReceiptData.map((e: { code: string }) => e?.code).join(', ') : EMPTY_INFO),
    [originalReceiptData]
  )

  const updateShipment = useCallback(async (code, id, val, key, handleState) => {
    try {
      await shipmentRepository.updateShipment(code, { [key]: val })
      handleState(val)
      M24Notification.success({ message: t(`shipmentUpdate.updateSuccess`) })
    } catch (err) {
      M24ErrorUtils.showError(t, err)
    }
  }, [])

  return (
    <div className={`basic ${expand ? '' : 'basic-collapsed'}`}>
      <span className={`m22-typo-heading ${theme}`}>{!loading ? t('shipment.deliveryAddress') : <Skeleton width={200} />}</span>
      {expand && (
        <div className="mg-t-16">
          <span className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? `${t('shipment.receiver')}:` : <Skeleton />}</span>
            <span className="basic-item__content robotomedium">{!loading ? receiver : <Skeleton width={200} />}</span>
          </span>

          <span className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? `${t('shipment.phone')}:` : <Skeleton />}</span>
            <span className="basic-item__content">{!loading ? phone : <Skeleton width={110} />}</span>
          </span>

          <span className="align-items-baseline flex fsz-14 line-h-22">
            <span className="basic-item__label">{!loading ? `${t('shipment.receiverAddress')}:` : <Skeleton />} </span>
            <span className="basic-item__content">{!loading ? deliveryAddress : <Skeleton />}</span>
          </span>

          <Divider />
          <div className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? `${t('shipment.yourCustomerCode')}:` : <Skeleton />}</span>
            <span className="basic-item__content">
              {!loading ? (
                <TruncateText
                  defaultValue={refCustomerCode}
                  editable={true}
                  keyUpdate="refCustomerCode"
                  cb={updateShipment}
                  className="basic-item__content"
                />
              ) : (
                <Skeleton width={150} />
              )}
            </span>
          </div>

          <div className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? `${t('shipment.originalId')}:` : <Skeleton />}</span>
            <span className="basic-item__content">
              {!loading ? (
                <TruncateText
                  defaultValue={originalReceipts}
                  className="basic-item__content"
                />
              ) : (
                <Skeleton width={150} />
              )}
            </span>
          </div>

          <div className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? `${t('shipment.yourOrderCode')}:` : <Skeleton />}</span>

            <span className="basic-item__content">
              {!loading ? (
                <TruncateText
                  defaultValue={refShipmentCode}
                  editable={true}
                  keyUpdate="refShipmentCode"
                  cb={updateShipment}
                  className="basic-item__content"
                />
              ) : (
                <Skeleton width={150} />
              )}
            </span>
          </div>

          <Divider />

          <div className="basic-item flex align-items-center">
            <span className="basic-item__label">
              <>{!loading ? `${t('shipment.chargedWeight')}:` : <Skeleton />}</>
            </span>
            <span className="basic-item__content">{!loading ? actualWeight : <Skeleton width={150} />}</span>
          </div>

          <div className="basic-item flex align-items-center">
            <span className="basic-item__label">{!loading ? <>{t('shipment.volumetric')}:</> : <Skeleton />}</span>
            <span className="basic-item__content">{!loading ? volumetric : <Skeleton width={150} />}</span>
          </div>

          <Divider />

          <div className="my-16">
            {!loading ? (
              <Services
                label={<>{t('shipment.services')}</>}
                data={shipment?.services}
                className="font-medium"
              />
            ) : (
              <Skeleton width={200} />
            )}
          </div>

          <Divider />

          <div className="basic-item flex align-items-center">
            <span className="basic-item__label no-space">
              <span>{!loading ? `${t('shipment.noteForOrder')}: ` : <Skeleton />}</span>
            </span>

            {!loading ? (
              <span className="dpl-flex txt-size-h7  align-items-baseline info-item width100pc">
                <TruncateText defaultValue={employeeNote} />
              </span>
            ) : (
              <Skeleton width={200} />
            )}
          </div>

          <div className="basic-item flex align-items-center">
            <span
              className="basic-item__label no-space"
              ref={refLabel}>
              {!loading ? (
                <>
                  {`${t('shipment.personalNote')}`}:
                  <Tooltip
                    title={<span className="fsz-10 line-h-20">{t('shipment.personalNote')}</span>}
                    color="#FBB739">
                    <i className="far fa-info-circle txt-color-primary txt-size-h8 cursor-pointer mg-l-3"></i>
                  </Tooltip>
                </>
              ) : (
                <Skeleton />
              )}
            </span>

            {!loading ? (
              <span
                className="dpl-flex txt-size-h7  align-items-baseline info-item width100pc"
                style={{ maxWidth: `calc(100% - ${refLabel.current?.offsetWidth}px)` }}>
                <TruncateText
                  defaultValue={personalNote}
                  editable={true}
                  cb={updateShipment}
                  keyUpdate="note"
                />
              </span>
            ) : (
              <Skeleton width={200} />
            )}
            <br />
          </div>

          <Divider />

          <div className="basic-item dpl-block">
            <div className="basic-item__label mg-bt-8">{!loading ? `${t('shipment.addressWarehouseChina')}: ` : <Skeleton />}</div>
            {!loading ? (
              <span className="txt-size-h7  align-items-baseline info-item width100pc">
                <Paragraph
                  className="mg-0    order-heading white-space-pre-wrap-impt"
                  copyable={
                    shipment?.receivingWarehouse?.detailWarehouse
                      ? {
                          text: receivingWarehouseAddressReplace,
                          icon: [
                            <Tooltip
                              key={'asas'}
                              title={<span className=" fsz-12  line-h-20">Copy</span>}
                              color="#FBB739">
                              <i className="far fa-copy line-h-14 w-12" />
                            </Tooltip>,
                            <Tooltip
                              key={'sdfdsdf'}
                              title={<span className=" fsz-12  line-h-20">Copied</span>}
                              color="#FBB739">
                              <i className="far fa-check fsz-12 line-h-14 w-12" />
                            </Tooltip>,
                          ],
                          tooltips: [false, false],
                        }
                      : false
                  }>
                  {receivingWarehouseAddressReplace}
                </Paragraph>
              </span>
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Information
