import { Input, Rate, Spin } from 'antd'
import { AxiosError } from 'axios'
import lodash from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComboBox from '../../components/ComboBox'
import { ClaimDetailContext } from '../../context/ClaimDetailContext'
import { claimRepository } from '../../repositories/ClaimRepository'
import M24Notification from 'src/utils/M24Notification'

interface RatingFormInterface {
  rating: number | undefined
  comment: string | undefined
}

export const Feedback = () => {
  const { t } = useTranslation()
  const { claim } = useContext(ClaimDetailContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [form, setForm] = useState<RatingFormInterface>({ rating: undefined, comment: undefined })

  useEffect(() => {
    setForm({
      rating: claim.rating,
      comment: claim.ratingComment,
    })
  }, [claim])

  const handleChangeRating = (rating: number) => {
    setForm({
      ...form,
      rating,
    })
  }

  const handleChangeComment = (comment: string) => {
    setForm({
      ...form,
      comment,
    })
  }

  const handleSubmitRating = () => {

    if (!form.rating) {
      M24Notification.error({
        message: t('rating.validate_rating'),
      })
      return
    }

    if (!form.comment) {
      M24Notification.error({
        message: t('rating.validate_comment'),
      })
      return
    }

    setLoading(true)
    claimRepository
      .rating(claim.code, {
        rating: form.rating,
        comment: form.comment,
      })
      .then((res) => {
        setForm({
          rating: res.data.rating,
          comment: res.data.ratingComment,
        })

        M24Notification.success({
          message: 'Đánh giá thành công',
        })

        document.body.click()
      })
      .catch((error: AxiosError) => {
        if (lodash.get(error.response, 'status') === 400) {
          M24Notification.error({
            message: t('rating.error_ticket_not_archived'),
          })
          setForm({
            rating: undefined,
            comment: undefined,
          })
        } else {
          M24Notification.error({
            message: t('rating.create_failed'),
          })
          setForm({
            rating: undefined,
            comment: undefined,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Spin spinning={loading}>
      <div className="ticket-feedback rate-box mg-t-16">
        <h4 className={'fsz-16 robotomedium'}>{t('tickets.feedback')}</h4>
        <div className={'info-group rate-star-box align-items-center'}>
          {/* <div className='info-group-label fsz-12 width182px'>{t('tickets.feedback_star')}:</div> */}
          <div className={`info-group-value`}>
            <Rate
              style={{ fontSize: 32 }}
              allowHalf={true}
              value={lodash.get(form, 'rating', 0) / 2}
              onChange={(value) => handleChangeRating(value * 2)}
              // onHoverChange={(value => handleMouseOverRating(value))}
            />
          </div>
        </div>
        <div className={'info-group rate-content-box mg-t-20'}>
          {/* <div className='info-group-label fsz-12 width182px'>{t('tickets.feedback_content')}:</div> */}
          <div className="info-group-value">
            <ComboBox
              label={'Enter để lưu đánh giá'}
              value={form.comment}
              isTextArea>
              <Input.TextArea
                disabled={loading}
                value={form.comment}
                onChange={(e) => handleChangeComment(e.target.value)}
                className={`rate-content`}
                style={{
                  pointerEvents: loading ? 'none' : 'inherit',
                }}
                onPressEnter={handleSubmitRating}
                rows={5}
                maxLength={500}
                showCount
              />
            </ComboBox>
          </div>
        </div>
      </div>
    </Spin>
  )
}
