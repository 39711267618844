import { Image } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../hooks/useTheme'
import DescriptionsInterface from '../../Interface/DescriptionsInterface'
import SaboImgInDesc from '../../resources/images/sapo-logo.png'
import M22Box from '../M22Box'

const Descriptions: React.FC<DescriptionsInterface> = ({
  title,
  descriptions,
  className,
  hasDescription,
  image = SaboImgInDesc,
  notShowAgain,
  showLater,
}) => {
  const { t } = useTranslation()
  const [theme] = useTheme()
  return hasDescription ? (
    <M22Box className="mg-bt-0">
      <div className={` rad-12 mg-bt-12 ${className ? className : ''}`}>
        <i
          className={`fa-solid fa-x flex justify-content-end cursor-pointer  fsz-12`}
          onClick={showLater}
        />
        <div className="flex justify-content-center mg-bt-16">
          <Image
            src={image}
            alt="ubo-image"
            preview={false}
            rootClassName=""
            height={100}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col mg-bt-24">
            <span className="robotomedium fsz-16 txt-center  line-h-24 mg-bt-2">{title}</span>
            <span className={`fsz-14 txt-center ${theme === 'dark' ? 'txt-color-gray2' : 'txt-color-gray7'} line-h-22`}>{descriptions}</span>
          </div>
          <div className="flex justify-content-center ">
            <span
              className={`fsz-12 line-h-20 descriptionsHide ${theme} ${theme === 'dark' ? 'txt-color-gray2' : ''} cursor-pointer whitespace mg-r-18`}
              onClick={notShowAgain}>
              {t('shoppingCart.descriptionsHide')}
            </span>
            <span
              className="fsz-12 line-h-20 txt-color-green1 cursor-pointer whitespace"
              onClick={showLater}>
              <i className="fa-solid fa-check mg-r-4"></i>
              {t('shoppingCart.descriptionsUnderstand')}
            </span>
          </div>
        </div>
      </div>
    </M22Box>
  ) : null
}

export default Descriptions
